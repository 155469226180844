<template>
    <el-row>
        <el-col :span="6">
            <img :src="imageUrl" width="130px">
        </el-col>
        <el-col :span="14">
            <span class="my-body-title" style="text-align: left;margin-top: 20px">
                {{item.title}}
            </span>
            <div style="height: 42px;margin-top: 20px">
                <span>兑换码：{{item.redeemCode}}</span>
            </div>
        </el-col>
        <el-col :span="4">
            <div style="text-align: left;margin-top: 30px">
                <span>{{item.isPosted === 1?"已发放":"未发放"}}</span>
            </div>
        </el-col>
    </el-row>
</template>

<script>
    export default {
        name: "rewardItemComponent",
        props:{
            item:Object
        },
        data(){
            return {
                imageUrl:""
            }
        },
        mounted() {
            if (this.item.prizeId === 1){
                this.imageUrl = require("@/assets/img/reward/baiduyun.png")
            }else if (this.item.prizeId === 2){
                this.imageUrl = require("@/assets/img/reward/bilibili.png")
            }else{
                this.imageUrl = require("@/assets/img/reward/mangoTV.png")
            }
        }
    }
</script>

<style scoped>

</style>