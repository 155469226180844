import axios from "../http";

function compile(data) {
    return axios({
        url:"/api/user/practice/compile",
        method:"post",
        data:data
    })
}

function getEBPFDataByUUID(uuid) {
    return axios({
        url:"/api/user/practice/getPackageJson",
        method:"get",
        params:{
            uuid:uuid
        }
    })
}
function getCompiledRecords() {
    return axios({
        url:"/api/user/practice/getCompiledRecords",
        method:"get"
    })
}
function list() {
    return axios({
        url:"/lab_online/list",
        method:"get"
    })
}

function startEBPF(data) {
    return axios({
        url:"/lab_online/start",
        method:"post",
        data:data,
        headers:{
            "Content-Type":"application/json"
        }
    })
}

function stopEBPF(id) {
    return axios({
        url:"/lab_online/stop",
        method:"post",
        data:{
            id:id
        },
        headers:{
            "Content-Type":"application/json"
        }
    })
}
function getSource(uuid) {
    return axios({
        url:"/api/user/practice/getSource",
        method:"get",
        params:{
            uuid:uuid
        }
    })
}
function delById(uuid) {
    return axios({
        url:"/api/user/practice/delById",
        method:"delete",
        params:{
            uuid:uuid
        }
    })
}
export default {compile,getEBPFDataByUUID,getCompiledRecords,list,startEBPF,stopEBPF,getSource,delById}