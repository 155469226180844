<template>
    <el-container class="my-layout-main">
        <img src="~@/assets/img/busy.png"/>
    </el-container>
</template>

<script>
    export default {
        name: "news_temp",
    }
</script>

<style scoped>

</style>