import axios from "../http";

//获取个人中心资料页面所需的info数据（经历、大学、工作、性别、地址等等）
function getPersonalDataInfo(userId){
    return axios({
        url:`/api/user/query/personalData/info`,
        method:"get",
        params:{
            userId:userId
        }
    })
}
//获取个人主页页面所需的信息（简介、地址、感兴趣）
function getPersonalInfo(userId) {
    return axios({
        url:"/api/user/query/personal/info",
        method:"get",
        params:{
            userId:userId
        }
    })
}
function  updateUserInfo( umUserInfo) {
    return axios({
        url:"/api/user/users/updateInfo",
        method:"put",
        data:umUserInfo
    })
}

function getScore(userId){
    return axios({
        url:"/api/user/prize/score",
        method:"get",
        params:{
            userId:userId
        }
    })
}

function  getUMUser() {
    return axios({
        url:"/api/user/users/getumuser",
        method:"get"
    })
}
function getUsernameByUserId(userId){
    return axios({
        url:`/api/user/users/info/${userId}`,
        method:"get"
    })
}
//获取
function getRealInfo() {
    return axios({
        url:'/api/user/query/user/real',
        method:"get"
    })
}
export default {getPersonalDataInfo,getPersonalInfo,updateUserInfo,getScore,getUMUser
    ,getUsernameByUserId,getRealInfo}