<template>
    <div>
        <el-row>
            <el-col>
                <div style="background-color: #bbdcea;width: 100%;height:150px;">
                    <img :src="this.$cookies.get('avatar')" width="150px" style="margin-left:38%;margin-top:70px;">
                </div>
                <el-form ref="ruleForm" label-width="100px" class="demo-ruleForm" style="margin-left:25%;margin-top: 30px" :model="userInfo">
                    <el-form-item>
                        <el-button type="primary" style="margin-right:30px;background-color:#2696BB;float: right;" @click="updateUserInfo" >保存资料</el-button>
                    </el-form-item>
                    <el-form-item label="企鹅昵称" style="width:350px;">
                        <el-input v-model="username" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="性别">
                        <el-radio-group  v-model="userInfo.gender">
                            <el-radio :label="1">男</el-radio>
                            <el-radio :label="2">女</el-radio>
                            <el-radio :label="0">你猜</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="生日">
                        <el-date-picker v-model="userInfo.birthday" type="date" style="width: 250px;"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="所在地区" style="width:350px;">
                        <el-input v-model="userInfo.location" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="行业">
                        <el-select v-model="userInfo.industry" placeholder="请选择行业" style="width:250px;">
                            <el-option v-for="item in options" :key="item.id"
                                       :value="item.id" :label="item.name">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="感兴趣的" style="width:600px;">
                        <el-input v-model="userInfo.interest" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="个人简介" style="width:600px;">
                        <el-input v-model="userInfo.intro" clearable></el-input>
                    </el-form-item>
                    <div v-show="show">
                        <el-form-item label="学校" style="width:350px;">
                            <el-input v-model="userInfo.university"></el-input>
                        </el-form-item>
                        <el-form-item label="经历" style="width:700px;"
                                      v-for="(item,index) in experience"
                                      v-bind:key="index">
                            <el-input v-model="item.company" placeholder="公司或组织名称" style="width: 248px;"></el-input>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <el-input v-model="item.occupation" placeholder="职位" style="width: 228px;"></el-input>
                            &nbsp;
                            <el-button @click="addExperience" v-if="index === 0"  icon="el-icon-plus" circle></el-button>
                            <el-button @click="removeExperience(index)" v-else  icon="el-icon-minus" circle></el-button>
                        </el-form-item>
                        <el-form-item label="作品" v-for="(item,index) in works" v-bind:key="index" >
                            <el-input v-model="works[index]" placeholder="gitee个人空间地址" style="width: 500px;"></el-input>&nbsp;&nbsp;
                            <el-button @click="addWork" v-if="index === 0" icon="el-icon-plus" circle></el-button>
                            <el-button @click="removeWork(index)" v-else icon="el-icon-minus" circle></el-button>
                        </el-form-item>
                    </div>
                    <el-form-item>
                        <el-button style="background-color:#a8c6d5;color:#045b83" v-on:click="show = !show">{{show?"收起":"更多资料"}}</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import saveInformation from "../../utils/personalCenter/saveInformation";
    import userInfoApi from "../../utils/login/userInfoApi";
    export default {
        name: "PersonalData",
        data() {
            return {
                userInfo:{},
                experience:[{company:"",occupation:""}],
                works:[""],
                username:'',
                show:false,
                options: [],//行业列表
            }
        },
        mounted() {
            this.getPersonalDataInfo();
            this.getUsernameByUserId();
            userInfoApi.getIndustry().then((res)=>{
                if (res.data.success){
                    this.options = res.data.data.list;
                }else{
                    this.$message("获取行业列表失败")
                }
            })
        },
        methods: {
            getPersonalDataInfo(){
                saveInformation.getPersonalDataInfo(this.$cookies.get("userId")).then((res)=>{
                    var data = res.data.data.info;
                    this.experience=data.experience != null&&data.experience != "null"?JSON.parse(data.experience):this.experience;
                    this.works = data.works!=null&&data.works!="null"?JSON.parse(data.works):this.works;
                    this.userInfo = data;
                })
            },
            getUsernameByUserId(){
                saveInformation.getUsernameByUserId(this.$cookies.get("userId")).then((res)=>{
                    this.username=res.data.data.username
                })
            },
            updateUserInfo() {
                var umUserInfo = {
                    /*userId:this.userId,*/
                    username:this.username,
                    gender:this.userInfo.gender,
                    birthday:this.userInfo.birthday,
                    location:this.userInfo.location,
                    industry:this.userInfo.industry,
                    interest:this.userInfo.interest,
                    intro:this.userInfo.intro,
                    university:this.userInfo.university,
                    experience:JSON.stringify(this.experience),
                    works: JSON.stringify(this.works)
                };
                saveInformation.updateUserInfo(umUserInfo).then((res)=>{
                    if (!res.data.success){
                        this.$message.error(res.data.message);
                    }else {
                        // this.array.push(res.data.data.comment);
                        // this.value = ""
                        this.$message("保存资料成功！")
                    }
                })
            }
            ,
            // 删除作品行
            removeWork(index) {
                this.works.splice(index,1)
            },
            //添加作品行
            addWork() {
                this.works.push("");
            },
            //删除经历行
            removeExperience(index) {
                this.experience.splice(index,1)
            },
            //添加经历行
            addExperience() {
                this.experience.push({company:"",occupation:""})
            },
            goPage(route){
                this.$router.push(route)
            }
        }
    }
</script>

<style scoped>
    /*文本框背景色*/
    ::v-deep .el-input__inner {
        background-color: rgba(215,228,232,0.7) !important;
    }
    /*头像样式*/
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius:50%;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
        border-radius: 50%;
    }
    /*背景图片*/
    .avatar2 {
        width:1400px;
        height: 230px;
    }
</style>