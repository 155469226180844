<template>
    <div style="margin-left: 20px;">
        <p style="text-align: center;color: #5cbb15" class="my-nav-menu-title">实践空间的系统密码均为11223300</p>
        <el-button type="primary" id="applyPractice" icon="el-icon-edit" @click="applyVm">新增申请</el-button>
        <el-button type="primary" id="getPracticeAccount" icon="el-icon-edit" @click="openLabLoginForm">获取实践账号</el-button>

        <el-card v-for="(item,index) in vmList" v-bind:key="index + keyCount" style="margin-top: 10px;margin-right: 10px;">
            <MyPracticeItemComponent :vm="item" @applyVm="applyVm"></MyPracticeItemComponent>
        </el-card>

        <el-dialog :visible="formDialog" title="新增申请" @close="formDialog=false">
            <el-form :model="apply" label-width="80px">
                <el-form-item label="空间名称">
                    <el-input placeholder="请为空间取名" v-model="apply.title"></el-input>
                </el-form-item>
                <el-form-item label="申请理由">
                    <el-input type="textarea" maxlength="250" show-word-limit placeholder="请输入内容"
                              v-model="apply.reason">
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="sendApply()">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog :visible="loginLab" title="登录实践平台" @close="loginLab=false">
            <el-form :model="apply" label-width="80px">
                <el-form-item label="手机号">
                    <el-input placeholder="手机号" v-model="login.username"></el-input>
                </el-form-item>
                <el-form-item label="密码">
                    <el-input placeholder="请输入密码" type="password" v-model="login.password"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="labLogin()">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import practice from "../../../utils/practice";
    import MyPracticeItemComponent from "@/components/practice/MyPracticeItemComponent";
    import taskStep from "@/utils/driver/taskStep";
    import common from "@/utils/common";
    import personalQuery from "@/utils/personalCenter/personalQuery";
    export default {
        name: "study_practice_temp",
        data(){
            return {
                vmList: [],
                formDialog:false,
                loginLab:false,
                apply:{
                    title:"",
                    reason:""
                },
                login:{
                    username:"",
                    password:"",
                },
                keyCount:0
            }
        },
        components:{
            MyPracticeItemComponent,
        },
        mounted(){
            this.getList();
            //新手指引
            let guide = parseInt(this.$cookies.get("guide"));
            if (common.needGuide(guide,4)){
                this.start();
            }
        },
        methods:{
            start(){
                this.$driver.defineSteps(taskStep.getPracticeStep(()=>{}));
                this.$driver.start();
                if (this.$cookies.get("isLogin") === '1' && this.$cookies.isKey("avatar")){
                    personalQuery.updateGuide(4).then((res)=>{
                        if (res.data.success){
                            this.$cookies.set("guide",res.data.data.guide,"30d")
                        }
                    })
                }else{
                    this.$message.error("您还未登录哦")
                }
            },
            getList(){
                practice.getVMList().then((res)=>{
                    if (res.data.code === 1){
                        this.vmList = res.data.data;
                    }
                })
            },
            applyVm(){
                this.formDialog = true
            },
            sendApply(){
                practice.applyVM(this.apply.title,this.apply.reason).then((res)=>{
                    if (res.data.success){
                        // this.getList();
                        // this.keyCount++;
                        this.formDialog = false;
                        location.reload();
                    }else{
                        this.$message.error(res.data.message);
                    }
                })
            },
            openLabLoginForm(){
                this.loginLab = true;
            },
            labLogin(){
                practice.loginLab(this.login.username,this.login.password).then((res)=>{
                    if (res.data.code === 1){
                        this.$message.success("获取实践平台账号成功")
                        this.loginLab = false;
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>