
<template>
    <el-container  class="my-layout-main">

        <div style="height:calc(100vh);min-height:500px;width:40px; position: absolute;z-index: 20;background-color: white;border: white;border-left: 1px solid #EFEFEF;border-right: 1px solid #EFEFEF;border-top: 1px solid #EFEFEF">
            <div class="divColor" @click="goPage('/ebpf/home/course')">
                <i class="el-icon-back" style="font-size: 20px;margin-top: 5px;"> </i>
                <p   style="font-size: 12px;margin-top: -3px;margin-bottom: -10px;">返回</p>
            </div>
            <el-divider ></el-divider>
            <div :class="isShowTool?'divColor2':'divColor'" style="position: absolute;margin: -10px 0 0 7px;" @click="isShowTool = true">
                <i class="el-icon-s-cooperation" style="font-size: 25px;"> </i>
                <p style="font-size: 12px;margin-top: -3px;margin-bottom: -10px;">工具</p>
            </div>
            <div :class="isShowTool?'divColor':'divColor2'" style="position: absolute;margin: 45px 0 0 7px;" @click="isShowTool = false">
                <img src="~@/assets/img/course/tool0.svg" v-show="isShowTool"/>
                <img src="~@/assets/img/course/tool.svg" v-show="!isShowTool"/>
                <p style="font-size: 12px;margin-top: -3px;margin-bottom: -10px;">文件</p>
            </div>
         </div>
        <!--左侧栏目-->
        <el-aside width="240px" style="margin-left: 29px;" v-show="!isShowTool">
            <AsideMenu :menu="taskDetail.directoryList"></AsideMenu>
        </el-aside>
        <!--左侧栏目-->
        <el-menu   style="min-width: 196px;min-height: 100vh;margin-left: 72px;" v-show="isShowTool">
            <p style="float: left;font-weight: bolder;">工具列表</p>
            <div v-for="(item,index) in classes.taskList" v-bind:key="index" :class="item.toolListColor"
                 style="clear:both;margin:30px 30px 10px 0px;cursor: pointer;float: left;" @click="clickClass(item.id,item.description)">
                <i style="font-size: 18px;float: left; " class="el-icon-circle-check" ></i>
                <span  style="padding-left: 10px;float: left;">{{item.title}}</span>
            </div>
        </el-menu>
        <!--中间内容-->
        <el-main  class="my-layout-main" style="text-align: left;padding: 10px;">
            <!--代码编辑部分-->
            <el-row :gutter="10">
                <!--中间列-->
                <el-col :span="11">
                    <el-card style="width: 100%;height: 280px" shadow="never">
                        <div id="myChart" style="width: 100%;height: 280px"></div>
                    </el-card>
                    <el-card id="taskDetailContent"  style="width: 100%;height: calc(100vh - 374px);margin-top: 10px;line-height: 2em;" shadow="never">
                        <div v-html="taskDetail.content"></div>
                    </el-card>
                </el-col>
                <!--右侧代码编辑栏-->
                <el-col :span="13">
                    <div>
                        <!--python eBPF代码编辑器-->
                        <codemirror v-model="programFile.code" class="my-code-editor"
                                    :global-options="programFile.globalOptions"
                                    :options='{mode:"text/x-python"}'
                                    style="border: #bebfbe 1px solid;"
                                    :style="{height:`calc(100vh - ${344 - resize.moveY}px)`}"></codemirror>
                    </div>
                    <div class="resize" id="resize" style="text-align: center">
                        <i class="el-icon-more" style="font-size: 25px;margin-top: -10px"></i>
                    </div>
                    <!--运行结果展示-->
                    <div>
                        <codemirror v-model="result" class="my-code-editor"
                                    :global-options="programFile.globalOptions"
                                    :options='{mode:"text/x-sh",readOnly: "nocursor"}'
                                    style="border: #bebfbe 1px solid;"
                                    :style="{'height':210-resize.moveY+'px'}"></codemirror>
                    </div>
                    <div style="margin-top: 10px">
                        <div style="float: left">
                            <el-button type="text" style="color: #24A4CB" size="mini" @click="modifyParams">
                                修改参数
                            </el-button>
                            <el-button type="text" style="color: #24A4CB" size="mini" @click="programFile.globalOptions.readOnly = false">
                                修改工具代码
                            </el-button>
                        </div>
                        <div style="float: right">
                            <el-button class="bt " style="color: black;" @click="saveProgram">
                                <span class="fontstyle" >保存</span>
                            </el-button>
                            <el-button class="bt"  style="border: none;background-color: #F8B62B;" @click="reset(activityId,taskDetail.directoryList[0].title)">
                                <span class="fontstyle" style="margin-left: -11px;" >重置本项目</span>
                            </el-button>
                            <el-button class="bt" style="border: none;background-color: #24A4CB;" @click="compileProgram(taskDetail.directoryList[0].id)">
                                <span class="fontstyle" >编译</span>
                            </el-button>
                            <el-button class="bt" type="success"  @click="runProgram(taskDetail.directoryList[0].id)">
                                <span class="fontstyle" >运行</span>
                            </el-button>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </el-main>

        <el-dialog  :visible.sync="chart_1.comm.show" title="可以选择输入命令行参数"
                    :show-close="false" width="422px" :modal-append-to-body='false'>
            <el-input style="margin-bottom: 20px;" v-model="chart_1.comm.param"></el-input>
            <el-button @click="chart_1.comm.show = false">取 消</el-button>
            <el-button type="primary" @click="chart_1.comm.show = false">确 定</el-button>
        </el-dialog>
    </el-container>
</template>
<script >
    import practice from "../../utils/practice";
    import taskApi from "../../utils/task/taskApi";
    import AsideMenu from "@/components/common/AsideMenu";
    import codemirror from "../../components/common/MyCodemirror";
    import "codemirror/lib/codemirror.css"
    import 'codemirror/theme/idea.css'
    import 'codemirror/mode/shell/shell.js'
    import 'codemirror/mode/python/python.js'
    import common from "../../utils/common";
    import cpuEcharts from "../../assets/cpu/cpuEcharts";
    /*
    * libbpf lab 页面
    * 本页面初始化需要用到三个参数：activityId taskId programFileId
    * 1、根据activityId 获取到任务列表，放在教程列表抽屉弹窗里
    * 2、根据taskId 获取到用户的程序文件列表 任务教程 和 图表初始化的选项
    * 3、根据文档id 获取到文档内容
    * */
    export default {
        name: "libbpf_lab",
        components:{
            AsideMenu,codemirror
        },
        data(){
            return{
               /* operationList:[
                    {command:"newFile",title:"新建文件"},
                ],*/
                activityId:"325632b48dc24fc0b59413881173b3b6",
                isShowTool:false,
                taskDetail:{
                    directoryList:[],
                    chartOption:{},
                    content:""
                },
                programFile:{
                    id:"",
                    code:"",
                    //代码编辑器配置样式
                    globalOptions: {
                        // 缩进格式
                        tabSize: 1,
                        // 主题，对应主题库 JS 需要提前引入
                        theme: 'idea',
                        // 显示行号
                        lineNumbers: true,
                        line: true,
                        lineWrapping: true,  // 自动换行
                        styleActiveLine: true, // 当前行背景高亮

                    },
                },
                result:"",
                //拖拽元素
                resize:{
                    startY:0,
                    count:0,
                    moveY:0,
                    isDown:false
                },
                //教程列表
                classes:{
                    currentTaskId:"",
                    show:false,
                    classGroupName:"cpu性能专题",
                    taskList:[{toolListColor:'tool-list-color'}]
                },
                chart_1:{
                    data: {
                        categories: [],
                        data: [],
                    },
                    comm:{
                        toolName:"",
                        param:"",
                        show:false
                    },
                    schema:[],//tooltip
                    object:null,
                    interval:null
                },
          }
        },
        mounted() {
            //初始化页面（任务列表、文档列表、教程、图表）
            this.initByActivityId(this.activityId);
            this.chart_1.object = this.$echarts.init(document.getElementById('myChart'));
            if (common.strBlank(this.$route.query.taskId)){
                this.$message.error("页面初始化缺少必要参数")
            }else{
                this.chart_1.comm.toolName = this.$route.query.toolName;
                this.classes.currentTaskId = this.$route.query.taskId;
                this.initByTaskId(this.$route.query.taskId)
            }
            //拖拽事件，此种方法可以解决js拖拽不流畅等问题
            document.ondragstart = document.ondragend = function(ev){
                ev.preventDefault()
            }
            document.getElementById("resize").addEventListener("mousedown",this.resizeHandler)

            //词条绑定click监听器
            this.entryBindClick()
        },
        destroyed(){
            clearInterval(this.chart_1.interval)
            document.getElementById("resize").removeEventListener("mousedown",this.resizeHandler)
        },
        watch: {
            $route: function (value) {
                this.setCheckColor(this.classes.taskList)
                if(!common.strBlank(value.query.taskId) && value.query.taskId!== this.classes.currentTaskId){
                    this.classes.currentTaskId = value.query.taskId;
                    this.chart_1.comm.toolName = value.query.toolName;
                    this.chart_1.comm.param = "";
                    this.initByTaskId(this.classes.currentTaskId);
                }
                if (value.query.programFileId !== undefined || value.query.programFileId >0 ){
                    this.programFile.id = value.query.programFileId;
                    this.getFileContent(this.programFile.id)
                }
            }
        },
        methods:{
            modifyParams(){
                this.chart_1.comm.show = true
                this.chart_1.comm.param=''
            },
            goPage(url){
               this.$router.push(url)
            },
            entryBindClick(){
                var isShow=false
                var content = "";
                document.addEventListener('click',function(e) {
                    function clickWords(entryName){
                        //被点击的id为当前id，执行click函数
                        if(e.target.id === entryName) {
                            isShow = !isShow
                            if (isShow === true) {
                                taskApi.getEntryDescription(e.target.id).then((res) => {
                                    if (res.data.success) {
                                        content = res.data.data.description
                                        var sb = '';
                                        sb += '<div id="appenddiv" class="el-popover el-popper " role="tooltip"   style="width:250px;height:100px;margin-left: '
                                            + (document.getElementById(entryName).offsetLeft - 40)
                                            + 'px;'
                                            + 'margin-top: '
                                            + (document.getElementById(entryName).offsetTop - 310)
                                            + 'px">'
                                            + content + ' </div>';
                                        var tmp = document.createElement('div');//创建一个div
                                        tmp.innerHTML += sb;//添加到div里
                                        // 添加到你指的的id的地
                                        document.getElementById("ppid").appendChild(tmp);
                                    }
                                })
                            }else if(isShow === false){
                                document.getElementById("appenddiv").remove();
                            }
                        }
                    }
                    //所有带有class="spanclass"的标签都绑定clcik监听
                    var list = document.getElementsByClassName("spanclass");
                    for(var i=0;i<list.length;i++){
                        list[i].onclick=clickWords(list[i].id)
                    }
                })
            },
            initByActivityId(activityId){
                taskApi.getTaskList(activityId).then((res)=>{
                    if (res.data.success){
                        this.classes.taskList = res.data.data.list;
                        this.setCheckColor(this.classes.taskList)
                    }else{
                        this.$message.error(res.data.message);
                    }
                })
            },
            setCheckColor(taskList){
                for(var i=0;i<taskList.length;i++){
                    if(this.$route.query.taskId==taskList[i].id){
                        taskList[i].toolListColor='tool-list-color2'
                    } else {
                        taskList[i].toolListColor='tool-list-color'
                    }
                }
            },
            initByTaskId(taskId){
                this.chart_1.object.clear();//清除echart示例
                this.initChart();
                this.chart_1.interval = setInterval(this.drawChart,3000);
                //获取文档目录
                practice.getDirectoryByTaskId(taskId).then((res)=>{
                    if (res.data.success){
                        this.taskDetail.directoryList = res.data.data.list;
                        this.programFile.id = this.taskDetail.directoryList[0].children[0].id;
                        this.getFileContent(this.taskDetail.directoryList[0].children[0].id);
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
                //获取教程和图表option
                taskApi.getMongoTaskTemplate(taskId).then((res)=>{
                    if (res.data.success){
                        this.taskDetail.content='<div id="ppid"></div>'+common.getMarkdownIt().render(res.data.data.mongoTaskTemplate.template);
                        this.taskDetail.chartOption = res.data.data.mongoTaskTemplate.example;
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            },
            getFileContent(id){
                if (id === undefined) return;
                practice.getFileContent(id).then((res)=>{
                    if(res.data.success){
                        this.programFile.code=res.data.data.content
                        this.result="";

                    }
                })
            },
            //保存、编译和运行
            saveProgram(){
                var data={
                    id:this.programFile.id,
                    content:this.programFile.code
                }
                practice.saveProgramFile(data).then((res)=>{
                    if(res.data.success){
                        this.$message("保存成功！")
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            },
            compileProgram(id){
                if (id === undefined || id === ""){
                    this.$message.error("请先点击项目文件夹")
                    return
                }
                practice.compileLibbpf(id).then((res)=>{
                    if (res.data.success){
                        this.result = window.atob(res.data.data.res)
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            },
            runProgram(id){
                if (id === undefined || id === ""){
                    this.$message.error("请先点击项目文件夹")
                    return
                }
                practice.runLibbpf(id).then((res)=>{
                    if (res.data.success){
                        this.result = res.data.data.res
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            },
            //重置项目文件
            reset(activityId,targetName){
                if (targetName === undefined || targetName === ""){
                    this.$message.error("出错啦，无法获取项目文件夹名称")
                    return
                }
                practice.resetTaskProgram(activityId,targetName).then((res)=>{
                    if (res.data.success){
                        this.$message("重置项目成功!")
                        this.getFileContent(this.programFile.id)
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            },
            //拉伸组件处理
            resizeHandler(e) {
                var vm = this;
                if (vm.resize.count === 0){
                    vm.resize.startY = e.clientY;
                }
                vm.resize.count++;
                vm.resize.isDown = true;
                document.onmousemove = function (e) {
                    if (vm.resize.isDown){
                        var endY = e.clientY;
                        vm.resize.moveY = endY - vm.resize.startY;
                    }
                };
                document.onmouseup = function () {
                    document.onmousemove = document.onmouseup = null
                }
            },
            //点击教程列表
            clickClass(id,description){
                var toolName = description.split(":")[0];
                this.classes.currentTaskId = id;
                this.chart_1.comm.toolName = toolName;
                this.chart_1.comm.param = "";
                this.initByTaskId(id);
                this.$router.replace("/ebpf/home/libbpf/lab?toolName="+toolName+"&taskId="+id);
                this.classes.show = false;
            },
            initChart(){
                clearInterval(this.chart_1.interval);
                taskApi.chartsOption(this.chart_1.comm.toolName,this.chart_1.comm.param).then((res)=>{
                    let ret = res.data;
                    this.chart_1.comm.toolName = ret.data.toolName;
                    let option = cpuEcharts.getCpuOption(ret.data.type,ret.data.title,ret.data.schema);
                    this.chart_1.object.setOption(option,true)
                })
            },
            drawChart(){
                var command = this.chart_1.comm.toolName;
                taskApi.chartsData(command,this.chart_1.comm.param).then((res)=>{
                    var data = res.data;
                    this.chart_1.object.setOption({
                        series: data.data.series
                    });
                })
            },
        }
    }
</script>

<style scoped>

    .tool-list-color{
        color: #0E5166;
    }
    .tool-list-color2{
        color: #7EBC39;
    }
    .divColor2{
        color: #24A4CB;
        cursor: pointer;
    }
    .divColor{
        color: #888888;
        cursor: pointer;
    }
    .fontstyle{
        position: relative;
        top:-5px;
    }
    .bt{

        width:80px;
        height:24px;
        color: #FFFFFF;
        font-size: 12px;
    }
    .el-menu-vertical-demo:not(.el-menu--collapse) {
        text-align: left;
    }
    /*拖拽区div样式*/
    .resize {
        cursor: row-resize;
        position: relative;
        background-size: cover;
        background-position: center;
        background-color: #c7c7c7;
        border-radius: 5px;
        width: 50px;
        height: 10px;
        margin: 0 auto;
        color: #ffffff;
    }
    /*拖拽区鼠标悬停样式*/
    .resize:hover {
        color: #2f2f2f;
    }
    .my-layout-main >>> .el-card{
        border: #bebfbe 1px solid;
        background: #f9f9f9;
        border-radius: 0;
    }

    .code-mirror {
        font-size: 13px;
        line-height: 150%;
        text-align: left;
    }
    .my-code-editor>>>.CodeMirror {
        height: 100%;
    }

    .my-code-editor-title {
        margin: 6px 0 10px 0;
        font-weight: bold;
        width:100%;
    }
</style>