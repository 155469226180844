import axios from "../utils/http";
import md5 from "js-md5";
//=========================云端实验 相关请求
function getVMList() {
    return axios({
        url:"/user_api/get_all_vm_state",
        method:"get"
    })
}
function getInfoById(containerId) {
    return axios({
        url:"/api/user/practice/get/info",
        method:"get",
        params:{
            containerId:containerId
        }
    })
}
function applyVM(title,reason) {
    return axios({
        url:"/api/user/practice/apply",
        method:"post",
        params:{
            title:title,
            reason:reason
        }
    })
}
function loginLab(username,password) {
    return axios({
        url:"/user_api/login",
        method:"post",
        data:{
            username:username,
            password:md5(password)
        }
    })
}

//=======================libbpf 相关请求
function saveProgramFile(data){
    return axios({
        url:"/api/user/bolipi/practice/saveProgramFile",
        method:"post",
        data:data
    })
}
function addProgramFile(targetName,parentId){
    return axios({
        url:"/api/user/bolipi/practice/insert/libbpfCompileTask",
        method:"post",
        data:{
            targetName:targetName,
            parentId:parentId
        }
    })
}
function getDirectoryByTaskId(taskId) {
    return axios({
        url:"/api/user/bolipi/practice/getDirectory/byTaskId",
        method:"get",
        params:{
            taskId:taskId
        }
    })
}

function getFileContent(id){
    return axios({
        url:"/api/user/bolipi/practice/getFileContent",
        method:"get",
        params:{
            id:id
        }

    })
}
function compileLibbpf(id) {
    return axios({
        url:"/api/user/bolipi/practice/compile",
        method:"get",
        params:{
            id:id
        }
    })
}
function runLibbpf(id) {
    return axios({
        url:"/api/user/bolipi/practice/run",
        method:"get",
        params:{
            id:id
        }
    })
}
function resetTaskProgram(activityId,targetName) {
    return axios({
        url:"/api/user/bolipi/practice/reset/task",
        method:"post",
        params:{
            activityId:activityId,
            targetName:targetName
        }
    })
}
export default {getVMList,getInfoById,applyVM,loginLab
    ,saveProgramFile,addProgramFile,getDirectoryByTaskId,getFileContent,
    compileLibbpf,runLibbpf,resetTaskProgram
}