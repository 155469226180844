<template>
    <div class="question-viewer">
        <div class="viewer-title">
            问题回答详情
            <el-button type="text" style="float: right"
                       class="el-icon-close"
                       @click="onCloseViewer">
            </el-button>
        </div>
        <el-card style="margin: 5px">
            <div slot="header">
                <span>{{data.question}}</span>
            </div>
            <div style="min-height: 150px;overflow-x: auto" v-html="data.answer">
            </div>
        </el-card>
    </div>
</template>

<script>
    export default {
        name: "practice_question_viewer",
        props:{
            closeViewer:{
                mustUseProp:false,
                default: () => { return () => {} },
                type:Function
            },
            data:{
                mustUseProp: true,
                default: () => { return {} },
                type: Object,
            }
        },
        methods:{
            onCloseViewer(){
                if (this.closeViewer){
                    this.closeViewer();
                }
            }
        }
    }
</script>

<style scoped>

    .question-viewer{
        border: 1px solid #868686;
        overflow: auto;
        max-height: 800px;
    }
    .question-viewer >>> .viewer-title{
        font-weight: bold;
        line-height: 40px;
        padding: 0 10px;
        background-color: #ebebeb;
    }
</style>