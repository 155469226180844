//导入路由
import Router from "vue-router"
import home from "@/ebpf/home";
import homeChildren from "./home";
import registerComponent from "@/main/login/registerComponent";
import pwdComponent from "@/main/login/modifyPassword";
import error from "@/main/error";
import external_link from "@/main/external_link";
import home_temp from "@/ebpf/home/home_temp";
import PersonalCenter from "../main/personal/PersonalCenter";
import personalCenter from "./personalCenter";
import personal from "../main/personal/personal";
//配置路由
let router = new Router({
    mode:'history',
    basePath:'/',
    routes:[
        //默认页
        {path: '/',redirect:'/ebpf/index'},
        {path: '/external',component:external_link},
        {path: '/register',component:registerComponent},
        {path: '/password',component:pwdComponent},
        {
            path:'/personalCenter',
            component:PersonalCenter,
            children:personalCenter
        },
        {path:'/personal/:userId', component:personal},
        {
            path:'/ebpf/index',
            component:home_temp,
            name:"首页"
        },
        {
            path: '/ebpf/home',
            name: '首页',
            component:home,
            children: homeChildren
        },
        {path:'*',component:error},
    ],
});
//最后一定要导出路由，在main.js中还需要这个router对象
export default router

