import axios from 'axios'
import qs from 'qs'
import {Message} from 'element-ui'

axios.defaults.withCredentials = true;

axios.interceptors.request.use(
    config => {
        //请求拦截一般会有哪些操作
        // 1.比如config中的一些信息不符合服务器的要求,这里可以做一些修改
        // 2.比如每次发送网络请求时,都希望在界面中显示一个请求的图标(然后再响应拦截中取消显示)
        // 3.某些网络请求必须携带一些特殊的信息(如登录token),如果没有携带就可以拦截并作响应提示

        // 给请求头添加token
        /*
        * 其中   /.*Authentication=([^;.]*).*$/    是一个正则表达式，用于从cookie中获取Authentication的值 ，
        * ([^;.]*) 是命名捕获，表示从匹配到的内容中 只获得 ()内的值。
        * string.match(regex) 得到的是一个数组， 第0项是匹配到的全部内容，第1项是通过命名捕获得到的内容，
        * 在这里就是Authentication的值。
        * 这样就完成了使用axios发送请求的正确配置了，同时保证了网站免受csrf攻击的影响.
        */
        let regex = /.*Authentication=([^;]*).*$/; // 用于从cookie中匹配 Authentication
        let auth = document.cookie.match(regex) === null ? null : document.cookie.match(regex)[1];
        if (auth) {
            //先检测登录用户（临时和注册用户），有就用登录用户的authentic
            config.headers.Authentication = auth;
        }
        if (config.method === "get" || config.method === "delete"){
            config.paramsSerializer = function (params) {
                return qs.stringify(params,{arrayFormat: 'repeat'})
            }
        }
        return config;
    },
    error => {
        console.log(error)
    }
);

axios.interceptors.response.use(
    response => {
        return response;
    },error => {
        if (error.response.status === 401){
            Message({
                type:"error",
                message:"您还没有登录呢"
            })
        }else if (error.response.status === 403){
            Message({
                type:"error",
                message:"您还没有此权限"
            })
        }
        return Promise.reject(error)
    }
);

export default axios;