import axios from '../http';

function getList(pageCount,pageSize,params) {
    return axios({
        url:`/api/admin/blog/list/${pageCount}/${pageSize}`,
        params:params,
        method:'get'
    })
}
function getNewBlogId() {
    return axios({
        url: "/api/admin/blog/newBlogId",
        method: 'get'
    })
}
function deleteByBlogId(blogIds) {
    return axios.delete("/api/admin/blog/newBlogId",
        {data:{blogIds:blogIds}}
    )
}
function dateFormat(date,format){
    return new Date(date).Format(format)
}

//保存博客
function blogSave(formData) {
    return axios({
        url:"/api/admin/blog/save",
        method: "post",
        data:formData
    })
}
//发布文章
function postBlog(blogId) {
    return axios({
        url:`/api/admin/blog/post/${blogId}`,
        method: 'post'
    })
}
function updateBlog(formData) {
    return axios({
        url:`/api/admin/blog/update`,
        method: 'put',
        data:formData
    })
}
//上传图片
function imgUpload(blogId,formData) {
    return axios({
        url:`/api/admin/blog/${blogId}/img`,
        method: 'post',
        data: formData,
        headers:{'content-Type':'multipart/form-data'},
    })
}
//获取博客
function getBlog(blogId) {
    return axios({
        url:"/api/admin/blog/get/"+blogId,
        method:"get",
    })
}
import {mavonEditor} from 'mavon-editor'
function getMD() {

    return mavonEditor.getMarkdownIt();
}
//获取问题内容
function getQuestionsContent(blogId,positionIds,positionIndex) {
    return axios({
        url: '/api/admin/question/getQuestions/' + blogId,
        method: 'get',
        params: {
            positionIds: positionIds,
            positionIndex: positionIndex
        }
    })
}
function getBlogTags(type){
    return axios({
        url:"/api/user/blog/getBlogTags",
        method:"get",
        params:{
            type:type
        }

    })
}
function getBlogListByTags(page,size,tagsList,tag){
    return axios({
        url:`/api/user/blog/getBlogListByTags/${page}/${size}`,
        method:'put',
        data:tagsList,
        params:{
            tag:tag
        }
    })
}
Date.prototype.Format = function (fmt) { //author: meizz
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
export default {getList,dateFormat,getNewBlogId,getBlogTags,getBlogListByTags,
    deleteByBlogId,blogSave,imgUpload,getBlog,postBlog,
    updateBlog,getMD,getQuestionsContent,}