<template>
    <el-container >
        <!--上方栏目-->
        <el-header class="my-layout" style="height: 50px">
            <HeaderMenu></HeaderMenu>
        </el-header>
        <el-container  style="height: 100vh;">
        <el-main style="text-align: left;padding: 0;">
            <el-col :span="20" :offset="2">
                <div style="background-color: #bbdcea;width: 100%;height:100px;position: relative">
                    <img src="/uploads/img/resource/initialAvatar.png" width="150px" style="margin-left:50px;margin-top:30px;position: absolute">
                    <p style="margin-top: 30px;margin-left: 300px;position:absolute;">
                        <span style="font-weight: bold;font-size: 30px">{{userInfo.username}}</span>
                        <span style="margin-left: 20px;font-size: 14px">{{userInfo.score}}积分</span>
                    </p>
                    <p style="margin-top: 70px;margin-left:300px;position: absolute">
                        <i class="bi bi-geo-alt"></i>&nbsp;IP地址：{{userInfo.location}}
                    </p>
                </div>
                <div style="text-align: left;margin-left:300px;margin-top:0;" :model="userInfo">
                    <el-button v-if="showEditBtn" type="primary" style="background-color:#2696BB;display: inline-block;float: right;margin:10px 20px 0 0"
                               @click="goPage('/personalCenter/PersonalData')" >
                        个人中心
                    </el-button>
                    <p><i class="bi bi-file-earmark-text-fill"></i>&nbsp;简介：{{userInfo.intro}}</p>
                    <p><i class="bi bi-tags-fill"></i>&nbsp;标签：{{userInfo.interest}}</p>
                </div>
                <br>
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="收藏" name="first">
                        <PreferenceListComponent v-if="tabIndex === '0'" :flag="true" :user-id="userInfo.userId"></PreferenceListComponent>
                    </el-tab-pane>
                    <!--<el-tab-pane label="提问" name="second">
                        <MyQuestionListComponent v-if="tabIndex === '1'"  :user-id="userInfo.userId"></MyQuestionListComponent>
                    </el-tab-pane>-->
                    <el-tab-pane label="回答" name="second">
                        <MyAnswerListComponent v-if="tabIndex === '1'" :user-id="userInfo.userId"></MyAnswerListComponent>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-main>
    </el-container>
        <el-footer class="my-layout" height="32px" style="background-color:#074E67;color:white;position: relative;">
            <a class="my-layout-icp" style="color: #d6d6d6" @click="goPage('/personalCenter/aboutus')">关于我们</a>
            <a class="my-layout-icp" style="color: #d6d6d6" @click="goPage('/personalCenter/agreement')">用户隐私协议</a>
            <a class="my-layout-icp" style="color: #d6d6d6" @click="goPage('/personalCenter/complaint')">举报申诉规则</a>
            <a class="my-layout-icp" style="color: #d6d6d6" @click="goPage('/personalCenter/problem')">常见问题</a>
            <a class="my-layout-icp" style="color: #d6d6d6" @click="goPage('/personalCenter/reports')">意见反馈</a>
            <a class="weixin">
                <img class="qrcode" src="~@/assets/img/bolipi.jpg" alt="微信二维码">
                <img src="~@/assets/img/vxicon2.png" class="my-layout-icp"   style="margin-top: 8px;width: 20px;height: 18px;">
            </a>

            <a class="my-layout-icp" style="color: #d6d6d6" href="https://beian.miit.gov.cn" target="_blank">苏ICP备2022009107号-1</a>
        </el-footer>
    </el-container>
</template>

<script>
    import saveInformation from "../../utils/personalCenter/saveInformation";
    import PreferenceListComponent from "../../components/user/notepad/PreferenceComponent";
    // import MyQuestionListComponent from "../../components/user/notepad/MyQuestionListComponent";
    import MyAnswerListComponent from "../../components/user/notepad/MyAnswerListComponent";
    import HeaderMenu from "../../components/common/HeaderMenu";

    export default {
        name: "personal",
        components:{
            PreferenceListComponent,
            // MyQuestionListComponent,
            MyAnswerListComponent,
            HeaderMenu
        },
        data(){
            return{
                userInfo:{
                    userId:'',
                    score:0,
                    username:'',
                    intro:'',
                    interest:'',
                    location:''
                },
                activeName:'first',
                showEditBtn:false,
                tabIndex:'0',
            }
        },
        beforeMount(){
            this.userInfo.userId = this.$route.params.userId;
        },
        mounted() {
            this.getPersonalInfo();
            this.getUsernameByUserId();
            this.getScore();
            this.ifself();
        },
        methods:{
            ifself(){
              if(this.userInfo.userId ==this.$cookies.get('userId'))
                  this.showEditBtn=true;
            },
            getPersonalInfo(){
                saveInformation.getPersonalInfo(this.userInfo.userId).then((res)=>{
                    var info = res.data.data.info;
                    this.userInfo.intro = info.intro;
                    this.userInfo.interest = info.interest;
                    this.userInfo.location = res.data.data.ipAddr;
                })
            },
            getUsernameByUserId(){
                    saveInformation.getUsernameByUserId(this.userInfo.userId).then((res)=>{
                        if(res.data.data==null){
                            this.userInfo={username:"该用户已注销"}
                        }else {
                            this.userInfo.username = res.data.data.username
                        }
                    })
            },
            getScore(){
                saveInformation.getScore(this.userInfo.userId).then((res)=>{
                    if(res.data.success) {
                        this.userInfo.score = res.data.data.score
                    }
                })
            },
            goPage(route){
                this.$router.push(route)
            },
            handleClick(tab) {
                this.tabIndex = tab.index
            }
        }
    }
</script>

<style scoped>
    /* 滑块横条 */
    ::v-deep .el-tabs__nav-wrap::after {
        width:100%;
        height:100%;
        background-color:#b9d8e6;
    }
    /* 设置滑块颜色 */
    ::v-deep .el-tabs__active-bar{
        background-color:#2696bb !important;
    }
    /* 设置标签当前选中样式 */
    ::v-deep .el-tabs__item.is-active{
        color: #2696bb !important;
    }
    /* 设置标签未被选中样式 */
    ::v-deep .el-tabs__item{
        font-size:18px;
        padding: 0 30px !important;
        text-align: center;
        width:100px;
        box-sizing: border-box;
        display: inline-block;
        position: relative !important;
        color:#000000 !important;
    }
    .weixin img.qrcode {
        position: absolute;
        z-index: 99;
        bottom: 30px;
        left:470px;
        width: 7.5rem;
        max-width: none;
        height: 7.5rem;
        transform: scale(0);
        transform-origin: bottom center;
        opacity: 0;
        border-radius: .25rem;
        -webkit-transition: all .4s ease-in-out;
        -o-transition: all .4s ease-in-out;
        transition: all .4s ease-in-out;
    }
    .weixin:hover img.qrcode {
        transform: scale(1);
        opacity: 1;
    }
</style>