<template>
    <el-card class="item-task-impl" shadow="hover">
        <el-row >
            <el-col :span="20" >
                <span class="my-body-title">{{item.taskTitle}}</span>
                <p class="my-body-summary">{{item.taskDescription}}</p>
            </el-col>
            <el-col :span="4"  >
                <div style="float: right;">
                    <span v-if="item.isPass === 0" class="status-content">未审核</span>
                    <span v-else-if="item.isPass === 1" class="status-content" style="color: #95e065">审核通过</span>
                    <span v-else class="status-content" style="color: #f19c37">审核不通过</span>
                </div>
            </el-col>
        </el-row>
        <div v-if="show" style="font-size: 14px">
            <div class="my-markdown-body" v-html="renderMD(mongoTaskImpl.content)"></div>
        </div>
        <el-row >
            <el-col :span="20">
                <el-button type="text" class="my-text-btn-primary" size="mini" v-on:click="show = !show">{{show?"收起":"展开"}}</el-button>
                <el-button type="text" class="my-text-btn-primary" size="mini" @click="updateTaskImpl" :disabled="item.isPass === 1">编辑</el-button>
                <el-button type="text" class="my-text-btn-primary"  :disabled="item.isPass === 1" size="mini"  @click="setNotActive(item.id)">删除</el-button>
            </el-col>
            <el-col :span="4" >
                <span class="status-content" style="float:right;padding-top: 5px">{{dateFormat(item.createdDate)}}</span>
            </el-col>
        </el-row>
    </el-card>
</template>

<script>
    import common from "@/utils/common";
    import taskApi from "@/utils/task/taskApi";
    import "mavon-editor/dist/css/index.css"
    export default {
        name: "TaskImplItemComponent",
        props:{
            item:Object
        },
        data(){
            return {
                show:false,
                mongoTaskImpl:{},
            }
        },
        mounted(){
            this.getMongoTaskImpl(this.item.id)
        },
        methods:{
            dateFormat(date){
                return common.dateFormat(date,"yyyy-MM-dd hh:mm:ss")
            },
            setNotActive(id){
                this.$confirm('您确定要删除此条任务记录吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    taskApi.setNotActive(id).then((res)=>{
                        if (!res.data.success){
                            this.$message.error(res.data.message)
                        }else{
                            this.getMongoTaskImpl(this.item.id)
                            this.$message("删除成功！")
                            this.$emit("refreshTaskImpl")
                        }
                    })
                }).catch(() => {
                });
            },
            getMongoTaskImpl(id){
                taskApi.getMongoTaskImplById(id).then((res)=>{
                    if (res.data.success){
                        this.mongoTaskImpl = res.data.data.taskImpl;
                    }
                })
            },
            updateTaskImpl(){
                var taskImplId = this.item.id;
                this.$router.push({
                    path:'/ebpf/home/my/editor/page',
                    query:{
                        relatedId:taskImplId,
                        kind:'taskImpl',
                        title:this.item.taskTitle,
                        isUpdate:'true',
                        imagePath:'taskImpl/'+this.item.taskId
                    }
                })
            },
            renderMD(value){
                return common.getMarkdownIt().render(value);
            }
        }
    }
</script>

<style scoped>
    .item-task-impl{
        margin-bottom: 8px;
    }
    ::v-deep .el-card__body{
        padding: 10px 20px;
    }
    p{
        white-space: pre-wrap;
        margin: 10px 0;
    }
    ::v-deep .el-button{
        padding: 5px 0;
    }
    .status-content{
        font-size: 15px;
        color: #cccccc;
    }
    .my-markdown-body >>> img{
        max-width: 800px;
    }
</style>