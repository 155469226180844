<template>
    <div>
        <el-form ref="ruleForm" :model="form"  label-position="left" class="index"  :rules="rules" style="margin-top: 20px;">

                <el-button type="primary" style="float:left;margin-left:20px;width:100px;background-color:#11B0E1;color:white;" @click="newProject">
                    新建
                </el-button>
                <el-button type="primary" style="margin-left:-1300px;width:100px;background-color:#11B0E1;color:white;" @click="deleteMulit">
                批量删除
                </el-button>
            <el-form-item style="margin-left:20px;margin-top: 25px;">
                <el-table :data="info" style="width: 1533px" row-key="id" border lazy  @selection-change="handleSelectChange"
                          :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                    <el-table-column type="selection" width="50"></el-table-column>
                    <el-table-column prop="id" label="id" width="100"></el-table-column>
                    <el-table-column prop="title" label="标题" width="100"></el-table-column>
                    <el-table-column prop="tagsList" label="标签" width="150"    >
                        <template slot-scope="scope">
                            <span v-for="(item,index) in scope.row.tagsList" :key="index">{{item.tagName}}<br/></span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="href" label="外部链接" width="100"></el-table-column>
                    <el-table-column prop="icon" label="图标" width="100"></el-table-column>
                    <el-table-column prop="summary" label="摘要" width="502"></el-table-column>
                    <el-table-column prop="modifiedDate" label="修改时间" width="100">
                        <template slot-scope="scope">
                            {{dateFormat(scope.row.modifiedDate)}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="isPass" label="审核结果" width="90">
                        <template slot-scope="scope">
                            {{scope.row.isPass === 0?"待审核":scope.row.isPass === 1? '通过':'驳回'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="isActive" label="是否删除" width="80">
                        <template slot-scope="scope">
                            {{scope.row.isActive===1?'否':'是'}}
                        </template>
                    </el-table-column>
                    <el-table-column  label="操作" width="160">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="showProject(scope.row.id)"  >查看</el-button>
                            <el-button type="text" size="small" @click="editProject(scope.row.id)">修改</el-button>
                            <el-button type="text"  size="small"  @click="setNotActive(scope.row.id)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form-item>
            <el-form-item>
                <!--分页-->
                <el-pagination background layout="prev, pager, next" :page-size="size" :total="totalCount"
                               @current-change="(val)=>handleCurrentChange(val)" :current-page="currentPage">
                </el-pagination>
            </el-form-item>
        </el-form>

    </div>
</template>

<script>
    import project from "../../utils/project";
    import menu from "../../utils/menu/menu";
    import common from "../../utils/common";
    export default {
        name: "adminProject",

        provide(){
            return{
                reload:this.reload
            }
        },
        data() {
            let typeCheck = (rule, value, callback) => {
                if (value === "") {
                    return callback(new Error("请选择类型"));
                } else {
                    callback();
                }
            };
            return {
                form: {
                    type: '',

                },
                dialogFormVisible: false,
                info: [],
                size:4,
                typeOptions:[],
                totalCount:4,
                currentPage:1,
                showFlag:false,
                selection:[],
                rules: {
                    type: [
                        {required: true, validator: typeCheck, trigger: 'blur'}
                    ],
                }
            }
        },
        mounted() {
            this.getProjectTypeOptions()
            this.getProjectByType(this.currentPage)
        },
        methods:{
            handleSelectChange(val){
                var ids = [];
                val.forEach((item)=>{
                    ids.push(item.id)
                })
                this.selection = ids;
            },
            deleteMulit(){
                this.$confirm('确定要删除吗？','提示',{
                    confirmButtonText:'确定',
                    cancelButtonText:'取消'
                }).then(()=>{
                    project.deleteMutliById(this.selection).then((res)=>{
                        if (res.data.success){
                            this.refresh()
                            this.$message("成功！")
                        }
                    })
                })

            },
            refresh(){
                project.getdata(this.currentPage,this.size,this.form.type).then((res)=>{
                    if(res.data.success){
                        this.info=res.data.data.list
                        this.totalCount=res.data.data.total
                    }
                })
            },
            setPassResult(id,result){
                project.setPassResult(id,result).then((res)=>{
                    if (res.data.success){
                        this.refresh()
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            },

            setNotActive(id){
                this.$confirm('您确定要删除吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    project.setNotActive(id).then((res) => {
                        if (res.data.success) {
                            this.refresh()
                        }
                    })
                })
            },
            editProject(id){
                this.$router.push({
                    path:`/ebpf/home/peditor/${id}`,
                    query:{
                        update:'true'
                    }
                })
            },
            showProject(projectId){
                this.$router.push(`/ebpf/home/project/show/${projectId}`)
            },
            dateFormat(date){
                return common.dateFormat(date,"yyyy-MM-dd hh:mm:ss")
            },
            //获取项目类型列表
            getProjectTypeOptions(){
                menu.getAllOptionProjectMenus(3,0).then((res)=>{
                    if(res.data.success){
                        this.typeOptions=res.data.data.list
                    }
                })
            },
            getProjectByType(page){
                        project.getProjectByUserId(page,this.size).then((res)=>{
                            if(res.data.success){
                                this.info=res.data.data.list
                                this.totalCount=res.data.data.total
                                this.currentPage=page
                            }
                        })

            },
            newProject(){
                this.dialogFormVisible=true;
                project.getid().then((res)=>{
                    var id = res.data.data.token;
                    this.$router.push(`/ebpf/home/peditor/${id}`)
                })
            },

            closeLogin(){
                this.dialogFormVisible=false;
                this.index=-1;
            },
            //列表翻页
            handleCurrentChange(val){
                this.getProjectByType(val);
            }
        }
    }
</script>

<style scoped>
    .index >>> .el-dialog{
        width:900px;
        height:750px;
    }

</style>