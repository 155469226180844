<template>
        <el-container>
            <el-main class="center">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/personalCenter/helpCenter' }">帮助中心</el-breadcrumb-item>
                    <el-breadcrumb-item>举报申诉</el-breadcrumb-item>
                </el-breadcrumb>
                <h1 style="text-align: left">举报申诉</h1>
                <h3 style="text-align: left">一.规则</h3>
                <p style="text-align: left">
                    为了营造良好网络生态，保障公民、法人和其他组织的合法权益，维护国家安全和公共利益，我们将根据《中华人民共和国国家安全法》、《中华人民共和国网络安全法》、《互联网信息服务管理办法》等法律、行政法规，自觉践行社会主义核心价值观，营造清朗的网络空间，并开展的弘扬正能量、处置违法和不良信息等相关活动。
                    依据国家相关法律法规规章制定，用户同意严格遵守以下义务：
                    <br>1、不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论。
                    <br>2、从中国大陆向境外传输资料信息时必须符合中国有关法规。
                    <br>3、不得利用网站从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动。
                    <br>4、不得干扰网站的正常运转，不得侵入网站及国家计算机信息系统。
                    <br>5、不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料。
                    <br>6、不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论。
                    <br>7、不得教唆他人从事本条所禁止的行为。
                    <br>8、不得利用在网站注册的账户进行牟利性经营活动。
                    <br>9、不得发布任何侵犯他人著作权、商标权等知识产权或合法权利的内容；用户应不时关注并遵守网站不时公布或修改的各类合法规则规定。
                </p>
                <h3 style="text-align: left">二.举报及申诉流程</h3>
                <p style="text-align: left">
                    若有用户在本站发布违反上述义务的违法、不良信息，其他用户秉承维护互联网安全的公民义务，对发布违法信息的违法用户进行举报，举报方式及流程：
                    <br>1、在违法用户发布信息界面有举报按钮；用户可点击举报按钮，提交文字或图片证据对违法用户予以举报。
                    <br>2、信息审核员收到用户举报信息后，将对举报信息进行核实。如实举报将对被举报用户采取警示、冻结、终止/中止/限制使用帐号等功能、解除或终止服务协议，或提出损害赔偿等措施，并对举报的处置结果反馈给举报用户和被举报用户。不实举报，将对核实结果对举报用户进行反馈。
                    <br>3、被举报用户对举报结果有异议的，可通过举报结果通报信息进行申诉，并提供文字或图片证据。
                    <br>4、信息审核员在收到申诉后，会综合对申诉用户提交证据与举报用户提交证据进行审核，并将审核处置结果反馈给申诉用户。
                </p>
            </el-main>
        </el-container>
</template>

<script>
    export default {
        name: "complaint"
    }
</script>

<style scoped>

</style>