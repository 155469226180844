<template>
    <div>
        <!--头像区域-->
        <div class="from-info">
            <div style="float: left" @click="isBlank(userAvatar.username)?'':newPage('/ebpf/home/personal/'+userAvatar.userId)" >
               <el-avatar class="from-head-img" :size="30" :src="userAvatar.avatar"></el-avatar>
               <span class="from-name" >
                {{userAvatar.username}}
               </span>
            </div>
            <div style="float: right">
                <slot name="right-up" v-bind:prop="{item}"></slot>
            </div>
        </div>
        <!--评论内容区域-->
        <div class="talk-box my-markdown-content" v-html="renderContent(content)"></div>
        <!--操作区域-->
        <el-row class="icon-btn">
            <el-col :span="12">
                <slot name="left-down" v-bind:prop="{item}"></slot>
            </el-col>
            <el-col :span="12" :offset="12" v-if="!isBlank(date)">
                <span class="operator-btn" style="float: right">{{dateFormat(date)}}</span>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import common from "@/utils/common";
    import "mavon-editor/dist/css/index.css"

    export default {
        name: "MyCardComponent",
        props:{
            userAvatar:Object,
            content:String,
            date:Number,
            item:Object
        },
        methods:{
            renderContent(content){
                return common.getMarkdownIt().render(content)
            },
            dateFormat(date){
                return common.dateFormat(date,"yyyy-MM-dd");
            },
            isBlank(item){
                return common.isBlank(item);
            },
            newPage(url){
                window.open(url,'_target')
            }
        }
    }
</script>
<style scoped>
    .talk-box >>> p{
        white-space: pre-wrap;
        margin: 0 0 5px 0;
        padding: 0 0;
        font-size: 13px;
    }
    .from-info{
        padding: 5px 10px;
        height: 30px;
        line-height: 30px;
    }
    .from-head-img{
        vertical-align: middle;
        cursor: pointer;
    }
    .from-name{
        margin-left: 10px;
        vertical-align: -3px;
        cursor: pointer;
        color: #7f7f7f;
        font-family: 微软雅黑;
        font-size: 14px;
    }
    .talk-box,.icon-btn{
        padding: 0 10px 0 55px;
    }
    .operator-btn{
        padding: 0 10px 0 0;
        color: #909090;
    }
    .operator-btn :hover{
        color: #ababab;
    }
    .talk-box,.operator-btn{
        font-size: 13px;
        font-family: 微软雅黑;
    }
    /*.my-markdown-content{*/
    /*    font-family: Menlo, 'Ubuntu Mono', Consolas, 'Courier New', 'Microsoft Yahei', 'Hiragino Sans GB', 'WenQuanYi Micro Hei', sans-serif*/
    /*}*/
    .my-markdown-content >>> img{
        max-width: 1000px;
    }
</style>