<template>
        <el-container>
            <el-main class="center">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/personalCenter/helpCenter' }">帮助中心</el-breadcrumb-item>
                    <el-breadcrumb-item>用户及隐私协议</el-breadcrumb-item>
                </el-breadcrumb>
                <privacy></privacy>
            </el-main>
        </el-container>
</template>

<script>
    import Privacy from "../components/login/privacy";
    export default {
        name: "agreement",
        components: {Privacy},
    }
</script>


<style scoped>
</style>