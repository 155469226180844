<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-left: 20px;margin-top: 10px;">
            <el-breadcrumb-item :to="{ path: '/personalCenter/achievement' }">积分兑换</el-breadcrumb-item>
            <el-breadcrumb-item>积分规则</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="text-align: left;margin-left: 20px;">
            <h1>一、积分规则</h1>
            <ol style="font-size: 16px">
                <li>完成任务会获得相应积分，上传的实验报告通过审核后积分自动下发用户的账户；</li>
                <li>上传视频、文章素材链接并被首次收藏可获得2积分，超过200个收藏，收藏变为每个1积分，每个素材上限为500积分。</li>
                <li>审核通过的任务，提交多次并都通过审核也只能获得一次任务积分；</li>
                <li>删除审核通过的任务积分不会减少；；</li>
                <li>可在积分明细中查看积分收入支出情况，兑换的物品可在兑换明细中查询。</li>
            </ol>
            <h1>二、积分使用</h1>
            <ol style="font-size: 16px">
                <li>积分是波粒派的唯一用于流通的货币单位；</li>
                <li>积分可用于兑换多个APP会员奖励，奖品兑换积分数量、兑换奖励、奖励数量将不断更新；</li>
                <li>兑换会员奖励的兑换码或物流编号应在兑换记录中查看，请勿泄露兑换码；</li>
                <li>积分还可用于报名参加波粒派网站线上或线下的活动。</li>
                <li>一切解释权归本网站所有。</li>
            </ol>
        </div>
    </div>
</template>

<script>
    export default {
        name: "rewardRule"
    }
</script>

<style scoped>

</style>