<template>
    <div class="background">
        <el-row>
            <el-col :span="12">
                <div class="left">
                    <img :src="userInfo.avatar" style="width:380px;height:380px;margin-left: 50px;margin-top:90px;">
                </div>
            </el-col>
            <el-col :span="12">
                <div class="right">
                    <el-form :model="userInfo" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="form" hide-required-asterisk="false">
                        <h2 style="margin-left: 150px;margin-top:50px;color: #3E4A4A" class="el-icon-s-management">&nbsp;&nbsp;个人探险手册</h2>
                        <el-form-item  prop="email" style="margin-left:40px;width:350px;margin-top:10px" label="电子邮箱">
                            <el-input  placeholder="请输入邮箱" v-model="userInfo.email" class="a1" ></el-input>
                        </el-form-item>
                        <el-form-item  prop="verifyCode" class="pr" style="margin-left:40px;width:350px;" label="验证码">
                            <el-input placeholder="请输入验证码"  v-model="userInfo.verifyCode" class="a1"></el-input>
                            <button @click.prevent="getCode()" class="code-btn" :disabled="!show">
                                <span v-show="show">获取验证码</span>
                                <span v-show="!show" class="count">{{count}}秒</span>
                            </button>
                        </el-form-item>
                        <el-form-item  prop="name" style="margin-left:40px;width:350px;" label="企鹅昵称">
                            <el-input  placeholder="昵称"  v-model="userInfo.username"  class="a1"></el-input>
                        </el-form-item>
                        <el-form-item style="margin-left:40px;width: 380px" label="行业">
                            <el-select v-model="userInfo.industry" placeholder="请选择" class="a1" style="margin-left:0;width: 250px;">
                                <el-option v-for="item in industries" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item style="margin-left:40px;width:350px" label="职位">
                            <el-input v-model="userInfo.major"  class="a1"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="2" :offset="8">
                                <el-button @click="submitForm('ruleForm')" class="btn-register">开始探索</el-button>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-link type="success" style="color: #539929;font-size: 15px;margin-left:120px;margin-top:-22px" :underline="false" @click="closePersonal">跳过</el-link>
                        </el-form-item>
                    </el-form>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import userInfoApi from "@/utils/login/userInfoApi";
    export default {
        name: "personalComponent",
        data() {
            let emailCheck = (rule, value, callback) => {
                 let reg = /^[A-Za-z0-9\u4e00-\u9fa5][A-Za-z0-9_.\u4e00-\u9fa5]*[A-Za-z0-9\u4e00-\u9fa5]@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                if (value === "") {
                    return callback(new Error("请输入电子邮箱"));
                }  else if (!reg.test(value)) {
                    return callback(new Error("请输入有效的电子邮箱"));
                } else {
                    callback();
                }
            };
            let nameCheck = (rule, value, callback) => {
                if (value === "") {
                    return callback(new Error("请输入企鹅昵称"));
                }  else if (value.length>10) {
                    return callback(new Error("请输入10字以内昵称"));
                } else {
                    callback();
                }
            };
            //验证码校验，6位验证码
            let code = (rule, value, callback) => {
                let authReg = /^[A-Za-z0-9]{6}$/;
                if (value === "") {
                    return callback(new Error("请输入验证码"));
                } else if (!authReg.test(value)) {
                    callback(new Error("请输入正确的6位验证码"));
                } else {
                    return callback();
                }
            };
            return {
                //获取短信验证码
                show: true,
                count: 60,
                //感兴趣的
                preferences: [],
                industries:[],
                userInfo: {
                    userId:'',
                    avatar:"/uploads/img/resource/initialAvatar.png",
                    email: '',
                    verifyCode: '',
                    username: '',
                    industry:'',//行业
                    major:'',//职位
                },
                rules: {
                    email: [
                        {required: true, validator: emailCheck, trigger: 'blur'}
                    ],
                    verifyCode: [
                        {required: true, validator: code, trigger: 'blur'}
                    ],
                    username: [
                        {required: true, validator: nameCheck, trigger: 'blur'}
                    ],
                }
            }
        },
        mounted(){
            userInfoApi.getIndustry().then((res)=>{
                if (res.data.success){
                    this.industries = res.data.data.list;
                }else{
                    this.$message("获取行业列表失败")
                }
            })
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        userInfoApi.setUserInfo(this.userInfo).then((res)=>{
                            this.$message(res.data.message);
                            if (res.data.success){
                                //close dialog
                                this.$emit("closePersonal")
                                this.goPage('/personalCenter/personal')
                            }
                        })
                    } else {
                        return false;
                    }
                });
            },
            goPage(route) {
                this.$router.push(route)
            },
            getCode() {
                if (this.userInfo.email === "") {
                    this.$message.error("电子邮箱不得为空");
                    return;
                }
                let reg =/^[A-Za-z0-9\u4e00-\u9fa5][A-Za-z0-9_.\u4e00-\u9fa5]*[A-Za-z0-9\u4e00-\u9fa5]@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                if(!reg.test(this.userInfo.email)){
                    this.$message.error("请输入有效电子邮箱");
                    return;
                }
                //axios请求
                userInfoApi.getCode(this.userInfo.email).then((res)=>{
                    if (res.data.success){
                        this.setTimer(res.data.data.second)
                    }else{
                        this.$message.error(res.data.message);
                    }
                })
            },
            closePersonal(){
                this.$emit('closePersonal')
            },
            setTimer(count){
                // 验证码倒计时
                if (!this.timer) {
                    this.count = count;
                    this.show = false;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= count) {
                            this.count--;
                        } else {
                            this.show = true;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000);
                }
            }
        }
    }
</script>

<style scoped>
    .background{
        background-color: rgba(214, 214, 214, 0);
        border-radius: 40px 40px 40px 40px;
        width:960px;
        height:540px;
    }
    .left{
        width:480px;
        height:540px;
        background-color:#aed0a6;
        border-radius: 40px 0  0 40px;
        box-shadow:4px 4px 10px gray;
    }
    .right{
        width:480px;
        height:540px;
        background-color:#FEFFfB;
        border-radius: 0 40px 40px 0;
        box-shadow:4px 4px 10px gray;
    }
    /*开始探索按钮*/
    .btn-register{
        width:310px;
        margin-left:-150px;
        margin-top:15px;
        background-color: #E5F0D0;
        color:#3e4a4a;
        box-shadow:0px 2px 6px 0px #EAE9E9;
    }
    .btn-register:hover{
        border: 1px solid #7a9743;
    }
    /*获取验证码按钮css*/
    .pr {
        position: relative;
    }
    .code-btn {
        width: 100px;
        height: 20px;
        position: absolute;
        top: 10px;
        right: 5px;
        z-index: 222;
        color: #539929;
        font-size: 14px;
        border: none;
        border-left: 1px solid #bababa;
        padding-left: 10px;
        background-color: #fff;
        cursor: pointer;
    }
    /*阴影*/
    .a1{
        box-shadow:0px 2px 6px 0px #EAE9E9;
    }
</style>