<template>
    <el-card class="box-card">
        <!--      问题描述      -->
        <div class="my-question-title">{{questionItem.title}}</div>
        <div style="padding-bottom: 10px;margin-top:5px">
            <slot name="down" v-bind:questionItem="questionItem"></slot>
            <el-button class="my-text-button" @click="getAnswers(questionItem.id)" v-if="noAnswer===false"
                       type="text" :style="'float:'+float">
                查看回答
            </el-button>
            <span class="operator-btn" style="float: right">{{dateFormat(questionItem.createdDate)}}</span>
        </div>


        <!--      回答列表      -->
        <div class="answer-content" v-show="show">
            <div v-for="(answer,index) in answers" v-bind:key="index">
                <el-divider style="color: #ebebeb"></el-divider>
                <slot name="answer" v-bind:prop="answer"></slot>
            </div>

            <el-divider content-position="center" style="color: #ebebeb" v-if="hasNextPage">
                <el-link type="primary" :underline="false" @click="load">查看更多</el-link>
            </el-divider>
            <el-divider content-position="center" style="color: #ebebeb" v-if="!hasNextPage">
                没有更多了
            </el-divider>
        </div>
    </el-card>
</template>

<script>
    import questions from "@/utils/study/questions";
    import common from "@/utils/common";
    export default {
        name: "MyQuestionComponent",
        props:{
            userId:String,
            questionItem:Object,
            questionMark:Object,
            float:String
        },
        data(){
            return {
                //回答
                answerPage:1,
                show:false,
                answers:[],
                hasNextPage:false,
                noAnswer:false
            }
        },
        mounted() {
            this.getAnswersNum(this.questionItem.id)
        },
        methods:{
            getAnswersNum(id){
                questions.getAnswers(id,this.answerPage,3).then((res)=> {
                    if (res.data.data.answerList.length == 0) this.noAnswer = true
                })
            },
            //展开问题回答
            getAnswers(id){
                if (this.show){
                    this.show = false
                }else{
                    //获取第一页回答，和回答总数
                    this.answerPage = 1;
                    this.answers = [];
                    this.hasNextPage = false;
                    questions.getAnswers(id,this.answerPage,3).then((res)=>{
                        let ret = res.data;
                        if (ret.success && this.answers.length === 0){
                            ret.data.answerList.forEach((item)=>{
                                this.answers.push(item)
                            });
                            this.hasNextPage = ret.data.hasNextPage;
                        }
                        this.show = true
                    })
                }
            },
            //加载回答
            load (id) {
                //获取第一页回答，和回答总数
                this.page++;
                questions.getAnswers(id,this.page,3).then((res)=>{
                    let ret = res.data;
                    if (ret.success){
                        this.hasNextPage = ret.data.hasNextPage;
                        ret.data.answerList.forEach((item)=>{
                            this.answers.push(item)
                        })
                    }
                })
            },
            getUserAvatar(userId,username,avatar){
                var name = userId === this.userId?'我的':username;
                return {userId:userId,username:name,avatar}
            },
            dateFormat(date){
                return common.dateFormat(date,"yyyy-MM-dd");
            },
        }
    }
</script>
<style scoped>

    .answer-content >>> .el-divider__text{
        font-size: 13px;
        text-align: left;
        color: #bfbfbf;
    }
    .answer-content >>> .el-divider {
        margin: 15px 0 0 0;
    }
    .operator-btn{
        font-size: 13px;
        font-family: 微软雅黑;
        color: #909090;
    }
</style>