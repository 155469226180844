<template>
    <el-container >
        <!--中间文章内容-->
        <el-container>
            <el-main class="center">
                <div :style="{'height': mainHeight+'px','overflow-y':'scroll'}"
                     id="myPage" ref="page" class="center-content" v-on:click.left="leftClick($event)"
                     v-html="result" @scroll="scrollHandler"></div>
            </el-main>
        </el-container>

    </el-container>
</template>

<script>

    import "highlight.js/styles/default.css"
    import "@/assets/css/main.css"
    import project from "../../utils/project";
    import blogApi from "../../utils/blog/blogApi";
    export default {
        name: "blog-replay",
        components: {},
        data() {
            return {
                //博客md格式文本
                content:"",
                //博客html格式文本
                result:"",
                //固定博客id
                projectId:"",
                //判断滚动完成。如果滚动完成为true;如果未完成或未滚动,为false
                scrollFinished: false,
                //监听的可视窗口高度，在mounted中会改变
                mainHeight:0,

            }
        },
        beforeMount() {
            this.projectId = this.$route.params.id;
        },
        mounted(){
            //获取project内容
            project.getProject(this.projectId).then((res)=>{
                if(res.data.success){
                    this.updateProjectContent(res.data.data.content)
                }
            })

            //设置main部分的高度
            this.mainHeight = window.innerHeight -120;
            this.scrollFinished = true;
        },
        methods:{
            //鼠标左键，帮助图片点击事件
            leftClick(){
                // if (e.target.tagName.toUpperCase() === 'IMG' &&
                //     e.target.getAttribute("src") === "/uploads/img/resource/help.png"){
                //     var alt = [e.target.getAttribute("alt")];
                //     var index = [parseInt(e.target.getAttribute("count"))];
                // }
            },
            //滚动监听
            scrollHandler(){
                //监听滚动完成，如果滚动完成，设置scrollFinished为true
                var top = this.$refs.page.scrollTop;
                var vm = this;
                setTimeout(function () {
                    vm.scrollFinished = vm.$refs.page.scrollTop === top;
                },100);
            },

            //更新博客内容
            updateProjectContent(content){
                this.content = content;
                this.result = blogApi.getMD().render(content);
            },
        }
    }
</script>

<style>
    /*  更改webkit内核浏览器样式，还原h1标题的大小   */
    :-webkit-any(article,aside,nav,section)
    :-webkit-any(article,aside,nav,section)
    :-webkit-any(article,aside,nav,section) h1 {
        font-size: 2em;
    }

</style>

<style scoped>
    #myPage >>> img {
        max-width: 100%;
        height: auto;
    }
    .center {
        border-right: 1px solid rgba(0,0,0,.07);
        padding-right: 0;
    }
    .center-content {
        text-align: left;
        padding-right: 10px;
    }
    /* 设置滚动条的样式 */
    ::-webkit-scrollbar {
        width: 8px;
    }
    /* 滚动槽 */
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
        border-radius: 10px;
    }
    /* 滚动条滑块 */
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
    }
</style>