<template>
    <div class="background">
        <el-row>
            <el-col>
                <el-form :model="dataForm" :rules="rules" ref="ruleForm" label-width="100px">
                    <br>
                    <h2 class="el-icon-folder-opened">&nbsp;上传资料</h2>
                    <el-form-item label="文件名称" prop="title" style="width:500px;margin-left: 50px;">
                        <el-input v-model="dataForm.title"  class="a1"></el-input>
                    </el-form-item>
                    <el-form-item label="内容描述" prop="summary" style="width: 500px;margin-left: 50px;">
                        <el-input type="textarea" v-model="dataForm.summary" class="a1"></el-input>
                    </el-form-item>
                    <el-form-item lable="方向标签" style="margin-left:-115px;">
                        方向标签&nbsp;&nbsp;&nbsp;
                        <el-select v-model="tags" multiple
                                filterable allow-create default-first-option
                                placeholder="请选择方向标签" style="width:400px;" class="a1">
                            <el-option v-for="item in options" :key="item.value"
                                    :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="文章类别" style="margin-left:50px;margin-top: -5px">
                        <el-radio-group v-model="dataForm.type" style="margin-left: -330px;">
                            <el-radio value="blog">文章</el-radio>
                            <el-radio value="video">视频</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="文章类型" style="margin-left:50px;margin-top: -10px">
                        <el-radio-group v-model="dataForm.isAuthor" style="margin-left: -87px;">
                            <el-radio value="1">原创</el-radio>
                            <el-radio value="0">转载</el-radio>
                        </el-radio-group>
                        &nbsp;&nbsp;
                        <el-input placeholder="复制原文链接" v-model="dataForm.originUrl" style="width: 230px;" clearable class="a1"></el-input>
                    </el-form-item>
                    <el-form-item style="margin-left: -115px;">
                        <el-upload :limit="1" :auto-upload="false" :on-change="preUpload"
                                   drag action="#" :http-request="fileUpload">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">只能上传单个markdown或pdf文件，且不超过30MB。将文件拖到此处，或<em>点击上传</em></div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')" style="background-color:#2696BB;margin-left:-120px;position: absolute;margin-top:-5px;width:150px;">上传</el-button>
                    </el-form-item>
              </el-form>
          </el-col>
      </el-row>
    </div>
</template>

<script>
    import common from "@/utils/common";

    export default {
        name: "UploadFileComponent",
        props:{
            taskId:String
        },
        data() {
            return {
                options: [
                    {value: 'eBPF技术与发展',label: 'eBPF技术与发展'},
                    {value: 'eBPF与网络', label: 'eBPF与网络'},
                    {value: 'eBPF与云计算', label: 'eBPF与云计算'},
                    {value: 'eBPF及其应用', label: 'eBPF及其应用'}
                    ],
                tags: '',
                dataForm: {
                    id:'',
                    relatedId:'',
                    title: '',
                    summary: '',
                    type:'',
                    originUrl:'',
                    isAuthor:0,
                },
                rules: {
                    title: [
                        { required: true, message: '请输入文件名称', trigger: 'blur' },
                        { min:1, max:20, message: '长度在1到20个字符', trigger: 'blur' }
                    ],
                    summary: [
                        { required: true, message: '请填写内容描述', trigger: 'blur' }
                    ],
                }
            };
        },
        mounted(){
            this.getUUID();
            this.dataForm.relatedId = this.taskId;
        },
        watch:{
            taskId:function (value) {
                this.dataForm.relatedId = value;
            }
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        alert('上传成功');
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            getUUID(){
                this.dataForm.id = common.simpleUUID();
            },
            preUpload(file){
                const type = file.name.split(".")[1];
                const isLt50M = file.size / 1024 / 1024 < 50;
                const fileType = type === 'md' || type === 'pdf';
                if (!isLt50M){
                    this.$message.error('上传文件过大');
                }
                if (!fileType){
                    this.$message.error('上传文件格式不符合要求');
                }
                return isLt50M && fileType;
            },
            fileUpload(){

            }
        }
    }
</script>

<style scoped>
    .background{
        background-color:#FBFEFF;
        width:645px;
        height:665px;
        border-radius: 15px 15px 15px 15px;
        box-shadow:4px 4px 10px gray;
        margin:-10px auto 0;
    }
    .a1{
        box-shadow:0 2px 6px 0 #EAE9E9;
    }
    ::v-deep .el-upload {
        width:460px;
    }
    ::v-deep .el-upload .el-upload-dragger {
        width: 460px;
    }
</style>