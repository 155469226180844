<template>
        <el-container>
            <el-main class="center">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/personalCenter/helpCenter' }">帮助中心</el-breadcrumb-item>
                    <el-breadcrumb-item>使用指南</el-breadcrumb-item>
                </el-breadcrumb>
                <h1 style="text-align: left">使用指南</h1>
                <img src="~@/assets/img/user/user1.jpg"  style="width: 1000px;height: 500px;">
                <p>
                    在波粒派大家可以自己探索和学习eBPF前沿的技术和知识。<br>未登录时，你可以在首页可以看到最新活动消息，观看eBPF的介绍视频，对eBPF有一个初步的认识。<br>拿着地图一路前行的企鹅代表参与探索eBPF技术的你。
                </p>
                <img src="~@/assets/img/user/user1-3.jpg"  style="width: 1000px;height: 500px;">
                <p>点击右上角的【登录】或代表你的小企鹅，注册登录后即可开始你eBPF探索之旅。</p>
                <img src="~@/assets/img/user/user2.jpeg" style="width: 1000px;height: 500px;">
                <p>进入注册|登录界面，注册属于你的账号。</p>
                <img src="~@/assets/img/user/user3.jpeg" style="width: 760px;height: 450px;">
                <p>注册后，会跳出个人探险手册的信息弹窗，设置你的个人信息。<br>鳄鱼君小提示：绑定邮箱的验证码一定要注意大小写哦！</p>
                <img src="~@/assets/img/user/user4.jpeg" style="width:760px;height:480px;">
                <img src="~@/assets/img/user/user5.jpeg" style="width:760px;height:480px;">
                <p>登录方式分为密码和短信登录。</p>
                <img src="~@/assets/img/user/user6.jpeg" style="width: 1000px;height: 500px;">
                <p>如果不小心忘记密码，可以用手机号或邮箱找回密码，最好不要忘哦。</p>
                <img src="~@/assets/img/user/user1-4.png" style="width: 1000px;height: 500px;">
                <p>登录后，有新手指引帮助你对网站的功能有基本的认识。如果再遇到问题，你可以手动点击网站解说员鳄鱼君，让他帮助你了解更多。</p>
                <img src="~@/assets/img/user/user1-2.jpg"  style="width: 1000px;height: 500px;">
                <p>网站【首页】中可以点击彩色的泡沫了解当前网站内的最新活动、项目动态，你上次浏览的内容、评论回复、笔记、分享和任务
                    <br><br>点击【个人主页】或【个人中心】了解自己的主页内容或账号设置；</p>
                <img src="~@/assets/img/user/user19.jpg" style="width: 1000px;height: 500px;">
                <p>个人主页中，可以跳转到个人中心，查看“我”的个人资料、笔记、收藏、提问和回答。</p>
                <img src="~@/assets/img/user/user20.png" style="width: 1000px;height: 500px;">
                <p>资料中，修改的资料会同步到个人主页。</p>
                <img src="~@/assets/img/user/user20-2.jpg" style="width: 1000px;height: 500px;">
                <p>点击更多资料可以编辑更多资料噢。</p>
                <img src="~@/assets/img/user/user21.jpg" style="width: 1000px;height: 500px;">
                <p>记事本中可以查看笔记、收藏、提问、回答。</p>
                <img src="~@/assets/img/user/user22.jpg" style="width: 1000px;height: 500px;">
                <p>如果忘了收藏，不用怕找不回来，点击【历史浏览】轻松找到 想再看亿遍的知识。</p>
                <img src="~@/assets/img/user/user23.jpg" style="width: 1000px;height: 500px;">
                <p>点击【积分兑换】可通过现有的积分到鳄鱼君的小摊位上免费兑换小礼品。根据自己的积分数兑换相应奖品，可查看积分兑换规则和兑换记录。
                    <br>鳄鱼君小提示：切记不要泄露兑换码！
                </p>
                <img src="~@/assets/img/user/user24.png" style="width: 1000px;height: 500px;">
                <p>账号设置中可以修改手机号、邮箱、密码，实名认证、注销。</p>
                <img src="~@/assets/img/user/user25.jpg" style="width: 1000px;height: 500px;">
                <p>帮助中心集合了每个页面的页脚，点击都可跳转至该页面。</p>
                <img src="~@/assets/img/user/user26.jpg" style="width: 1000px;height: 500px;">
                <p>【问题反馈】如果你对网站功能或运行的宝贵建议，欢迎随时联系我们！<br>
                    &nbsp;&nbsp;如果找不到答案，那就用“万能办法”：发邮件！
                </p>
                <p>&nbsp;&nbsp;点击【探险手册】【探索之旅】【项目集市】探索eBPF的细节。</p>
                <img src="~@/assets/img/user/user7.jpg" style="width: 1000px;height: 500px;">
                <p>【探险手册】-文档中，你可以探索、阅读和学习eBPF相关的文档，点击题目可进入下一页。</p>
                <img src="~@/assets/img/user/user8.jpg" style="width: 1000px;height: 500px;">
                <p>
                    左边目录点击跳转至相应段落；<br>选中一段文字之后点击【问一问】或右击即可提出问题，内容应与该文章相关；
                    点击【查一查】或右击可以跳转至百度；【使用帮助】提示如何提问；<br>
                    鳄鱼君小提示：根据网信办《互联网跟帖评论服务管理规定》要求，网站按照 “后台实名、前台自愿” 原则，对注册用户进行真实身份信息认证，不得向未认证真实身份信息的用户提供跟帖、评论服务，所以发布任何内容前都需先进行实名认证哦。
                </p>
                <img src="~@/assets/img/user/user9.jpeg">
                <p>【探险手册】-视频中，点击想要了解的视频链接可跳转至哔哩哔哩了解查看详情。</p>
                <img src="~@/assets/img/user/user10.jpg" style="width: 1000px;height: 500px;">
                <p>【探险手册】-实践中，你还可以亲手进行小实践，无需配置环境，只需要实名认证，并使用波粒派账号密码就能登录云端实验平台，登录后可申请获取实践空间。</p>
                <img src="~@/assets/img/user/user11.jpg" style="width: 1000px;height: 500px;">
                <p>【探索之旅】是波粒派的特色之一。在探索之旅中有最近发布的活动、学习eBPF技术的新手教程，为了简化 eBPF 程序的开发、构建、分发、运行而设计的轻量级开发框架和便捷实践云端实践平台，可以将预编译的 eBPF 程序打包为通用的 JSON 或 WASM 模块，跨架构和内核版本进行分发，无需重新编译即可动态加载运行。</p>
                <img src="~@/assets/img/user/user11-2.png" style="width: 600px;height: 200px;">
                <p>鳄鱼君小提示：如果要参与活动或使用工具需要进行实名认证哦！</p>
                <p>探索之旅中，活动不设限制，任何想要参加的人实名即可报名，开启任务。</p>
                <img src="~@/assets/img/user/user12.jpg" style="width: 1000px;height: 500px;">
                <img src="~@/assets/img/user/user12-2.jpg" style="width: 1000px;height: 500px;">
                <img src="~@/assets/img/user/user12-3.jpg" style="width: 1000px;height: 500px;">
                <img src="~@/assets/img/user/user12-4.jpg" style="width: 1000px;height: 500px;">
                <p>进入开启探索eBPF的旅程，完成任务，输入关键运行代码和运行结果，记录过程中的心得体会，写随笔、笔记，点击生成实验报告，审核通过后获得积分可兑换奖品。</p>
                <img src="~@/assets/img/user/user15.jpg" style="width: 1000px;height: 500px;">
                <p>生成的实验报告在当前任务下的【素材库】-【实验报告】中查看状态及详情。
                    <br>鳄鱼君小提示：实践报告在审核之前都是可以修改的，审核通过之后就不能修改了！
                </p>
                <img src="~@/assets/img/user/user13.jpg" style="width: 1000px;height: 500px;">
                <p>活动中还可以参与相关任务的讨论，与小伙伴们共同进步。点击头像可跳转至对方的个人主页，查看其相关信息。</p>
                <img src="~@/assets/img/user/user14.jpg" style="width: 1000px;height: 500px;">
                <p>还可以分享自己找到的素材链接并选择类型，建立自己的素材仓库：在我的素材中查看。</p>
                <img src="~@/assets/img/user/user16.jpg" style="width: 1000px;height: 500px;">
                <img src="~@/assets/img/user/user16-2.jpg" style="width: 1000px;height: 500px;">
                <p>【素材库】-素材广场中可以浏览、支持或收藏别人分享的素材链接。</p>
                <img src="~@/assets/img/user/user11-3.jpg" style="width: 1000px;height: 500px;">
                <p>eunomia-bpf 是一个开源的 eBPF 动态加载运行时和开发工具链。使用 eunomia-bpf ，可以在编写 eBPF 程序时只编写内核态代码，自动获取内核态导出信息；或使用 WASM 进行用户态交互程序的开发。</p>
                <img src="~@/assets/img/user/user17.jpg" style="width: 1000px;height: 500px;">
                <p>【项目集市】中，集合了各个公司、团队发布的优秀项目，了解当前大家热门研究的技术。</p>
                <img src="~@/assets/img/user/user18.jpg" style="width: 1000px;height: 500px;">
                <p>消【消息驿站】中，将呈现行业相关的新闻消息，包括发展前景、趋势，最新项目、技术等。还在开发中，敬请期待。</p>
            </el-main>
        </el-container>
</template>

<script>
    export default {
        name: "useguide"
    }
</script>

<style scoped>

</style>