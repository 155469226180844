<template>
    <div class="task-impl">
        <TaskImplItemComponent :item="item" v-for="item in list" v-bind:key="item.id" @refreshTaskImpl="getList(1)"></TaskImplItemComponent>


        <el-pagination :current-page="curPage" style="bottom: 5px;float: right;" v-if="total > size" :page-size="size"
                       background layout="prev, pager, next" :total="total"
                       @current-change="handleCurrentChange"></el-pagination>
    </div>
</template>

<script>
    import TaskImplItemComponent from "@/components/task/material/TaskImplItemComponent";
    import taskApi from "@/utils/task/taskApi";
    export default {
        name: "TaskImplListComponent",
        components:{
            TaskImplItemComponent
        },
        props: {
            id: String,
        },
        data(){
            return {
                relatedId:'',
                list:[],
                curPage:1,
                size:5,
                total:0,
            }
        },
        watch:{
            id:function(value){
                this.relatedId = value;
                this.getList(1);
            }
        },
        mounted(){
            this.relatedId = this.id;
            this.getList(this.curPage)
        },
        methods:{
            handleCurrentChange(val){
                this.curPage = val;
                this.getList(val)
            },
            getList(page){
                taskApi.getTaskImplList(page,this.size,this.relatedId).then((res)=>{
                    if (res.data.success){
                        this.total = res.data.data.total;
                        this.list = res.data.data.list;
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .task-impl{
        text-align: left;
        padding: 0 20px;
        overflow-y: auto;
    }

</style>