<template>
    <div>
        <el-card class="a1" style="margin-top: 10px" @click.native="goPage('/personalCenter/aboutus')">&nbsp;&nbsp;&nbsp;&nbsp;<i class="el-icon-s-data"></i>&nbsp;&nbsp;&nbsp;&nbsp;关于我们</el-card>
        <el-card class="a1" @click.native="goPage('/personalCenter/useguide')">&nbsp;&nbsp;&nbsp;&nbsp;<i class="el-icon-s-management"></i>&nbsp;&nbsp;&nbsp;&nbsp;使用指南</el-card>
        <el-card class="a1" @click.native="goPage('/personalCenter/agreement')">&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chat-right-text-fill"></i>&nbsp;&nbsp;&nbsp;&nbsp;用户隐私协议</el-card>
        <el-card class="a1" @click.native="goPage('/personalCenter/complaint')">&nbsp;&nbsp;&nbsp;&nbsp;<i class="el-icon-warning"></i>&nbsp;&nbsp;&nbsp;&nbsp;举报申诉</el-card>
        <el-card class="a1" @click.native="goPage('/personalCenter/problem')">&nbsp;&nbsp;&nbsp;&nbsp;<i class="el-icon-question"></i>&nbsp;&nbsp;&nbsp;&nbsp;常见问题</el-card>
        <el-card class="a1" @click.native="goPage('/personalCenter/contactus')">&nbsp;&nbsp;&nbsp;&nbsp;<i class="el-icon-phone"></i>&nbsp;&nbsp;&nbsp;&nbsp;联系我们</el-card>
<!--        <el-card class="a1" @click.native="goPage('/personalCenter/cancellationNotice')">&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-patch-minus-fill"></i>&nbsp;&nbsp;&nbsp;&nbsp;注销须知</el-card>-->
    </div>
</template>

<script>
    export default {
        name: "helpCenter",
        methods:{
            goPage(route){
                this.$router.push(route)
            }
        }
    }
</script>

<style scoped>
    .a1{
        height: 78px;
        cursor: pointer;
        line-height:38px;
        margin-top: 16px;
        margin-left: 50px;
        margin-right:50px;
    }
</style>