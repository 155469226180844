<template>
    <el-container class="bg">
        <div class="background">
            <el-row>
                <el-col :span="12">
                    <div class="left" style="top:calc(50vh - 500px);position: relative;">
                        <img src="@/assets/img/ebpf.png" style="width:420px;height:350px;margin-left: 0px;margin-top:60px;">
                        <p style="font-size: 14px;color: #539929;margin-top:50px;margin-left: -65px;"><a href="https://cilium.io" style="color: #539929;text-decoration:none" target="_blank">Cilium 是一个开源软件，https://cilium.io</a></p>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="right" style="top:calc(50vh - 500px);position: relative;">
                        <span @click="goPage2('/ebpf/home/study/doc?tag=官方文档')" style="cursor:pointer;color: black;margin-left:150px;font-size: 18px;position: absolute;margin-top: 25px;"> 登录</span>
                        <el-form :model="update"  status-icon :rules="rules" ref="ruleForm" label-width="60px" class="form" >
                            <h2 style="margin-left:-5px;margin-top:50px;color: #3E4A4A;" class="el-icon-s-opportunity">&nbsp;&nbsp;找回密码</h2>
                            <el-form-item  prop="account" style="margin-left:5px;width: 400px;margin-top:20px">
                                <el-input  placeholder="手机号或邮箱" v-model="update.account" class="a1"></el-input>
                            </el-form-item>
                            <el-form-item prop="status" style="margin-left: 5px;width: 400px;">
                                <slider @success="setStatus" v-model="update.status"></slider>
                            </el-form-item>
                            <el-form-item  prop="verification" class="pr" style="margin-left:5px;width: 400px;">
                                <el-input placeholder="请输入验证码"  v-model="update.verification" class="a1"></el-input>
                                <button @click.prevent="getCode()" class="code-btn" :disabled="!show">
                                    <span v-show="show">获取验证码</span>
                                    <span v-show="!show" class="count">{{count}}秒</span>
                                </button>
                            </el-form-item>
                            <el-form-item  prop="password"  style="margin-left:5px;width: 400px;">
                                <el-input   placeholder="输入新密码"  v-model="update.password" type="password" show-password class="a1"></el-input>
                            </el-form-item>
                            <el-form-item  prop="password2"  style="margin-left:5px;width: 400px;">
                                <el-input   placeholder="确认新密码"  v-model="update.password2" type="password" show-password class="a1"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="2" :offset="8">
                                    <el-button @click="submitForm('ruleForm')"  class="btn-register">确认修改</el-button>
                                </el-col>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-col>
            </el-row>
        </div>
    </el-container>
</template>

<script>
    import Slider from "../../components/login/verifySliderComponent";
    import modifyPassword from "@/utils/login/modifyPassword";
    import loginApi from "../../utils/login/loginApi";
    export default {
        name: "pwdComponent",
        components: {Slider},
        data() {
            //手机号与邮箱校验
            let telCheck = (rule, value, callback) => {
                // let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
                //  let reg = /^[A-Za-z0-9\u4e00-\u9fa5][A-Za-z0-9_.\u4e00-\u9fa5]*[A-Za-z0-9\u4e00-\u9fa5]@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                let reg = /((13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$)|([A-Za-z0-9\u4e00-\u9fa5][A-Za-z0-9_.\u4e00-\u9fa5]*[A-Za-z0-9\u4e00-\u9fa5]@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$)/;
                if (value === "") {
                    return callback(new Error("请输入手机号或邮箱"));
                }  else if (!reg.test(value)) {
                    return callback(new Error("请输入有效的手机号码或邮箱"));
                } else {
                    callback();
                }
            };
            //滑块验证
            let slider = (rule, value, callback) => {
                if (value === false) {
                    return callback(new Error("请验证滑块"));
                } else {
                    return callback();
                }
            };
            //验证码校验，4位数字验证码
            let code = (rule, value, callback) => {
                let authReg = /^[A-Za-z0-9]{6}$/;
                if (value === "") {
                    return callback(new Error("请输入验证码"));
                } else if (!authReg.test(value)) {
                    callback(new Error("请输入正确的6位验证码"));
                } else {
                    return callback();
                }
            };
            //密码校验，必须为8~18位包含数字和字母的密码
            let validatePass = (rule, value, callback) => {
                let regex = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]{8,18}$");
                if (value === "") {
                    callback(new Error("请输入密码"));
                } else if (value.length < 8 || value.length > 28) {
                    callback(new Error("请输入8~18位包含数字与字母密码"));
                } else if (!regex.test(value)) {
                    callback(new Error("密码必须同时包含字母、数字，密码长度在8~18之间"));
                } else {
                    callback();
                }
            };
            //密码一致性校验规则
            let same = (rule,value,callback)=>{
                if (value === ''){
                    callback(new Error("请再次输入密码"));
                }else if (value !== this.update.password){
                    callback(new Error("两次输入密码不一致"))
                }else{
                    callback();
                }
            };
            return {
                update: {
                    account: '',
                    status: false,
                    verification: '',
                    password: '',
                    password2:'',
                },
                //获取短信验证码
                show: true,
                count: 60,
                rules: {
                    account: [
                        {required: true, validator: telCheck, trigger: 'blur'}
                    ],
                    status: [
                        {required: true, validator: slider, trigger: 'blur'}
                    ],
                    verification: [
                        {required: true, validator: code, trigger: 'blur'}
                    ],
                    password: [
                        {required: true, validator: validatePass, trigger: 'blur'}
                    ],
                    password2: [
                        {required: true, validator: same, trigger: 'blur'}
                    ],
                }
            }
        },
        methods: {
            goPage2(url){
                this.$router.push({
                    path:url,
                    query:{
                        loginFlag:true
                    }
                })
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.modifyPassword()
                    } else {
                        return false;
                    }
                });
            },
            //像后端发送修改密码请求
            modifyPassword() {
                modifyPassword.modifyPassword(this.update).then((res) => {
                    let ret = res.data;
                    if (ret.success) {
                        this.$router.go(-1)
                    } else {
                        this.$message(ret.message)
                    }
                });
            },
            //获取验证码
            getCode() {
                if (this.update.account === "") {
                    this.$message.error("手机号或邮箱不得为空");
                    return;
                }
                let phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
                let emailReg = /^[A-Za-z0-9\u4e00-\u9fa5][A-Za-z0-9_.\u4e00-\u9fa5]*[A-Za-z0-9\u4e00-\u9fa5]@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                if(phoneReg.test(this.update.account)){
                    this.sendBySMS(this.update.account);
                }else if (emailReg.test(this.update.account)){
                    this.sendByEmail(this.update.account);
                }else{
                    this.$message.error("请输入有效的手机号或邮箱")
                }
                if (this.update.status === false) {
                    this.$message.error("请验证滑块");
                }
            },
            //获取短信结束
            setStatus() {
                this.update.status = true;
            },
            sendBySMS(phoneNumber){
                loginApi.isPhoneExist(phoneNumber).then((res)=>{
                    if (!res.data.success){
                        this.$message.error("手机号不存在，请先注册")
                    }else{
                        //axios请求获取验证码
                        modifyPassword.getVerifyCodeBySMS(phoneNumber).then((res)=>{
                            if (!res.data.success){
                                this.$message(res.data.message)
                            }else{
                                // 验证码倒计时
                                this.setTimer(res.data.data.second);
                            }
                        })
                    }
                })
            },
            sendByEmail(email){
                loginApi.isEmailExist(email).then((res)=>{
                    if (!res.data.success){
                        this.$message.error("邮箱不存在，请先注册")
                    }else{
                        modifyPassword.getVerifyCodeByEmail(email).then((res)=>{
                            if (!res.data.success){
                                this.$message(res.data.message)
                            }else{
                                this.setTimer(res.data.data.second);
                            }
                        })
                    }
                })
            },
            //设置验证码倒计时
            setTimer(count){
                if (!this.timer) {
                    this.count = count;
                    this.show = false;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= count) {
                            this.count--;
                        } else {
                            this.show = true;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000);
                }
            }
        }
    }
</script>

<style scoped>
    .bg{
        background: url("~@/assets/img/registerbg.jpg") no-repeat;
        /*兼容浏览器版本*/
        background-size: 100% 100%;
        position: fixed;
        width: 100%;
        height: 100%;
    }
    .background{
        background-color: rgba(214, 214, 214, 0);
        border-radius: 40px 40px 40px 40px;
        width:960px;
        height:540px;
        margin:240px auto 0;
    }
    .left{
        width:480px;
        height:540px;
        background-color:#aed0a6;
        border-radius: 40px 0  0 40px;
        box-shadow:4px 4px 10px gray;
    }
    .right{
        width:480px;
        height:540px;
        background-color:#FEFFfB;
        border-radius: 0 40px 40px 0;
        box-shadow:4px 4px 10px gray;
    }
    /*确认修改按钮*/
    .btn-register{
        width:338px;
        margin-left:-135px;
        margin-top:15px;
        background-color: #E5F0D0;
        color:#3e4a4a;
        box-shadow:0px 2px 6px 0px #EAE9E9;
    }
    .btn-register:hover{
        border: 1px solid #7a9743;
    }
    /*获取验证码按钮css*/
    .pr {
        position: relative;
    }
    .code-btn {
        width: 100px;
        height: 20px;
        position: absolute;
        top: 10px;
        right: 5px;
        z-index: 222;
        color: #539929;
        font-size: 14px;
        border: none;
        border-left: 1px solid #bababa;
        padding-left: 10px;
        background-color: #fff;
        cursor: pointer;
    }
    /*阴影*/
    .a1{
        box-shadow:0px 2px 6px 0px #EAE9E9;
    }
</style>