<template>
    <div class="background">
        <el-row>
            <h2 style="text-align: center;"><i class="bi bi-patch-minus-fill"></i>&nbsp;注销</h2>
            <el-col>
                <el-form :model="cancellationReason" :rules="rules" ref="ruleForm" style="margin-left: 30px">
                    <el-form-item style="margin-top: -30px;">
                        <h3 style="color: black;">请选择注销原因</h3>
                    </el-form-item>
                    <el-form-item style="margin-top: -40px;" prop="type">
                        <el-checkbox-group v-model="cancellationReason.type">
                            <el-checkbox label="注销多余账号" name="type"></el-checkbox><br>
                            <el-checkbox label="不感兴趣" name="type"></el-checkbox><br>
                            <el-checkbox label="知识太难了，被劝退" name="type"></el-checkbox><br>
                            <el-checkbox label="网站内容晦涩难懂" name="type"></el-checkbox><br>
                            <el-checkbox label="内容没营养，对我没有帮助" name="type"></el-checkbox><br>
                            <el-checkbox label="网页界面设计拉跨，形式无聊不生动" name="type"></el-checkbox><br>
                            <el-checkbox label="网页功能不喜欢" name="type"></el-checkbox><br>
                            <el-checkbox label="找到其他更好用的网站" name="type"></el-checkbox>
                            <el-input placeholder="复制您觉得好用的网站链接" style="display: inline-block;width: 400px" v-model="cancellationReason.input"></el-input><br>
                            <el-checkbox label="其他" name="type"></el-checkbox>
                            <el-input
                                    type="textarea"
                                    :rows="3"
                                    placeholder="请填写注销原因"
                                    v-model="cancellationReason.textarea"
                                    style="width:600px;">
                            </el-input>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')" style="background-color:#2696BB;width:388px;margin-top:8px;margin-left: 110px;">下一步</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: "cancellationReason",
        data() {
            return {
                cancellationReason:{
                    input:'',
                    textarea: '',
                    type:[]
                },
                rules:{
                    type: [
                        { type:'array',required:true, message:"请至少选择一个注销原因", trigger: 'change'}
                    ],
                }
            }
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$emit("next",9)
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .background{
        background-color:#FBFEFF;
        width:680px;
        height:675px;
        border-radius: 10px 10px 10px 10px;
        box-shadow:4px 4px 10px gray;
        margin:150px auto 0;
    }
</style>