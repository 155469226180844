<template>
    <mavon-editor :style="{'min-height': height}" class="my-editor my-editor-font"
                  ref="mavonEditor" codeStyle="default"
                  :subfield="false" defaultOpen="edit"
                  :toolbars="toolbars"
                  :value ="modelValue"
                  @change="change"
                  @imgAdd="$imgAdd"
                  @imgDel="$imgDel"
                  :externalLink = externalLink>

        <!-- 左工具栏后加入自定义按钮  -->
        <template slot="left-toolbar-after">
            <button v-if="save" @click="saveBlog"
                    type="button"
                    class="my-op-icon fa fa-mavon-floppy-o"
                    aria-hidden="true"
                    title="保存"
            >保存</button>
            <slot name="left-toolbar-after"></slot>
        </template>
    </mavon-editor>
</template>

<script>
    import { mavonEditor } from 'mavon-editor'
    import "mavon-editor/dist/css/index.css"
    import editor from "@/utils/commonServer";

    export default {
        name: "MyEditor",
        components:{
            mavonEditor
        },
        props:{
            imagePath:String,
            modelValue:String,
            height:{
                type:String,
                default:"500px"
            },
            save:{
                type:Boolean,
                default: false
            }
        },
        emits:['update:modelValue'],
        data(){
            return {
                externalLink:{
                    markdown_css: function () {
                        return '/cdn-lib/markdown/github-markdown.min.css'
                    },
                    hljs_js: function(){
                        return '/cdn-lib/highlightjs/highlight.min.js'
                    },
                    hljs_css: function(css){
                        return '/cdn-lib/highlightjs/styles/' + css + '.min.css'
                    },
                    hljs_lang: function(lang) {
                        return '/cdn-lib/highlightjs/languages/' + lang + '.min.js'
                    },
                    katex_css: function() {
                        return '/cdn-lib/katex/katex.min.css'
                    } ,
                    katex_js: function() {
                        return '/cdn-lib/katex/katex.min.js'
                    }
                },
                toolbars: {
                    bold: true, // 粗体
                    italic: true, // 斜体
                    header: true, // 标题
                    underline: false, // 下划线
                    strikethrough: false, // 中划线
                    mark: false, // 标记
                    superscript: false, // 上角标
                    subscript: false, // 下角标
                    quote: true, // 引用
                    ol: true, // 有序列表
                    ul: true, // 无序列表
                    link: true, // 链接
                    imagelink: true, // 图片链接
                    code: true, // code
                    table: true, // 表格
                    fullscreen: false, // 全屏编辑
                    readmodel: false, // 沉浸式阅读
                    htmlcode: false, // 展示html源码
                    help: true, // 帮助
                    /* 1.3.5 */
                    undo: false, // 上一步
                    redo: false, // 下一步
                    trash: false, // 清空
                    save: false, // 保存（触发events中的save事件）
                    /* 1.4.2 */
                    navigation: false, // 导航目录
                    /* 2.1.8 */
                    alignleft: false, // 左对齐
                    aligncenter: false, // 居中
                    alignright: false, // 右对齐
                    /* 2.2.1 */
                    subfield: false, // 单双栏模式
                    preview: true, // 预览
                }
            }
        },
        methods:{
            saveBlog(){
                this.$emit('editorSave');
            },
            $imgAdd(pos,$file){
                var formData = new FormData();
                formData.append('image',$file);
                formData.append('path',this.imagePath);
                editor.imageUpload(formData).then((res)=>{
                    let ret = res.data;
                    this.$refs.mavonEditor.$img2Url(pos,ret.data.url)})
            },
            $imgDel(pos){
                delete this.img_file[pos];
            },
            change(value){
                this.$emit('update:modelValue',value)
            }
        }
    }
</script>

<style scoped>
    @import url('/public/cdn-lib/markdown/github-markdown.min.css');
    .my-editor-font{
        font-family: Menlo, 'Ubuntu Mono', Consolas, 'Courier New', 'Microsoft Yahei', 'Hiragino Sans GB', 'WenQuanYi Micro Hei', sans-serif
    }
    .my-editor >>> img{
        max-width: 1000px;
    }
</style>