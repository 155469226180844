<template>
    <div >
        <el-menu :router="true" active-text-color="#24A4CB" :default-active="activePath"
                 text-color="#004E67" :default-openeds="this.defaultOpens"
                 class="el-menu-vertical-demo menu" style="min-height: calc(100vh - 20px)">

            <div v-for="(item,index) in menu" v-bind:key="index">
                <!-- 一级菜单 -->
                <el-menu-item v-if="item.children === undefined || item.children.length === 0" :index="item.href">
                    <i v-if="item.icon" style="font-size:18px;margin-right: 15px" :class="item.icon"></i>
                    <span class="my-nav-menu-title" slot="title">
                        {{item.title}}
                    </span>
                    <el-dropdown v-if="hasOperationList" style="float: right;margin-right: 20px" trigger="hover"
                                 @command="handleCommand($event,item.href)">
                        <span class="el-dropdown-link">
                            <i class="el-icon-more"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <div v-for="(item,index) in OperationList" v-bind:key="index">
                                <el-dropdown-item :command="item.command">{{item.title}}</el-dropdown-item>
                            </div>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-menu-item>
                <!-- 有二级菜单的一级菜单 -->
                <el-submenu v-else :index="item.href">
                    <template slot="title">
                        <i style="font-size: 20px" v-if="item.icon" :class="item.icon"></i>
                        <span class="my-nav-menu-title" style="padding-left: 10px" slot="title">
                            {{item.title}}
                        </span>

                        <el-dropdown v-if="hasOperationList" style="float: right;margin-right: 20px"
                                     @command="handleCommand($event,item.href)">
                            <span class="el-dropdown-link">
                                <i class="el-icon-more"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <div v-for="(item,index) in OperationList" v-bind:key="index">
                                    <el-dropdown-item :command="item.command">{{item.title}}</el-dropdown-item>
                                </div>
                            </el-dropdown-menu>
                        </el-dropdown>

                    </template>
                    <!-- 二级菜单 -->
                    <div v-for="(item,index) in item.children" v-bind:key="index">
                        <el-menu-item class="my-nav-submenu-title" :index="item.href">
                            &ensp;&ensp;&ensp;<i style="font-size: 20px" v-if="item.icon" :class="item.icon"></i>
                            {{item.title}}
                        </el-menu-item>
                    </div>
                </el-submenu>
            </div>
        </el-menu>
    </div>
</template>

<script>
    export default {
        name: "eBPFMenu",
        props: {
            menu:Array,
            hasOperationList:{
                type:Boolean,
                default:false
            },
            OperationList:Array
        },
        data(){
            return{
                indexArray:[]
            }
        },
        computed:{
            activePath(){
                console.log(this.$route)
                if(this.$route.name=='使用帮助')
                    return '/personalCenter/helpCenter'
                else if(this.$route.name=='资料')
                    return  '/personalCenter/personalData'
                return decodeURI(this.$route.fullPath)
            },
            defaultOpens(){
                return this.menu.map((item)=>{
                    return item.href
                })
            },
        },
        methods:{
            handleCommand(command,href){
                if (this.hasOperationList){
                    this.$emit("handleClick",command,href)
                }
            }
        }
    }
</script>

<style scoped>
    .el-menu-vertical-demo >>> .el-menu-item:hover{
        background-color: rgba(0, 184, 255, 0.07) !important;
    }.el-menu-vertical-demo >>> .el-submenu__title:hover{
         background-color: rgba(0, 184, 255, 0.07) !important;
     }
    .el-menu-vertical-demo:not(.el-menu--collapse) {
        min-height: 400px;
        text-align: left;
    }
    .menu{
        margin-left: 12px;
    }
    .el-menu-item.is-active{
        background-color: rgba(0, 184, 255, 0.07)!important;
    }
    .my-icon{
        margin-right: 10px;
    }
    .finished {
        color: #00bb00;
    }

</style>