import axios from "../http";

function modifyOldemail(domain){
    return axios({
        url:`/api/user/users/modifyOldphone`,
        method:"put",
        data:domain

    })
}
function  modifyNewemail( domain) {
    return axios({
        url:"/api/user/users/modifyNewemail",
        method:"put",
        data:domain
    })
}

function getVerifyCode(receiver){
    return axios({
        url:"/api/user/mail/verificationCode",
        method:"get",
        params:{
            receiver:receiver
        }
    })
}
export default {modifyOldemail,modifyNewemail,getVerifyCode}