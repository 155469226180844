<template>
    <el-container class="my-layout-main" >
        <!--左侧栏目-->
        <el-aside width="280px">
            <AsideMenu :menu="asideMenu"></AsideMenu>
        </el-aside>
        <!--中间内容-->
        <el-container>
            <el-main class="center">
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
    import '@/assets/css/main.css'
    import AsideMenu from '../../components/common/AsideMenu'
    import commonServer from "@/utils/commonServer";

    export default {
        name: "study_temp",
        components:{
            AsideMenu
        },
        data(){
            return {
                mainHeight:0,
                asideMenu:[],
            }
        },
        created(){
            this.getMenuList();
        },
        mounted(){
            //设置main部分的高度
            this.mainHeight = window.innerHeight -120;
        },
        methods:{
            goPage(url){
                this.$router.push({
                    path:url
                })
            },
            setUserInfo(){
                this.closeLogin();
                this.goPage('/ebpf/home/study/doc?tag=官方文档')
            },
            getMenuList(){
                commonServer.getMenuList("探险手册").then((res)=>{
                    if (res.data.success){
                        this.asideMenu = res.data.data.list;
                    }else{
                        this.$message.error(res.data.message);
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .center {
        border-right: 1px solid rgba(0,0,0,.07);
        text-align: left;
        padding: 10px;
    }
    .video-box{
        margin-top: 10px;
    }
    .box-card{
        color: inherit;
    }
    /* 设置标签未被选中样式 */
    ::v-deep .el-tabs__item{
        color:#004E67 !important;
    }
    /* 设置滑块颜色 */
    ::v-deep .el-tabs__active-bar{
        background-color: rgba(38, 150, 187, 0.86) !important;
    }
    /* 设置标签当前选中样式 */
    ::v-deep .el-tabs__item.is-active{
        color:rgb(38, 150, 187) !important;
    }
</style>