import axios from '@/utils/http'
import JsEncrypt from "jsencrypt"

let publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDTqvVSmn92yD/+qRdAspxop79UwXNhRMHAvn0NaCwfpOZGlJvSVJ3iNgiI5AoA0NIG3oCqHAP/AydBr7daXpzH79K163HATjKG6UmE1pGrHFUaZk3aJaE1c3X+cubp6TResg3G62yyD6TBn5x6E4qh44P82MJYdSeLX3FJEzsImQIDAQAB";

function getIndustry() {
    return axios({
        url:"/api/user/constance/industry",
        method:"get",
    })
}

function getTags(page,size) {
    return axios({
        url:`/api/user/tag/list/${page}/${size}`,
        method:"get"
    })
}

function setUserInfo(data) {
    return axios({
        url:"/api/user/users/userInfo",
        method:"post",
        data:data
    })
}

function getCode(email) {
    return axios({
        url:"/api/user/mail/verificationCode",
        method:"get",
        params:{
            receiver:email
        }
    })
}

function getUserAvatar(userId) {
    return axios({
        url:`/api/user/users/info/${userId}`,
        method:"get"
    })
}
function idcheck(realName,certNo) {
    return axios({
        url:"/api/user/users/idCheck",
        method:"post",
        data:{
            certNo:rsaEncrypt(certNo),
            realName:rsaEncrypt(realName)
        }
    })
}
function rsaEncrypt(str) {
    let rsa = new JsEncrypt();
    rsa.setPublicKey(publicKey);
    return rsa.encrypt(str)
}

export default {getIndustry,getTags,setUserInfo,getCode,getUserAvatar,idcheck,rsaEncrypt}
