<template>
    <el-main class="my-layout-main" style="text-align: left">
        <!--按钮组-->
        <el-button @click="compile()" type="primary" size="mini" style="margin: 10px 0">编译</el-button>
        <el-button-group style="margin: 0 10px">
            <el-button @click="metricsConfig.show = true" :disabled="ebpf_compiled.id >= 0" type="success" size="mini">
                运行可视化组件
            </el-button>
        </el-button-group>
        <el-button-group style="margin: 0 10px">
            <el-button @click="list()" type="primary" size="mini">管理ebpf程序</el-button>
            <el-button type="primary" @click="help = true" size="mini">帮助</el-button>
        </el-button-group>
        <!--配置弹窗-->
        <el-dialog title="请设置自己的配置文件" :visible.sync="metricsConfig.show" @close="metricsConfig.show = false">
            <codemirror v-model="metricsConfig.metrics" :global-options="globalOptions"
                :options='{mode:"application/x-json"}'></codemirror>
            <span slot="footer" class="dialog-footer">
                <el-button @click="metricsConfig.show = false">取 消</el-button>
                <el-button type="primary" @click="startExporter(ebpf_compiled.uuid)">确 定</el-button>
            </span>
        </el-dialog>
        <!--管理程序弹窗-->
        <el-dialog width="800px" :visible.sync="ebpf_list.show" @close="ebpf_list.show = false">
            <div slot="title">
                <span style="font-weight: bold;margin-right: 20px">管理ebpf程序</span>
                <el-button size="mini" @click="list()"><i class="el-icon-refresh-left"></i></el-button>
            </div>
            <el-table :data="ebpf_list.data" stripe>
                <el-table-column prop="createdTime" label="日期" width="200px">
                    <template slot-scope="scope">
                        {{dataFormat(scope.row.createdTime)}}
                    </template>
                </el-table-column>
                <el-table-column prop="id" label="name"></el-table-column>
                <!--状态存储exporter的id id = -1 说明exporter中没有运行此程序-->
                <el-table-column prop="ebpfId" label="状态">
                    <template slot-scope="scope">
                        {{scope.row.ebpfId >=0?"正在运行":"已停止"}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="300px">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" @click="dialogRunExporter(scope.row.id)">运行</el-button>
                        <el-button size="mini" @click="stop(scope.row.ebpfId)" type="danger">停止</el-button>
                        <el-button size="mini" type="primary" @click="getSource(scope.row.id)">查看</el-button>
                        <el-button size="mini" type="danger" @click="del(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <!--右侧抽屉弹窗-->
        <el-drawer title="帮助" :visible.sync="help" direction="rtl" :before-close="beforeDrawerClose">
            <online_help></online_help>
        </el-drawer>
        <!--代码编辑部分-->
        <el-row :gutter="10">
            <!--左侧列-->
            <el-col :span="8">
                <div style="margin: 0 0 10px 0;font-weight: bold">
                    <span>头文件编辑器</span>
                    <el-input v-model="headerFile.headerFilename" @input="headerFile.hasHeader = true"
                        style="width: 200px;margin: 0 10px" size="mini" placeholder="文件名格式为:name.bpf.h"></el-input>
                </div>
                <!--头文件编辑器-->
                <codemirror v-model="headerFile.headerCode" :global-options="globalOptions"
                    :options='{mode:"text/x-python"}'></codemirror>

                <div style="margin: 10px 0;font-weight: bold">
                    <span>编译输出</span>
                    <el-button style="float: right" size="mini" type="success"
                        :disabled="ebpf_compiled.uuid.length === 0"
                        @click="downloadByUrl(ebpf_compiled.package_url,'package.json')">
                        下载编译后的文件
                    </el-button>
                </div>
                <codemirror v-model="response" :global-options="globalOptions" :options="responseOptions"></codemirror>
            </el-col>
            <!--中间列-->
            <el-col :span="8">
                <p class="my-code-editor-title">代码编辑器</p>
                <!--python eBPF代码编辑器-->
                <codemirror v-model="pythonCode" class="my-code-editor" :global-options="globalOptions"
                    :options='{mode:"text/x-python"}' style="height: 640px"></codemirror>
            </el-col>
            <!--右侧列-->
            <el-col :span="8">
                <div style="margin: 0 0 10px 0;font-weight: bold">
                    <el-button-group>
                        <el-tooltip effect="dark" content="run" placement="top">
                            <el-button size="mini" type="success" @click="runEcli()" :disabled="ecli.show">
                                <i class="bi bi-play"></i>
                            </el-button>
                        </el-tooltip>
                        <el-tooltip effect="dark" content="stop" placement="top">
                            <el-button size="mini" type="danger" @click="closeEcli()" :disabled="!ecli.show">
                                <i class="bi bi-stop"></i>
                            </el-button>
                        </el-tooltip>
                    </el-button-group>
                    <span style="float: right">运行结果</span>
                </div>
                <codemirror v-model="ecli.output" class="my-code-editor" :global-options="globalOptions"
                    :options="responseOptions" style="height: 640px"></codemirror>
            </el-col>
        </el-row>
    </el-main>
</template>

<script>
import codemirror from "../../components/common/MyCodemirror";
import online_help from "../../components/online_lab/online_help";
import "codemirror/lib/codemirror.css"
import 'codemirror/theme/monokai.css'
import 'codemirror/mode/shell/shell.js'
import 'codemirror/mode/python/python.js'
import 'codemirror/mode/javascript/javascript.js'
import online from "@/utils/task/online";
import common from "@/utils/common";
import { createSocket, closeSocket } from '@/utils/websocket'
import { opensnoop_bpf_c, opensnoop_bpf_h, config_metrics } from "../../assets/example/opensnoop";

export default {
    name: "online",
    components: { codemirror,online_help },
    data() {
        return {
            //.c文件
            pythonCode: "",
            //头文件
            headerFile: {
                hasHeader: false,
                headerCode: "",
                headerFilename: ""
            },
            //编译结果
            response: "",
            //代码编辑器配置样式
            globalOptions: {
                // 缩进格式
                tabSize: 1,
                // 主题，对应主题库 JS 需要提前引入
                theme: 'monokai',
                // 显示行号
                lineNumbers: true,
                line: true,
                lineWrapping: true,  // 自动换行
                styleActiveLine: true, // 当前行背景高亮
            },
            responseOptions: {
                mode: "text/x-sh",
                readOnly: "nocursor"
            },
            //ebpf 编译结果
            ebpf_compiled: {
                uuid: "",//pm compile record id
                id: -1,//exporter id
                package_url: ""
            },
            //正在运行的ebpf程序列表
            ebpf_list: {
                show: false,
                data: []
            },
            //配置
            metricsConfig: {
                show: false,
                metrics: config_metrics
            },
            ecli: {
                show: false,
                output: "",
            },
            help: false
        }
    },
    mounted() {
        //默认展示示例
        this.example();
    },
    //监听刷新关闭
    created() {
        window.addEventListener('beforeunload', () => { this.beforeUnload() })
    },
    beforeDestroy() {
        this.stop(this.ebpf_compiled.id);
        this.closeEcli()
    },
    destroyed() {
        window.removeEventListener('beforeunload', () => { this.beforeUnload() })
    },
    methods: {
        compile() {
            if (common.strBlank(this.pythonCode)) {
                this.$message.error("编辑器内容不得为空");
                return
            }
            if (this.headerFile.hasHeader && !this.headerFile.headerFilename.endsWith(".bpf.h")) {
                this.$message.error("头文件格式不正确")
            }
            if (this.ebpf_compiled.id > -1) {
                this.stop(this.ebpf_compiled.id)
            }
            var data = {
                hasHeaderCode: this.headerFile.hasHeader,
                headerCodeFilename: this.headerFile.headerFilename,
                headerCodeContent: window.btoa(this.headerFile.headerCode),
                mainCodeContent: window.btoa(this.pythonCode),
                contentEncoding: "base64",
                timeout: 60,
                type: "python"
            };
            online.compile(data).then((res) => {
                if (res.data.success) {
                    this.response = window.atob(res.data.data.res);
                    this.ebpf_compiled.uuid = res.data.data.uuid;
                    this.ebpf_compiled.package_url = res.data.data.fUrl;
                } else {
                    this.response = res.data.message;
                }
            })
        },
        // eunomia exporter and prometheus
        startExporter(uuid) {
            if (common.strBlank(uuid)) {
                this.$message.error("尚未获得编译结果")
                return
            }
            if (this.ebpf_compiled.id > -1) {
                this.stop(this.ebpf_compiled.id)
            }
            let metrics = JSON.parse(this.metricsConfig.metrics);
            online.getEBPFDataByUUID(uuid).then((res) => {
                if (res.data.success) {
                    this.runEBPF(metrics
                        , window.atob(res.data.data.ebpf_data)
                        , res.data.data.compiled_ebpf_filename)
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        runEBPF(metrics, ebpf_data, compiled_ebpf_filename) {
            var data = {
                name: this.ebpf_compiled.uuid,
                metrics: metrics,
                ebpf_data: ebpf_data,
                compiled_ebpf_filename: compiled_ebpf_filename
            };
            online.startEBPF(data).then((res) => {
                if (!common.isBlank(res.data.id)) {
                    this.ebpf_compiled.id = res.data.id;
                    window.open("http://47.114.155.73:9090", "_target")
                }
            }).catch((error) => {
                if (error.response.status > 400) {
                    this.$message.error(error.response.data + "请再次点击运行")
                }
            })
        },
        stop(ebpfId) {
            if (ebpfId === -1) {
                return
            } else {
                online.stopEBPF(ebpfId).then((res) => {
                    if (!common.isBlank(res.data)) {
                        this.ebpf_compiled.id = -1;
                        this.$message.success("运行成功关闭")
                    } else {
                        this.$message.error(res.data)
                    }
                }).catch(error => {
                    this.$message.error(error.response.data)
                })
            }
        },
        // list programs running in exporter
        list() {
            let records;
            let list;
            online.list().then((res) => {
                list = res.data
            })
            online.getCompiledRecords().then((res) => {
                if (res.data.success) {
                    records = res.data.data.list;
                    //构建list数据列表
                    records.forEach((item) => {
                        list.forEach((ebpf) => {
                            if (ebpf.name === item.id) {
                                item.ebpfId = ebpf.id;
                            }
                        })
                    })
                    this.ebpf_list.data = records;
                    this.ebpf_list.show = true;
                }
            })
        },
        // event listener
        beforeUnload() {
            this.stop(this.ebpf_compiled.id);
            this.closeEcli();
        },
        // ecli
        runEcli() {
            this.ecli.output = "";
            if (common.strBlank(this.ebpf_compiled.uuid)) {
                this.$message.error("您还未获取到编译后的id");
                return;
            } else {
                this.ecli.show = true;
                createSocket("wss://bolipi.com:8081/api/user/lab/online/" + this.ebpf_compiled.uuid);
                window.addEventListener('onmessageWS', (e) => { this.getSocketData(e) })
            }
        },
        closeEcli() {
            window.removeEventListener('onmessageWS', (e) => { this.getSocketData(e) })
            closeSocket();
            this.ecli.show = false;
        },
        getSocketData(e) {
            const data = e && e.detail.data;
            let ret = JSON.parse(data);
            if (ret.data.type === "input" || ret.data.type === "error") {
                this.ecli.output = this.ecli.output + window.atob(ret.data.message)
            } else if (ret.data.type === "tracePipe") {
                this.ecli.output = this.ecli.output + "trace_pipe \n" + window.atob(ret.data.message)
            } else if (ret.data.type === "close") {
                closeSocket()
            }
        },
        example() {
            this.headerFile.headerCode = opensnoop_bpf_h;
            this.pythonCode = opensnoop_bpf_c
            this.headerFile.headerFilename = "opensnoop.bpf.h"
        },
        //util
        downloadByUrl(url, fileName) {
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
        dataFormat(date) {
            return common.dateFormat(date, "yyyy-MM-dd hh:mm:ss")
        },
        //弹窗列表中点击运行
        dialogRunExporter(uuid) {
            this.ebpf_compiled.uuid = uuid;
            this.metricsConfig.show = true;
        },
        getSource(uuid) {
            online.getSource(uuid).then((res) => {
                if (res.data.success) {
                    this.pythonCode = window.atob(res.data.data.bpf_c);
                    this.headerFile.headerFilename = res.data.data.headerFilename;
                    this.headerFile.headerCode = window.atob(res.data.data.bpf_h);
                    this.ebpf_compiled.uuid = uuid;
                    this.ebpf_compiled.package_url = res.data.data.package_url;
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        del(uuid) {
            online.delById(uuid).then((res) => {
                if (res.data.success) {
                    this.$message.error("您已成功删除")
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        beforeDrawerClose(done) {
            done()
        }
    }
}
</script>

<style scoped>
.code-mirror {
    font-size: 13px;
    line-height: 150%;
    text-align: left;
}

.my-code-editor>>>.CodeMirror {
    height: 100%;
}

.my-code-editor-title {
    margin: 6px 0 10px 0;
    font-weight: bold
}
</style>


<style>
/* Tomorrow Theme */
/* http://jmblog.github.com/color-themes-for-google-code-highlightjs */
/* Original theme - https://github.com/chriskempson/tomorrow-theme */

/* Tomorrow Comment */
.hljs-comment,
.hljs-quote {
    color: #8e908c;
}

/* Tomorrow Red */
.hljs-variable,
.hljs-template-variable,
.hljs-tag,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class,
.hljs-regexp,
.hljs-deletion {
    color: #c82829;
}

/* Tomorrow Orange */
.hljs-number,
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-type,
.hljs-params,
.hljs-meta,
.hljs-link {
    color: #f5871f;
}

/* Tomorrow Yellow */
.hljs-attribute {
    color: #eab700;
}

/* Tomorrow Green */
.hljs-string,
.hljs-symbol,
.hljs-bullet,
.hljs-addition {
    color: #718c00;
}

/* Tomorrow Blue */
.hljs-title,
.hljs-section {
    color: #4271ae;
}

/* Tomorrow Purple */
.hljs-keyword,
.hljs-selector-tag {
    color: #8959a8;
}

.hljs {
    display: block;
    overflow-x: auto;
    color: #4d4d4c;
    padding: 0.5em;
}

.hljs-emphasis {
    font-style: italic;
}

.hljs-strong {
    font-weight: bold;
}
</style>
    