<template>
    <div class="background">
        <el-row>
            <el-col>
                <h2 style="text-align: center;margin-top: 20px;"><i class="bi bi-file-lock"></i>&nbsp;修改密码</h2>
                <el-form :model="changepassword" :rules="rules" ref="ruleForm" label-width="60px" style="margin-left: 85px;">
                    <el-form-item  prop="old" style="width:450px;margin-top:25px;">
                        <el-input  placeholder="旧密码" v-model="changepassword.old" class="a1" show-password></el-input>
                    </el-form-item>
                    <el-form-item prop="new" class="pr" style="width: 450px">
                        <el-input placeholder="新密码"  v-model="changepassword.new" class="a1" show-password></el-input>
                    </el-form-item>
                    <el-form-item prop="renew" class="pr" style="width: 450px">
                        <el-input placeholder="确认新密码"  v-model="changepassword.renew" class="a1" show-password></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')" style="background-color:#2696BB;width:388px;margin-top: 15px;">确定</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-link :underline="false" style="margin-left: 170px;margin-top: -25px;" @click="submitForm2">忘记密码</el-link>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import md5 from "js-md5";
    import modifyPassword from "../../../utils/login/modifyPassword";
    export default {
        name: "changePassword",
        data(){
            //密码校验，必须为8~18位包含数字和字母的密码
            let validatePass = (rule, value, callback) => {
                var regex=/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]{8,18}$/;
                if (value === "") {
                    callback(new Error("请输入密码"));
                } else if (value.length < 8|| value.length > 28) {
                    callback(new Error("请输入8~18位包含数字与字母密码"));
                } else if (!regex.test(value)) {
                    callback(new Error("密码必须同时包含字母、数字，密码长度在8~18之间"));
                } else {
                    callback();
                }
            };
            //密码一致性校验规则
            let same = (rule,value,callback)=>{
                if (value === ''){
                    callback(new Error("请再次输入密码"));
                }else if (value !== this.changepassword.new){
                    callback(new Error("两次输入密码不一致"))
                }else{
                    callback();
                }
            };
            return{
                changepassword:{
                    old:'',
                    new:'',
                    renew:'',
                    account:'',
                    status:true
                },
                rules:{
                   /* old: [
                        { required:true, validator:validatePass, trigger: 'blur'}
                    ],*/
                    new: [
                        { required:true, validator: validatePass, trigger: 'blur'}
                    ],
                    renew: [
                        { required:true, validator: same, trigger: 'blur'}
                    ]
                }
            }
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        var data={
                            account: this.account,
                            status: this.changepassword.status,
                            oldpass: md5(this.changepassword.old) ,
                            password:  md5(this.changepassword.new),
                            password2: md5(this.changepassword.renew),
                        }

                        modifyPassword.personalModifyPassword(data).then((res)=>{
                            if (!res.data.success){
                                this.$message.error(res.data.message);
                            }else {
                                this.$emit("next",0)
                                alert("修改密码成功！")
                            }
                        })

                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            submitForm2() {
              this.$emit("next",7)
            }
        }
    }
</script>

<style scoped>
    .background{
        background-color:#FBFEFF;
        width:680px;
        height:380px;
        border-radius: 10px 10px 10px 10px;
        box-shadow:4px 4px 10px gray;
        margin:150px auto 0;
    }
    /*阴影*/
    .a1{
        box-shadow:0 2px 3px 0 #EAE9E9;
    }
</style>