import axios from "../http";

function modifyOldPhone(domain){
    return axios({
        url:`/api/user/users/modifyOldphone`,
        method:"put",
        data:domain

    })
}
function  modifyPhone( domain) {
    return axios({
        url:"/api/user/users/modifyNewphone",
        method:"put",
        data:domain
    })
}

function getVerifyCode(phoneNum){
    return axios({
        url:"/api/user/sms/verifyCode",
        method:"put",
        params:{
            phoneNum:phoneNum
        }
    })
}
export default {modifyOldPhone,modifyPhone,getVerifyCode}