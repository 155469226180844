<template>
    <el-card class="item-task-impl" shadow="hover" >
        <el-row  v-if="!personal">
            <el-col >
                <!--<el-avatar class="from-head-img" :size="30" :src="fromUser.avatar"></el-avatar>-->
            </el-col>
        </el-row>
        <el-row >
            <el-col :span="20">
                <span class="my-body-title" >{{item.title}}</span>
                <el-tag :key="index" v-for="(tag,index) in tableData"  style="margin-left: 10px;color:#5B5B5B;"  size="small"   class="my-tag"
                        :disable-transitions="false"  >
                    {{tag}}
                </el-tag>
                <p style=" font-size: 13px;">{{item.summary}}</p>
                <a class="my-link" style="cursor: pointer;" v-on:click="goPage(item.url,item.title,item.id)">{{item.url}}</a>
            </el-col>
            <el-col v-if="personal" :span="4" >
                <span v-if="item.isPass === 0" class="status-content">未审核</span>
                <span v-else-if="item.isPass === 1" class="status-content" style="color: #95e065">审核通过</span>
                <span v-else class="status-content" style="color: #f19c37">审核不通过</span>
            </el-col>
        </el-row>
        <el-row v-if="personal" >
            <el-col :span="18">
                <el-button type="text" class="my-text-btn-primary" size="mini" @click="uploadDialogFormVisible = true" :disabled="item.isPass === 1">编辑</el-button>
                <el-button type="text" class="my-text-btn-primary" size="mini" @click="setNotActive(item.id)">删除</el-button>
            </el-col>
            <el-col :span="6">
                <span style="padding-top: 5px;font-size: 14px;color: #cccccc;float: right">{{dateFormat(item.modifiedDate)}}</span>
            </el-col>
        </el-row>
        <el-row v-else >
            <el-col :span="21" >
                <span class="from-name" style="margin-left:0px;vertical-align: 1px;" @click="isBlank(fromUser.username)?'':newPage('/ebpf/home/personal/'+item.createdByUserId)" >
                       分享者：{{fromUser.username}}
                </span>
                <el-button type="text" class="bi bi-hand-thumbs-up"
                           @click="setLike(item.id)"
                           :class="likeStatus?'':'btn-gray'">
                    {{likeNum}}
                </el-button>
                <el-button type="text" class="el-icon-star-off my-text-btn-primary"
                           @click="setPreference(item.id)"
                           :class="preferenceStatus?'':'btn-gray'"></el-button>
            </el-col>
            <el-col :span="3">
                <span style="float:right;padding-top: 5px;font-size: 14px;color: #cccccc;">{{dateFormat(item.modifiedDate)}}</span>
            </el-col>
        </el-row>
<!--        <el-dialog :visible.sync="updateDialog" width="70%" top="25vh">-->
<!--            <div style="background-color: #ffffff;padding: 10px">-->
<!--                <FileEditComponent :item="item" @refreshList="refresh"-->
<!--                                     :height="600" @close="closeEditDialog"></FileEditComponent>-->
<!--            </div>-->
<!--        </el-dialog>-->
        <!--上传资料弹窗-->
        <el-dialog :visible.sync="uploadDialogFormVisible" :show-close="false" top="0vh"  width="960px">
            <el-button type="info" icon="el-icon-close" circle @click="closeFile2" style="margin-left:920px;margin-top: -10px;width: 40px;height: 40px;"></el-button>
            <upload-data  :item="item" :editFlag="true" @closeLogin="closeFile2" >
            </upload-data >
        </el-dialog>
    </el-card>
</template>

<script>
    import common from "@/utils/common";
    import fileApi from "@/utils/task/fileApi";
    import userInfoApi from "@/utils/login/userInfoApi";
    import UploadData from "./uploadData";

    export default {
        name: "FileItemComponent",
        components: {UploadData},
        props:{
            item:Object,
            personal:{
                type:Boolean,
                default:true
            }
        },
        watch:{
            item:function () {
                this.getFromUserAvatar();
            }
        },
        data(){
            return {
                likeStatus:false,
                // dislikeStatus:false,
                preferenceStatus:false,
                likeNum:0,
                // dislikeNum:0,
                fromUser:{},
                updateDialog:false,
                uploadDialogFormVisible:false,
                tableData:[],
                tagsTmp:[]
            }
        },
        mounted(){
            if (!common.isBlank(this.item.thumb)){
                this.likeNum = this.item.thumb.likeNum;
                // this.dislikeNum = this.item.thumb.dislikeNum;
                this.likeStatus = this.item.thumb.likeStatus === 1;
                // this.dislikeStatus = this.item.thumb.dislikeStatus === 1;
                this.preferenceStatus = this.item.thumb.preferenceStatus === 1;
            }
            this.getFromUserAvatar();
            this.getTags()
        },
        methods:{
            getTags(){
                fileApi.getTags(this.item.id).then((res)=>{
                    if(res.data.success) {
                        this.tagsTmp = res.data.data.list
                        for(var i=0;i<this.tagsTmp.length;i++) {
                                this.tableData.push(this.tagsTmp[i].tagName)}
                        }
                })
            },
            closeFile2(){
                this.uploadDialogFormVisible=!this.uploadDialogFormVisible
            },
            refresh(){
              this.$emit('refreshList')
            },
            closeEditDialog(){
                this.updateDialog = false;
            },
            newPage(url){
                window.open(url,'_target')
            },
            isBlank(item){
              return common.isBlank(item);
             },
            getFromUserAvatar(){
                userInfoApi.getUserAvatar(this.item.createdByUserId).then((res)=>{
                    if (res.data.success){
                        if(res.data.data==null) {
                            this.fromUser={username : "一个不知道名字的分享英雄"}
                        }
                        else {
                            this.fromUser = {
                                username: res.data.data.username,
                                avatar: res.data.data.avatar
                            }
                        }


                    }else {
                        this.$message.error(res.data.message)
                    }
                })
            },
            goPage(url,title,id){
                fileApi.clickItem(url,title,id);
                fileApi.goPage(url,this.$router)
            },
            dateFormat(date){
                return common.dateFormat(date,"yyyy-MM-dd hh:mm:ss")
            },
            setNotActive(id){
                this.$confirm('您确定要删除此素材吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type:"warning"
                }).then(() => {
                    fileApi.setNotActive(id).then((res)=>{
                        if (!res.data.success){
                            this.$message.error(res.data.message)
                        }else{
                            this.$message("删除成功！")
                            this.$emit("refreshList")
                        }
                    })
                }).catch(() => {
                });
            },
            setLike(id){
                fileApi.setLike(id,!this.likeStatus).then((res)=>{
                    if (res.data.success){
                        this.likeStatus = !this.likeStatus;
                        if (this.likeStatus){
                            this.likeNum++
                        }else{
                            this.likeNum--
                        }
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            },
            // setDislike(id){
            //     fileApi.setDislike(id,!this.dislikeStatus).then((res)=>{
            //         if (res.data.success){
            //             this.dislikeStatus = !this.dislikeStatus;
            //             if (this.dislikeStatus){
            //                 this.dislikeNum++
            //             }else{
            //                 this.dislikeNum--
            //             }
            //         }else{
            //             this.$message.error(res.data.message);
            //         }
            //     })
            // },
            setPreference(id){
                fileApi.setPreference(id,!this.preferenceStatus).then((res)=>{
                    if (res.data.success){
                        this.preferenceStatus = !this.preferenceStatus
                    }else{
                        this.$message.error(res.data.message);
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .item-task-impl{
        margin-bottom: 8px;
    }
    ::v-deep .el-card__body{
        padding: 10px 20px;
    }
    p{
        white-space: pre-wrap;
        margin: 10px 0;
    }
    .item-content{
        margin-left: 16px;
    }
    ::v-deep .el-button{
        padding: 5px 0;
    }
    .status-content{
        font-size: 15px;
        color: #cccccc;
        float: right;
    }
    .btn-gray{
        color: #c8c8c8;
    }
    .from-name{
        margin-left: 10px;
        vertical-align: 15px;
        cursor: pointer;
        color: #7f7f7f;
        font-family: 微软雅黑;
        font-size: 14px;
    }
</style>