<template>
    <div  style="background-color: #F5F5F5;flex-grow: 1;display: flex;">
    <el-container class="index my-layout-main" style="background-color: #E5EAF0;flex-grow: 1;" >
        <el-main>
            <el-row style="padding-top: calc(50vh - 350px)">
                <el-col :span="24" >
                    <div class="top-box you-she" style="margin: 0 auto;cursor: inherit;">
                        <button class="btn" style="width: 196px;height: 40px;bottom: 20px"
                                @click="goPage('/ebpf/home/libbpf/demo?taskId=3d8c5854f11c485a90452901de0021af&activityId=50cbac16d06348bea17c43d5c1030ff9')">
                            第一阶段
                        </button><button class="btn" style="width: 196px;height: 40px;left:580px;bottom: 20px"
                                @click="goPage('/ebpf/home/libbpf/demo?taskId=b886065008b045f78275647781196548&activityId=50cbac16d06348bea17c43d5c1030ff9')">
                            第二阶段
                        </button><button class="btn" style="width: 196px;height: 40px;bottom: 20px;left: 1100px"
                                @click="goPage('/ebpf/home/libbpf/demo?taskId=a929e505ef134d878801495e848bfc07&activityId=50cbac16d06348bea17c43d5c1030ff9')">
                            第三阶段
                        </button>
                    </div>
                </el-col>
            </el-row>
            <el-row style="padding-top:30px">
                <el-col :span="8" >
                    <div class="left-box you-she" style="margin: 0 auto;cursor: inherit;">
                        <img src="~@/assets/img/activity/CPU10.svg" style="width: 430px;height: 280px">
                        <div style="position: absolute;top: 20px;right: 0px;color: #e4d67b">
                            性能监测专题&ensp;&ensp;
                            <div style="font-size: 32px;margin-left: 40px">
                                &ensp;&ensp;—CPU
                            </div>
                        </div>
                        <button class="btn" style="width: 196px;height: 40px;bottom: 20px"
                                @click="libbpf">
                            开启探索
                        </button>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="right-box you-she" style="color: #DDEAFF;margin: 0 auto;cursor: inherit;">
                        <div style="padding-top: 20px">
                            云端实验平台
                        </div>
                        <div>
                            <img src="~@/assets/img/activity/right-square.png" width="200" style="margin: 25px 0 0 10px"/>
                            <div style="font-size: 20px;letter-spacing: 10px;margin: 40px 20px auto 0;float: right">
                                环境成熟<br>&ensp;&ensp;&ensp;&ensp;工具齐全
                            </div>
                        </div>
                        <button class="btn" style="width: 196px;height: 40px;bottom: 20px;" @click="applyExperiment">申请实验</button>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="center-box" style="margin: 0 auto;cursor: inherit">
                        <div class="you-she" style="padding-top: 20px;line-height: 32px">
                            eBPF as a
                        </div>
                        <div class="box">
                            <span class="you-she">service</span>
                            <div class="align-center" style="padding-bottom: 10px">
                                <img src="~@/assets/img/activity/center-penguin.png" style="height: 91px"/>
                                <img src="~@/assets/img/activity/center-arrow.png">
                                <img src="~@/assets/img/activity/center-bee.png"/>
                            </div>
                        </div>
                        <button class="btn" style="width: 196px;height: 40px;bottom: 15px;" @click="goPage('/ebpf/home/libbpf/online')">点击体验</button>
                    </div>
                </el-col>
            </el-row>

            <el-dialog :visible.sync="dialogFormVisible" :show-close="false" width="672px" :modal-append-to-body='false'>
                <name-tip  @setUserInfo="setUserInfo" ></name-tip>
            </el-dialog>

        </el-main>
    </el-container>
    </div>
</template>

<script>
    import activitySignUp from "../../utils/task/activitySignUp";
    import NameTip from "./nameTip";
    import noticeQuery from "../../utils/notice/noticeQuery";
    import common from "@/utils/common";
    import video from "@/utils/study/video";
    export default {
        name: "activityPoster",
        components: {NameTip},
        data(){
            return{
                dialogFormVisible:false,
                signUp:false,
                videoShow:false
            }
        },
        methods:{
            applyExperiment(){
                var activityId = "ac836b4d8ef44a44880e0126082a2e90";
                //未登录时弹出登录界面
                if (this.$cookies.isKey("avatar")===false) {
                    this.goPage2('/ebpf/home/activityPoster')
                    this.$router.go(0)
                }
                activitySignUp.isUserSignUp(activityId).then((res)=>{
                    if (res.data.success){
                        this.signUp = res.data.data.signUp;
                        if (this.signUp){//已报名
                            this.goPage('/ebpf/home/taskTemp/practice')
                        } else{//未报名
                            activitySignUp.signupHere(activityId).then((res)=>{
                                if (res.data.success){
                                    this.signUp = res.data.data.signUp;
                                    this.$message.success("您已报名成功");
                                    //新增um_notice记录
                                    var data={
                                        type:1
                                    }
                                    noticeQuery.signUpInsertNotice(data).then((res)=>{
                                        if(res.data.success){
                                            noticeQuery.sendWebSocketMessage(this.$cookies.get("userId"))
                                        }
                                    })
                                    this.goPage('/ebpf/home/taskTemp/practice')
                                }else if(res.data.message==='请先实名认证！') {//未实名认证
                                    this.dialogFormVisible = true;
                                }
                            })
                        }
                    }
                })
            },
            setUserInfo(){
                this.dialogFormVisible=false;
            },
            goPage2(url){
                this.$router.replace({
                    path:url,
                    query:{
                        loginFlag:true
                    }
                })
            },
            libbpf(){
                var activityId = "325632b48dc24fc0b59413881173b3b6";
                //未登录时弹出登录界面
                if (this.$cookies.isKey("avatar")===false) {
                    this.goPage2('/ebpf/home/activityPoster')
                    this.$router.go(0)
                }
                activitySignUp.isUserSignUp(activityId).then((res)=>{
                    if (res.data.success){
                        this.signUp = res.data.data.signUp;
                        if (this.signUp){//已报名
                            // this.goPage('/ebpf/home/libbpf/lab?taskId=c60dc82bf80f431799e60b0d8ccec72d')
                               this.goPage('/ebpf/home/course')
                        } else{//未报名
                            activitySignUp.initCPUSubject().then((res)=>{
                                if (res.data.success){
                                    this.signUp = true;
                                    this.$message.success("您已报名成功");
                                    this.goPage('/ebpf/home/course')
                                }else if(res.data.message==='请先实名认证！') {//未实名认证
                                    this.dialogFormVisible = true;
                                }
                            })
                        }
                    }
                })
            },
            newPage(url){
                if (common.isBlank(url)||url === ""){
                    return
                }
                video.goPage(url,this.$router)
            },
            goPage(route){
                    this.$router.push(route)
            }
        }
    }
</script>

<style scoped>
    .index >>> .el-dialog{
        background-color: rgba(0,0,0,0);
        box-shadow: 0 0 0 !important;
    }
    /*此处报名按钮样式*/
    .btn{
        background-color:#FDD805;
        color:#433100;
        border-radius: 13px;
        font-size: 22px;font-weight: bolder;
        font-family: 微软雅黑;
        border: 0;
        position: absolute;
        left: 120px;
        cursor: pointer;
    }

    .align-center{
        display: flex;justify-content: center;align-items: center
    }

    .you-she{
        font-family: youShe;
        color:#361A0A;
    }

    .top-box{
        background: url("~@/assets/img/activity/112.svg") no-repeat;
        cursor: pointer;
        width: 1420px;
        height: 280px;
        z-index:1;
        border-radius: 30px;font-size: 32px;
        position: relative;
    }
    .center-box{
        background: url("~@/assets/img/activity/center-bg.jpg") no-repeat;
        cursor: pointer;
        width: 430px;
        height: 280px;
        z-index:1;
        border-radius: 30px;
        font-size: 32px;
        position: relative;
    }

    .left-box{
        background: url("~@/assets/img/activity/left-bg.png") no-repeat;
        cursor: pointer;
        width: 430px;
        height: 280px;
        z-index:1;
        border-radius: 30px;font-size: 32px;
        position: relative;
    }

    .right-box{
        background: url("~@/assets/img/activity/right-bg.png") no-repeat;
        cursor: pointer;
        width: 430px;
        height: 280px;
        z-index:1;
        border-radius: 30px;
        font-size: 32px;
        position: relative;
    }

    .box{
        border: 5px #BFBFBF solid;
        margin: 10px 20px;
    }

    /*.test_box{*/
    /*    width: 30%;*/
    /*    float: left;*/
    /*    -webkit-transition-property: width;*/
    /*    -webkit-transition-duration: 1s;*/
    /*    -webkit-transition-timing-function: linear;*/
    /*}*/
    /*.test_box:hover{*/
    /*    width: 40%;*/
    /*}*/
</style>