<template>
    <!--任务评论标签页组件-->
    <div class="comment-list">
        <el-card style="position: relative" class="comment-edit">
            <el-tabs @tab-click="handleClick" active-name="edit"  style="text-align: center;">
                <el-tab-pane label="编辑" name="edit" @init="edit" >
                    <el-input type="textarea" id="commentInput" :autosize="{minRows:2,maxRows:14}"
                              placeholder="开始编辑..." v-model="value"></el-input>
                    <el-button size="mini" @click="saveComment" style="float: right">评论</el-button>
                </el-tab-pane>
                <el-tab-pane label="预览" name="preview" @init="preview" >
                    <div v-html="result" style="text-align: left"></div>
                </el-tab-pane>
            </el-tabs>
            <div v-if="toolbars">
                <el-button type="text" class="tab-btn" style="left: 290px" @click="bold">
                    <i class="fa-mavon-bold"></i></el-button>
                <el-button type="text" class="tab-btn" style="left: 330px" @click="italic">
                    <i class="fa-mavon-italic"></i></el-button>
                <el-button type="text" class="tab-btn" style="left: 370px" @click="code">
                    <i class="fa-mavon-code"></i></el-button>
                <el-button type="text" class="tab-btn" style="left: 410px" @click="quote">
                    <i class="fa-mavon-quote-left"></i></el-button>
            </div>
        </el-card>
        <el-divider></el-divider>
        <div v-for="(item,index) in array" :key="index + keyCount">
            <TaskCommentItemComponent :data="item" @refreshList="getCommentsList(curPage,taskId)" ></TaskCommentItemComponent>
        </div>
        <el-pagination style="margin-top: 20px;text-align: right" :current-page="curPage" :page-size="size" v-show="showPage"
                       background layout="prev, pager, next" :total="total"
                       @current-change="handleCurrentChange"></el-pagination>
    </div>
</template>

<script>
    import TaskCommentItemComponent from "./TaskCommentItemComponent";
    import "mavon-editor/dist/css/index.css"
    import comment from "@/utils/task/comment";
    import common from "../../../utils/common";
    export default {
        name: "TaskCommentComponent",
        components:{TaskCommentItemComponent},
        props:{
            taskId:String
        },
        data(){
            return {
                relatedId:"",
                value:"",//comment value
                result:"",//comment of html type
                toolbars:true,//show toolbars or not
                size:20,//comment list page size
                curPage:1,//current page
                total:0,
                array:[],
                //用于更新评论列表
                keyCount: 0,
                showPage:false
            }
        },
        watch:{
            taskId:function(value){
                this.relatedId = value;
                this.getCommentsList(1,value)
            }
        },
        mounted(){
            if (this.taskId){
                this.relatedId = this.taskId;
                this.getCommentsList(1,this.taskId)
            }
        },
        methods:{
            getCommentsList(page,relatedId){
                this.curPage = page;
                comment.getList(page,this.size,relatedId).then((res)=>{
                    if (res.data.success){
                        this.array = res.data.data.list;
                        this.total = res.data.data.total;
                        this.keyCount += this.size;
                        if(this.total>this.size)
                            this.showPage=true
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            },
            //comment edit and preview tab pages
            handleClick(tab){
                tab.$listeners.init()
            },
            //preview comment init
            preview(){
                this.toolbars = false;
                this.result = common.getMarkdownIt().render(this.value);
            },
            //edit page init
            edit(){
                this.toolbars = true;
            },
            saveComment(){
                if (common.isBlank(this.relatedId)){
                    this.$message.error("无法获取任务id");
                    return;
                }
                var result = {
                    content:this.value,
                    relatedId:this.relatedId,
                };
                comment.saveComment(result).then((res)=>{
                    if (!res.data.success){
                        this.$message.error(res.data.message);
                    }else {
                        this.getCommentsList(1,this.taskId)
                        this.value = ""
                    }
                })
            },
            //加粗
            bold(){
                this.value+="**粗体**"
            },
            //斜体
            italic(){
                this.value+="*斜体*"
            },
            code(){
                this.value+="```java\n代码块\n```"
            },
            quote(){
                this.value+=">引用"
            },
            handleCurrentChange(val){
                this.curPage = val;
                this.getCommentsList(val,this.taskId);
            }
        }
    }
</script>

<style scoped>
    .comment-list >>> .el-divider--horizontal {
        margin: 10px 0 0 0;
    }
    .comment-list >>> .el-card__body{
        padding: 0 20px;
    }
    ::v-deep .el-tabs__item{
        width: 100px;
        padding: 0;
        font-size: 13px;
    }
    ::v-deep .el-tabs__content{
        padding: 0 0 10px 0 !important;
    }
    ::v-deep .el-tabs__header{
        margin: 0 0 5px;
    }
    .tab-btn{
        position: absolute;
        top: 1px;
        color: #555855;
        font-size: 13px;
    }
    ::v-deep .el-textarea__inner{
        font-size: 13px !important;
        font-family: 微软雅黑;
        resize: none;
    }
    .comment-edit >>> .el-textarea__inner{
        border: none !important;
        outline: none !important;
    }
    .comment-edit >>> p{
        margin: 5px 0;
        padding: 0 20px;
    }
    .el-tab-pane >>> .el-button{
        background: #2089aa;
        color: #ffffff;
    }
    .el-tab-pane >>> .el-button:hover{

    }
</style>