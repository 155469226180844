<template>
    <div   style="border:1px solid #BBBBBB;top:calc(50vh - 180px);position:relative;margin-left:  auto;margin-right:auto;width: 620px;min-height: 280px;background-color: #FAFEFF;border-radius: 8px;">
       <img src="~@/assets/img/wait-img.png" style="width: 40px;height: 40px;margin-left: -120px;margin-top: 35px;position: absolute;" />
       <img src="~@/assets/img/wait-words.png" style="width: 180px;height: 40px;margin-left:-66px;margin-top: 35px;position: absolute; ">
        <p style="font-size: 18px;font-family: '微软雅黑;color: #2D3B41;margin-left:34px;margin-top:111px;position: absolute;">正在跳转至外部链接，请注意您的账号和财产安全</p>
        <p style="font-size: 16px;font-family: '微软雅黑;color: #2D3B41;margin-left:34px;margin-top:154px;position: absolute;">您即将离开波粒派，跳转至：
        {{$route.query.link|ellipsis}}
        </p>
        <el-button @click="goPage($route.query.link)" style="margin-left:178px;margin-top:214px;width: 100px;height: 32px;color: #FFFFFF;background-color: #2696BB;border-radius:6px;position: absolute;">
            <p style="margin-top: -4px;">  继续访问 </p>
        </el-button>
    </div>
</template>

<script>
    export default {
        name: 'index',
        data () {
            return {
            }
        },
        filters:{
            ellipsis(value){
                if (!value) return '';
                if (value.length > 38) {
                    return value.slice(0,38) + '...'
                }
                return value
            }
        },
        mounted(){
        },
        methods:{
            goPage(url){
                window.open(url,"_self")
            }
        }
    }
</script>

<style scoped>

</style>