<template>
    <el-card class="item-task-impl" shadow="hover">
        <el-row>
            <el-col :span="20" style="text-align: left;margin-top:11px;">
                <span class="my-body-title"  style="font-size:15px">{{item.title}}</span>

                <div v-for="(item2,index2) in tags" :key="index2"  style="margin-top:13px;">
                    <el-popover v-if="tags[index2].tagName.indexOf('@')!==-1"
                            placement="bottom"
                            width="200"
                            trigger="click">
                        <el-table :data="gridData" :cell-style="{padding:'0px'}">
                            <el-table-column width="200"  property="title" label="项目列表"></el-table-column>
                        </el-table>
                        <el-tag v-if="tags[index2].tagName.indexOf('@')!==-1" slot="reference"  type="warning" size="small"   class="my-tag" style="float: left;cursor:pointer;" @click="clickAuthor(tags[index2].tagName)" >{{tags[index2].tagName}} </el-tag>
                        <el-tag v-else slot="reference"  type="warning" size="small"   class="my-tag" style="float: left;" @click="clickAuthor(tags[index2].tagName)" >{{tags[index2].tagName}} </el-tag>
                    </el-popover>
                    <el-tag v-if="tags[index2].tagName.indexOf('@')===-1" disable-transitions type="success" style="margin-left:17px;float: left;" size="small"  class="my-tag">{{tags[index2].tagName}}</el-tag>
                </div>
            </el-col>
            <el-col :span="2.5" style="padding-top: 10px; float: right;cursor: pointer;" @click.native="showProject(item.id)" >
                <span  style="font-size: 14px;color: #008DB0;font-weight:bold;" > 了解更多</span>
                <i class="el-icon-d-arrow-right" style="margin-left: 5px;font-weight:bold;color: #008DB0;"></i>
            </el-col>
        </el-row>
        <el-row style="text-align: left;margin-top:10px;">
            <p  class="my-body-summary" >{{item.summary}}</p>
        </el-row>
        <el-row>
<!--            <a :href="item.href" target="_blank" style="text-decoration:none;color: white">-->
<!--            </a>-->
        </el-row>

    </el-card>
</template>

<script>
    import project from "../../utils/project"
    import video from "../../utils/study/video";
    export default {
        name: "PreferenceItemComponent",
        props:{
            item:Object,
            personal:{
                type:Boolean,
                default:true
            }
        },
        data(){
            return {
                info:{},
                tags:[],
                gridData:[]
            }
        },
        watch:{
          item:function () {
              this.getTag(this.item.id);
          }
        },
        mounted(){
            this.getTag(this.item.id);
        },
        methods:{
            clickAuthor(author){
                project.getProjectByAuthor(author).then((res)=>{
                    if(res.data.success){
                        console.log(res.data.data.list)
                        this.gridData=res.data.data.list
                        // this.gridData.proTitle=res.data.data.list
                    }
                })

            },
            showProject(projectId){
                //获取project内容
                project.getProject(projectId,this.item.title,this.$cookies.get("userId"),this.item.type).then((res)=>{
                    if(res.data.success&&res.data.data.content!=''){
                      let routerjump= this.$router.resolve(`/ebpf/home/project/show/${projectId}`)
                      window.open(routerjump.href,'_blank')
                    }else{
                        // window.open(this.item.href)
                        video.goPage(this.item.href,this.$router)
                    }

                })
            },

            getTag(relatedId){
                project.getTag(relatedId).then((res)=>{
                    if(res.data.success){
                        this.tags=res.data.data.list

                    }
                })
            }

        }
    }
</script>

<style scoped>


    .item-task-impl{
        margin-bottom: 8px;
    }
    ::v-deep .el-card__body{
        padding: 10px 20px;
    }
    p{
        white-space: pre-wrap;
        margin: 10px 0;
    }
    .item-content{
        margin-left: 16px;
    }
    ::v-deep .el-button{
        padding: 5px 0;
    }
    .status-content{
        font-size: 15px;
        color: #cccccc;
        float: right;
    }
    .btn-gray{
        color: #c8c8c8;
    }
</style>