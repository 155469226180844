<template>
    <el-container class="home"  style="overflow:hidden; display:flex;" >
        <el-header style="width: 102%;margin-left: -20px;" height="50px" >
            <HeaderMenu></HeaderMenu>
        </el-header>
        <router-view >
        </router-view>
        <el-footer class="my-layout" height="32px" v-show="!this.$route.meta.unfooter" style="background-color: #E5EAF0;position: relative;z-index: 30;">
            <a class="my-layout-icp" @click="goPage('/personalCenter/aboutus')">关于我们</a>
            <a class="my-layout-icp" @click="goPage('/personalCenter/agreement')">用户隐私协议</a>
            <a class="my-layout-icp" @click="goPage('/personalCenter/complaint')">举报申诉规则</a>
            <a class="my-layout-icp" @click="goPage('/personalCenter/problem')">常见问题</a>
            <a class="my-layout-icp" @click="goPage('/personalCenter/reports')">意见反馈</a>
            <a class="weixin">
                <img class="qrcode" src="~@/assets/img/bolipi.jpg" alt="微信二维码">
                <img src="~@/assets/img/vxicon.png" class="my-layout-icp" style="margin-top: 8px; width: 20px;height: 18px;">
            </a>
            <a class="my-layout-icp" href="https://beian.miit.gov.cn" target="_blank">苏ICP备2022009107号-1</a>
        </el-footer>
    </el-container>

</template>

<script>
    import HeaderMenu from '../components/common/HeaderMenu'
    export default {
        name: "home",
        components: {
            HeaderMenu
        },
        methods:{
            goPage(route){
                this.$router.push(route)
            }
        }
    }
</script>


<style scoped>
    .home {
        min-height: 100vh;
        display: flex;
    }
    .weixin img.qrcode {
        position: absolute;
        z-index: 99;
        bottom: 30px;
        left:470px;
        width: 7.5rem;
        max-width: none;
        height: 7.5rem;
        transform: scale(0);
        transform-origin: bottom center;
        opacity: 0;
        /*border: .3125rem solid #0085ba;*/
        border-radius: .25rem;
        -webkit-transition: all .4s ease-in-out;
        -o-transition: all .4s ease-in-out;
        transition: all .4s ease-in-out;
    }
    .weixin:hover img.qrcode {
        transform: scale(1);
        opacity: 1;
    }
</style>