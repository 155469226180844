<template>
    <el-container class="bg">
        <div class="background" style="margin: auto auto">
            <el-row>
                <el-col :span="12">
                    <div class="left">
                        <img src="@/assets/img/ebpf.png" style="width:420px;height:350px;margin-left: 0px;margin-top:60px;">
                        <p style="font-size: 14px;color: #539929;margin-top:58px;margin-left: -65px;"><a href="https://cilium.io" style="color: #539929;text-decoration:none" target="_blank">Cilium 是一个开源软件，https://cilium.io</a></p>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="right">
                        <span @click="goPage2('/ebpf/home/study/doc?tag=官方文档')" style="color: black;margin-left:150px;text-decoration: none;font-size: 18px;position: absolute;padding-top: 36px;cursor: pointer;">登录</span>
                        <el-form :model="register"  status-icon :rules="rules" ref="ruleForm" label-width="60px" class="form" >
                            <h2 style="margin-left:-5px;padding-top:50px;color: #3E4A4A" class="el-icon-user-solid">&nbsp;&nbsp;账号注册</h2>
                            <el-form-item  prop="umUsers.phoneNumber" style="margin-left:5px;width: 400px;margin-top:10px">
                                <el-input  placeholder="请输入手机号" v-model="register.umUsers.phoneNumber" class="a1"></el-input>
                            </el-form-item>
                            <el-form-item  prop="umUsers.password"  style="margin-left:5px;width: 400px;">
                                <el-input   placeholder="请输入密码"  v-model="register.umUsers.password" type="password" show-password class="a1"></el-input>
                            </el-form-item>
                            <el-form-item prop="status" style="margin-left: 5px;width: 400px;">
                                <slider @success="setStatus" v-model="register.status"></slider>
                            </el-form-item>
                            <el-form-item  prop="verifyCode" class="pr" style="margin-left:5px;width: 400px;">
                                <el-input placeholder="请输入短信验证码"  v-model="register.verifyCode" class="a1"></el-input>
                                <button @click.prevent="getCode()" class="code-btn" :disabled="!show">
                                    <span v-show="show">获取验证码</span>
                                    <span v-show="!show" class="count">{{count}}秒</span>
                                </button>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="2" :offset="8">
                                    <el-button @click="submitForm('ruleForm')"  class="btn-register">注册</el-button>
                                </el-col>
                            </el-form-item>
                            <el-form-item prop="checked" style="margin-left: -60px;">
                                <el-checkbox v-model="register.checked">
                                    <p style="font-size: 15px;margin-top:25px;margin-left:-5px">我已阅读并接受波粒派<el-button type="text" @click="privacyDialog = true" style="text-decoration: none">《用户及隐私协议》</el-button></p>
                                </el-checkbox>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-col>
            </el-row>
            <el-dialog :visible="privacyDialog" @close="privacyDialog = false">
                <privacy></privacy>
                <el-button @click="privacyDialog = false">关闭</el-button>
            </el-dialog>
        </div>
    </el-container>
</template>

<script>
    import Slider from "../../components/login/verifySliderComponent";
    import register from "@/utils/login/register";
    import loginApi from "../../utils/login/loginApi";
    import Privacy from "../../components/login/privacy";
    export default {
        name: "registerComponent",
        components: {Privacy, Slider},
        data() {
            //手机号校验
            let telCheck = (rule, value, callback) => {
                let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
                if (value === "") {
                    return callback(new Error("请输入手机号"));
                } else if (value.length !== 11) {
                    return callback(new Error("手机号码必须是11位数字"));
                } else if (!reg.test(value)) {
                    return callback(new Error("请输入有效的手机号码"));
                } else {
                    callback();
                }
            };
            //密码校验，必须为8~18位包含数字和字母的密码
            let validatePass = (rule, value, callback) => {
                let regex = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]{8,18}$");
                if (value === "") {
                    callback(new Error("请输入密码"));
                } else if (value.length < 8 || value.length > 18) {
                    callback(new Error("请输入8~18位包含数字与字母密码"));
                } else if (!regex.test(value)) {
                    callback(new Error("密码必须同时包含字母、数字，密码长度在8~18之间"));
                } else {
                    callback();
                }
            };
            //验证码校验，4位数字验证码
            let code = (rule, value, callback) => {
                let authReg = new RegExp("^[A-Za-z0-9]{6}$");
                if (value === "") {
                    return callback(new Error("请输入验证码"));
                } else if (!authReg.test(value)) {
                    callback(new Error("请输入正确的6位验证码"));
                } else {
                    return callback();
                }
            };
            //滑块验证
            let slider = (rule, value, callback) => {
                if (value === false) {
                    return callback(new Error("请验证滑块"));
                } else {
                    return callback();
                }
            };
            //用户及隐私协议验证
            let privacy = (rule, value, callback) => {
                if (value === false) {
                    return this.$message.error("请勾选波粒派《用户及隐私协议》");
                }  else {
                    return callback();
                }
            };
            return {
                register: {
                    umUsers:{
                        userId:'',
                        phoneNumber: '',
                        password: '',
                    },
                    verifyCode: '',
                    status: false,
                    checked:false
                },
                //获取短信验证码
                show: true,
                count: 60,
                privacyDialog:false,
                rules: {
                    umUsers:{
                        phoneNumber: [
                            {required: true, validator: telCheck, trigger: 'blur'}
                        ],
                        password: [
                            {required: true, validator: validatePass, trigger: 'blur'}
                        ]
                    },
                    verifyCode: [
                        {required: true, validator: code, trigger: 'blur'}
                    ],
                    status: [
                        {required: true, validator: slider, trigger: 'blur'}
                    ],
                    checked: [
                        { required:true,validator:privacy, trigger: 'blur'}
                    ]
                }
            }
        },
        mounted(){
            //获取uuid
            register.getRegisterToken().then((res)=>{
                if (res.data.success){
                    this.register.umUsers.userId = res.data.data.token;
                }else{
                    this.$message(res.data.message)
                }
            })
        },
        methods: {
            goPage2(url){
               this.$router.push({
                   path:url,
                   query:{
                       loginFlag:true
                   }
               })
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        register.register(this.register).then((res)=>{
                            if (res.data.success){
                                localStorage.setItem("register",'true');
                                this.$router.go(-1);
                            }else{
                                this.$message(res.data.message)
                            }
                        })
                    } else {
                        return false;
                    }
                });
            },
            goPage(route) {
                this.$router.push(route)
            },
            //获取验证码
            getCode() {
                if (this.register.umUsers.phoneNumber === "") {
                    this.$message.error("手机号不得为空");
                    return;
                }
                if(this.register.umUsers.phoneNumber.length!==11){
                    this.$message.error("请输入11位手机号");
                    return;
                }
                let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
                if(!reg.test(this.register.umUsers.phoneNumber)){
                    this.$message.error("请输入有效手机号");
                    return;
                }
                if (this.register.status === false) {
                    this.$message.error("请验证滑块");
                    return;
                }
                loginApi.isPhoneExist(this.register.umUsers.phoneNumber).then((res)=>{
                    if (res.data.success){
                        this.$message.error("该手机号已被注册，请重新输入")
                    }else{
                        this.sendVerifyCode();
                    }
                })
            },
            //获取短信结束
            setStatus() {
                this.register.status = true;
            },
            sendVerifyCode(){
                //axios请求,发送验证码
                register.getVerifyCode(this.register.umUsers.phoneNumber).then((res)=>{
                    if (!res.data.success){
                        this.$message(res.data.message)
                    }else{
                        this.setTimer(res.data.data.second);
                    }
                });
            },
            setTimer(count){
                // 验证码倒计时
                if (!this.timer) {
                    this.count = count;
                    this.show = false;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= count) {
                            this.count--;
                        } else {
                            this.show = true;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000);
                }
            }
        }
    }
</script>

<style scoped>
    .bg{
        background: url("~@/assets/img/registerbg.jpg") no-repeat;
        /*兼容浏览器版本*/
        background-size: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: auto;
    }
    .background{
        background-color: rgba(214, 214, 214, 0);
        border-radius: 40px 40px 40px 40px;
        width:960px;
        height: 540px;
    }
    .left{
        width:480px;
        height:540px;
        background-color:#aed0a6;
        border-radius: 40px 0  0 40px;
        box-shadow:4px 4px 10px gray;
    }
    .right{
        width: 480px;
        height:540px;
        background-color: #FEFFfB;
        border-radius: 0 40px 40px 0;
        box-shadow:4px 4px 10px gray;
    }
    /*注册按钮*/
    .btn-register{
        width:338px;
        margin-left:-130px;
        margin-top:10px;
        background-color: #E5F0D0;
        color:#3e4a4a;
        box-shadow:0px 2px 6px 0px #EAE9E9;
    }
    .btn-register:hover{
        border: 1px solid #7a9743;
    }
    /*获取验证码按钮css*/
    .pr {
        position: relative;
    }
    .code-btn {
        width: 100px;
        height: 20px;
        position: absolute;
        top: 10px;
        right: 5px;
        z-index: 222;
        color: #539929;
        font-size: 14px;
        border: none;
        border-left: 1px solid #bababa;
        padding-left: 10px;
        background-color: #fff;
        cursor: pointer;
    }
    /*阴影*/
    .a1{
        box-shadow:0px 2px 6px 0px #EAE9E9;
    }
</style>