function getTaskStep(finished) {
    return ([
        {
            element: '#uploads',
            popover: {
                title: '上传网址',
                description: '这里是为大家准备的上传入口，你可以上传在学习过程中觉得好的资料链接',
                position: 'down'
            }
        },
        {
            element: '#wareTabs',
            popover: {
                title: '素材库',
                description: '我的素材是为大家准备的属于自己的仓库；实验报告存放自己提交的实验报告；素材广场可以看到大家觉得有价值的内容',
                position: 'top'
            },
            onNext(){
                finished()
            }
        }
    ]);
}

function getHomeStep(finished) {
    return ([
        {
            element: '#home_guide',
            popover: {
                title: '新手指引',
                description: '点击即可进入新手指引过程',
                position: 'right'
            }
        },
        {
            element: '#home_video',
            popover: {
                title: '什么是eBPF',
                description: '点击观看视频可以帮助您快速了解eBPF。',
                position: 'down'
            }
        },
        {
            element: '#home_penguin',
            popover: {
                title: '开启探索之旅',
                description: '点击开启eBPF探索之旅',
                position: 'down'
            }
        },
        {
            element: '#home_bee',
            popover: {
                title: 'solo-io',
                description: '这些是eBPF的相关项目之一，点击可进入官网',
                position: 'down'
            }
        },
       /* {
            element:'#home_prize',
            popover: {
                title: '兑换奖励',
                description: '点击进行积分兑换奖励',
                position: 'down'
            }
        },*/
       /*
        {
            element: '#home_bee_guard',
            popover: {
                title: 'cilium',
                description: '这些是eBPF的相关项目之一，点击可进入官网',
                position: 'down'
            }
        },
        {
            element: '#home_hourse',
            popover: {
                title: 'eunomia-bpf',
                description: '点击可前往查看github eunomia项目',
                position: 'down'
            }
        },
        {
            element: '#home_falco',
            popover: {
                title: 'falco',
                description: '点击可前往查看falco官网',
                position: 'down'
            }
        },
        {
            element: '#home_bcc',
            popover: {
                title: 'bcc',
                description: '点击可前往查看bcc官网',
                position: 'down'
            }
        },*/
        {
            element: '#home_login',
            popover: {
                title: '登录入口',
                description: '点击即可进入登录账号',
                position: 'top'
            },
            onNext(){
                finished()
            }
        }
    ]);
}

function getPracticeStep(finished) {
    return ([
        {
            element: '#getPracticeAccount',
            popover: {
                title: '登录实践账号',
                description: '使用本网站注册的手机号和密码进行登录即可，登录一次即可，再次登录本网站时无需重新登录实践账号',
                position: 'down'
            }
        },
        {
            element: '#applyPractice',
            popover: {
                title: '申请实践空间',
                description: '首次申请实践空间时需要先登录实践账号，登录后即可点击申请',
                position: 'down'
            },
            onNext(){
                finished()
            }
        }
    ]);
}

export default {getTaskStep,getHomeStep,getPracticeStep}