<template>
    <el-container class="index" style="height: 100vh;display: flex">
        <el-header >
            <!--        太阳本页-->
            <img src="~@/assets/img/home/sunn.png" style="top:1.5%;left:3%;" class="home-header" @click="goPage('/ebpf/index')"/>
            <!--        探险手册-->
            <div class="home-header study" @click="goPage('/ebpf/home/study/doc?tag=官方文档')">
                <div style="position: relative;line-height: 74px">
                    <span class="my-nav-menu-title">探险手册</span>
                </div>
            </div>
            <!--        探索之旅-->
            <div class="home-header task" @click="goPage('/ebpf/home/activityPoster')">
                <div style="position: relative;line-height: 74px">
                    <span class="my-nav-menu-title">探索之旅</span>
                </div>
            </div>
            <!--        项目集市-->
            <div class="home-header project" @click="goPage('/ebpf/home/project/item?type=eBPF技术与发展')">
                <div style="position: relative;line-height: 70px;top: 4px">
                    <span class="my-nav-menu-title">项目集市</span>
                </div>
            </div>
            <!--        消息驿站-->
            <!--<div class="home-header news" @click="goPage('/ebpf/home/news')">
                <div style="position: relative;line-height: 74px;left: -12px">
                    <span class="my-nav-menu-title">消息驿站</span>
                </div>
            </div>-->
        </el-header>
        <el-main class="my-layout-main" style="overflow: hidden;text-align: left;padding: 0">
            <div></div>
            <!--        视频入口泡泡-->
            <img src="~@/assets/img/home/video.png" id="home_video" @click="videoShow = true" style="top:20%;left:5%;" class="my-bubble"/>
            <!--        暑期企鹅泡泡-->
            <img src="~@/assets/img/home/bubble1.png" id="home_penguin" style="top:25%;left:18%;z-index:2;position: absolute;cursor: pointer;" @click="goPage('/ebpf/home/activityPoster')"/>
            <!--        蜜蜂泡泡-->
            <img src="~@/assets/img/home/bubble2.png" id="home_bee" style="top:30%;left:43%;" class="my-bubble" @click="newPage('https://github.com/solo-io/bumblebee')"/>
            <!--        蜜蜂守卫泡泡-->
            <img src="~@/assets/img/home/bubble3.png" id="home_bee_guard" style="top:50%;left:35%;" class="my-bubble" @click="newPage('https://github.com/cilium/tetragon')"/>
            <!--        eunomia bpf -->
            <img src="~@/assets/img/home/bubble4.png" id="home_hourse" style="top:13%;left:35%;" class="my-bubble" @click="newPage('https://github.com/eunomia-bpf/eunomia-bpf')"/>
            <!--        蓝标泡泡-->
            <img src="~@/assets/img/home/bubble5.png" id="home_falco" style="top:12%;left:17%;" class="my-bubble" @click="newPage('https://falco.org')"/>
            <!--        紫标泡泡-->
            <img src="~@/assets/img/home/bubble6.png" id="home_bcc" style="top:63%;left:24%;" class="my-bubble" @click="newPage('https://github.com/iovisor/bcc')"/>
            <!--        右云-->
            <div class="cloud">
                <div style="position: relative;line-height: 74px;text-align: center">
                    <el-avatar v-if="isLogin" style="display: inline-block;vertical-align: middle" size="medium" :src="avatar"></el-avatar>
                    <el-button type="text" class="my-nav-menu-title" @click="open"
                               style="display:inline-block;vertical-align: middle;">
                        {{isLogin?"个人主页":"登录"}}</el-button>
                </div>
            </div>
            <!--        鳄鱼跳转使用指南-->
            <img src="~@/assets/img/home/crocodile.png" id="home_guide" style="top:63%;left: 0" class="my-bubble" @click="start" />
            <!--        树丛-->
            <img src="~@/assets/img/home/grass.png" style="z-index:2;position: absolute;left: 0;bottom: 0"/>
            <!--        奖励兑换鳄鱼-->
            <img src="~@/assets/img/home/prize.png" id="home_prize" style="top:45%;left:67%;cursor: auto;" class="my-bubble" />
<!--
            <img src="~@/assets/img/home/prize.png" id="home_prize" style="top:45%;left:67%;" class="my-bubble" @click="goPage('/personalCenter/achievement')"/>
-->
            <!--        浏览图标1-->
            <img src="~@/assets/img/home/icon1.png" style="top:67%;left:65%;" class="my-bubble2"/>
            <div class="history" @click="lastView(my.history.url,my.history.title)">
                <div style="position: relative;line-height: 16px;padding: 0 5px;text-align: center">
                    <p class="home-dialog-text">上次浏览：</p>
                    <p class="home-dialog-text text">{{my.history.title}}</p>
                </div>
            </div>
            <!--        任务图标2-->
            <img src="~@/assets/img/home/icon2.png" style="top:27%;left:70%;" class="my-bubble2"/>
            <div class="my-task" @click="goPage2('/ebpf/home/taskTemp/task?taskId='+my.task.id)">
                <div style="position: relative;line-height: 16px;left: -12px;text-align: center">
                    <p class="home-dialog-text">我的任务：</p>
                    <p class="home-dialog-text text">{{my.task.title}}</p>
                </div>
            </div>
            <!--        评论图标3-->
            <img src="~@/assets/img/home/icon3.png" style="top:68%;left:75%;" class="my-bubble2"/>
            <div class="comment" @click="goPage2('/ebpf/home/taskTemp/task?taskId=bd40ed654dcf40d5b8b191b058174d5b')">
                <div style="position: relative;line-height: 16px;top: 25px;text-align: center">
                    <p class="home-dialog-text">评论回复：</p>
                    <p class="home-dialog-text text">{{my.comment}}</p>
                </div>
            </div>
            <!--        笔记图标4-->
            <img src="~@/assets/img/home/icon4.png" style="top:55%;left:87%;" class="my-bubble2"/>
            <div class="notepad" @click="goPage2('/personalCenter/notepad')">
                <div style="position: relative;line-height: 16px;top: 25px;text-align: center">
                    <p class="home-dialog-text">我的笔记：</p>
                    <p class="home-dialog-text text">{{my.task.title}}</p>
                </div>
            </div>
            <!--        分享图标5-->
            <img src="~@/assets/img/home/icon5.png" style="top:35%;left:83%;" class="my-bubble2"/>
            <div class="my-share" @click="newPage(my.share.url)">
                <div style="position: relative;line-height: 16px;left: 4px;text-align: center">
                    <p class="home-dialog-text">我的分享：</p>
                    <p class="home-dialog-text text">{{my.share.title}}</p>
                </div>
            </div>
            <!--        地图企鹅登录-->
            <img :src="isLogin?loginSrc:logoutSrc" id="home_login" style="top:62%;left:44%;" class="my-bubble" @click="open"/>
            <!--        地图企鹅登录弹窗-->
            <el-dialog :visible.sync="dialogFormVisible"
                       :show-close="false"
                       :close-on-click-modal="false"
                       width="960px" top="calc(50vh - 380px)"
                       :modal-append-to-body='false'>
                <el-button type="success" icon="el-icon-close" circle class="my-login-button1" style="margin-left: 990px"
                           @click="closeLogin"></el-button>
                <loginComponent @closeLogin="closeLogin" @setUserInfo="setUserInfo"></loginComponent>
            </el-dialog>
            <!--        个人探险手册弹窗-->
            <el-dialog :visible.sync="register"
                       :show-close="false"
                       :modal-append-to-body='false'
                       width="960px" top="calc(50vh - 380px)">
                <el-button type="success" icon="el-icon-close" circle class="my-login-button1" style="margin-left: 990px"
                           @click="register = false"></el-button>
                <personal-component @closePersonal="register = false"></personal-component>
            </el-dialog>
            <!--视频播放弹窗-->
            <el-dialog  v-if="videoShow" visible width="960px" :show-close="false" :modal-append-to-body='false' :close-on-click-modal="false">
                <el-button type="primary" icon="el-icon-close" style="margin-left: 900px;" circle @click="videoShow = false"></el-button>
                <video controls autoplay width="800px" src="/uploads/video/EBPF0702.mp4"></video>
            </el-dialog>
            <el-footer style="z-index: 2000;">
                <el-row type="flex" justify="end">
                    <el-col :lg="12" :md="16" :xl="9">
                        <a href="https://beian.miit.gov.cn" target="_blank" class="icp"  style="margin-left: -100px;"><p>苏ICP备2022009107号-1</p></a>
                        <a class="icp" style="margin-left: 100px;cursor:pointer;" @click="goPage('/personalCenter/aboutus')"><p>关于我们</p></a>
                        <a class="icp" style="margin-left: 200px;cursor:pointer;" @click="goPage('/personalCenter/agreement')"><p>用户隐私协议</p></a>
                        <a class="icp" style="margin-left: 330px;cursor:pointer;" @click="goPage('/personalCenter/complaint')"><p>举报申诉规则</p></a>
                        <a class="icp" style="margin-left: 450px;cursor:pointer;" @click="goPage('/personalCenter/problem')"><p>常见问题</p></a>
                        <a class="icp" style="margin-left: 550px;cursor:pointer;" @click="goPage('/personalCenter/reports')"><p>意见反馈</p></a>
                        <a class="icp" style="margin-left: 640px;cursor:pointer;" @click="goPage('/personalCenter/useguide')"><p>使用指南</p></a>
                    </el-col>
                </el-row>
            </el-footer>
        </el-main>
    </el-container>
</template>

<script>
    import loginComponent from "../../components/login/loginComponent";
    import personalComponent from "../../components/login/personalComponent";
    import common from "../../utils/common";
    import personalQuery from "../../utils/personalCenter/personalQuery";
    import video from "../../utils/study/video";
    import taskStep from "../../utils/driver/taskStep";
    import fileApi from "../../utils/task/fileApi";
    export default {
        name: "home_temp",
        data(){
            return {
                dialogFormVisible:false,
                register:false,
                loginSrc:require("@/assets/img/home/map.png"),
                logoutSrc:require("@/assets/img/home/signe.png"),
                isLogin:false,
                avatar:"/uploads/img/resource/initialAvatar.png",
                videoShow:false,
                my:{
                    history:{title:"请先登录",url:""},
                    comment:"请先登录",
                    share:{title:"请先登录",url:""},
                    task:{title:"请先登录"}
                },
            }
        },
        components:{
            loginComponent,personalComponent
        },
        mounted() {
            //如果用户刚刚注册，将登录信息保存到cookie中
            if (localStorage.getItem("register") === "true") {
                this.register = true;
                localStorage.removeItem("register");
            }
            this.isLogin = this.getLogin();
            this.initPage();
        },
        methods:{
            setUserInfo(){
                this.isLogin = true;
                //此处无需处理登录成功的信息
                this.closeLogin();
                this.initPage();
                //新手指引
                let guide = parseInt(this.$cookies.get("guide"));
                if (common.needGuide(guide,1)) {
                    this.start();
                }
            },
            closeLogin(){
                this.dialogFormVisible=false;
            },
            goPage(route,query){
                this.$router.push({
                    path:route,
                    query:query
                })
            },
            goPage2(route,query){
                if (this.getLogin()){
                    this.$router.push({
                        path:route,
                        query:query
                    })
                }else{
                    this.dialogFormVisible = true;
                }
            },
            getLogin(){
                return !common.isBlank(this.$cookies.get("isLogin")) && this.$cookies.get("isLogin") === "1"
            },
            open(){
                if (this.getLogin()){
                    this.goPage('/personal/'+this.$cookies.get("userId"));
                }else{
                    this.dialogFormVisible = true;
                }
            },
            initPage(){
                if (this.isLogin){
                    //初始化路径（浏览，任务，分享，讨论等）
                    personalQuery.initHomePage().then((res)=>{
                        if(res.data.success){
                            this.my.history = res.data.data.history === null?{title:"您还没有浏览记录",url:""}:res.data.data.history ;
                            this.my.comment = res.data.data.comment === undefined?"您还没有参与讨论":res.data.data.comment;
                            this.my.share = res.data.data.share === null?{title:"您还没有分享内容",url:""}:res.data.data.share;
                            this.my.task = res.data.data.task === null? {title:"您还没有开启任务"}:res.data.data.task;
                        }
                    })
                }
            },
            newPage(url){
                if (this.getLogin()) {
                    if (common.isBlank(url) || url === "") {
                        return
                    }
                    video.goPage(url, this.$router)
                }else {
                    this.dialogFormVisible = true;
                }
            },
            lastView(url,title){
                if (this.getLogin()) {
                    if (common.isBlank(url) || url === "") {
                        return;
                    }
                    fileApi.goPage(url+ "?title=" + title,this.$router)
                    // window.open(url + "?title=" + title, '_blank')
                }else{
                    this.dialogFormVisible = true;
                }
            },
            start(){
                this.$driver.defineSteps(taskStep.getHomeStep(()=>{
                    if (common.needGuide(this.$cookies.get("guide"),1)) {
                        personalQuery.updateGuide(1).then((res)=>{
                            if (res.data.success){
                                this.$cookies.set("guide",res.data.data.guide,"30d")
                            }
                        })
                    }
                }));
                this.$driver.start();
            }
        }
    }
</script>
<style scoped>
    .index >>> .el-dialog{
        background-color: rgba(0,0,0,0);
        box-shadow: 0 0 0 !important;
    }
    .index{
        background: url("~@/assets/img/home/bg2.jpg") no-repeat fixed;
        /*兼容浏览器版本*/
        background-size: 100% 100%;
        min-width: 1200px;
        min-height: 500px;
    }
    /*底部关于样式*/
    .icp{
        font-size:15px;
        color:white;
        text-decoration-line: none;
        position: fixed;
        text-align:center;
        bottom:-5px;
        margin:0 auto;
        z-index:10;
    }
    .home-header{
        z-index:2000;position:absolute;cursor: pointer;
    }
    .study{
        background: url("~@/assets/img/home/study.png") no-repeat;
        left:14%;
        width: 127px;
        height: 72px;
        top:2%;
    }
    .task{
        background: url("~@/assets/img/home/task.png") no-repeat;
        left:23%;
        width: 128px;
        height: 72px;
        top:2%;
    }
    .project{
        background: url("~@/assets/img/home/project.png") no-repeat;
        left:33%;
        width: 133px;
        height: 74px;
        top:1.5%;
    }
    .news{
        background: url("~@/assets/img/home/news.png") no-repeat;
        left:43%;
        width: 149px;
        height: 75px;
        top:1.5%;
    }
    .cloud{
        background: url("~@/assets/img/home/cloud.png") no-repeat;
        position:absolute;
        top:3%;
        left:90%;
        z-index:1;
        width: 135px;
        height: 74px;
    }
    .home-dialog-text{
        font-size: 14px;
        color: #777777;
        font-weight: bold;
        font-family: Avenir, Helvetica, Arial, sans-serif;
    }
    .text{
        display: block;
        overflow: hidden;
        word-break: keep-all;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .history{
        background: url("~@/assets/img/home/dialog1.png") no-repeat;
        position:absolute;
        z-index:1;
        width: 140px;
        height: 92px;
        top:57%;left:59%;
        cursor: pointer;
    }
    .my-task{
        background: url("~@/assets/img/home/dialog2.png") no-repeat;
        position:absolute;
        z-index:1;
        width: 153px;
        height: 82px;
        top:20%;left:59%;
        cursor: pointer;
    }
    .comment{
        background: url("~@/assets/img/home/dialog3.png") no-repeat;
        position:absolute;
        z-index:1;
        width: 159px;
        height: 116px;
        top:74%;left:74%;
        cursor: pointer;
    }
    .notepad{
        background: url("~@/assets/img/home/dialog4.png") no-repeat;
        position:absolute;
        z-index:1;
        width: 127px;
        height: 115px;
        top:60.5%;left:88%;
        cursor: pointer;
    }
    .my-share{
        background: url("~@/assets/img/home/dialog5.png") no-repeat;
        position:absolute;
        z-index:1;
        width: 153px;
        height: 76px;
        top:26%;left:85%;
        cursor: pointer;
    }
    .my-bubble{
        z-index:1;position: absolute;cursor: pointer;
    }
    .my-bubble2{
        z-index:1;position: absolute;
    }
</style>