import axios from "../http";

function getRewardList(page,size) {
    return axios({
        url:`/api/user/prize/list/${page}/${size}`,
        method:"get"
    })
}
function exchangeReward(id) {
    return axios({
        url:"/api/user/prize/exchange",
        method:"put",
        params:{
            id:id
        }
    })
}
function getDetailList(page,size) {
    return axios({
        url:`/api/user/prize/points/detail/${page}/${size}`,
        method:"get",
    })
}
function getExchangeList(page,size) {
    return axios({
        url:`/api/user/prize/exchange/list/${page}/${size}`,
        method:"get"
    })
}

export default {getRewardList,exchangeReward,getDetailList,getExchangeList}