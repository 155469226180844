<template>
    <div class="ware-house">
        <el-card style="text-align: left">
            <el-row id="uploads">
                <el-col :span="13">
                    <el-input v-model="input" placeholder="输入你想分享的网址" rel="code" id="link" clearable></el-input>
                </el-col>
                <!--    上传-->
                <el-col :span="3">
                    <el-button class="el-icon-upload upload-icon-btn" size="mini" @click="uploadLink"></el-button>
                </el-col>
            </el-row>

        </el-card>
        <el-divider></el-divider>
        <el-tabs value="first" class="my-tabs" id="wareTabs" @tab-click="handleTabClick" style="text-align: center;">
            <el-tab-pane label="我的素材" name="first">
                <FileListComponent :id="relatedId" ref="myFileList" v-if="tabIndex === '0'"></FileListComponent>
            </el-tab-pane>
            <el-tab-pane label="实验报告" name="second">
                <TaskImplListComponent :id="relatedId" v-if="tabIndex === '1'"></TaskImplListComponent>
            </el-tab-pane>
            <el-tab-pane label="素材广场" name="third">
                <FileListComponent :id="relatedId" :personal="false" v-if="tabIndex === '2'"></FileListComponent>
            </el-tab-pane>
        </el-tabs>
<!--        上传文件弹窗-->
        <el-dialog :visible.sync="dialogFormVisible" :show-close="false" top="0vh">
            <el-button type="info" icon="el-icon-close" circle @click="closeFile" style="margin-left:680px;margin-top: -10px;"></el-button>
            <UploadFileComponent :task-id="relatedId" @closeLogin="closeFile"></UploadFileComponent>
        </el-dialog>
        <!--上传资料弹窗-->
        <el-dialog :visible.sync="uploadDialogFormVisible" :show-close="false" top="0vh"  width="960px">
            <el-button type="info" icon="el-icon-close" circle @click="closeFile2" style="margin-left:920px;margin-top: -10px;"></el-button>
            <upload-data :taskId="taskId" @closeLogin="closeFile2" :link="input" @refreshList="refreshList"></upload-data>
        </el-dialog>
    </div>
</template>

<script>
    import UploadFileComponent from "@/components/task/material/UploadFileComponent";
    import TaskImplListComponent from "@/components/task/material/TaskImplListComponent";
    import FileListComponent from "@/components/task/material/FileListComponent";
    import fileApi from "@/utils/task/fileApi";
    import taskStep from "../../../utils/driver/taskStep";
    import common from "@/utils/common";
    import personalQuery from "@/utils/personalCenter/personalQuery";
    import UploadData from "./uploadData";
    export default {
        name: "WareHouseComponent",
        components:{
            UploadData, UploadFileComponent,TaskImplListComponent,FileListComponent
        },
        props:{
           taskId:String
        },
        data() {
            return {
                input:'',
                dialogFormVisible:false,
                uploadDialogFormVisible:false,
                tabIndex:'0',
                category:'',
            };
        },
        computed:{
            relatedId(){
                return this.taskId;
            }
        },
        mounted() {
            console.log(this.taskId)
            let guide = parseInt(this.$cookies.get("guide"));
            if (common.needGuide(guide,2)){
                this.start();
            }
        },
        methods:{
            closeFile2(){
                this.uploadDialogFormVisible=!this.uploadDialogFormVisible
            },
            start(){
                this.$driver.defineSteps(taskStep.getTaskStep(()=>{
                    if (common.needGuide(this.$cookies.get("guide"),2)){
                        personalQuery.updateGuide(2).then((res)=>{
                            if (res.data.success){
                                this.$cookies.set("guide",res.data.data.guide,"30d")
                            }
                        })
                    }
                }));
                this.$driver.start();
            },
            refreshList(){
                this.$refs.myFileList.getList(1,this.relatedId)
            },
            //分享网址
            uploadLink(){

                //校验输入的网址
                let reg =/^(http|https|ftp):\/\/([\w.]+\/?)\S*$/i;
                if(this.input===''){
                    this.$message.error("分享网址不能为空");
                    return;
                }
                else if(!new RegExp(reg).test(this.input)){
                    this.$message.error("网址格式不正确");
                    return;
                }
                // if(this.category.length === 0){
                //     this.$message.error("请选择文件类别");
                //     return;
                // }
                // fileApi.saveLink(this.input,this.relatedId,this.category).then((res)=>{
                //     if (!res.data.success){
                //         this.$message.error(res.data.message)
                //         if(!res.data.message.equals("本站资料中已有该网址"))
                //            this.saveLinkWithTitle();
                //     }else{
                //         this.$message(res.data.message);
                //         this.$refs.myFileList.getList(1,this.relatedId)
                //     }
                // })
                else {
                    this.uploadDialogFormVisible = !this.uploadDialogFormVisible
                }
            },
            //关闭上传文件弹窗
            closeFile(){
                this.dialogFormVisible=false;
            },
            handleTabClick(tab){
                this.tabIndex = tab.index
            },
            saveLinkWithTitle(){
                //输入网址详情弹窗
                this.$prompt('请填写网址标题：', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPattern:/^.+$/,
                    inputErrorMessage: '请描述分享的网址详情'
                }).then(({value}) => {
                    fileApi.saveLinkWithTitle(this.input,this.relatedId,this.category,value).then((res)=>{
                        if (!res.data.success){
                            this.$message.error(res.data.message)
                        }else{
                            this.$message(res.data.message);
                            this.$refs.myFileList.getList(1,this.relatedId)
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消分享链接'
                    });
                });
            }
        },
    }
</script>

<style scoped>
    .ware-house >>>.el-dialog{
        background-color: rgba(0,0,0,0);
        box-shadow: 0 0 0 !important;
    }
    /* el-divider 修改高度&虚线效果 */
    .el-divider--horizontal{
        margin: 10px 0 5px 0;
        background: 0 0;
        border-top: 1px dashed #a19f9c;
    }
    /*竖条分割线*/
    .el-divider--vertical {
        font-size: 30px;
    }
    /*标签栏样式开始*/
    /* 滑块横条 */
    ::v-deep .el-tabs__nav-wrap::after {
        margin-left: 15px;
        width:350px;
        position: static !important;
    }
    /* 设置标签未被选中样式 */
    ::v-deep .el-tabs__item{
        font-size:14px;
        padding: 0 20px !important;
        width: 120px;
        box-sizing: border-box;
        display: inline-block;
        position: relative !important;
    }
    ::v-deep .el-tabs__content{
        padding: 0 !important;
    }
    ::v-deep .el-tabs__header{
        margin: 0;
    }
    /*标签栏样式结束*/
    /*上传链接按钮*/
    .upload-icon-btn{
        border: none;
        font-size:25px;
        margin: 0 20px;
    }
    .upload-icon-btn:hover{
        background-color: white;
    }
    .upload-card >>> .el-card__body{
        padding: 10px;
    }
    .upload-btn{
        margin: 0 20px;
    }
</style>