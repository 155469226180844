<template>
    <div>
        <!--<h1>error 出错啦</h1>-->
        <img src="~@/assets/img/404/failedLoad.svg" />

        <p style="margin: -420px 0px 0px 40px;font-size: 21px;">点击 <span style="color: #347CAF;text-decoration: underline;cursor: pointer;" @click="goPage">刷新</span> 或者稍后再试 </p>
    </div>
</template>

<script>
    export default {
        name: "error",
        methods:{
            goPage(){
                this.$router.go(0)
            }
        }
    }
</script>

<style scoped>

</style>