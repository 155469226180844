<template>
    <div class="header">
        <el-menu  :default-active="activeIndex"  class="el-menu" active-text-color="#24A4CB" text-color="#004E67" :router="true" mode="horizontal">
            <el-menu-item index="/ebpf/index">
                <svg viewBox="0 0 566.93 566.93" style="height: 40px">
                    <path fill="#003D50" d="M248.66,432.99c-1.48,3.1-2.96,5.91-4.2,8.83c-6.42,15.15-12.34,30.48-16.1,46.56c-0.74,3.18-1.98,6.27-2.51,9.48c-0.81,4.84-7.86,7.95-12.56,3.53c-2.47-2.32-3.68-5.26-3.57-8.54c0.08-2.5,0.52-5.07,1.28-7.46
			c4.66-14.7,11.27-28.54,18.95-41.88c2.62-4.56,5.28-9.11,8.06-13.89c-41-15.41-73.15-41.6-95.28-79.47
			c-22.15-37.91-29.08-78.81-22.29-122.25c-1.24,0-1.93,0.92-2.72,1.53c-10.82,8.28-19.57,18.41-26.24,30.26
			c-2.75,4.9-4.9,10.12-7.42,15.16c-0.88,1.76-1.84,3.56-3.13,5.03c-1.57,1.78-3.61,1.17-3.88-1.18c-0.19-1.73,0-3.68,0.6-5.31
			c7.37-19.76,19.02-36.47,35.75-49.48c2.36-1.84,4.8-3.6,7.3-5.24c1.39-0.91,2.16-1.96,2.56-3.6
			c4.57-18.77,11.84-36.45,22.22-52.74c26.22-41.14,62.94-67.92,110.23-80.12c17.55-4.53,35.42-6.37,53.47-5.17
			c53.03,3.51,96.87,25.54,130.95,66.38c19.83,23.77,32.25,51.17,38.03,81.58c2.59,13.63,3.59,27.36,3.02,41.21
			c-0.02,0.44,0,0.89,0,1.74c0.76-0.57,1.32-0.92,1.79-1.36c14.15-13.33,23.75-29.47,29.46-47.98c1.2-3.9,2.01-7.92,3.11-11.85
			c0.38-1.37,0.87-2.82,1.71-3.92c1.27-1.65,2.86-1.42,3.99,0.32c0.83,1.28,1.11,2.71,0.72,4.17c-1.68,6.31-3.07,12.73-5.2,18.88
			c-6.96,20.1-18.37,37.22-35.05,50.62c-1.52,1.22-1.64,2.7-1.83,4.29c-2.02,16.23-6.18,31.89-12.45,46.99
			c-22.24,53.55-68.1,92.94-124.45,106.86c-1.38,0.34-2.52,0.86-3.5,1.9c-7.06,7.55-13.06,15.81-17.01,25.43
			c-1.53,3.72-2.63,7.57-2.85,11.57c-0.62,11.28,5.94,17.1,16.04,17.91c7.96,0.64,15.51-1.24,22.84-4.12
			c16.32-6.41,30.22-16.43,42.5-28.81c0.58-0.58,1.22-1.12,1.69-1.78c1.45-2.05,3.3-1.91,5.33-1.14c4.36,1.64,7.91,7.26,7.44,11.98
			c-0.07,0.75-0.46,1.59-0.95,2.16c-16.19,18.87-35.36,33.32-59.7,39.96c-9.05,2.47-18.24,3.08-27.47,0.73
			c-10.74-2.74-18.39-9.08-22.78-19.32c-2.13-4.97-1.82-10.14-1.2-15.3c0.85-7.1,3.09-13.83,6.16-20.26
			c2.23-4.67,4.81-9.16,7.24-13.73c0.42-0.79,0.91-1.54,1.66-2.8c-12.01,1.05-23.62,1.23-35.25,0.16
			C271.6,438.43,260.22,436.21,248.66,432.99z M445.85,296.9c-1.54,0.5-2.39,0.78-3.23,1.06c-21.07,7.08-42.55,8.76-64.51,5.32
			c-25.18-3.94-48.15-13.42-69.71-26.78c-12.01-7.44-22.86-16.42-33.97-25.03c-12.85-9.96-26.58-18.38-41.41-25.02
			c-23.73-10.62-48.46-15.73-74.52-13.38c-3.17,0.29-3.2,0.26-4.04,3.22c-8.79,31.09-8.06,61.93,2.69,92.43
			c25.46,72.24,100.87,113.95,175.6,97.22c50.09-11.21,91.44-47.67,108.86-96.03C443.08,305.82,444.31,301.66,445.85,296.9zM447.9,286.49c15-77.27-33.41-157.89-116.78-175.67c-80.74-17.22-149.53,32.89-170.95,90.34c0.68-0.14,1.29-0.25,1.9-0.4
			c17.61-4.37,35.36-4.85,53.27-2.09c23.41,3.61,45.01,12.03,65.3,24.07c13.55,8.04,25.98,17.65,38.24,27.49
			c12.76,10.24,26.67,18.56,41.58,25.26c21.45,9.63,43.84,14.75,67.45,13.9C434.64,289.14,441.31,288.48,447.9,286.49z"/>
                </svg>
            </el-menu-item>
            <el-menu-item style="margin-left: 40px" class="my-nav-menu-title top-nav-menu"
                          index="/ebpf/home/study/doc?tag=官方文档">探险手册</el-menu-item>
            <el-menu-item class="my-nav-menu-title top-nav-menu" index="/ebpf/home/activityPoster" >探索之旅</el-menu-item>
            <el-menu-item class="my-nav-menu-title top-nav-menu" index="/ebpf/home/project/item?type=eBPF技术与发展" >项目集市</el-menu-item>
<!--
            <el-menu-item class="my-nav-menu-title top-nav-menu" index="/ebpf/home/news">消息驿站</el-menu-item>
-->

            <el-menu-item style="float: right;">
                <el-dropdown v-if="userInfo.isLogin" @command="menuClick">
                    <div >
                        <el-avatar size="medium" :src="userInfo.avatar">登录</el-avatar>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="1">个人主页</el-dropdown-item>
                        <el-dropdown-item command="2">个人中心</el-dropdown-item>
                        <el-dropdown-item command="3">退出</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <button class="my-btn-clear" v-else @click="dialogFormVisible=true">
                    <el-avatar size="medium">登录</el-avatar>
                </button>
            </el-menu-item>
           <!-- <el-popover  placement="bottom"  width="368" trigger="click"  >
                    <el-row style="z-index: 1;">
                        <el-col :span="19">
                            <el-tabs value="first"   style="width: 368px;">
                                <el-tab-pane label="通知" name="first">
                                    <notice :flag="false" :info="info"></notice>
                                    <span style="float: left;color: #848484;font-size: 14px;font-weight: bold;cursor: pointer;"  @click="setAllRead" >全部已读 </span>

                                    <div style="float: right;color: #848484;font-size: 14px;font-weight: bold;cursor: pointer;" @click="goPage('/ebpf/home/noticeCenter')">
                                        <span>查看全部通知</span>
                                        <i class="el-icon-arrow-right" style="font-weight: bold"> </i>
                                    </div>
                                </el-tab-pane>
&lt;!&ndash;                                <el-tab-pane label="消息" name="second">消息2</el-tab-pane>&ndash;&gt;
                            </el-tabs>
                        </el-col>
                        <el-col :span="5">
                            <span style="float: right;font-weight: bold;cursor: pointer;position: absolute;z-index: 2" @click="getUnReadNotice">只显示未读</span>
                        </el-col>
                    </el-row>

                &lt;!&ndash;           消息通知图标&ndash;&gt;
                <el-button type="text" slot="reference" style="float:right;margin-top: -15px;margin-left: 20px;height: 40px;">
                    <i class="el-icon-message-solid" style="line-height: 50px;font-size:25px;color:#9F9F9F;"></i>
                    <el-badge :value="count" class="item"  v-if="count!=0" style="margin-left:-10px;float:right;">
                    </el-badge>
                </el-button>
            </el-popover>-->
            <el-menu-item style="float: right" v-if="$cookies.get('roleId')==='4'"
                          index="/ebpf/home/project/admin">
                <i class="el-icon-plus" style="font-weight: bold"></i>
            </el-menu-item>
          <!--&lt;!&ndash;  搜索栏&ndash;&gt;
            <el-menu-item style="float: right">
                <el-input size="small" class="header-search"
                          suffix-icon="el-icon-search"></el-input>
            </el-menu-item>-->
        </el-menu>
<!--        登录弹窗-->
        <el-dialog :visible.sync="dialogFormVisible"
                   style="text-align: left"
                   :show-close="false"
                   :close-on-click-modal="false"
                   width="960px" top="calc(50vh - 380px)">
            <el-button type="success" icon="el-icon-close" circle class="my-login-button1" style="margin-left: 990px" @click="closeLogin"></el-button>
            <loginComponent @setUserInfo="setUserInfo"></loginComponent>
        </el-dialog>
        <!--        个人探险手册弹窗-->
        <el-dialog :visible.sync="register"
                   style="text-align: left"
                   :show-close="false"
                   :modal-append-to-body='false'
                   width="960px" top="calc(50vh - 380px)">
            <el-button type="success" icon="el-icon-close" circle class="my-login-button1"
                       @click="closePersonal" style="margin-left: 990px"></el-button>
            <personal-component @closePersonal="closePersonal"></personal-component>
        </el-dialog>
    </div>
</template>

<script>
    import loginComponent from "../login/loginComponent";
    import personalComponent from "@/components/login/personalComponent";
    import loginApi from "@/utils/login/loginApi";
    // import Notice from "./notice";
    import noticeQuery from "../../utils/notice/noticeQuery";
    export default {
        name: "headerMenu",
        data(){
          return {
              dialogFormVisible:false,
              register:false,
              visible: false,
              activeIndex:'/ebpf/home/activityPoster',
              userInfo:{
                  avatar:'',
                  isLogin:false
              },
              page:1,
              size:3,
              count:0,
              info:[]
          }
        },
       /* watch:{
          $route: function () {
              console.log("route:"+this.$route)
              if(this.$route.name=='文档阅读')
                  this.activeIndex='/ebpf/home/study/doc?tag=官方文档'
              else if(this.$route.name=='探索之旅')
                  this.activeIndex='/ebpf/home/activityPoster'
              else if(this.$route.name=='项目集市')
                  this.activeIndex='/ebpf/home/project/item?type=eBPF技术与发展'
              else
                  this.activeIndex=''
          }
        },*/
        mounted(){
           if(this.$route.name=='文档阅读')
               this.activeIndex='/ebpf/home/study/doc?tag=官方文档'
           else if(this.$route.name=='探索之旅'||this.$route.name=='任务详情')
               this.activeIndex='/ebpf/home/activityPoster'
           else if(this.$route.name=='项目集市')
               this.activeIndex='/ebpf/home/project/item?type=eBPF技术与发展'
           else
               this.activeIndex=''
            //如果用户刚刚注册，将登录信息保存到cookie中
            if (localStorage.getItem("register") === "true"){
                this.register = true;
                this.setUserInfo();
                localStorage.removeItem("register");
            }
          //如果用户cookie中有avatar，说明它曾登录过，那么直接设置路径即可
          if (this.$cookies.isKey("avatar")){
              this.userInfo.avatar = this.$cookies.get("avatar")
              this.userInfo.isLogin = true
              this.refreshList()
          }
          if (this.$route.query.loginFlag){
              this.dialogFormVisible=true
          }
            //初始化websocket
            this.initWebSocket()


        },
        components:{
            // Notice,
            loginComponent,personalComponent
        },
        methods:{
            refreshList(){
                this.selectUnReadedCount()
                // console.log(localStorage.getItem("unReadFlag"))
                if(localStorage.getItem("unReadFlag")==='true'){
                    this.getUnReadNotice()
                    localStorage.removeItem("unReadFlag");
                }else {
                    this.getNoticeList(1, 3)
                }
            },
            selectUnReadedCount(){
                noticeQuery.selectUnReadedCount().then((res)=>{
                    if(res.data.success){
                        this.count=res.data.data.count
                    }
                })

            },
            setAllRead(){
                noticeQuery.setAllRead().then((res)=>{
                    if(res.data.success){
                        this.getNoticeList(1,3)
                        noticeQuery.sendWebSocketMessage(this.$cookies.get("userId"))
                    }
                })

            },
            getUnReadNotice(){
                localStorage.setItem("unReadFlag",true)
                noticeQuery.getUnReadNotice(this.page,this.size).then((res)=>{
                    if(res.data.success){
                        this.info=res.data.data.list
                        this.count=res.data.data.total
                    }
                })

            },
            getNoticeList(page,size){
               noticeQuery.getNotice(page,size).then((res)=>{
                   if(res.data.success){
                       // this.count=res.data.data.total
                       this.info=res.data.data.list
                   }
               })
            },
            goPage(route){
                this.$router.push({
                    path:route
                })
            },
            closeLogin(){
             this.dialogFormVisible=false;
            },
            closePersonal(){
                this.register =false;
            },
            setUserInfo(){
                this.userInfo.avatar = "/uploads/img/resource/initialAvatar.png";
                this.userInfo.isLogin = true;
                this.closeLogin();
            },
            toPersonalCenter(){
                this.goPage('/personal/'+this.$cookies.get("userId"))
            },
            menuClick(command) {
                if (command === '2') {
                    this.goPage('/personalCenter/personalData')
                } else if (command === '1') {
                    this.toPersonalCenter()
                } else{
                    loginApi.logout().then((res)=>{
                        if (res.data.success){
                            this.userInfo.isLogin = false;
                            this.goPage("/ebpf/index")
                        }
                    })
                }
            },
            initWebSocket: function () { // 建立连接
                // WebSocket与普通的请求所用协议有所不同，ws等同于http，wss等同于https
                var userId = this.$cookies.get("userId");
                var url="wss://bolipi.com:8081/api/user/websocket/"+userId
                this.websock = new WebSocket(url);
                this.websock.onopen = this.websocketonopen;
                this.websock.send = this.websocketsend;
                this.websock.onerror = this.websocketonerror;
                this.websock.onmessage = this.websocketonmessage;
                this.websock.onclose = this.websocketclose;
            },
            // 连接成功后调用
            websocketonopen: function () {
                console.log("WebSocket连接成功");
            },
            // 发生错误时调用
            websocketonerror: function (e) {
                console.log("WebSocket连接发生错误:"+e);
            },
            // 给后端发消息时调用
            websocketsend: function (e) {
                console.log("WebSocket连接发生错误:"+e);
            },
            // 接收后端消息
            // vue 客户端根据返回的cmd类型处理不同的业务响应
            websocketonmessage: function () {
                this.refreshList()
            },
            // 关闭连接时调用
            websocketclose: function (e) {
                console.log("connection closed (" + e.code + ")");
            }
        }
    }
</script>
<style>
    .el-menu-item{
        line-height: 45px !important;
        height: 50px !important;
    }
</style>

<style scoped>
    .header >>> .el-dialog{
        background-color: rgba(0,0,0,0);
        box-shadow: 0 0 0 !important;
    }
    .el-menu{
        z-index: 50;
    }
    .header-search >>> .el-input__inner{
        background: #ffffff;
        padding: 1px 30px 1px 10px;
        border-radius: 15px;
        width: 250px;
    }
    .top-nav-menu{
        padding-top: 4px;
        margin-left: 20px;
    }
</style>