import axios from "../http";

function getNodeInfo(nodeId) {
    return axios({
        url:"/api/user/graph/getNodeInfo",
        method:"get",
        params:{
            nodeId:nodeId
        }
    })
}
function getGraphInfo(taskId) {
    return axios({
        url:"/api/user/graph/getGraphInfo",
        method:"get",
        params:{
            taskId:taskId
        }
    })
}
export default {getNodeInfo,getGraphInfo}