import axios from "../http";
//跳转到外部链接
function goPage(url,router){
    const href = router.resolve({
        path:"/external",
        query:{
            link:url
        }
    })
    window.open(href.href,'_blank')
}
function getList(type,page,size) {
    return axios({
        url:`/api/user/video/list/${type}/${page}/${size}`,
        method:'get'
    })
}
function clickVideo(uuid,title,videoId,url) {
    return axios({
        url:"/api/user/video/click",
        method:"get",
        params:{
            uuid:uuid,
            title:title,
            videoId:videoId,
            url:url
        }
    })
}
export default {goPage,getList,clickVideo}