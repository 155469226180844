<template>
    <el-container class="my-layout-main">
        <!--左侧栏目-->
        <el-aside width="280px">
            <AsideMenu :menu="asideMenu"></AsideMenu>
        </el-aside>
        <!--中间内容-->
        <el-main>
            <router-view></router-view>
        </el-main>
    </el-container>
</template>

<script>
    import AsideMenu from "../../components/common/AsideMenu";
    import commonServer from "@/utils/commonServer";
    export default {
        name: "project_temp",
        components:{
            AsideMenu
        },
        data(){
            return {
                mainHeight:0,
                page:1,
                size:20,
                asideMenu:[]
            }
        },
        mounted(){
            //设置main部分的高度
            this.mainHeight = window.innerHeight -120;
            this.getMenuList();
        },
        methods:{
            getMenuList(){
                commonServer.getMenuList("项目集市").then((res)=>{
                    if (res.data.success){
                        this.asideMenu = res.data.data.list;
                    }else{
                        this.$message.error(res.data.message);
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>