import axios from '../http'
//获取回答
function getAnswers(questionId,page,size) {
    return axios({
        url:"/api/user/questions/getAnswers/"+questionId+"/"+page+"/"+size,
        method:"get",
    })
}
//创建回答id
function getAnswerId() {
    return axios({
        url:"/api/user/questions/id/create"
    })
}
//保存临时用户回答
function saveTempAnswer(questionId,answerId,answerContent) {
    return axios({
        url:"/api/user/temp/answer/save",
        method:"post",
        data:{
            questionId:questionId,
            answerId:answerId,
            answerContent:answerContent
        },
    })
}
//保存登录用户回答
function saveAnswer(questionId,answerId,answerContent) {
    return axios({
        url:"/api/user/questions/answer/save",
        method:"post",
        data:{
            questionId:questionId,
            answerId:answerId,
            answerContent:answerContent
        },
    })
}
//删除回答
function delAnswer(answerId) {
    return axios({
        url:"/api/user/questions/answer/"+answerId,
        method:"delete"
    })
}
//删除问题
function delQuestion(questionId) {
    return axios({
        url:"/api/user/questions/question/"+questionId,
        method:"delete"
    })
}

export default {getAnswers,getAnswerId,saveTempAnswer,saveAnswer,delAnswer,delQuestion}