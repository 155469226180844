import noticeCenter from "../components/common/noticeCenter";
import study_temp from "../ebpf/study/study_temp";
import study_doc from "../ebpf/study/doc/study_doc";
import study_video from "../ebpf/study/video/study_video";

import online from "../ebpf/task/online";
import libbpfLab from "../ebpf/task/libbpf_lab";
import task_demo from "../ebpf/task/task_demo";

import coursePreview from "../ebpf/task/coursePreview";
import activityPoster from "../ebpf/task/activityPoster";
import task_temp from "../ebpf/task/task_temp";
import study_practice_temp from "../ebpf/study/practice/study_practice_temp";

import task from "../ebpf/task/task";
import project_temp from "../ebpf/project/project_temp";
import projectReplay from "../ebpf/project/projectReplay";
import news_temp from "../ebpf/news/news_temp";
import postil_temp from "../ebpf/study/doc/postil_temp";
import editor2 from "../ebpf/project/editor2";
import myEditorPage from "@/components/common/MyEditorPage";
import cloud from "../ebpf/project/cloud";
import adminProject from "../ebpf/project/adminProject";

const homeChildren = [
    {
        path:"noticeCenter",
        component:noticeCenter
    },
    {
        path: 'study',
        component:study_temp,
        name:"探索手册",
        children:[
            {
                path:"doc",
                name:"文档阅读",
                component:study_doc
            },
            {
                path: "video",
                name: "视频",
                component: study_video
            }
        ]
    },
    {
        path: 'libbpf',
        name: "ebpf",
        component: {render: (e) => e("router-view")},
        children:[
            {
                path: "online",
                name:"在线实验",
                component: online
            },
            {
                path: "lab",
                name:"libbpf实验室",
                component: libbpfLab
            },
            {
                path: "demo",
                component: task_demo,
                name: "任务样例"
            },
        ]
    },
    {
        path:"course",
        name:"教程预览",
        component:coursePreview
    },
    {
        path: 'activityPoster',
        component:activityPoster,
        name:"探索之旅"
    },

    {
        path: 'taskTemp',
        component:task_temp,
        name:"探索之旅",
        children:[
            {
                path:"practice",
                name:"实践",
                component:study_practice_temp
            },
            {
                path: "task",
                component: task,
                name: "任务详情"
            }
        ]
    },
    {
        path: "project",
        component: project_temp,
        name: "项目集市",
        children:[
            {
                path:'item',
                component: cloud,
                name:"项目集市"

            },
        ]
    },
    {
        path:'project/admin',
        component:adminProject
    },
    {
        path:'peditor/:id',
        component:editor2
    },
    {
        path: 'project/show/:id',
        name:'项目集市',
        component: projectReplay
    },
    {
        path: "news",
        component: news_temp,
        name: "消息驿站"
    },
    {
        path:"study/document/:id",
        name:"文档阅读",
        component:postil_temp,
        meta:{
            unfooter:true
        }
    },
    {path:'my/editor/page',component:myEditorPage},

]
export default homeChildren;