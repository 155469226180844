<template>
    <div style="width: 1200px;margin: auto">
        <el-row style="margin: 20px 0">
            <el-col :span="16">
                <el-input v-model="title"></el-input>
            </el-col>
            <el-col :span="8">
                <el-button style="float: right" @click="save">提交内容</el-button>
            </el-col>
        </el-row>
        <MyEditor :modelValue="mongo.content" ref="myEditor"
                  @update:modelValue="newValue => mongo.content = newValue"
                  :imagePath="imagePath"
        ></MyEditor>
    </div>
</template>

<script>
    import MyEditor from "@/components/common/MyEditor";
    import taskApi from "@/utils/task/taskApi";
    export default {
        name: "MyEditorPage",
        components:{
            MyEditor
        },
        data(){
            return {
                relatedId:'',
                kind:'',
                title:'',
                imagePath:'',
                isUpdate:false,
                mongo:{}
            }
        },
        mounted(){
            this.relatedId = this.$route.query.relatedId;
            this.kind = this.$route.query.kind;
            this.title = this.$route.query.title;
            this.imagePath = this.$route.query.imagePath;
            this.isUpdate = this.$route.query.isUpdate === 'true';

            if (this.isUpdate && this.kind === "taskImpl"){
                this.getMongoTaskImpl(this.relatedId);
            }
        },
        methods:{
            getMongoTaskImpl(id){
                taskApi.getMongoTaskImplById(id).then((res)=>{
                    if (res.data.success){
                        this.mongo = res.data.data.taskImpl;
                    }
                })
            },
            save(){
                if (this.kind === "taskImpl"){
                    this.saveMongoTaskImpl()
                }
            },
            saveMongoTaskImpl(){
                taskApi.submitReport(this.mongo).then((res)=>{
                    if (res.data.success){
                        this.$message.success("提交成功")
                        this.$router.go(-1)
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            }
        }
    }
</script>

<style scoped>
</style>