<template>
    <div class="background">
        <el-row>
            <el-col>
                <h2 style="text-align: center;margin-top: 30px;"><i class="bi bi-phone"></i>&nbsp;修改手机号</h2>
                <el-form  :model="modify1" :rules="rules" ref="ruleForm" label-width="60px" style="margin-left:85px;">
                    <el-form-item  prop="oldphone" style="width:450px;margin-top:20px;">
                        <el-input  placeholder="原手机号" v-model="modify1.oldphone" class="a1"></el-input>
                    </el-form-item>
                    <el-form-item prop="status" style="width:450px;">
                        <Slider @success="setStatus" v-model="modify1.status"></Slider>
                    </el-form-item>
                    <el-form-item prop="verifyCode" class="pr" style="width: 450px">
                        <el-input placeholder="验证码"  v-model="modify1.verifycode" class="a1"></el-input>
                        <button @click.prevent="getCode()" class="code-btn" :disabled="!show" >
                            <span v-show="show">获取验证码</span>
                            <span v-show="!show" class="count">{{count}}秒</span>
                        </button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')" style="background-color:#2696BB;width:388px;">下一步</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-link :underline="false" style="margin-left: 100px;margin-top: -25px;" @click="submitForm2">手机号不再使用或不在身边</el-link>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import Slider from "../../../components/login/verifySliderComponent";
    import modifyPhone from "../../../utils/personalCenter/modifyPhone";
    export default {
        name: "modifyPhone_one",
        data(){
            //手机号校验
            let telCheck = (rule, value, callback) => {
                var reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
                if (value === "") {
                    return callback(new Error("请输入手机号码"));
                } else if (!reg.test(value)) {
                    return callback(new Error("请输入有效的手机号码"));
                } else {
                    callback();
                }
            };
            //验证码校验，6位数字验证码
            let code = (rule, value, callback) => {
                let authReg = /^\d{6}$/;
                if (value === "") {
                    return callback(new Error("请输入验证码"));
                }else if (!authReg.test(value)) {
                    callback(new Error("请输入正确的6位验证码"));
                } else {
                    return callback();
                }
            };
            //滑块验证
            let slider = (rule, value, callback) => {
                if (value === false) {
                    return callback(new Error("请验证滑块"));
                }  else {
                    return callback();
                }
            };
            return{
                modify1:{
                    oldphone:'',
                    status:false,
                    verifycode:''
                },
                //获取短信验证码
                show: true,
                count: 60,
                rules:{
                    oldphone: [
                        { required:true, validator: telCheck, trigger: 'blur'}
                    ],
                    status: [
                        {required: true, validator: slider, trigger: 'blur'}
                    ],
                    verifycode: [
                        { required:true, validator: code, trigger: 'blur'}
                    ]
                }
            }
        },
        components: {Slider},
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {

                        var domain = {
                            account:this.modify1.oldphone,
                            status:this.modify1.status,
                            verification:this.modify1.verifycode,
                        };
                        console.log(domain)
                        modifyPhone.modifyOldPhone(domain).then((res)=>{
                            if (!res.data.success){
                                this.$message.error(res.data.message);
                            }else {
                                this.$emit("next",3)
                            }
                        })

                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            submitForm2() {
               this.$emit("next",2)
            },
            getCode() {
                if(this.modify1.oldphone === ""){
                    this.$message.error("手机号不得为空");
                    return;
                }
                let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
                if(!reg.test(this.modify1.oldphone)){
                    this.$message.error("请输入有效手机号");
                    return;
                }
                if (this.modify1.status === false) {
                    this.$message.error("请验证滑块");
                    return;
                }
                modifyPhone.getVerifyCode(this.modify1.oldphone).then((res)=>{
                    if (!res.data.success){
                        this.$message.error(res.data.message);
                    }else {
                        this.setTimer(res.data.data.second);
                    }
                })
            },
            setTimer(count){
                // 验证码倒计时
                if (!this.timer) {
                    this.count = count;
                    this.show = false;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= count) {
                            this.count--;
                        } else {
                            this.show = true;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000);
                }
            },
            //获取短信结束
            setStatus(){
                this.modify1.status = true;
            }
        }
    }
</script>

<style scoped>
    .background{
        background-color:#FBFEFF;
        width:680px;
        height:380px;
        border-radius: 10px 10px 10px 10px;
        box-shadow:4px 4px 10px gray;
        margin:150px auto 0;
    }
    /*阴影*/
    .a1{
        box-shadow:0 2px 3px 0 #EAE9E9;
    }
    /* 短信验证码css */
    .pr {
        position: relative;
    }
    /*获取验证码按钮css*/
    .code-btn {
        width: 100px;
        height: 20px;
        position: absolute;
        top: 10px;
        right: 5px;
        z-index: 222;
        color: #0088B5;
        font-size: 14px;
        border: none;
        border-left: 1px solid #bababa;
        padding-left: 10px;
        background-color: #fff;
        cursor: pointer;
    }
</style>