<template>
    <div>
        <div v-for="(item,index) in questionsList" v-bind:key="index" style="margin: 5px">
            <MyQuestionComponent float="left" :question-item="item" :user-id="userId">
                <template v-slot:down="{questionItem}">
                    <el-button v-if="isCenter" class="my-text-button" @click="delQuestion(questionItem.id)"
                               type="text" style="float:left">
                        <i class="el-icon-delete">删除</i></el-button>
                </template>
                <template v-slot:answer="{prop}">
                    <MyCardComponent :date="prop.answer.modifiedDate" :item="prop.answer" :content="prop.answer.content"
                                     :user-avatar="getUserAvatar(prop.answer.modifiedByUserId,prop.username,prop.avatar)">
                    </MyCardComponent>
                </template>
            </MyQuestionComponent>
        </div>

        <el-pagination :current-page="curPage" :page-size="size" v-if="total > size" style="float:right;"
                       background layout="prev, pager, next" :total="total"
                       @current-change="handleCurrentChange"></el-pagination>
    </div>
</template>

<script>
    import personalQuery from "../../../utils/personalCenter/personalQuery";
    import questions from "@/utils/study/questions";
    import MyCardComponent from "@/components/common/MyCardComponent";
    import MyQuestionComponent from "@/components/user/notepad/MyQuestionComponent";
    export default {
        name: "MyQuestionListComponent",
        props:{
            userId:String,
            isCenter:{
                type:Boolean,
                default:false
            }
        },
        components:{
            MyQuestionComponent,
            MyCardComponent
        },
        data(){
            return {
                questionsList:[],//问题内容
                size:10,
                total:0,
                curPage:1
            }
        },
        mounted() {
            this.getList(this.curPage);
        },
        methods:{
            handleCurrentChange(val){
                this.curPage = val;
                this.getList(val)
            },
            getList(page){
                personalQuery.getMyQuestionList(page,this.size,this.userId).then((res)=>{
                    if (res.data.success){
                        this.questionsList = res.data.data.questions;
                        this.total = res.data.data.total;
                    }
                })
            },
            getUserAvatar(userId,username,avatar){
                var name = userId === this.$cookies.get('userId')?'我的':username;
                return {userId:userId,username:name,avatar}
            },
            delQuestion(id){
                this.$confirm('此操作将删除该问题, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    questions.delQuestion(id).then((res)=>{
                        this.$message.info(res.data.message)
                        this.getList(this.curPage)
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
        }
    }
</script>

<style scoped>
    .answer-content >>> .el-divider__text{
        font-size: 13px;
        text-align: left;
        color: #bfbfbf;
    }
    .answer-content >>> .el-divider {
        margin: 15px 0 0 0;
    }
</style>