<template>
    <div class="task-impl" :style="{'height': pageHeight+'px'}">
        <NoteItemComponent  :flag="flag" :item="item"  v-for="item in list" v-bind:key="item.id" @refreshList="refreshList"></NoteItemComponent>
        <!--分页-->
        <el-pagination v-show="showPage"  background layout="prev, pager, next" :page-size="size" :total="total" style="float:right;"
                       @current-change="handleCurrentChange" :current-page="currentPage">
        </el-pagination>
    </div>
</template>

<script>
    import NoteItemComponent from "@/components/user/notepad/NoteItemComponent";
    import personalQuery from "../../../utils/personalCenter/personalQuery";
    export default {
        name: "NoteListComponent",
        components:{
            NoteItemComponent
        },
        props: {
            userId:String,
            flag:Boolean
        },
        data(){
            return {
                relatedId:'',
                list:[],
                currentPage:1,
                size:5,
                total:0,
                pageHeight:0,
                showPage:false
            }
        },
        watch:{
            id:function(value){
                this.relatedId = value;
                this.getNote(1)
            }
        },
        mounted(){
            this.pageHeight = window.innerHeight - 400;
            this.relatedId = this.id;
            this.getNote(1);
            this.showPage=false
        },
        methods:{
            refreshList(){
                this.getNote(1);
            },
            handleCurrentChange(val){
                this.getNote(val)
            },
            getNote(page){
                this.currentPage = page;
                personalQuery.getNote(page,this.size,this.userId).then((res)=>{
                    if (res.data.success){
                        this.total = res.data.data.total;
                        this.list = res.data.data.list;
                        if(this.total>this.size)
                            this.showPage=true
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            }

        }
    }
</script>

<style scoped>
    .task-impl{
        text-align: left;
        padding: 0 20px;
        overflow-y: auto;
    }

</style>