<template>
    <div class="task-impl">
        <PreferenceItemComponent @refreshList="init(1)" :flag="flag"
                           :item="item" v-for="item in myPreferenceList" v-bind:key="item.id"></PreferenceItemComponent>
        <!--分页-->
        <el-pagination background layout="prev, pager, next" v-if="total > size" :page-size="size" :total="total" style="float:right;"
                       @current-change="handleCurrentChange" :current-page="currentPage">
        </el-pagination>
    </div>
</template>

<script>
    import PreferenceItemComponent from "@/components/user/notepad/PreferenceItemComponent";
    import personalQuery from "../../../utils/personalCenter/personalQuery";
    export default {
        name: "PreferenceListComponent",
        components:{
            PreferenceItemComponent
        },
        props:{
            id:String,
            flag:Boolean,
            userId:String
        },
        data(){
            return {
                relatedId:'',
                myPreferenceList:[],
                currentPage:1,
                size:5,
                total:5,
                page:1
            }
        },
        watch:{
            id:function(value){
                this.relatedId = value;
                this.init()
            }
        },
        mounted(){
            this.relatedId = this.id;
            this.init()
        },
        methods:{
            handleCurrentChange(val){
                this.getMysqlPreference(val)
            },
            init(){
                //取出mysql中前5条
                this.getMysqlPreference(1)
            },
            getMysqlPreference(page){
                this.currentPage=page
                personalQuery.getMysqlPreference(page,this.size,this.userId).then((res)=>{
                    if (res.data.success){
                        this.myPreferenceList=res.data.data.list
                        this.total=res.data.data.total
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            }

        }
    }
</script>

<style scoped>
    .task-impl{
        text-align: left;
        padding: 0 20px;
        overflow-y: auto;
    }
    .task-impl >>> .el-card__body{
        cursor: pointer;
    }
</style>