<template>
    <div>
        <!-- 博客目录 ：用于递归调用一级菜单 -->
        <el-menu-item :class="this.item.href === this.activeId?'active':''"
                      :id = "item.href" class="blog-menu" :index="item.href">
            <i v-if="item.children.length > 0" @click="changeShowState" style="font-size: 15px"
               :class="childShow?'el-icon-arrow-down':'el-icon-arrow-right'"></i>
            <i v-else>&ensp;&ensp;&ensp;&ensp;</i>
            <span :class="styles" slot="title">{{item.title}}</span>
        </el-menu-item>
        <!--二级菜单-->
        <div v-if="item.children.length > 0">
            <div  v-for="(item,indexC) in item.children" v-show="childShow" v-bind:key="indexC">
                <BlogCatalogComponent :active-id="activeId" @openChild="openChild"
                                      styles="my-nav-submenu-title my-menu-item" :item="item"></BlogCatalogComponent>
            </div>
        </div>
    </div>
</template>

<script>
    import "@/assets/css/main.css"
    //文章的目录导航
    export default {
        name: "BlogCatalogComponent",
        props:{
            item:Object,
            styles:String,
            activeId:String
        },
        data(){
            return {
                childShow:false,
            }
        },
        watch:{
            activeId: function (value) {
                if (value === this.item.href){
                    this.$emit('openChild');
                    document.getElementById(this.item.href).scrollIntoView({
                        behavior:"smooth",
                        block:"center",
                        inline:"center"
                    })
                }
            }
        },
        methods:{
            openChild(){
                this.childShow = true
            },
            changeShowState(){
                this.childShow = !this.childShow;
            }
        }
    }
</script>

<style scoped>
    .active {
        background-color: #e6f7ff;
    }
</style>