<template>
    <div>
        <!--如果虚拟空间是属于当前用户的-->
        <div v-if="isMine">
            <el-row style="vertical-align: middle;line-height: 30px">
                <el-col :span="10">
                    <span class="my-nav-menu-title">{{practice.title}}</span>
                </el-col>
                <el-col :offset="4" :span="10">
                    <el-button style="float: right" type="primary" size="small" @click="goToLab(practice.url)">开始实践</el-button>
                </el-col>
            </el-row>
            <!--中间内容-->
            <div class="my-body-text">您的密码是：{{practice.randomPassword}}</div>
            <!--标签和剩余时间-->
            <el-row style="vertical-align: middle;line-height: 40px">
                <el-col :span="10">
                    <el-tag class="my-tag">我的空间</el-tag>
                    <span class="my-nav-submenu-title">使用中</span>
                </el-col>
                <el-col :offset="4" :span="10">
                    <el-button style="float: right" size="small">
                        有效期至 {{dateFormat(practice.expiredDate)}}
                    </el-button>
                </el-col>
            </el-row>
        </div>
        <!--如果虚拟空间不属于当前用户-->
        <el-row v-else style="vertical-align: middle;line-height: 30px">
            <el-col :span="6">
                <span style="font-weight: bold"  class="my-nav-menu-title">{{hasPractice?practice.title:vm.dom_name}}</span>
            </el-col>
            <el-col :span="6">
                <span  class="my-nav-submenu-title">{{vmStatus}}</span>
            </el-col>
            <el-col :span="6">
                <el-tag style="float: right;margin-top: 4px" class="my-tag">
                    {{hasPractice?'有人使用':'无人使用'}}
                </el-tag>
            </el-col>
            <el-col :span="6">
                <el-button style="float: right" size="small" v-if="hasPractice">
                    有效期至  {{dateFormat(practice.expiredDate)}}
                </el-button>
                <el-button style="float: right" type="primary" size="small" v-else :disabled="vm.state !== 1" @click="apply">
                    点击申请
                </el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import common from "@/utils/common";
    import practice from "../../utils/practice";

    export default {
        name: "PracticeItemComponent",
        props:{
            vm:Object
        },
        data(){
            return {
                isMine:false,
                hasPractice:false,
                practice:{}
            }
        },
        computed:{
            vmStatus(){
                let state = this.vm.state;
                if (state === 1){
                    return "就绪"
                }else if (state === 0){
                    return "重置中"
                }else if (state === -1){
                    return "用户使用中"
                }else if (state === -2){
                    return "用户已释放但未重置"
                }else{
                    return "维护中"
                }
            }
        },
        mounted() {
            //如果用户cookie中有avatar，说明它曾登录过，那么直接设置路径即可
            if (this.$cookies.get("isLogin") === '1' && this.$cookies.isKey("avatar")){
                this.preHandle();
            }
        },
        methods:{
            preHandle(){
                practice.getInfoById(this.vm.id).then((res) => {
                    if (res.data.success) {
                        this.practice = res.data.data.practice;
                    }
                    this.hasPractice = !common.isBlank(this.practice);
                    this.isMine = this.hasPractice && !common.strBlank(this.practice.randomPassword)
                })
            },
            apply(){
                this.$emit('applyVm')
            },
            dateFormat(date){
                return common.dateFormat(date,'yyyy-MM-dd hh:mm:ss')
            },
            goToLab(url){
                window.open(url,'_target')
            }
        }
    }
</script>

<style scoped>

</style>