<template>
    <div>
        <ProjectItemComponent :item="item" v-for="(item,index) in asideMenu" :key="index"> </ProjectItemComponent>
        <el-pagination v-show="showPage" background layout="prev, pager, next" :page-size="size" :total="totalCount" style="float:right;"
                       @current-change="handleCurrentChange" :current-page="currentPage">
        </el-pagination>
    </div>


</template>

<script>
    import project from "../../utils/project"
    import ProjectItemComponent from "./ProjectItemComponent"
    export default {
        name: "cloud",
        components:{
            ProjectItemComponent
        },
        data(){
            return{
                type:"",
                size:5,
                asideMenu:[],
                totalCount:5,
                currentPage:1,
                showPage:false
            }
        },
        mounted() {
            this.type = this.$route.query.type;
            this.getData(1,this.size,this.type);
        },
        watch:{
            $route:function (value) {
                this.type = value.query.type;
                this.getData(1,this.size,this.type);
                this.showPage=false;
            }
        },
        methods:{
            handleCurrentChange(val){
                this.getData(val,this.size,this.type)
            },

            getData(page,size,type){
                this.currentPage=page
                project.getdata(page,size,type).then((res)=>{
                    if(res.data.success){
                        this.asideMenu=res.data.data.list
                        this.totalCount=res.data.data.total
                        console.log(this.totalCount)
                        if(this.totalCount>this.size)
                            this.showPage=true
                    }
                })
            }
        }
    }
</script>

<style scoped>
</style>