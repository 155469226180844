<template>
    <div id="menu" ref="menu">
        <el-menu class="el-menu-vertical-demo menu" active-text-color="#24A4CB"
                 text-color="#004E67" style="color: rgb(0,78,103)" @select="handleClick">

            <el-menu-item v-show="showBack" :index="backUrl">
                <i class="el-icon-back"></i>
                <span class="my-nav-menu-title">返回 &ensp;&ensp;</span>
            </el-menu-item>

            <div v-for="(item,index) in menu" v-bind:key="index">
                <BlogMenuItem :active-id="defaultHref" styles="my-nav-menu-title my-menu-item" :item="item"></BlogMenuItem>
            </div>
            <a href="#" hidden id="anchor"></a>
        </el-menu>
    </div>
</template>

<script>
    import BlogMenuItem from "@/components/blog/BlogMenuItem";
    export default {
        name: "eBPFMenu",
        props: {
            menu:Array,
            showBack:{
                type:Boolean,
                default:false
            },
            indexValid:{
                type: Boolean,
                default: true
            },
            backUrl:String,
            defaultHref:String
        },
        components: {
            BlogMenuItem
        },
        methods:{
            //实现锚点跳转
            handleClick(key){
                if (key === this.backUrl){
                    this.$router.replace(this.backUrl)
                }else{
                    var a = document.getElementById("anchor");
                    a.setAttribute('href',key);
                    a.click();
                }
            }
        }
    }
</script>

<style scoped>
    .el-menu-vertical-demo >>> .el-menu-item:hover{
        background-color: rgba(0, 184, 255, 0.07) !important;
    }
    .el-menu-vertical-demo:not(.el-menu--collapse) {
        /*width: 200px;*/
        min-height: 400px;
        text-align: left;
    }
    .menu{
        margin-left: 12px;
        border-top: #d6d6d6 1px solid;
    }
</style>