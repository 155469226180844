function getScatterOption(title,schema) {
    var scatterOption = {
    title: {
        text: '',
        subtext: 'Data from: bolipi'
    },
    tooltip: {
        // trigger: 'axis',
        showDelay: 0,
        axisPointer: {
            show: true,
            type: 'cross',
            lineStyle: {
                type: 'dashed',
                width: 1
            }
        }
    },
    grid: {
    },
    xAxis: [
        {
            type: 'value',
            scale: true,
            axisLabel: {
                formatter: function (val) {
                    return new Date(val).toLocaleTimeString();
                }
            },
            splitLine: {
                show: false
            }
        }
    ],
    yAxis: [
        {
            type: 'value',
            scale: true,
            axisLabel: {
                formatter: '{value}'
            },
            splitLine: {
                show: false
            }
        }
    ],
    visualMap:[{
        show:false,
        dimension:3,//使用的数据维度
        min:0,
        max:29,
        orient:'vertical',
        text: ['HIGH', 'LOW'],
        calculable:true,
        seriesIndex:0,
        inRange: {
            color: [
                "#fff1f0","#ffccc7","#ffa39e","#ff7875","#ff4d4f","#f5222d","#cf1322","#a8071a","#820014","#5c0011",
                "#e6fffb","#b5f5ec","#87e8de","#5cdbd3","#36cfc9","#13c2c2","#08979c","#006d75","#00474f","#002329",
                "#f9f0ff","#efdbff","#d3adf7","#b37feb","#9254de","#722ed1","#531dab","#391085","#22075e","#120338"
            ]
        }
    }],
    series: [
        {
            type: 'scatter',
            emphasis: {
                focus: 'series'
            },
            // prettier-ignore
            data: [],
        }
    ]
};
    scatterOption.title = title;
    scatterOption.tooltip.formatter = function (params) {
        var tip = '时间' + new Date(params.value[0]).toLocaleTimeString()+"<br>";
        schema.forEach((item)=>{
            tip = tip + item.text+":"+params.value[item.index]+item.unit+"<br>";
        })
        return tip
    };
    return scatterOption;
}
function getLineOption(title,schema) {
    var lineOption = {
        title:{
            text:""
        },
        tooltip:{
            trigger:"axis",
            axisPointer:{
                type:"cross",
                label:{
                    backgroundColor:"#283b56"
                }
            },
        },
        legend:{

        },
        xAxis:[
            {
                type:"value",
                boundaryGap:true,
                scale:true,
                axisLabel: {
                    formatter: function (val) {
                        return new Date(val).toLocaleTimeString();
                    }
                },
            }
        ],
        yAxis:[
            {
                type:"value",
                scale:true,
                name:"number"
            }
        ],
        series:[
            {
                name:"line",
                type:"line",
                data:[]
            }
        ]
    };
    lineOption.title = title;
    lineOption.tooltip.formatter = function (params) {
        var tip = '时间' + new Date(params[0].value[0]).toLocaleTimeString()+"<br>";
        for (var i = 0;i<params.length;i++){
            var param = params[i];
            schema.forEach((item)=>{
                tip = tip+param.seriesName+"-"+item.text+":"+param.value[item.index]+item.unit+"<br>";
            })
        }
        return tip
    };
    return lineOption;
}
function getBarOption(title) {
    var barOption = {
        title:{
            text:""
        },
        tooltip:{
            trigger:"axis",
            axisPointer:{
                type:"cross",
                label:{"backgroundColor":"#283b56"}
            }
        },
        legend:{},
        dataZoom:{
            show:false,
            start:0,
            end:100
        },
        xAxis:[
            {
                type:"category",
                boundaryGap:true,
            }
        ],
        yAxis:[
            {
                type:"value",
                scale:true,
                name:"Order"
            }
        ],
        series:[
            {
                name:"Dynamic Bar",
                type:"bar",
                data:[]
            }
        ]
    };
    barOption.title = title;
    return barOption;
}
function getCpuOption(type,title,schema) {
    if (type === "scatter"){
        return getScatterOption(title,schema);
    }else if (type === "line"){
        return getLineOption(title,schema);
    }else if (type === "bar"){
        return getBarOption(title);
    }
}
export default {getScatterOption,getLineOption,getBarOption,getCpuOption}