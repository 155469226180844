<template>
    <el-container class="news my-layout-main">
        <!--右侧主页面-->
        <el-main>
            <el-tabs type="border-card" class="main-up" :value="activeTab" @tab-click="handleClick">
                <el-tab-pane label="任务详情" name="detail">
                    <TaskDetailComponent :id="taskId" v-if="activeTab === 'detail'"></TaskDetailComponent>
                </el-tab-pane>
                <el-tab-pane :label="commentLabel" name="comment">
                    <TaskCommentComponent :task-id="taskId" v-if="activeTab === 'comment'"></TaskCommentComponent>
                </el-tab-pane>
                <el-tab-pane label="素材库" name="ware">
                    <WareHouseComponent :task-id="taskId" v-if="activeTab === 'ware'"></WareHouseComponent>
                </el-tab-pane>
            </el-tabs>
        </el-main>
    </el-container>
</template>

<script>
    import TaskDetailComponent from "@/components/task/resolve/TaskDetailComponent";
    import TaskCommentComponent from "@/components/task/comment/TaskCommentComponent";
    import WareHouseComponent from "@/components/task/material/WareHouseComponent";
    import taskApi from "@/utils/task/taskApi";
    import common from "@/utils/common";

    export default {
        name: "task_temp",
        components:{
            TaskDetailComponent,TaskCommentComponent,WareHouseComponent
        },
        data(){
            return {
                activeTab:"detail",
                taskId:'',
                commentLabel:'讨论区',
                activityId:"ac836b4d8ef44a44880e0126082a2e90",
                taskList:[],
            }
        },
        beforeMount() {
            this.taskId=this.$route.query.taskId;
        }
        ,
        mounted(){
            if (!common.strBlank(this.$route.query.active)){
                this.activeTab = this.$route.query.active;
            }
            this.taskChange(this.taskId)
        },
        watch:{
            $route:function (value) {
                if (!common.strBlank(this.$route.query.active)){
                    this.activeTab = this.$route.query.active;
                }
                this.taskChange(value.query.taskId)
            }
        },
        methods:{
            taskChange(id){
                this.taskId = id;
                taskApi.countCommentByTaskId(id).then((res)=>{
                    if (res.data.success){
                        var count = res.data.data.count;
                        if (count>0){
                            this.commentLabel = '讨论区('+count+')';
                        }else{
                            this.commentLabel = '讨论区';
                        }
                    }else{
                        this.$message.error(res.data.message);
                    }
                })
            },
            handleClick(tab){
                this.activeTab = tab.name;
            }
        }
    }
</script>

<style scoped>
    .news >>> .el-main{
        padding: 0;
    }
    .left-aside{
        border-right: 1px solid #DCDFE6;
    }
    .main-up{
        box-sizing: border-box!important;
        box-shadow: 0 0 0 0 !important;
        border: 0 !important;
    }
    .main-up >>> .el-tabs__content{
        padding: 0 0 0 10px;
    }
    /* 设置滑块颜色 */
    ::v-deep .el-tabs__active-bar{
        background-color: #11B0E1 !important;
    }
    /* 设置标签当前选中样式 */
    ::v-deep .el-tabs__item.is-active{
        color:#11B0E1 !important;
    }
    /* 设置标签当前选中样式 */
    ::v-deep .el-tabs__item:hover{
        color:#00C3FF;
    }
    .task-link{
        font-size: 15px;
        font-weight: bold;
    }
</style>