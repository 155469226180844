<template>
    <div class="jc-component__range">
        <div class="jc-range" :class="rangeStatus?'success':''" >
            <i @mousedown="rangeMove" :class="rangeStatus?successIcon:startIcon"></i>
            {{rangeStatus?successText:startText}}
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            // 成功之后的函数
            successFun: {
                type: Function
            },
            //成功图标
            successIcon: {
                type: String,
                default: 'el-icon-success'
            },
            //成功文字
            successText: {
                type: String,
                default: '验证成功'
            },
            //开始的图标
            startIcon: {
                type: String,
                default: 'el-icon-d-arrow-right'
            },
            //开始的文字
            startText: {
                type: String,
                default: '请按住滑块，拖到最右边'
            },
            //失败之后的函数
            errorFun: {
                type: Function
            }
        },
        data(){
            return {
                disX : 0,
                rangeStatus: false
            }
        },
        methods:{
            //滑块移动
            rangeMove(e){
                let ele = e.target;
                let startX = e.clientX;
                let eleWidth = ele.offsetWidth;
                let parentWidth =  ele.parentElement.offsetWidth;
                let MaxX = parentWidth - eleWidth;
                if(this.rangeStatus){//不运行
                    return false;
                }
                document.onmousemove = (e) => {
                    let endX = e.clientX;
                    this.disX = endX - startX;
                    if(this.disX<=0){
                        this.disX = 0;
                    }
                    if(this.disX>=MaxX-eleWidth){//减去滑块的宽度,体验效果更好
                        this.disX = MaxX;
                    }
                    ele.style.transition = '.1s all';
                    ele.style.transform = 'translateX('+this.disX+'px)';
                    e.preventDefault();
                }
                document.onmouseup = ()=> {
                    if(this.disX !== MaxX){
                        ele.style.transition = '.5s all';
                        ele.style.transform = 'translateX(0)';
                        //执行成功的函数
                        this.errorFun && this.errorFun();
                    }else{
                        this.rangeStatus = true;
                        this.$emit("success");
                        //执行成功的函数
                        this.successFun && this.successFun();
                    }
                    document.onmousemove = null;
                    document.onmouseup = null;
                }
            }
        }
    };
</script>


<style scoped>
    .jc-range{
        background-color:white;
        position: relative;
        transition: 1s all;
        user-select: none;
        color: #333;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px; /*no*/
        box-shadow:0px 2px 6px 0px #EAE9E9;
        border: 1px solid rgba(163,163,163,0.3);
        border-radius: 4px 4px 4px 4px;
    }
    .jc-range i{
        position: absolute;
        left: 0;
        width: 60px;/*no*/
        height: 100%;
        color: #919191;
        background-color: #fff;
        border: 1px solid  rgba(163,163,163,0.3);
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow:0px 2px 6px 0px #EAE9E9;
    }
    .jc-range.success{
        background-color: #7AC23C;
        color: #fff;
    }
    .jc-range.success i{
        color: #7AC23C;
    }
</style>