
import axios from '../http'

//左侧文章导航
var asideMenuData = [];
var asideMenuBuildFinished = false;

function addMenuNode(parentNode,node) {
    if (parentNode.length <= 0 || parentNode[parentNode.length-1].tag >= node.tag){
        parentNode.push(node);
    }else {
        addMenuNode(parentNode[parentNode.length-1].children,node)
    }
}

function getBlog(blogId,uuid,title) {
    //获取博客内容
    return axios({
        url:"/api/user/blog/get/"+blogId,
        method:"get",
        params:{
            uuid:uuid,
            title:title
        }
    })
}

function closeBlog(blogId,uuid,title) {
    return axios({
        url:`/api/user/blog/close/${blogId}`,
        method: "get",
        params:{
            uuid:uuid,
            title:title
        }
    })
}
//获取问题内容
function getAsideContent(blogId,positionIds,positionIndex) {
    return axios({
        url: '/api/user/questions/getQuestions/' + blogId,
        method: 'get',
        params: {
            positionIds: positionIds,
            positionIndex: positionIndex
        }
    })
}

function saveTempQuestionToServer(blogId,positionId,questionContent,blogContent) {
    //问题提交到服务器
    return axios({
        url: '/api/user/temp/question/save',
        data: {
            positionId: positionId,
            question: questionContent,
            blogId: blogId,
            blogContent: blogContent
        },
        method: 'post'
    })
}
function saveQuestionToServer(blogId,positionId,questionContent,blogContent) {
    //问题提交到服务器
    return axios({
        url: '/api/user/blog/question/save',
        data: {
            positionId: positionId,
            question: questionContent,
            blogId: blogId,
            blogContent: blogContent
        },
        method: 'post'
    })
}
function baidu(keyword) {
    return axios({
        url:'/api/user/temp/baidu',
        params: {
            keyword:keyword
        },
        method:'get'
    })
}
export default {asideMenuData,asideMenuBuildFinished,addMenuNode,
    getBlog,closeBlog,getAsideContent,saveTempQuestionToServer,saveQuestionToServer,baidu}