<template>
    <div  >
        <el-row >
            <el-form label-position="left" label-width="100px" :model="pmproject"   :rules="rules" ref="ruleForm"  style="padding-left: 20px;margin-top: 10px;">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="项目类型" class="a1" prop="type" >
                            <el-select  placeholder="请选择项目类型"  style="width: 250px;float: left;" v-model="pmproject.type"  >
                                <el-option   v-for="(item) in pmproject.typeOptions" :key="item.id"
                                             :label="item.title" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10">
                        <el-form-item label="标题" class="a1" style=" padding: 0px 0px;" prop="title">
                            <el-input style="width: 250px;float: left" v-model="pmproject.title"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="摘要" class="a1" prop="summary">
                    <el-input :rows="4" type="textarea" style="width: 700px;float: left;" v-model="pmproject.summary"></el-input>
                </el-form-item>
                <el-form-item label="href" class="a1" >
                    <el-input style="width: 450px;float: left;" v-model="pmproject.href"></el-input>
                </el-form-item>
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="标签"  prop="tagList"  v-for="(item,index) in pmproject.tagList" v-bind:key="index" >
                            <el-input  style="width: 400px;float: left;" v-model="item.tagName"></el-input>&nbsp;&nbsp;
                            <el-button style="float: left" @click="addtag" v-if="index === 0" icon="el-icon-plus" circle></el-button>
                            <el-button style="float: left" @click="removetag(index)" v-else icon="el-icon-minus" circle></el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-button  style="margin-right: -60px;" type="primary" @click="insertProject('ruleForm')">保存</el-button>
                    </el-col>
                </el-row>
                <el-form-item >

                    <mavon-editor style="min-height: 800px;margin-left: -100px;" ref="mavonEditor" v-model="projectContent" @save="insertProject('ruleForm')"
                                  @imgAdd="$imgAdd" @imgDel="$imgDel" :externalLink = externalLink></mavon-editor>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row >
            <br>

        </el-row>
    </div>
</template>

<script>
    import { mavonEditor } from 'mavon-editor'
    import "mavon-editor/dist/css/index.css"
    import project from "../../utils/project";
    import menu from "../../utils/menu/menu";

    export default {
        name: "editor2",
        components:{
            mavonEditor
        },
        data(){
            let typeCheck = (rule, value, callback) => {
                if (value === "") {
                    return callback(new Error("请选择类型"));
                } else {
                    callback();
                }
            };
            let titleCheck = (rule, value, callback) => {
                if (value === "") {
                    return callback(new Error("请输入标题"));
                } else {
                    callback();
                }
            };
            let summaryCheck = (rule, value, callback) => {
                if (value === "") {
                    return callback(new Error("请输入摘要"));
                } else {
                    callback();
                }
            };
            let tagCheck = (rule, value, callback) => {
                if (value[0].tagName === "") {
                    return callback(new Error("请输入标签"));
                } else {
                    callback();
                }
            };
            return {
                title:"",
                blogId:"",
                projectContent:"",
                pmproject:{
                    id:'',
                    title:'',
                    href:'',
                    summary:'',
                    tagList:[{tagName:""}],
                    type:'',
                    typeOptions:[]
                },
                externalLink:{
                    markdown_css: false,
                    hljs_js: () => '/cdn-lib/highlightjs/highlight.min.js',
                    hljs_css: (css) => '/cdn-lib/highlightjs/styles/' + css + '.min.css',
                    hljs_lang: (lang) => '/cdn-lib/highlightjs/languages/' + lang + '.min.js',
                    katex_css: () => '/cdn-lib/katex/katex.min.css',
                    katex_js: () => '/cdn-lib/katex/katex.min.js'
                },
                rules: {
                    type: [
                        {required: true, validator: typeCheck, trigger: 'blur'}
                    ],
                    title: [
                        {required: true, validator: titleCheck, trigger: 'blur'}
                    ],
                    summary: [
                        {required: true, validator: summaryCheck, trigger: 'blur'}
                    ],
                    tagList: [
                        {required: true, validator: tagCheck, trigger: 'blur'}
                    ]
                }
            }
        },
        mounted(){
            this.pmproject.id = this.$route.params.id
            if(this.$route.query.update=='true') {
                project.getProject(this.pmproject.id).then((res) => {
                    if (res.data.success) {
                        this.pmproject.title = res.data.data.title
                        this.pmproject.summary = res.data.data.summary
                        this.pmproject.type = res.data.data.type
                        this.projectContent = res.data.data.content
                    }
                })
                this.getTag(this.pmproject.id)
            }
            this.getProjectTypeOptions()
        },
        methods:{
            //获取项目类型列表
            getProjectTypeOptions(){
                menu.getAllOptionProjectMenus(3,0).then((res)=>{
                    if(res.data.success){
                        this.pmproject.typeOptions=res.data.data.list
                    }
                })
            },
            // 删除作品行
            removetag(index) {
                this.pmproject.tagList.splice(index,1)
            },
            //添加作品行
            addtag() {
                this.pmproject.tagList.push({"tagName":""});
            },
            getTag(relatedId){
                project.getTag(relatedId).then((res)=>{
                    if(res.data.success){
                        this.pmproject.tagList=res.data.data.list

                    }
                })
            },
            insertProject(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {

                        var data = {
                            pmProject: this.pmproject,
                            tagList: this.pmproject.tagList,
                            content: this.projectContent
                        }
                        if(this.$route.query.update=='true') {
                            project.updateProjectTag(data)
                            data.tagList=[]
                        }
                        project.insertProject(data).then((res) => {
                            if (res.data.success) {
                                this.$message("成功！")
                                this.getId();
                            } else {
                                this.$message.error(res.data.message)
                            }

                        })

                    }else {
                        console.log('error submit!!');
                        return false;
                    }
                })
            },
            $imgAdd(pos,$file){
                var formData = new FormData();
                formData.append('image',$file);
                project.imgUpload(this.pmproject.id,formData).then((res)=>{
                    let ret = res.data;
                    this.$refs.mavonEditor.$img2Url(pos,ret.data.url)})
            },
            $imgDel(pos){
                delete this.img_file[pos];
            }
        }
    }
</script>

<style scoped>
    @import url('/public/cdn-lib/markdown/github-markdown.min.css');

</style>