<template>
    <div class="background">
        <el-row>
            <el-col>
                <h2 style="text-align: center;margin-top:20px;"><i class="bi bi-key-fill"></i>&nbsp;修改密码</h2>
                <el-form  :model="forgetPassword" :rules="rules" ref="ruleForm" label-width="60px" style="margin-left:85px;">
                    <el-form-item  prop="newphone" style="width:450px;margin-top:20px;">
                        <el-input  placeholder="绑定的手机号或邮箱" v-model="forgetPassword.newphone" class="a1"></el-input>
                    </el-form-item>
                    <el-form-item prop="status" style="width:450px;">
                        <Slider @success="setStatus" v-model="forgetPassword.status"></Slider>
                    </el-form-item>
                    <el-form-item prop="verifyCode" class="pr" style="width: 450px">
                        <el-input placeholder="验证码"  v-model="forgetPassword.verifycode" class="a1"></el-input>
                        <button @click.prevent="getCode()" class="code-btn" :disabled="!show" >
                            <span v-show="show">获取验证码</span>
                            <span v-show="!show" class="count">{{count}}秒</span>
                        </button>
                    </el-form-item>
                    <el-form-item prop="new" class="pr" style="width: 450px">
                        <el-input placeholder="新密码"  v-model="forgetPassword.new" class="a1" show-password></el-input>
                    </el-form-item>
                    <el-form-item prop="renew" class="pr" style="width: 450px">
                        <el-input placeholder="确认新密码"  v-model="forgetPassword.renew" class="a1" show-password></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')" style="background-color:#2696BB;width:388px;margin-top: 12px;">完成</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import Slider from "../../../components/login/verifySliderComponent";
    import modifyPhone from "../../../utils/personalCenter/modifyPhone";
    import modifyPassword from "../../../utils/login/modifyPassword";
    import md5 from "js-md5";
    import modifyEmail from "../../../utils/personalCenter/modifyEmail";

    export default {
        name: "forgetPassword",
        data(){
            //手机号与邮箱校验
            let telCheck = (rule, value, callback) => {
                // let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
                //  let reg = /^[A-Za-z0-9\u4e00-\u9fa5][A-Za-z0-9_.\u4e00-\u9fa5]*[A-Za-z0-9\u4e00-\u9fa5]@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                let reg = /((13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$)|([A-Za-z0-9\u4e00-\u9fa5][A-Za-z0-9_.\u4e00-\u9fa5]*[A-Za-z0-9\u4e00-\u9fa5]@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$)/;
                if (value === "") {
                    return callback(new Error("请输入手机号或邮箱"));
                }  else if (!reg.test(value)) {
                    return callback(new Error("请输入有效的手机号码或邮箱"));
                } else {
                    callback();
                }
            };
            //验证码校验，6位数字验证码
            let code = (rule, value, callback) => {
                let authReg = /^\d{6}$/;
                if (value === "") {
                    return callback(new Error("请输入验证码"));
                }else if (!authReg.test(value)) {
                    callback(new Error("请输入正确的6位验证码"));
                } else {
                    return callback();
                }
            };
            //滑块验证
            let slider = (rule, value, callback) => {
                if (value === false) {
                    return callback(new Error("请验证滑块"));
                }  else {
                    return callback();
                }
            };
            //密码校验，必须为8~18位包含数字和字母的密码
            let validatePass = (rule, value, callback) => {
                let regex = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]{8,18}$");
                if (value === "") {
                    callback(new Error("请输入密码"));
                } else if (value.length < 8 || value.length > 28) {
                    callback(new Error("请输入8~18位包含数字与字母密码"));
                } else if (!regex.test(value)) {
                    callback(new Error("密码必须同时包含字母、数字，密码长度在8~18之间"));
                } else {
                    callback();
                }
            };
            //密码一致性校验规则
            let same = (rule,value,callback)=>{
                if (value === ''){
                    callback(new Error("请再次输入密码"));
                }else if (value !== this.forgetPassword.new){
                    callback(new Error("两次输入密码不一致"))
                }else{
                    callback();
                }
            };
            return{
                forgetPassword:{
                    newphone:'',
                    status:false,
                    verifycode:'',
                    new:'',
                    renew:''
                },
                //获取短信验证码
                show: true,
                count: 60,
                rules:{
                    newphone: [
                        { required:true, validator: telCheck, trigger: 'blur'}
                    ],
                    status: [
                        {required: true, validator: slider, trigger: 'blur'}
                    ],
                    verifycode: [
                        { required:true, validator: code, trigger: 'blur'}
                    ],
                    new: [
                        { required:true, validator: validatePass, trigger: 'blur'}
                    ],
                    renew: [
                        { required:true, validator: same, trigger: 'blur'}
                    ]
                }
            }
        },
        components: {Slider},
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        var domain={
                            phoneNumber:this.forgetPassword.newphone,
                            status:this.forgetPassword.status,
                            verification:this.forgetPassword.verifycode,
                            password:md5(this.forgetPassword.new),
                            newPass:md5(this.forgetPassword.renew)
                        }
                        modifyPassword.forgetPassword(domain).then((res)=>{
                            if(res.data.success){
                                this.$emit("next",0)
                                alert("修改密码成功！")
                            }
                            else{
                                this.$message.error(res.data.message)
                            }
                        })


                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            getCode() {
                if(this.forgetPassword.newphone === ""){
                    this.$message.error("手机号不得为空");
                    return;
                }
                let phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
                let emailReg = /^[A-Za-z0-9\u4e00-\u9fa5][A-Za-z0-9_.\u4e00-\u9fa5]*[A-Za-z0-9\u4e00-\u9fa5]@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                if(phoneReg.test(this.forgetPassword.newphone)){
                    modifyPhone.getVerifyCode(this.forgetPassword.newphone).then((res)=>{
                        if (!res.data.success){
                            this.$message.error(res.data.message);
                        }else {
                            this.setTimer(res.data.data.second)
                        }
                    })
                }else if (emailReg.test(this.forgetPassword.newphone)){
                    modifyEmail.getVerifyCode(this.forgetPassword.newphone).then((res)=>{
                        if (!res.data.success){
                            this.$message.error(res.data.message);
                        }else {
                            this.setTimer(res.data.data.second)
                        }
                    });
                }else{
                    this.$message.error("请输入有效的手机号或邮箱")
                }
                if (this.forgetPassword.status === false) {
                    this.$message.error("请验证滑块");
                    return;
                }

            },
            //获取短信结束
            setStatus(){
                this.forgetPassword.status = true;
            },
            setTimer(count){
                // 验证码倒计时
                if (!this.timer) {
                    this.count = count;
                    this.show = false;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= count) {
                            this.count--;
                        } else {
                            this.show = true;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000);
                }
            }
        }
    }
</script>

<style scoped>
    .background{
        background-color:#FBFEFF;
        width:680px;
        height:466px;
        border-radius: 10px 10px 10px 10px;
        box-shadow:4px 4px 10px gray;
        margin:150px auto 0;
    }
    /*阴影*/
    .a1{
        box-shadow:0 2px 3px 0 #EAE9E9;
    }
    /* 短信验证码css */
    .pr {
        position: relative;
    }
    /*获取验证码按钮css*/
    .code-btn {
        width: 100px;
        height: 20px;
        position: absolute;
        top: 10px;
        right: 5px;
        z-index: 222;
        color:#0088B5;
        font-size: 14px;
        border: none;
        border-left: 1px solid #bababa;
        padding-left: 10px;
        background-color: #fff;
        cursor: pointer;
    }
</style>