
<template>
    <el-container class="news my-layout-main">
        <!--右侧主页面-->
        <el-main>
            <!--左侧代码编辑栏-->
            <div style="text-align:left;padding: 10px;z-index: 99;position: absolute;width: 400px;right: 0">
                <el-switch v-model="codeViewOption.show" style="float: right;margin-top: 5px"></el-switch>
                <div v-if="codeViewOption.show">
                    <el-button size="mini" type="success" style="margin-left: 10px;margin-bottom: 10px" @click="runTicketCode">
                        运行
                    </el-button>
                </div>
                <div v-if="codeViewOption.show">
                    <div id>
                        <!--python eBPF代码编辑器-->
                        <codemirror v-model="codeViewOption.codeContent" class="my-code-editor"
                                    :global-options="codeViewOption.globalOptions"
                                    :options='{mode:"text/x-python",readOnly: true }'
                                    style="border: #bebfbe 1px solid;"
                                    :style="{height:`calc(100vh - ${354 - codeViewOption.resize.moveY}px)`}"></codemirror>
                    </div>
                    <div class="resize" id="resize" style="text-align: center">
                        <i class="el-icon-more" style="font-size: 25px;margin-top: -10px"></i>
                    </div>
                    <!--运行结果展示-->
                    <div>
                        <codemirror v-model="codeViewOption.runResult" class="my-code-editor"
                                    :global-options="codeViewOption.globalOptions"
                                    :options='{mode:"text/x-sh",readOnly: "nocursor"}'
                                    style="border: #bebfbe 1px solid;"
                                    :style="{'height':210-codeViewOption.resize.moveY+'px'}"></codemirror>
                    </div>
                </div>

            </div>
            <!--右侧问题详情栏-->
            <div style="text-align:left;padding: 10px;z-index: 99;position: absolute;width: 400px;left: 0"
                 v-if="questionView.show">
                <Practice_question_viewer :close-viewer="onCloseQuestionViewer" :data="questionView"></Practice_question_viewer>
            </div>
            <div style="width: calc(100% - 10px);height:calc(100vh - 100px);position: absolute;z-index: 10;left: 0">
                <RelationGraph ref="seeksRelationGraph" :options="graphOptions" :on-node-click="onNodeClick"></RelationGraph>
            </div>
        </el-main>
    </el-container>
</template>

<script>
    import RelationGraph from 'relation-graph'
    import codemirror from "../../components/common/MyCodemirror";
    import "codemirror/lib/codemirror.css"
    import 'codemirror/theme/idea.css'
    import 'codemirror/mode/shell/shell.js'
    import 'codemirror/mode/python/python.js'
    import Practice_question_viewer from "../../components/practice/practice_question_viewer";
    import knowledgeGraph from "../../utils/task/knowledgeGraph";
    import taskApi from "../../utils/task/taskApi";
    import common from "../../utils/common";
    export default {
        name: "task_temp",
        components:{
            Practice_question_viewer,
            RelationGraph,codemirror
        },
        data(){
            return {
                activityId:"",
                taskId:"",
                graphOptions:{
                    allowSwitchLineShape:true,
                    allowSwitchJunctionPoint:true,
                    defaultJunctionPoint:'border',
                    allowShowMiniToolBar:false
                },
                graphData:{
                    rootId:"",
                    nodes:[],
                    links:[]
                },
                codeViewOption:{
                    show:false,
                    //代码编辑器配置样式
                    globalOptions: {
                        // 缩进格式
                        tabSize: 1,
                        // 主题，对应主题库 JS 需要提前引入
                        theme: 'idea',
                        // 显示行号
                        lineNumbers: true,
                        line: true,
                        lineWrapping: true,  // 自动换行
                        styleActiveLine: true, // 当前行背景高亮

                    },
                    resize:{
                        startY:0,
                        count:0,
                        moveY:0,
                        isDown:false
                    },
                    taskImplId:"",
                    codeContent:"",
                    runResult:""
                },
                questionView:{
                    show:true,
                    nodeId:"",
                    question:"",
                    answer:""
                }
            }
        },
        mounted(){
            if (common.strBlank(this.$route.query.taskId)){
                this.$message.error("页面初始化缺少必要参数")
            }else{
                this.taskId = this.$route.query.taskId;
                this.activityId = this.$route.query.activityId;
                this.setCodeView(this.taskId);
                this.setRelationData(this.taskId);
            }
        },
        watch: {
            $route: function (value) {
                if(!common.strBlank(value.query.taskId) && value.query.taskId!== this.taskId){
                    this.taskId = value.query.taskId;
                    this.activityId = value.query.activityId;
                }
            }
        },
        methods:{
            runTicketCode(){
                if(!this.$cookies.get("userId")){
                    this.$message.error("请先登录！")
                    return
                }

                var data = {
                    userId:this.$cookies.get("userId"),
                    code:this.codeViewOption.codeContent
                };
                taskApi.runTicketCode(data).then((res)=>{
                    if (res.data.success){
                        this.$message.success("运行成功！")
                        this.codeViewOption.runResult=""+res.data.data.list
                    }
                })
            },
            setCodeView(taskId){
                taskApi.getMongoTaskTemplate(taskId).then((res)=>{
                    if (res.data.success){
                        this.codeViewOption.codeContent = res.data.data.mongoTaskTemplate.example;
                    }
                })
            },
            setRelationData(taskId){
                knowledgeGraph.getGraphInfo(taskId).then((res)=>{
                    if (res.data.success){
                        this.graphData = res.data.data;
                        // 以上数据中的node和link可以参考"Node节点"和"Link关系"中的参数进行配置
                        this.$refs.seeksRelationGraph.setJsonData(this.graphData, null)
                    }
                })
                // var __graph_json_data = {
                //     rootId: 'a',
                //     nodes: [
                //         { id: 'a', text: 'main', nodeShape:0, width:80,height:80, borderColor: 'yellow' },
                //         { id: 'b', text: '线程', nodeShape:0, width:40,height:40 },
                //         { id: 'c', text: '调度', nodeShape: 0, width: 40, height: 40 }
                //     ],
                //     links: [
                //         { from: 'a', to: 'b',lineWidth: 2,color: "#141210"},
                //         { from: 'a', to: 'c',lineWidth: 2,color: "#201d19"}
                //     ]
                // }
            },
            onNodeClick(nodeObject){
                this.questionView.show = true;
                this.questionView.nodeId = nodeObject.id;
                knowledgeGraph.getNodeInfo(this.questionView.nodeId).then((res)=>{
                    if (res.data.data.nodeInfo !== null){
                        this.questionView.question = res.data.data.nodeInfo.text;
                        this.questionView.answer = res.data.data.nodeInfo.answerHtml;
                    }else{
                        this.$message.error("相关问题不存在哦")
                    }
                })
            },
            onCloseQuestionViewer(){
                this.questionView.show = false;
            }
        }
    }
</script>

<style scoped>
    .news >>> .el-main{
        padding: 0;
    }
    /*拖拽区div样式*/
    .resize {
        cursor: row-resize;
        position: relative;
        background-size: cover;
        background-position: center;
        background-color: #c7c7c7;
        border-radius: 5px;
        width: 50px;
        height: 10px;
        margin: 0 auto;
        color: #ffffff;
    }
    /*拖拽区鼠标悬停样式*/
    .resize:hover {
        color: #2f2f2f;
    }
    .my-code-editor>>>.CodeMirror {
        height: 100%;
    }
</style>