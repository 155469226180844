<template>
        <el-container>
            <el-main class="center">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/personalCenter/helpCenter' }">帮助中心</el-breadcrumb-item>
                    <el-breadcrumb-item>联系我们</el-breadcrumb-item>
                </el-breadcrumb>
                <h1 style="text-align: left">联系我们</h1>
                <el-card shadow="never" style="text-align: left">
                    &nbsp;&nbsp;如有问题请发送至邮箱kuangqixinxi@126.com。
                </el-card>
            </el-main>
        </el-container>
</template>

<script>
    export default {
        name: "contactus"
    }
</script>

<style scoped>

</style>