import axios from "../http";


function signupHere(activityId){
    return axios({
        url:`/api/user/activity/registration/`,
        method:"get",
        params:{
            activityId:activityId
        }
    })
}
function isUserSignUp(activityId) {
    return axios({
        url:"/api/user/activity/isSignUp",
        method:"get",
        params:{
            activityId:activityId
        }
    })
}
function realnameAuthentication(){
    return axios({
        url:"/api/user/activity/realnameAuthentication",
        method:"get"

    })
}
function initCPUSubject() {
    return axios({
        url:"/api/user/activity/init/cpu_subject",
        method:"get",
    })
}
export default {signupHere,isUserSignUp,realnameAuthentication,initCPUSubject}