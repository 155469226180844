import axios from '../http';

function getNotice(page,size){
    return axios({
        url:`/api/user/notice/getNotice/${page}/${size}`,
        method:"get"

    })
}
function getUnReadNotice(page,size){
    return axios({
        url:`/api/user/notice/getUnReadNotice/${page}/${size}`,
        method:"get"

    })
}
function setAllRead(){
    return axios({
        url:`/api/user/notice/setAllRead`,
        method:"put"
    })
}

function setSomeOneRead(id){
    return axios({
        url:`/api/user/notice/setSomeOneRead`,
        method:"put",
        params:{
            id:id
        }
    })
}

function selectUnReadedCount(){
    return axios({
        url:`/api/user/notice/selectUnReadedCount`,
        method:"get"
    })
}

function signUpInsertNotice(data){
    return axios({
        url:`/api/user/notice/signUpInsert`,
        method:"post",
        data:data
    })
}
function sendWebSocketMessage(userId){
    return axios({
        url:`/api/user/notice/sendMessage`,
        method:"get",
        params:{
            userId:userId
        }
    })
}
export default {getNotice,getUnReadNotice,setAllRead,setSomeOneRead,selectUnReadedCount,signUpInsertNotice,sendWebSocketMessage
}