<template>

    <div class="task-impl">
        <FileItemComponent :personal="personal" @refreshList="getList(curPage,relatedId)"
                           :item="item" v-for="item in myFileList" v-bind:key="item.id"></FileItemComponent>
        <el-pagination :current-page="curPage" style="bottom: 5px;float:right;" v-if="total > size" :page-size="size"
                       background layout="prev, pager, next" :total="total"
                       @current-change="handleCurrentChange"></el-pagination>
    </div>
</template>

<script>
    import FileItemComponent from "@/components/task/material/FileItemComponent";
    import fileApi from "@/utils/task/fileApi";
    export default {
        name: "TaskImplListComponent",
        components:{
            FileItemComponent
        },
        props:{
            id:String,
            personal:{
                type:Boolean,
                default:true
            }
        },
        data(){
            return {
                relatedId:'',
                myFileList:[],
                curPage:1,
                size:5,
                total:0,
            }
        },
        watch:{
            id:function(value){
                this.relatedId = value;
                this.getList(1,value)
            }
        },
        mounted(){
            this.relatedId = this.id;
            this.getList(this.curPage,this.relatedId)
        },
        methods:{
            handleCurrentChange(val){
                this.curPage = val;
                this.getList(val,this.relatedId)
            },
            getList(page,relatedId){
                if (this.personal){
                    this.getMyFileList(page,relatedId)
                }else{
                    this.getAllFileList(page,relatedId)
                }
            },
            getMyFileList(page,relatedId){
                fileApi.getMyFileList(page,this.size,relatedId).then((res)=>{
                    if (res.data.success){
                        this.myFileList = res.data.data.list;
                        this.total = res.data.data.total
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            },
            getAllFileList(page,relatedId){
                fileApi.getAllFileList(page,this.size,relatedId).then((res)=>{
                    if (res.data.success){
                        this.myFileList = res.data.data.list;
                        this.total = res.data.data.total
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .task-impl{
        text-align: left;
        padding: 0 20px;
        overflow-y: auto;
    }

</style>