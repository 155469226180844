import axios from "../http"

function getTags( relatedId) {
    return axios({
        url:"/api/user/files/getTags",
        method:"get",
        params:{
            relatedId:relatedId,
        }
    })
}
function saveLink(link,relatedId,tag) {
    return axios({
        url:"/api/user/files/link/save",
        method:"post",
        data:{
            relatedId:relatedId,
            url:link,
            tag:tag
        }
    })
}
function updateLinkWithTitle(data) {
    return axios({
        url:"/api/user/files/link/update",
        method:"put",
        data:data
    })
}
function getMyFileList(page,size,relatedId) {
    return axios({
        url:`/api/user/files/myList/${page}/${size}`,
        method:"get",
        params:{
            relatedId:relatedId
        }
    })
}
function getAllFileList(page,size,relatedId) {
    return axios({
        url:`/api/user/files/list/${page}/${size}`,
        method:"get",
        params:{
            relatedId:relatedId
        }
    })
}
function setNotActive(id) {
    return axios({
        url:`/api/user/files/remove/${id}`,
        method:"delete"
    })
}
function setLike(id,likeStatus) {
    return axios({
        url:`/api/user/files/like/${id}`,
        method:"put",
        params:{
            status:likeStatus?1:0
        }
    })
}
function setDislike(id,dislikeStatus) {
    return axios({
        url:`/api/user/files/dislike/${id}`,
        method:"put",
        params:{
            status:dislikeStatus?1:0
        }
    })
}
function setPreference(id,preferenceStatus) {
    return axios({
        url:`/api/user/files/preference/${id}`,
        method:"put",
        params:{
            status:preferenceStatus?1:0
        }
    })
}
function saveLinkWithTitle(link,relatedId,tag,title,summary) {
    return axios({
        url:"/api/user/files/link/fsave",
        method:"post",
        data:{
            relatedId:relatedId,
            url:link,
            tag:tag,
            title:title,
            summary:summary
        }
    })
}
function setNotPreference(relatedId){
    return axios({
        url:"/api/user/files/preference/"+relatedId,
        method:"put",
        params:{
            status:0
        }
    })
}
//跳转到外部链接
function goPage(url,router){
    const href = router.resolve({
        path:"/external",
        query:{
            link:url
        }
    })
    window.open(href.href,'_blank')
}
function clickItem(url,title,id) {
    return axios({
        url:"/api/user/files/click",
        method:"get",
        params:{
            url:url,
            title:title,
            id:id

        }
    })
}
function getLinkTitle(link) {
    return axios({
        url:"/api/user/files/link/getLinkTitle",
        method:"get",
        params:{
           link:link
        }
    })
}
export default {
    saveLink,getMyFileList,getAllFileList,setNotActive,setLike,setDislike,setPreference,saveLinkWithTitle,setNotPreference,goPage,clickItem,getLinkTitle
    ,updateLinkWithTitle,getTags
}