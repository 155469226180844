export const opensnoop_bpf_h = `#ifndef __OPENSNOOP_H
#define __OPENSNOOP_H

#define TASK_COMM_LEN 32
#define NAME_MAX 255

// used for export event
struct event {
	int pid;
	int uid;
	int flags;
	char comm[TASK_COMM_LEN];
	char fname[NAME_MAX];
};

#endif /* __OPENSNOOP_H */

`;
export const opensnoop_bpf_c = `#include <vmlinux.h>
#include <bpf/bpf_helpers.h>
#include "opensnoop.bpf.h"

const volatile pid_t targ_pid = 0;

struct
{
 __uint(type, BPF_MAP_TYPE_PERF_EVENT_ARRAY);
 __uint(key_size, sizeof(u32));
 __uint(value_size, sizeof(u32));
} events SEC(".maps");

static __always_inline int trace_enter(struct trace_event_raw_sys_enter *ctx, bool is_openat)
{
 struct event event = {}; /* event data */
 u32 pid = bpf_get_current_pid_tgid();

 event.pid = bpf_get_current_pid_tgid() >> 32;
 if (targ_pid && targ_pid != event.pid) /* filter by pid */
  return 0;
 event.uid = bpf_get_current_uid_gid();
 bpf_get_current_comm(&event.comm, sizeof(event.comm));
 if (is_openat)
 {
  bpf_probe_read_user_str(&event.fname, sizeof(event.fname), (const char *)ctx->args[1]);
  event.flags = (int)ctx->args[2];
 }
 else
 {
  bpf_probe_read_user_str(&event.fname, sizeof(event.fname), (const char *)ctx->args[0]);
  event.flags = (int)ctx->args[1];
 }
 bpf_perf_event_output(ctx, &events, BPF_F_CURRENT_CPU,
        &event, sizeof(event)); /* emit event */
 return 0;
}

SEC("tracepoint/syscalls/sys_enter_open")
int tracepoint__syscalls__sys_enter_open(struct trace_event_raw_sys_enter *ctx)
{
 return trace_enter(ctx, false);
}

SEC("tracepoint/syscalls/sys_enter_openat")
int tracepoint__syscalls__sys_enter_openat(struct trace_event_raw_sys_enter *ctx)
{
 return trace_enter(ctx, true);
}

char LICENSE[] SEC("license") = "GPL";
`;
export const config_metrics = `{
    "counters": [
        {
            "name": "eunomia_file_open_counter",
            "description": "test",
            "labels": [
                {
                    "name": "pid",
                    "from": ""
                },
                {
                    "name": "comm",
                    "from": ""
                },
                {
                    "name": "filename",
                    "from": "fname"
                }
            ]
        }
    ]
}`;