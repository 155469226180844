<template>
    <div style="margin: 10px">
        <h2>通过在线编译运行快速体验 eBPF 和可视化</h2>
        <p>本在线编译平台由 <code>eunomia-bpf</code> 工具链提供支持，详细文档请参考 <a
                href="https://eunomia-bpf.github.io/" target="_blank">eunomia-bpf.github.io/</a></p>
        <h3>在线编译</h3>
        <p>在代码编辑器中编写 eBPF 的内核态程序，应当遵循 libbpf-tools 的内核态代码编写约定，即：</p>
        <ul>
            <li><code>代码编辑器</code> (*.bpf.c) 包含 BPF C 代码，它被编译成 package.json</li>
            <li><code>头文件编辑器</code> (*.bpf.h) 可以选择包含通过 perf event 或环形缓冲区导出到用户空间的类型</li>
        </ul>
        <p>我们目前只支持使用基于 libbpf 的内核态代码，BCC 代码支持由于存在一些语法上的差异，还在开发中。</p>
        <p>编写完成代码后，点击 <code>编译</code> 按钮即可编译成 eBPF 的内核态程序，在 <code>编译输出</code> 中查看编译输出：</p>
        <p><img src="~@/assets/img/compile-output.png" alt="imgb" width="500px"></p>
        <p>更多信息请参考：<a href="https://eunomia-bpf.github.io/mannual.html" target="_blank">eunomia-bpf.github.io/mannual.html</a>
        </p>
        <p>更多例子请参考：<a
                href="https://github.com/eunomia-bpf/eunomia-bpf/tree/master/bpftools/examples" target="_blank">https://github.com/eunomia-bpf/eunomia-bpf/tree/master/bpftools/examples</a>
        </p>
        <h3>在线运行</h3>
        <p>点击右侧的绿色运行按钮运行：</p>
        <p><img src="~@/assets/img/run-ebpf.png" alt="imgc"></p>
        <p>也可以通过 <code>下载编译后的文件</code> 查看编译好的程序，并在本地使用 <code>ecli</code> 直接运行：</p>
        <pre class="hljs"><code><div><span class="hljs-meta">$</span><span class="bash"> <span class="hljs-comment"># 下载安装 ecli 二进制</span></span>
<span class="hljs-meta">$</span><span class="bash"> wget https://aka.pw/bpf-ecli -O ./ecli &amp;&amp; chmod +x ./ecli</span>
<span class="hljs-meta">$</span><span class="bash"> <span class="hljs-comment"># 运行 eBPF 程序（root shell）</span></span>
<span class="hljs-meta">$</span><span class="bash"> sudo ./ecli run package.json  </span>
</div></code></pre>
        <h3>使用 Prometheus 在线获取可视化结果</h3>
        <p>点击 <code>运行可视化组件</code> 按钮，在弹出的窗口中配置 prometheus metrics 信息:</p>
        <p><img src="~@/assets/img/prometheus-config.png" alt="imgd" width="500px"></p>
        <p>点击 <code>确定</code> 即可跳转到 Prometheus 界面，可通过选择 graph 查看可视化结果：</p>
        <p><img src="~@/assets/img/prometheus-graph.png" alt="imgd" width="500px"></p>
        <h4>管理ebpf程序</h4>
        <p>您可以通过点击'管理ebpf程序'按钮，查看您自己的ebpf程序列表。</p>
        <img src="/uploads/img/resource/ebpf_manager.jpg" width="500px">
        <p>
            点击对应程序右侧操作栏中的按钮，可以对该程序进行对应运行、停止、查看或删除的操作
        </p>
        <h2>关于 eunomia-bpf</h2>
        <p>eunomia-bpf 是一套编译工具链和运行时，以及一些附加项目，我们希望做到让 eBPF 程序：</p>
        <ul>
            <li>让 eBPF 程序的编译和运行过程大大简化，抛去繁琐的用户态模板编写、繁琐的 BCC 安装流程，只需要编写内核态 eBPF
                程序，编译后即可在不同机器上任意内核版本下运行，并且轻松获取可视化结果。</li>
            <li>真正像 JavaScript 或者 WASM 那样易于分发和运行，或者说内核态或可观测性层面的 FaaS：eBPF 即服务，通过 API 请求快速分发和运行，无需管理基础设施和用户态加载程序；
            </li>
        </ul>
        <a href="https://github.com/eunomia-bpf/eunomia-bpf" target="_blank">github.com/eunomia-bpf/eunomia-bpf</a>
    </div>
</template>

<script>
    export default {
        name: "online_help"
    }
</script>

<style scoped>

</style>