<template>
    <!--任务解答部分组件-->
    <div class="task-detail">
        <el-row>
            <!--任务详情页面左部-->
            <el-col :span="24-drawer">
                <div class="task-form">
                    <MyEditor :modelValue="taskImpl.content" ref="myEditor"
                              @update:modelValue="newValue => taskImpl.content = newValue"
                              :save="true" @editorSave="saveTaskImpl"
                              :imagePath="'taskImpl/'+id">
                        <template slot="left-toolbar-after">
                            <button @click="uploadTaskImpl"
                                    type="button"
                                    class="my-op-icon el-icon-upload"
                                    aria-hidden="true"
                                    title="提交实验报告">提交</button>
                        </template>
                    </MyEditor>
                </div>
            </el-col>
            <el-col :span="drawer">
                <div :style="{'height': pageHeight-91+'px'}">
                    <div class="example-body" v-if="drawer === 8">
                        <el-row>
                            <h2 style="float: left">任务示例</h2>
                            <el-button circle size="small"
                                       style="float: right"
                                       class="el-icon-close"
                                       @click="drawer = 1"
                            ></el-button>
                        </el-row>
                        <div style="margin-top: 10px" v-html="renderedExample"></div>
                    </div>
                    <div v-else>
                        <el-button circle class="el-icon-position" type="primary"
                                   style="position: absolute;bottom: 30px;right: 20px"
                                   @click="drawer = 8"
                        ></el-button>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import MyEditor from "@/components/common/MyEditor";
    import taskApi from "@/utils/task/taskApi";
    import common from "@/utils/common";
    export default {
        name: "TaskDetailComponent",
        props: {
            //taskId
            id: String
        },
        components:{
            MyEditor
        },
        data(){
            return {
                taskImpl:{
                    id:'',
                    taskId:'',
                    activityId:'',
                    title:'',
                    content:'',
                    example:''
                },
                renderedExample:'',
                pageHeight:0,
                drawer:8,
            }
        },
        mounted() {
            this.pageHeight = window.innerHeight;
            if (!common.strBlank(this.id)){
                this.getTaskTemplate(this.id)
            }
        },
        watch:{
            $route: function () {
                this.getTaskTemplate(this.$route.query.taskId)
            }
        },
        methods:{
            getTaskTemplate(id){
                taskApi.getTaskDetail(id).then((res)=>{
                    if (res.data.success){
                        this.taskImpl = res.data.data.taskImpl;
                        this.renderExample(this.taskImpl.example);
                    }else{
                        this.$message.error(res.data.message);
                    }
                })
            },
            renderExample(example){
                this.renderedExample = common.getMarkdownIt().render(example)
            },
            saveTaskImpl(){
                var formData = {
                    id:this.taskImpl.id,
                    taskId:this.id,
                    activityId:this.taskImpl.activityId,
                    content:this.taskImpl.content,
                    status:0,
                    isPost:1
                };
                taskApi.submitReport(formData).then((res)=>{
                    if (res.data.success){
                        this.$message.success("您的实验报告已保存")
                    }else{
                        this.$message.error(res.data.message);
                    }
                })
            },
            uploadTaskImpl(){
                var formData = {
                    id:this.taskImpl.id,
                    taskId:this.id,
                    activityId:this.taskImpl.activityId,
                    content:this.taskImpl.content,
                    status:1, //是否提交
                    isPost:1 //是否公开
                };
                this.$confirm('审核通过后是否允许公开显示在评论区？', '确认信息', {
                    distinguishCancelAndClose: true,
                    confirmButtonText: '公开',
                    cancelButtonText: '不公开'
                }).then(() => {
                    taskApi.submitReport(formData).then((res)=>{
                        if (res.data.success){
                            this.$message.success("您的实验报告已提交");
                            this.getTaskTemplate(this.id);
                        }else{
                            this.$message.error(res.data.message);
                        }
                    });
                }).catch(action => {
                    if (action === 'cancel'){
                        formData.isPost = 0;
                        taskApi.submitReport(formData).then((res)=>{
                            if (res.data.success){
                                this.$message.success("您的实验报告已提交");
                                this.getTaskTemplate(this.id);
                            }else{
                                this.$message.error(res.data.message);
                            }
                        });
                    }
                });
            }
        }
    }
</script>

<style scoped>

    .task-detail{
        padding: 0;
        overflow-y: auto;
        text-align: left;
    }
    .task-form >>> .shadow{
        box-shadow: 0 0 0 0 !important;
    }
    .example-body{
        box-sizing: border-box;
        border-left: 1px solid #DCDFE6;
        font-size: 14px;
        padding: 10px 20px;
        margin-left: 10px;
        height: 100%;
    }
</style>