import axios from '../http';
import md5 from "js-md5";
//获取注册用户id
function getRegisterToken() {
    return axios({
        url:"/api/user/users/registerToken",
        method:"get",
    })
}
//获取手机验证码
function getVerifyCode(phoneNumber) {
    return axios.get('/api/user/sms/verifyCode',{params:{phoneNum:phoneNumber}})
}
//注册用户
function register(params) {
    return axios({
        url:"/api/user/users/register",
        method:'post',
        data:{
            umUsers:{
                userId:params.umUsers.userId,
                phoneNumber: params.umUsers.phoneNumber,
                password: md5(params.umUsers.password),
            },
            verifyCode: params.verifyCode,
            status: params.status,
            checked:params.checked
        }
    })
}
//注销用户
function delUser() {
    return axios({
        url:"/api/user/users/del/user",
        method:"delete"
    })
}

export default {getRegisterToken,getVerifyCode,register,delUser}