<template>
  <div>
    <div v-for="(item,index) in info" :key="index" @click="goPage('/ebpf/home/noticeCenter')" class="a1" >
            <el-row v-if="item.isNoted !=1">
                  <i :class="item.icon" style="color: #3E86B9;font-size:28px;float: left;margin-right: 20px;"></i>
                  <div v-if="flag===false&&(item.isNoted !=1)" style="margin-left:34px;width: 10px;height: 10px;background-color: #F14C47;border-radius: 100%">
                  </div>
                  <span style="float:left;font-size: 15px;">【{{item.title}}】{{JSON.parse(item.message).stitle}}  <span style="color: #FF6868;">{{JSON.parse(item.message).result}} </span>{{JSON.parse(item.message).reason}}</span>
            </el-row>
            <el-row v-else-if="item.isNoted ===1">
                  <i :class="item.icon" style="color: #848484;font-size:28px;float: left;margin-right: 20px;"></i>
                  <span style="color:#848484;float:left;font-size: 15px;">【{{item.title}}】{{JSON.parse(item.message).stitle}}  <span style="color: #848484;">{{JSON.parse(item.message).result}} </span>{{JSON.parse(item.message).reason}}</span>
            </el-row>
            <el-row style="float: right;margin-top: -23px;" v-if="flag===true">
               {{dateFormate(item.createdTime)}}
            </el-row>
            <el-divider></el-divider>
<!--        <el-card shadow="never">-->
<!--                <i class="bi bi-calendar2-range" style="color: #3E86B9;font-size:28px;"></i>-->
<!--        </el-card>-->
<!--        <el-card shadow="never">-->
<!--                <i class="bi bi-exclamation-circle-fill" style="color: #3E86B9;font-size:28px;"></i>-->
<!--        </el-card>-->
    </div>

  </div>
</template>

<script>
    import common from "../../utils/common";

    export default {
        name: "notice",
        props:{
          flag:Boolean,
          info:Array
        },
        data(){
            return{
                status:false,
                page:1,
                size:5,
                totalCount:5,
                message:{},
                tableData: [{
                    createdTime: '2016-05-03',
                    title: '审核',
                    message:{"stitle":"您提交的bcc项目实验报告","result":"审核已通过","reason":"，原因是:"},
                    icon:'bi bi-calendar2-week'
                }]
            }
        },
        mounted() {

            },
        methods:{

            dateFormate(date){
              return  common.dateFormat(date,"yyyy-MM-dd")
            },

            goPage(route){
                    this.$router.push({
                        path: route
                    })
            }
        }
    }
</script>

<style scoped>
.a1{
    cursor: pointer;
}
</style>