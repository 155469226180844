import PersonalData from "../main/personal/PersonalData";
import notepad from "../main/personal/notepad";
import achievement from "../main/personal/achievement";
import rewardRule from "../main/personal/reward/rewardRule";
import setup from "../main/personal/setup";
import helpCenter from "../main/personal/helpCenter";
import aboutus from "../main/personal/help/aboutus";
import useguide from "../main/personal/help/useguide";
import agreement from "../main/agreement";
import complaint from "../main/personal/help/complaint";
import contactus from "../main/personal/help/contactus";
import problem from "../main/personal/help/problem";
import cancellationNotice from "../main/personal/help/cancellationNotice";
import history from "../main/personal/history";
import bug_report from "../main/personal/bug_report";

const personalCenter = [
    {path:"personalData",component:PersonalData,name:"资料"},
    {path:"notepad",component:notepad,name:"记事本"},
    {path:"achievement",component:achievement,name:"积分兑换"},
    {path:"reward/rule",component:rewardRule,name:"积分规则"},
    {path:"history",component:history,name:"历史浏览"},
    {path:"setup",component:setup,name:"账号设置"},
    {path:"reports",component:bug_report,name:"您的建议"},
    {path:"helpCenter",component:helpCenter,name:"使用帮助"},
    {path:"aboutus",component:aboutus,name:"使用帮助"},
    {path:"useguide",component:useguide,name:"使用帮助"},
    {path:"agreement",component:agreement,name:"使用帮助"},
    {path:'complaint',component:complaint,name:"使用帮助"},
    {path:'contactus',component:contactus,name:"使用帮助"},
    {path:"problem",component:problem,name:"使用帮助"},
    {path:"cancellationNotice",component:cancellationNotice},
]

export default personalCenter;