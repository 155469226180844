import axios from '../utils/http'
//上传图片
function imageUpload(formData){
    return axios({
        url:`/api/user/uploads/img`,
        method: 'post',
        data: formData,
        headers:{'content-Type':'multipart/form-data'},
    })
}
function getMenuList(title) {
    return axios({
        url:"/api/user/menu/list",
        method:"get",
        params:{
            title:title
        }
    })
}
export default {imageUpload,getMenuList}