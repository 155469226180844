import axios from "../http"

//任务部分函数
function getTaskList(activityId) {
    return axios({
        url:`/api/user/task/list/${activityId}`,
        method:"get"
    })
}
function getTaskDetail(id) {
    return axios({
        url:`/api/user/task/detail/${id}`,
        method:"get",
    })
}
//实验报告相关函数
function submitReport(formData) {
    return axios({
        url:"/api/user/task/impl/save",
        method:"post",
        data:formData
    })
}
function getTaskImplList(page,size,relatedId) {
    return axios({
        url:`/api/user/task/impl/list/${page}/${size}`,
        method:"get",
        params:{
            relatedId:relatedId
        }
    })
}
function getMongoTaskImplById(id) {
    return axios({
        url:`/api/user/task/impl/${id}`,
        method:"get"
    })
}
function setNotActive(id) {
    return axios({
        url:`/api/user/task/impl/delete/${id}`,
        method:"delete",
    })
}
//任务讨论区相关函数
function countCommentByTaskId(taskId) {
    return axios({
        url:"/api/user/task/comment/count",
        method:"get",
        params:{
            taskId:taskId
        }
    })
}
//图表初始化配置请求
function chartsOption(toolName,commParam) {
    return axios({
        url:`/api/libbpf/bolipi/practice/get/option`,
        method:"get",
        params:{
            toolName:toolName,
            commParam:commParam
        }
    })
}
//图表数据请求
function chartsData(command,commParam) {
    return axios({
        url:`/api/libbpf/bolipi/practice/get/series`,
        method:"get",
        params:{
            toolName:command,
            commParam:commParam
        }
    })
}
function getMongoTaskTemplate(id) {
    return axios({
        url:`/api/user/task/mongoTemplate`,
        method:"get",
        params:{
            taskId:id
        }
    })
}
function getEntryDescription(entryName){
    return axios({
        url:`/api/user/task/getEntryDescription`,
        method:"get",
        params:{
            entry:entryName
        }
    })
}
function  runTicketCode(data) {
    return axios({
        url:"/api/user/stock/runStockCode",
        method:"post",
        data:data
    })
}
export default {getTaskList,getTaskDetail,submitReport,getTaskImplList,getEntryDescription,runTicketCode,
    getMongoTaskImplById,setNotActive,countCommentByTaskId,chartsOption,chartsData,getMongoTaskTemplate}