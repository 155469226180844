<template>
    <div style="margin-left:200px;height: 100vh;">
      <div style="width: 1160px;margin-top: 50px;">
       <span style="float:left;font-size: 24px;color: #101010;font-weight: bold; ">通知中心</span>
       <!--<span  style="float:right;">通知设置</span>
       <i class="bi bi-gear-fill" style="float:right;margin-top: 3px;margin-right: 5px;"></i>-->
       <div style="cursor: pointer;margin-top: -20px;" @click="setAllRead">
       <span style="float:right;margin-right: 0px;">全部标为已读</span>
       <i class="el-icon-success" style="float:right;margin-top: 3px;margin-right: 5px;"></i>
       </div>
       <br>
       <el-divider ></el-divider>
      </div>
       <el-tabs value="first"  style="width:1160px;">
            <el-tab-pane label="站内通知" name="first" >
                <notice :flag="flag" :info="info"></notice>
            </el-tab-pane>
<!--            <el-tab-pane label="我的私信" name="second"></el-tab-pane>-->
       </el-tabs>
        <!--分页-->
        <el-pagination background layout="prev, pager, next" :page-size="size" :total="totalCount" v-if="flag!=false"
                       @current-change="handleCurrentChange" :current-page="page">
        </el-pagination>
    </div>
</template>

<script>

    import Notice from "./notice";
    import noticeQuery from "../../utils/notice/noticeQuery";

    export default {
        name: "noticeCenter",
        components:{
            Notice
        },
        data(){
          return{
              info:[],
              totalCount:0,
              page:1,
              size:5,
              flag:true
          }
        },
        mounted() {
            this.getNoticeList(1,5)
        },
        methods:{
            setAllRead(){
              noticeQuery.setAllRead().then((res)=>{
                  if(res.data.success){
                      this.getNoticeList(1,5)
                  }
              })
            },
            handleCurrentChange(val){
                this.getNoticeList(val,this.size)
            },
            getNoticeList(page,size){
                noticeQuery.getNotice(page,size).then((res)=>{
                    if(res.data.success){
                        this.totalCount=res.data.data.total
                        this.info=res.data.data.list
                        for(var i=0;i<this.info.length;i++){
                            noticeQuery.setSomeOneRead(this.info[i].id)
                        }
                        noticeQuery.sendWebSocketMessage(this.$cookies.get("userId"))
                    }
                })
            },
        }


    }
</script>

<style scoped>
    /* 设置标签未被选中样式 */
    ::v-deep .el-tabs__item{
        font-size:17px;
        color:#000000 !important;
    }
</style>