<template>
    <div style="padding: 10px 20px">
        <el-card v-for="(item,index) in answerList" v-bind:key="index" style="margin: 5px">
            <div class="my-question-title" style="margin-left: 10px;margin-bottom: 5px">{{item.questionTitle}}</div>
            <MyCardComponent :content="item.answerContent" :date="item.createdDate" :item="item" :user-avatar="user">
                <template v-if="isCenter" v-slot:right-up="{prop}">
                    <el-dropdown>
                        <span><i class="el-icon-more"></i></span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <el-button type="text" @click="delAnswer(prop.item.id)">
                                    <i class="el-icon-delete"></i>&nbsp;删除
                                </el-button>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </MyCardComponent>
        </el-card>


        <el-pagination :current-page="curPage" :page-size="size" v-if="total > size" style="float:right;"
                       background layout="prev, pager, next" :total="total"
                       @current-change="handleCurrentChange"></el-pagination>
    </div>
</template>

<script>
    import personalQuery from "../../../utils/personalCenter/personalQuery";
    import questions from "../../../utils/study/questions";
    import MyCardComponent from "@/components/common/MyCardComponent";
    import userInfoApi from "@/utils/login/userInfoApi";

    export default {
        name: "MyAnswerListComponent",
        components: {MyCardComponent},
        props:{
            userId:String,
            isCenter:{
                type:Boolean,
                default:false
            }
        },
        data(){
            return {
                answerList:[],
                size:10,
                total:0,
                curPage:1,
                user:{
                    userId:"",
                    avatar:"",
                    username:""
                }
            }
        },
        mounted() {
            this.getUserAvatar(this.userId);
            this.getList(this.curPage);
        },
        methods:{
            handleCurrentChange(val){
                this.curPage = val;
                this.getList(val)
            },
            getUserAvatar(userId){
                userInfoApi.getUserAvatar(userId).then((res)=>{
                    if (res.data.success){
                        this.user.username = res.data.data.username;
                        this.user.avatar = res.data.data.avatar;
                        this.user.userId = userId;
                    }else {
                        this.$message.error(res.data.message)
                    }
                })
            },
            getList(page){
                personalQuery.getMyAnswerList(page,this.size,this.userId).then((res)=>{
                    if (res.data.success){
                        this.answerList = res.data.data.list;
                        this.total = res.data.data.total;
                    }
                })
            },
            delAnswer(id){
                this.$confirm('此操作将删除该回答, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    questions.delAnswer(id).then((res)=>{
                        this.$message(res.data.message)
                        this.getList(this.curPage)
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            }
        }
    }
</script>

<style scoped>

</style>