import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/global.css'
import VueRouter from 'vue-router'
import router from './router/router'
import Axios from './utils/http'
import Contextmenu from 'vue-contextmenujs'
import VueCookies from 'vue-cookies'
import "bootstrap-icons/font/bootstrap-icons.css"
import Driver from "driver.js"
import "driver.js/dist/driver.min.css"
import "./assets/css/font.css"


// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart, LineChart, ScatterChart} from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  ToolboxComponent,
  LegendComponent,
  DataZoomComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BrushComponent,
  VisualMapComponent
} from 'echarts/components';
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';


echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  ToolboxComponent,
  LegendComponent,
  DataZoomComponent,
  BarChart,
  ScatterChart,
  LineChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  BrushComponent,
  VisualMapComponent
])

Vue.prototype.$echarts = echarts;
Vue.prototype.$axios = Axios;
Vue.prototype.$driver = new Driver({
  className: 'scoped-class', // className to wrap driver.js popover
  animate: true,  // Animate while changing highlighted element
  opacity: 0.5,  // Background opacity (0 means only popovers and without overlay)
  padding: 10,    // Distance of element from around the edges
  allowClose: false, // Whether clicking on overlay should close or not
  nextBtnText: '下一步', // Next button text for this step
  doneBtnText:'完成',
  prevBtnText:'上一步',
  closeBtnText:'跳过',
});
Vue.use(ElementUI)
Vue.use(VueRouter)
Vue.use(Contextmenu)
Vue.use(VueCookies)

Vue.config.productionTip = false

new Vue({
  router,
  render:h=>h(App)
}).$mount("#app")
