<template>
    <div style="margin: 10px">
        <div style="font-weight: bold;font-size: 18px;margin: 10px 30px">提出您对网站的看法</div>
        <el-form label-width="100px" style="margin-top: 20px" :model="feedback" :rules="rules" ref="ruleForm">
            <el-form-item label="问题类型" prop="type">
                <el-radio-group v-model="feedback.type">
                    <el-radio label="侵权投诉"></el-radio>
                    <el-radio label="优化建议"></el-radio>
                    <el-radio label="网站bug"></el-radio>
                    <el-radio label="功能反馈"></el-radio>
                    <el-radio label="其他"></el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="建议内容" prop="content">
                <el-input type="textarea" style="width: 500px" v-model="feedback.content"
                          :autosize="{minRows:4,maxRows:8}"></el-input>
            </el-form-item>
            <el-form-item label="图片示例">
                <el-upload class="upload-demo" drag multiple :http-request="handleUploads" action="#" :file-list="feedback.fileList">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submit('ruleForm')">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import commonServer from "@/utils/commonServer";
    import feedback from "@/utils/feedback/feedback";
    import common from "@/utils/common";

    export default {
        name: "bug_report",
        data(){
            let typeCheck=(rule,value,callback)=>{
                console.log(value)
                if(value===""||value===null){
                    return callback(new Error("请选择类型"))
                }else{
                    callback();
                }
            };
            let contentCheck=(rule,value,callback)=>{
                console.log(value)
                if(value===""||value===null){
                    return callback(new Error("请填写建议内容"))
                }else{
                    callback();
                }
            };
            return {
                feedback:{
                    id:"",
                    type:"",
                    content:"",
                    fileList:[]

                },
                rules:{
                    type:[{required: true, validator: typeCheck, trigger: 'blur'}],
                    content:[{required:true,validator:contentCheck,trigger:'blur'}]
                }
            }
        },
        mounted(){
            this.getFeedbackId();
        },
        methods:{
            getFeedbackId() {
                feedback.getToken().then((res) => {
                    if (res.data.success) {
                        this.feedback.id = res.data.data.token;
                    }
                })
            },
            submit(formName){
                this.$refs[formName].validate((valid) => {
                    if(valid){
                        console.log("valid")
                        if (common.strBlank(this.feedback.id)){
                            this.$message.error("请刷新后重试")
                            return
                        }
                        feedback.save(this.feedback).then((res)=>{
                            if (res.data.success){
                                this.feedback = {id:"",type:"",content:"",fileList:[]}
                                this.$message.success("感谢您的建议");
                                this.getFeedbackId()
                            }
                        })
                    }
                })

            },
            handleUploads(event){
                var formData = new FormData();
                formData.append('image',event.file);
                formData.append('path',"feedback");
                commonServer.imageUpload(formData).then((res)=>{
                    if (res.data.success){
                        this.feedback.fileList.push(res.data.data.url)
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
