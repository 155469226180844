import axios from '../http'
//上传图片
function getToken(){
    return axios({
        url:`/api/user/feedback/token`,
        method: 'get',
    })
}
function save(data) {
    return axios({
        url:"/api/user/feedback/save",
        method:"post",
        data:data
    })
}
export default {getToken,save}