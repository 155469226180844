import axios from '../http';

function getAllOptionProjectMenus(parentId,grandfatherId){
    return axios({
        url:`/api/user/menu/getAllOptionProjectMenus`,
        method:"get",
        params:{
            parentId:parentId,
            grandfatherId:grandfatherId
        }
    })
}

export default {getAllOptionProjectMenus
}