<template xmlns:display="http://www.w3.org/1999/xhtml">
    <!--单条评论组件-->
    <div style="text-align: left" class="comment-item">
        <!--头像区域-->
        <div class="from-info">
            <div style="float: left" @click="isBlank(fromUser.username)?'':newPage('/ebpf/home/personal/'+data.fromUserId)">
                <el-avatar class="from-head-img"  :size="30" :src="fromUser.avatar"  ></el-avatar>
                <span   class="from-name" >
                    {{fromUser.username}}
                </span>
            </div>
            <span class="operator-btn" style="float: right" v-if="blogFlag===true">{{dateStr(data.modifiedDate)}}</span>
            <span v-show="isReply && data.parentId !== cmId" class="from-name">
                <span style="font-size: 14px">回复</span> &nbsp;&nbsp;&nbsp; {{toUser.username}}
            </span>
        </div>
        <!--评论内容区域-->
        <div class="talk-box" v-html="replyCommentDelete?'<p>此评论已删除</p>':renderContent(data.content)"></div>
        <!--操作区域-->
        <el-row class="icon-btn">
            <el-col :span="18">
                <el-button type="text" @click="showReply" class="operator-btn" v-if="!isReply && (data.isActive > 0)" >
                    <i class="el-icon-s-comment" ></i>&nbsp;{{replyShow?"收起":"查看 "+data.isActive}}
                </el-button>
                <el-button type="text" @click="replyCommentEdit = !replyCommentEdit" class="operator-btn">
                    <i class="bi bi-chat-dots"></i>&nbsp;回复
                </el-button>
                <el-button type="text" class="operator-btn" @click="reportDialog">
                    <i class="el-icon-info"></i>&nbsp;举报
                </el-button>
                <el-button type="text" @click="deleteComment"
                           v-if=" data.fromUserId === this.$cookies.get('userId')"
                           :disabled="replyCommentDelete" class="operator-btn">
                    <i class="el-icon-delete"></i>&nbsp;删除
                </el-button>
            </el-col>
            <el-col :span="6" v-if="blogFlag===false">
                <span class="operator-btn" style="float: right">{{dateStr(data.modifiedDate)}}</span>
            </el-col>
        </el-row>
        <el-row v-show="replyCommentEdit" class="icon-btn" style="margin-top: 5px" :gutter="10">
            <el-col :span="22" v-if="blogFlag===false">
                <el-input type="textarea" :autosize="{minRows:1,maxRows:5}"
                          placeholder="开始编辑..." v-model="replyComment"></el-input>
            </el-col>
            <el-col :span="22" v-else>
                <el-input type="textarea" :autosize="{minRows:1,maxRows:5}"  style="width: 230px;"
                          placeholder="开始编辑..." v-model="replyComment"></el-input>
            </el-col>
            <el-col :span="2" >
                <el-button size="mini" @click="saveReply" style="float: right">回复</el-button>
            </el-col>
        </el-row>
        <!--回复区域-->
        <div class="reply-box" v-show="replyShow">
            <div v-for="(reply,j) in replies" :key="j">
                <TaskCommentItemComponent :is-reply="true" :cm-id="data.id" :data="reply" :blogFlag="blogFlag"
                                          @addReply="addRely"></TaskCommentItemComponent>
            </div>
        </div>
        <el-divider v-show="!isReply"></el-divider>
        <!--投诉弹窗-->
        <el-dialog :visible="reportShow" title="举报投诉" @close="reportShow = false">
            <el-form :model="report">
                <el-form-item>
                    <el-radio-group v-model="report.illegalType">
                        <el-radio style="margin: 10px"
                                  v-for="item in illegalType" :key="item.id" :label="item.id">
                            {{item.name}}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item v-show="report.illegalType === 206||report.illegalType === 207">
                    <el-input type="textarea" maxlength="100" v-model="report.note"
                              placeholder="请输入相关内容" show-word-limit></el-input>
                </el-form-item>
                <el-form-item>
                    <el-col :span="10" :offset="10">
                        <el-button size="small" style="color: #ffffff;"
                                   type="primary" class="my-btn-primary" @click="sendReport">提交
                        </el-button>
                    </el-col>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import common from "@/utils/common";
    import comment from "@/utils/task/comment";
    import userInfoApi from "@/utils/login/userInfoApi";
    import "mavon-editor/dist/css/index.css"

    export default {
        name: "TaskCommentItemComponent",
        props:{
            data:Object,
            //是否是子回复
            isReply:{
                type:Boolean,
                default:false
            },
            cmId:String,
            blogFlag:Boolean
        },
        data() {
            return {
                replyShow: false,
                replyCommentEdit:false,
                replyComment: '',
                replyCommentDelete:false,
                //评论用户名、头像、和回复
                fromUser:{},
                toUser:{},
                replies:[],
                //投诉弹窗
                report:{
                    illegalType:0,
                    note:"",
                    reportedContentId:"",
                    reportedUserId:""
                },
                reportShow:false,
                illegalType:[]
            }
        },
        mounted(){
            this.getFromUserAvatar();
            if (this.isReply){
                this.getToUserAvatar();
            }
            comment.getIllegalType().then((res)=>{
                if (res.data.success){
                    this.illegalType = res.data.data.list;
                }
            })
        },
        methods: {
            dateStr(date){
                return common.dateStr(date);
            },
            showReply(){
                if (!this.replyShow){
                    comment.getReplyById(this.data.id).then((res)=>{
                        if (res.data.success){
                            this.replies = res.data.data.reply;
                            this.replyShow = !this.replyShow
                        }
                    })
                }else{
                    this.replyShow = !this.replyShow;
                }
            },
            saveReply(){
                var cmId = this.isReply?this.cmId:this.data.id;
                var result = {
                    content:this.replyComment,
                    toUserId:this.data.fromUserId,
                    relatedId:this.data.relatedId,
                    parentId:this.data.id
                };
                comment.saveReply(result,cmId).then((res)=>{
                    if (!res.data.success){
                        this.$message.error(res.data.message)
                        return
                    }
                    this.replyComment = "";
                    if (this.isReply){
                        this.$emit('addReply',res.data.data.reply);
                    }else{
                        this.addRely(res.data.data.reply);
                    }
                    this.replyCommentEdit=!this.replyCommentEdit
                    this.$emit("refreshList")
                })
            },
            getFromUserAvatar(){
                userInfoApi.getUserAvatar(this.data.fromUserId).then((res)=>{
                    if (res.data.success){
                        this.fromUser = {
                            username:res.data.data.username,
                            avatar:res.data.data.avatar
                        }
                    }else {
                        this.$message.error(res.data.message)
                    }
                })
            },
            getToUserAvatar(){
                userInfoApi.getUserAvatar(this.data.toUserId).then((res)=>{
                    if (res.data.success){
                        this.toUser = {
                            username:res.data.data.username,
                            avatar:res.data.data.avatar
                        }
                    }else {
                        this.$message.error(res.data.message)
                    }
                })
            },
            //change local list for show
            addRely(reply){
                this.replies.push(reply)
            },
            deleteComment(){
                comment.deleteComment(this.data.id).then((res)=>{
                    if (!res.data.success){
                        this.$message.error(res.data.message);
                    }else{
                        this.replyCommentDelete = true;
                    }
                })
            },
            renderContent(content){
                return common.getMarkdownIt().render(content)
            },
            reportDialog(){
                this.reportShow = true;
            },
            sendReport(){
                this.report.reportedContentId = this.data.id;
                this.report.reportedUserId = this.data.fromUserId;
                comment.saveIllegalReport(this.report).then((res)=>{
                    if (res.data.success){
                        this.report.note = "";
                        this.reportShow = false;
                    }
                    this.$message(res.data.message)
                })
            },
            newPage(url){
                window.open(url,'_blank')
            },
            isBlank(item){
                return common.isBlank(item);
            }
        },
    }
</script>

<style scoped>
    .from-info{
        padding: 5px 10px;
        height: 30px;
        line-height: 30px;
    }
    .from-head-img{
        vertical-align: middle;
        cursor: pointer;
    }
    .from-name{
        margin-left: 10px;
        vertical-align: -3px;
        cursor: pointer;
        color: #7f7f7f;
        font-family: 微软雅黑;
        font-size: 14px;
    }
    .talk-box{
        padding: 0 10px 0 55px;
    }
    .icon-btn{
        padding: 0 10px 0 35px;
    }
    .operator-btn{
        padding: 0 0px 0 0;
        color: #909090;
    }
    .operator-btn :hover{
        color: #ababab;
    }
    .talk-box,.operator-btn{
        font-size: 13px;
        font-family: 微软雅黑;
    }
    .talk-box >>> img{
        max-width: 800px;
    }
    p{
        white-space: pre-wrap;
        margin: 0 0 5px 0;
        padding: 0 0;
        font-size: 13px;
    }
    .reply-box{
        background-color: rgba(0, 0, 0, 0.09);
        margin:5px 0 5px 55px;
        padding: 0 0 5px 0;
        border-radius: 5px;
    }
    .comment-item{
        font-family: 微软雅黑;
    }
    .comment-item >>> .el-divider {
        margin: 5px;
    }

</style>