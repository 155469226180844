<template>
    <div style="text-align: left">
        <el-card class="box-card">
            <!--      问题描述      -->
            <div class="my-question-title">[{{mark.positionIndex}}]{{item.title}}</div>
            <div style="padding-bottom: 10px">
                <el-button class="my-text-button" v-show="item.createdByUserId === userId"
                           type="text" style="float:right" @click="delQuestion">
                    <i class="el-icon-delete">删除</i></el-button>
                <el-button class="my-text-button" v-if="mark.answerCount+countAnswers!=0"
                           type="text" style="float:right" @click="getAnswers">
                    <i class="el-icon-caret-bottom" ></i>查看回答({{mark.answerCount+countAnswers}})</el-button>
                <el-button class="my-text-button" style="float:right" type="text" @click="answer">
                    <i class="el-icon-edit">写回答</i></el-button>
            </div>
            <!--      回答列表      -->
            <div class="answer-content" v-show="show">
                <div v-for="(answer,index) in answers" v-bind:key="index">
                    <el-divider style="color: #ebebeb"></el-divider>
                    <MyCardComponent :date="answer.answer.modifiedDate" :item="getItem(answer.answer,index)" :content="answer.answer.content"
                                     :user-avatar="getUserAvatar(answer.answer.modifiedByUserId,answer.username,answer.avatar)">
                        <template v-if="answer.answer.modifiedByUserId === userId" v-slot:right-up="{prop}">
                            <el-dropdown>
                                <span><i class="el-icon-more"></i></span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>
                                        <el-button type="text" size="mini" @click="delAnswer(prop.item.id,prop.item.index)">
                                            删除
                                        </el-button>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </MyCardComponent>
                </div>
                <el-divider content-position="center" style="color: #ebebeb" v-if="hasNextPage">
                    <el-link type="primary" :underline="false" @click="load">查看更多</el-link>
                </el-divider>
                <el-divider content-position="center" style="color: #ebebeb" v-if="!hasNextPage">
                    没有更多了
                </el-divider>
            </div>
        </el-card>
        <!--    写回答弹框    -->
        <el-dialog title="写回答" :visible.sync="dialogVisible">
            <mavon-editor  v-model="newAnswer" :toolbars="toolbars" @helpToggle="help"></mavon-editor>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveAnswerToServer">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { mavonEditor } from 'mavon-editor'
    import "@/assets/css/main.css"
    import "mavon-editor/dist/css/index.css"
    import questions from '../../utils/study/questions'
    import MyCardComponent from "@/components/common/MyCardComponent";
    import common from "@/utils/common";

    export default {
        name: "AsideQuestions",
        props:{
            //问题的具体信息 item.id为问题id item.blogId
            item:Object,
            //回答一页显示的数量
            size:{
                type:Number,
                default:3
            },
            //问题序号和回答数
            mark:Object,
            userId:String
        },
        mounted() {
          console.log(this.mark)
        },
        data(){
            return {
                //问题回答数量
                countAnswers:0,
                //是否展开回答
                show: false,
                //是否显示写回答的弹框
                dialogVisible:false,
                //新回答的内容
                newAnswer:"",
                //回答列表
                answers:[],
                //是否有更多回答
                hasNextPage:true,
                page:1,
                //新回答的id
                newAnswerId:"",
                toolbars: {
                    bold: true, // 粗体
                    italic: true, // 斜体
                    header: true, // 标题
                    underline: true, // 下划线
                    strikethrough: true, // 中划线
                    mark: true, // 标记
                    superscript: false, // 上角标
                    subscript: false, // 下角标
                    quote: true, // 引用
                    ol: false, // 有序列表
                    ul: true, // 无序列表
                    link: false, // 链接
                    imagelink: false, // 图片链接
                    code: false, // code
                    table: true, // 表格
                    fullscreen: false, // 全屏编辑
                    readmodel: false, // 沉浸式阅读
                    htmlcode: false, // 展示html源码
                    help: true, // 帮助
                    /* 1.3.5 */
                    undo: true, // 上一步
                    redo: true, // 下一步
                    trash: true, // 清空
                    save: false, // 保存（触发events中的save事件）
                    /* 1.4.2 */
                    navigation: false, // 导航目录
                    /* 2.1.8 */
                    alignleft: true, // 左对齐
                    aligncenter: true, // 居中
                    alignright: true, // 右对齐
                    /* 2.2.1 */
                    subfield: false, // 单双栏模式
                    preview: false, // 预览
                },
            }
        },
        components:{
            MyCardComponent,
            'mavon-editor':mavonEditor
        },
        watch:{
          item:function () {
              this.show = false;
          }
        },
        methods:{
            help(){
              this.dialogVisible=false
            },
            //展开问题回答
            getAnswers(){
                if (this.show){
                    this.show = false
                }else{
                    //获取第一页回答，和回答总数
                    this.page = 1;
                    this.answers = [];
                    this.hasNextPage = false;
                    questions.getAnswers(this.item.id,this.page,this.size).then((res)=>{
                        let ret = res.data;
                        if (ret.success && this.answers.length === 0){
                            ret.data.answerList.forEach((item)=>{
                                this.answers.push(item)
                            });
                            this.hasNextPage = ret.data.hasNextPage;
                        }
                        this.show = true
                    })
                }
            },
            //打开问题回答框 同时获取回答id 保证幂等性
            answer(){
                questions.getAnswerId().then((res)=>{
                    this.newAnswer=''
                    this.newAnswerId = res.data.data.token;
                    this.dialogVisible = true;
                })
            },
            //保存回答到服务器
            saveAnswerToServer: function () {
                questions.saveAnswer(this.item.id, this.newAnswerId, this.newAnswer).then((res) => {
                    this.$message(res.data.message);
                    this.countAnswers++;
                    this.dialogVisible = false;
                    //获取第一页回答，和回答总数
                    this.page = 1;
                    this.answers = [];
                    this.hasNextPage = false;
                    questions.getAnswers(this.item.id,this.page,this.size).then((res)=>{
                        let ret = res.data;
                        if (ret.success && this.answers.length === 0){
                            ret.data.answerList.forEach((item)=>{
                                this.answers.push(item)
                            });
                            this.hasNextPage = ret.data.hasNextPage;
                        }
                    })
                })
            },
            //加载回答
            load () {
                //获取第一页回答，和回答总数
                this.page++;
                questions.getAnswers(this.item.id,this.page,this.size).then((res)=>{
                    this.count += this.size;
                    let ret = res.data;
                    if (ret.success){
                        this.hasNextPage = ret.data.hasNextPage;
                        ret.data.answerList.forEach((item)=>{
                            this.answers.push(item)
                        })
                    }
                })
            },
            delAnswer(id,index){
                this.$confirm('此操作将删除该回答, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    questions.delAnswer(id).then((res)=>{
                        this.$message(res.data.message);
                        this.answers.splice(index,1);
                        this.countAnswers --;
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            renderContent(content){
                return common.getMarkdownIt().render(content)
            },
            delQuestion(){
                this.$confirm('此操作将删除该问题, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    questions.delQuestion(this.item.id).then((res)=>{
                        let ret = res.data;
                        if (ret.success && (ret.data.newContent !== undefined )){
                            this.$emit("updateBlogContent",ret.data.newContent);
                        }else if (ret.success && ret.data.newContent === undefined){
                            this.$emit("updateAsideContent");
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            getUserAvatar(userId,username,avatar){
                var name = userId === this.userId?'我的':username;
                return {userId:userId,username:name,avatar}
            },
            getItem(item,index){
                item.index = index;
                return item
            }
        }
    }
</script>

<style scoped>
    .answer-content >>> .el-divider__text{
        font-size: 13px;
        text-align: left;
        color: #bfbfbf;
    }
    .answer-content >>> .el-divider {
        margin: 15px 0 0 0;
    }
</style>