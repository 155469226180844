<template>
    <div class="background">
        <i class="el-icon-close" style="font-size: 20px;float: right;margin-right: 20px;margin-top: 10px;" @click="clickClose"></i>
        <span style="font-size: 20px;color: black;float: left;margin-left: 15px;margin-top: 15px;">提示</span>
        <br>
        <p style="color: black;text-align: left;margin-top:45px;margin-left: 15px;">本活动涉及内容发布，所以根据网信办
            <span style="font-weight:bold;">《互联网跟帖评论服务管理规定》</span>，在报名活动前，需要请您前往【个人中心】-【编辑资料】-【账号设置】中进行
            <span style="font-weight:bold;">实名认证</span>。</p>
        <el-button  style="border: none;background-color:#F8B62B;width:128px;height:28px;color: white;font-size: 14px;margin-top: 13px;float: right;margin-right: 20px;"  @click="goRealnameAuthetication">
            <span style="top: -5px;position: relative;">前往实名认证</span>
        </el-button>
    </div>
</template>

<script>
    export default {
        name: "nameTip",
        methods:{
            clickClose(){
              this.$emit("setUserInfo")
            },
            goRealnameAuthetication(){
                this.goPage('/personalCenter/setup')
            },
            goPage(route){
                this.$router.push(route)
            }
        }

    }

</script>

<style scoped>
    .background{
        background-color:#fffef6;
        width:576px;
        height:172px;
        border-radius: 5px 5px 5px 5px;
        box-shadow:4px 4px 10px gray;
        margin:150px auto 0;
    }

</style>