<template>
        <div class="block" >
            <el-button type="primary" style="margin-top: 20px;margin-left:50px;"  @click="deleteHistory" >清除浏览记录</el-button>
             <el-timeline style="margin-top: 30px;"   >
                 <el-timeline-item color="#2696BB" :timestamp="dateFormat(item.createdDate)" placement="top"  v-for="(item,index) in info" :key="index" >
                     <history-item :item="item" ></history-item>
                 </el-timeline-item>
             </el-timeline>
             <!--分页-->
             <el-pagination  v-show="showPage" background layout="prev, pager, next" :page-size="size" :total="totalCount" style="float:right;"
                     @current-change="handleCurrentChange" :current-page="currentPage">
             </el-pagination>
</div>
</template>
<script>
    import personalQuery from "@/utils/personalCenter/personalQuery"
    import common from "@/utils/common";
    import HistoryItem from "../../components/user/history/historyItem";

    export default {
        name: "history",
        components: {HistoryItem},
        data(){
            return {
                size:8,
                info:[],
                totalCount:8,
                currentPage:1,
                showPage:false
            }
        },
        mounted() {
            this.getHistory(1)

        },
        methods:{
            deleteHistory(){
                personalQuery.deleteHistory().then((res)=>{
                    if(res.data.success){
                        this.$message("成功！")
                        this.getHistory(1)
                    }
                })
            },
            handleCurrentChange(val){
                this.getHistory(val)
            },
            dateFormat(date){
                return common.dateFormat(date,"yyyy-MM-dd")
            },
            getHistory(page){
                this.currentPage=page
                personalQuery.getHistory(page,this.size).then((res)=>{
                    if(res.data.success){
                        this.info=res.data.data.list
                        this.totalCount=res.data.data.total
                        if(this.totalCount>this.size)
                            this.showPage=true
                        else
                            this.showPage=false
                    }
                })

            }

        }
    }
</script>
<style scoped>
    /*卡片样式*/
    .a1{
        margin-top: 16px;
    }
    /*第一个标签样式*/
    .a2{
        display: inline-block;
        width:80px;
        text-align: center;
        border-radius: 20px 20px 20px 20px;
    }
    /*第二个标签样式*/
    .a3{
        display: inline-block;
        width:90px;
        text-align: center;
        border-radius: 20px 20px 20px 20px;
    }

</style>