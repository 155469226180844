<template>
    <div class="setup">
        <h2 style="margin-left: 30px;">账号安全</h2>
        <el-divider></el-divider>
        <!--手机号-->
        <p style="color: #7b7b7b;font-size:16px;margin-left: 30px;display: inline-block">手&nbsp;&nbsp;机&nbsp;&nbsp;号</p>
        <p style="font-size:16px;margin-left: 50px;display: inline-block">{{userInfo.phoneNumber}}</p>
        <p style="color:rgba(100,83,83,0.45);font-size:10px;margin-left: 30px;display: inline-block">仅自己可见</p>
        <el-button type="primary" class="a2" @click="num = 1">更换</el-button>
        <el-divider class="a1"> </el-divider>
        <!--旧手机号修改手机号弹窗1-->
        <el-dialog :visible="num === 1" :show-close="false" top="0vh">
            <el-button type="primary" icon="el-icon-close" circle class="my-login-button2" @click="closereason" style="margin-top: 110px;margin-left: 800px;"></el-button>
            <modifyPhone_one @next="next"></modifyPhone_one>
        </el-dialog>
        <!--邮箱修改手机号弹窗2-->
        <el-dialog :visible="num === 2" :show-close="false" top="0vh">
            <el-button type="primary" icon="el-icon-close" circle class="my-login-button2" @click="closereason" style="margin-top: 110px;margin-left: 800px;"></el-button>
            <modifyPhone_two @next="next"></modifyPhone_two>
        </el-dialog>
        <!--设置新手机号弹窗3-->
        <el-dialog :visible="num === 3" :show-close="false" top="0vh">
            <el-button type="primary" icon="el-icon-close" circle class="my-login-button2" @click="closereason" style="margin-top: 110px;margin-left: 800px;"></el-button>
            <modifyPhone_three @next="next"></modifyPhone_three>
        </el-dialog>
        <!--电子邮箱-->
        <p style="color: gray;font-size:16px;margin-left: 30px;display: inline-block">电子邮箱</p>
        <p v-if="userInfo.email!=null" style="font-size:16px;margin-left: 50px;display: inline-block" >{{userInfo.email|hideMiddle}}</p>
        <p style="color:rgba(100,83,83,0.45);font-size:10px;margin-left: 30px;display: inline-block">仅自己可见</p>
        <el-button type="primary" class="a2" @click="num=4" v-if="userInfo.email!=null&&userInfo.email!==''">修改</el-button>
        <el-button type="primary" class="a2" @click="num=10" v-else>绑定</el-button>
        <el-divider class="a1"> </el-divider>
        <!--原电子邮箱修改电子邮箱弹窗1-->
        <el-dialog :visible="num===4" :show-close="false" top="0vh">
            <el-button type="primary" icon="el-icon-close" circle class="my-login-button2" @click="closereason" style="margin-top: 110px;margin-left: 800px;"></el-button>
            <modifyEmail_one @next="next"></modifyEmail_one>
        </el-dialog>
        <!--设置新电子邮箱弹窗2-->
        <el-dialog :visible="num===5" :show-close="false" top="0vh">
            <el-button type="primary" icon="el-icon-close" circle class="my-login-button2" @click="closereason" style="margin-top: 110px;margin-left: 800px;"></el-button>
            <modifyEmail_two @next="next"></modifyEmail_two>
        </el-dialog>
        <!--绑定电子邮箱弹窗1-->
        <el-dialog :visible="num===10" :show-close="false" top="0vh">
            <el-button type="primary" icon="el-icon-close" circle class="my-login-button2" @click="closereason" style="margin-top: 110px;margin-left: 800px;"></el-button>
            <setEmail @next="next"></setEmail>
        </el-dialog>
        <!--登录密码-->
        <p style="color: gray;font-size:16px;margin-left: 30px;display: inline-block">登录密码</p>
        <p style="font-size:16px;margin-left: 50px;display: inline-block">***********</p>
        <p style="color:rgba(100,83,83,0.45);font-size:10px;margin-left: 30px;display: inline-block">仅自己可见</p>
        <el-button type="primary" class="a2" @click="num=6">修改</el-button>
        <el-divider class="a1"> </el-divider>
        <!--修改密码弹窗-->
        <el-dialog :visible="num===6" :show-close="false" top="0vh">
            <el-button type="primary" icon="el-icon-close" circle class="my-login-button2" @click="closereason" style="margin-top: 110px;margin-left: 800px;"></el-button>
            <changePassword @next="next"></changePassword>
        </el-dialog>
        <!--忘记密码弹窗-->
        <el-dialog :visible="num===7" :show-close="false" top="0vh">
            <el-button type="primary" icon="el-icon-close" circle class="my-login-button2" @click="closereason" style="margin-top: 110px;margin-left: 800px;"></el-button>
            <forgetPassword @next="next"></forgetPassword>
        </el-dialog>
        <!--姓名身份证号-->
        <p style="color: gray;font-size:16px;margin-left: 30px;display: inline-block">姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名</p>
        <p style="font-size:16px;margin-left: 50px;display: inline-block">{{info.realName}}</p>
        <p style="color:rgba(100,83,83,0.45);font-size:10px;margin-left: 30px;display: inline-block;">仅自己可见</p>
        <br>
        <p style="color: gray;font-size:16px;margin-left: 30px;display: inline-block">身份证号</p>
        <p style="font-size:16px;margin-left: 50px;display: inline-block">{{info.certNo}}</p>
        <p style="color:rgba(100,83,83,0.45);font-size:10px;margin-left: 30px;display: inline-block">仅自己可见</p>
        <el-button type="primary" class="a2" style="margin-top: -20px;" @click="realname=true">实名</el-button>
        <el-divider class="a1"> </el-divider>
        <!--实名认证弹窗-->
        <el-dialog :visible.sync="realname" :show-close="false" top="0vh">
            <el-button type="primary" icon="el-icon-close" circle class="my-login-button2" @click="closereal" style="margin-top: 110px;margin-left: 800px;"></el-button>
            <realname @closeRealName="closeRealName"></realname>
        </el-dialog>
        <!--账号注销-->
        <p style="color: gray;font-size:16px;margin-left: 30px;display: inline-block">账号注销</p>
        <el-button type="primary" class="a2" @click="num=8">注销</el-button>
        <el-divider class="a1"> </el-divider>
        <!--注销原因弹窗-->
        <el-dialog :visible="num===8" :show-close="false" top="0vh">
            <el-button type="primary" icon="el-icon-close" circle class="my-login-button2" @click="closereason" style="margin-top: 110px;margin-left: 800px;"></el-button>
            <cancellationReason @next="next"></cancellationReason>
        </el-dialog>
        <!--注销规则弹窗-->
        <el-dialog :visible="num===9" :show-close="false" top="0vh">
            <el-button type="primary" icon="el-icon-close" circle class="my-login-button2" @click="closereason" style="margin-top: 110px;margin-left: 800px;"></el-button>
            <cancellationRule @next="closeCancellation"></cancellationRule>
        </el-dialog>
    </div>
</template>

<script>
    import modifyPhone_one from "./set/modifyPhone_one";
    import modifyPhone_two from "./set/modifyPhone_two";
    import modifyPhone_three from "./set/modifyPhone_three";
    import modifyEmail_one from "./set/modifyEmail_one";
    import modifyEmail_two from "./set/modifyEmail_two";
    import changePassword from "./set/changePassword";
    import forgetPassword from "./set/forgetPassword";
    import realname from "./set/realname";
    import cancellationReason from "./set/cancellationReason";
    import cancellationRule from "./set/cancellationRule";
    import saveInformation from "../../utils/personalCenter/saveInformation";
    import setEmail from "./set/setEmail";
    export default {
        name: "setup",
        filters: {
            hideMiddle(val) {
                return `${val.substring(0, 3)}******${val.substring(val.length - 6)}`
            }
        },
        data() {
            return {
                num:0,
                userInfo:{},
                info:{},
                realname:false
            };
        },
        components:{
            modifyPhone_one,modifyPhone_two,modifyPhone_three,modifyEmail_one
            ,modifyEmail_two,changePassword,forgetPassword,realname,cancellationReason,cancellationRule,setEmail
        },
        mounted() {
            this.getUMUser();
            this.getPersonalInformation();
        },
        methods:{
            getUMUser(){
                saveInformation.getUMUser().then((res)=>{
                    if (res.data.success){
                        this.userInfo = res.data.data.user;
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            },
            getPersonalInformation(){
                saveInformation.getRealInfo().then((res)=>{
                    if (res.data.success){
                        this.info = res.data.data.realInfo;
                    }else{
                        // this.$message.error(res.data.message)
                    }
                })
            },
            next(num){
                this.num = num;
            },
            closeRealName(){
                this.realname = false;
                this.$router.go(0);
            },
            closeCancellation(num){
                this.num = num;
                this.$router.push('/ebpf/index')
            },
            closereal(){
                this.realname=false
            },
            closereason(){
             this.num=0;
            }
        },
    }
</script>

<style scoped>
    .setup >>> .el-dialog{
        background-color: rgba(0,0,0,0);
        box-shadow: 0 0 0 !important;
    }
    /*分割线虚线*/
    .a1{
        background: 0 0;
        border-top: 1px dashed #a19f9c;
    }
    .a2{
        background-color:#2696BB;
        display: inline-block;
        float: right;
        width:120px;
        margin-right: 20px;
    }
</style>