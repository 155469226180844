<template>
    <div class="index">
        <el-row style="margin-top: 23vh;z-index: 100">
            <el-col :span="2">
                <!--价格招牌-->
                <img src="~@/assets/img/reward/price.svg" height="200px"
                     style="margin-left:1.5vw;">
                <!--剩余积分的黄色招牌-->
                <div class="rewards-detail">
                    <div style="position: relative;">
                        <el-button type="text" class="score" style="margin-top:30px;margin-left:50px;">
                            剩余积分：{{score}}
                        </el-button>
                        <el-button type="text" style="margin-left:40px;" @click="getDetailList(1)">
                            <i class="el-icon-question"></i>查询积分明细
                        </el-button>
                    </div>
                </div>
            </el-col>
            <el-col :span="2" :offset="5">
                <!--兑换物品图片-->
                <img :src="list[0].imageUrl" width="100px" style="margin-top: 0;cursor: pointer"
                     @click="open(list[0].id,0)">
            </el-col>
            <el-col :span="2" :offset="4">
                <img :src="list[1].imageUrl" width="100px" style="margin-top:-3vh;margin-left:2vw;cursor: pointer"
                     @click="open(list[1].id,1)">
            </el-col>
            <el-col :span="1" :offset="1">
                <img :src="list[2].imageUrl" width="100px" style="margin-top:0;cursor: pointer"
                     @click="open(list[2].id,2)">
            </el-col>
            <el-col :span="1" :offset="4">
                <!--积分兑换小铺招牌-->
                <img src="~@/assets/img/reward/shop.svg" height="350px"
                     style="margin-top: 5vh">
            </el-col>
        </el-row>
        <!--下方鳄鱼-->
        <img src="~@/assets/img/reward/snack.svg" height="600px"
             style="position:absolute;bottom: 0px;margin-left: 12vw;z-index: 2">
        <!--明细弹窗-->
        <el-dialog v-if="dialog" visible :modal-append-to-body="false" title="兑换明细" @close="dialog = false">
            <el-row>
                <el-col :span="4" :offset="1">
                    <el-button type="text" @click="goRulePage"><i class="el-icon-question"></i>积分规则</el-button>
                </el-col>
                <el-col :span="4" :offset="14">
                    <el-button type="text" style="float: right" v-if="!rewardExchange"
                               @click="getExchangeList(1)">
                        <i class="el-icon-present"></i>兑换明细
                    </el-button>
                    <el-button type="text" style="float: right" v-else @click="rewardExchange = false">
                        <i class="bi bi-coin"></i>积分明细
                    </el-button>
                </el-col>
            </el-row>
            <div v-if="!rewardExchange">
                <el-table :data="detailList" style="width: 100%" stripe>
                    <el-table-column label="收入/支出" prop="operatePoints" align="center">
                        <template slot-scope="scope">
                            {{scope.row.operateType == 1?"+":"-"}} {{scope.row.operatePoints}}
                        </template>
                    </el-table-column>
                    <el-table-column label="获取消费方式" prop="pointsType" align="center">
                        <template slot-scope="scope">
                            {{scope.row.pointsType == 1?"任务完成获取":"兑换物品"}}
                        </template>
                    </el-table-column>
                    <el-table-column label="时间" prop="createdTime" align="center" >
                        <template slot-scope="scope">
                            {{dateFormat(scope.row.createdDate)}}
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination style="margin-top: 20px;text-align: right" :page-size="5"
                               @current-change="handleCurrentChange"
                               background layout="prev, pager, next" :total="detailTotal">
                </el-pagination>
            </div>
            <div v-else style="padding: 0 20px">
                <rewardItemComponent v-for="item in exchangeList" v-bind:key="item.id" :item="item"></rewardItemComponent>
                <div v-if="exchangeList.length === 0" >
                    <img src="~@/assets/img/reward/exchangeNull.png" height="200px" style="margin-left: 15%">
                    <p style="text-align: center;font-weight: bold">还未兑换奖品，快去完成任务再来兑换吧</p>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import reward from "../../utils/personalCenter/reward";
    import common from "../../utils/common";
    import saveInformation from "../../utils/personalCenter/saveInformation";
    import rewardItemComponent from "../../components/user/reward/rewardItemComponent";

    export default {
        name: "achievement",
        components:{
            rewardItemComponent
        },
        data(){
            return {
                list:[],
                dialog:false,
                //积分明细
                detailList:[],
                detailTotal:0,
                score:0,
                //兑换明细
                rewardExchange:false,
                exchangeList:[]
            }
        },
        mounted() {
            this.getList();
            this.getMyScore();
        },
        methods: {
            //获取可兑换奖品列表
            getList(){
                reward.getRewardList(1,3).then((res)=>{
                    if (res.data.success){
                        this.list = res.data.data.list;
                    }
                })
            },
            //获取用户剩余积分
            getMyScore(){
                saveInformation.getScore(this.$cookies.get('userId')).then((res)=>{
                    if (res.data.success){
                        this.score = res.data.data.score;
                    }
                })
            },
            //兑换奖品
            // open(id,index) {
            open() {
                // this.$confirm(this.list[index].title+'需要'+this.list[index].scoreNeeded+'积分，是否要兑换该会员?', '提示', {
                this.$confirm('积分兑换将于不久后上线', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$message.info("感谢您的光临");
                    // reward.exchangeReward(id).then((res)=>{
                    //     this.$message(res.data.message)
                    // })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消兑换'
                    });
                });
            },
            dateFormat(date){
                return common.dateFormat(date,"yyyy-MM-dd hh:mm:ss")
            },
            handleCurrentChange(val){
                this.getDetailList(val)
            },
            //获取积分明细表
            getDetailList(page){
                reward.getDetailList(page,5).then((res)=>{
                    if (res.data.success){
                        this.detailList = res.data.data.list;
                        this.detailTotal = res.data.data.total;
                        this.dialog = true;
                    }
                })
            },
            //积分规则页面
            goRulePage(){
                this.$router.push("/personalCenter/reward/rule");
            },
            //获取兑换明细表
            getExchangeList(page){
                reward.getExchangeList(page,3).then((res)=>{
                    if (res.data.success){
                        this.exchangeList = res.data.data.list;
                        this.rewardExchange = true;
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .index{
        background: url("~@/assets/img/reward/bg.png") no-repeat;
        width:calc(100% - 280px);
        height:calc(100% - 82px);
        position:fixed;
        background-size:100% 100%;
        max-width: 1720px;
        min-height: 600px;
    }
    .score{
        font-size: 16px;
        font-weight: bold;
        color: #004E67;
    }
    .rewards-detail{
        background: url("~@/assets/img/reward/rewardsDetail.svg") no-repeat;
        margin-top: 26%;margin-left:1%;
        width: 200px;
        height: 150px;
    }
</style>