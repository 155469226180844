import { render, staticRenderFns } from "./TaskCommentItemComponent.vue?vue&type=template&id=9a1fffec&scoped=true&xmlns%3Adisplay=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml&"
import script from "./TaskCommentItemComponent.vue?vue&type=script&lang=js&"
export * from "./TaskCommentItemComponent.vue?vue&type=script&lang=js&"
import style0 from "./TaskCommentItemComponent.vue?vue&type=style&index=0&id=9a1fffec&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a1fffec",
  null
  
)

export default component.exports