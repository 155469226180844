<template>
        <el-container>
            <el-main class="center">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/personalCenter/helpCenter' }">帮助中心</el-breadcrumb-item>
                    <el-breadcrumb-item>常见问题</el-breadcrumb-item>
                </el-breadcrumb>
                <h1 style="text-align: left">常见问题</h1>
                <el-collapse  accordion style="text-align: left">
                    <h3>一.常见回答汇总</h3>
                    <el-collapse-item title="1.如何修改昵称、性别、生日、所在地址、行业等资资料？" name="1">
                        <div style="font-size: 15px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在【个人中心】-【资料】或【个人主页】-【编辑资料】-【资料】中点击 “修改资料”，进行修改填写，点击“保存资料”→修改成功。</div>
                    </el-collapse-item>
                    <el-collapse-item title="2.如何修改密码？" name="2">
                        <div style="font-size: 15px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在【个人中心】-【账户设置】或【个人主页】-【编辑资料】-【账户设置】中点击“修改”，输入原密码、新密码，并二次确认新密码，点击“确认”→修改成功。<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;若忘记密码可再登录界面或【账户设置】中点击“忘记密码”使用手机号或邮箱进行修改。
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="3.如何更换手机号？" name="3">
                        <div style="font-size: 15px">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在【个人中心】-【账户设置】或【个人主页】-【编辑资料】-【账户设置】中点击“更换”，输入原手机号，验证并填写验证码，点击“下一步”，输入新手机号，验证并填写验证码，点击“确认”→更换成功！
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="4.如何实名认证？" name="4">
                        <div style="font-size: 15px">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在【个人中心】-【账户设置】或【个人主页】-【编辑资料】-【账户设置】中点击“实名”，输入姓名、身份证号，点击“确认”→修改成功。<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;一个账号只可实名认证一个人，一经实名不可更改。
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="5.如何注销账号？" name="5">
                        <div style="font-size: 15px">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在【个人中心】-【账户设置】或【个人主页】-【编辑资料】-【账户设置】中点击“注销”，选择原因、阅读注销须知、进行验证，点击“永久注销”。<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;账号注销后，您将无法登录、使用波粒派账号，请您仔细阅读、充分理解《波粒派账号注销须知》。
                        </div>
                    </el-collapse-item>
                </el-collapse>
                <el-collapse  accordion style="text-align: left">
                    <h3>二.发布上传问题</h3>
                    <el-collapse-item title="1.可以上传哪些素材？" name="1">
                        <div style="font-size: 15px">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;学习总结、优秀的eBPF相关学习文章、多篇文章的学后感。支持上传原文链接。上传素材前要注意网站是否已经存在相同素材，避免素材重复，完全相同的素材不予通过。<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;可在【探索之旅】-【讨论区】中进行相关讨论，或在【探险手册】-【文档】的任意文章中进行问答。
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="2.上传素材有哪些注意事项？" name="2">
                        <div style="font-size: 15px">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;①如果上传的素材是互联网上获取的，上传时需要放出原文链接，如果纸质书，请标明书名及作者；<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;②如果上传素材是借鉴多个其他网上内容，但有经过自己的学习消化总结，需要尽可能在原文中附上所有参考链接或参考文献（找不到原链接的请文字说明）；<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;③自己学习过程中的经验教训总结，可标注特殊标签（类似学习记录、原创）若有参考内容，请在原文中附上原文链接或参考文献；<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;④需要尽可能设置符合文章内容标签；<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;⑤务必尊重其他人的成果；<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;⑥请多次阅读您上传的每一篇内容，确保您在上传过程中有所收获。
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="3.如何做笔记？" name="3">
                        <div style="font-size: 15px">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在【探索之旅】-【暑假学习活动】-【任务详情】中填入关键运行代码、输出运行结果、填写学习心得→提交实验报告。<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;可在【探索之旅】-【活动任务】-【素材库】中对实验报告进行展开、编辑、删除。<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;可在【个人中心】-【记事本】-【笔记】中对笔记进行展开、编辑、删除。<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;可在【个人主页】-【笔记】中对笔记进行展开查看。<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;可在波粒派首页，点击“我的笔记”，跳转至【记事本】中对笔记进行展开、编辑、删除。<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注意： 完成任务后，可自动生成实验报告、包含笔记。实验报告审核通过之后不可编辑。
                        </div>
                    </el-collapse-item>
                </el-collapse>
                <el-collapse  accordion style="text-align: left">
                <h3>三.活动相关问题</h3>
                <el-collapse-item title="1.如何参加活动？" name="1">
                    <div style="font-size: 15px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;点击探索之旅→点击开始探索→前往实名认证→重新登陆即可进入活动。<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;波粒派首页→点击开启eBPF探索之旅→点击开始探索→前往实名认证→重新登陆即可进入活动。<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注意：根据网信办《互联网跟帖评论服务管理规定》，在活动报名时需要实名认证。
                    </div>
                </el-collapse-item>
<!--                <el-collapse-item title="2.积分获取途径？" name="2">-->
<!--                    <div style="font-size: 15px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;完成活动任务。</div>-->
<!--                </el-collapse-item>-->
<!--                <el-collapse-item title="3.积分的用途？" name="3">-->
<!--                    <div style="font-size: 15px">-->
<!--                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;兑换奖励、参与项目、参与活动、报名参加线下研讨会。-->
<!--                    </div>-->
<!--                </el-collapse-item>-->
                <el-collapse-item title="2.如何领取、完成任务？" name="4">
                    <div style="font-size: 15px">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;报名参与活动，即可开始任务，无需领取；在活动的任务界面上传提交代码链接、运行结果，并提交实验报告，当网站审核通过后，即算完成任务。<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;一个任务可以重复进行多次。
                    </div>
                </el-collapse-item>
            </el-collapse>
            <el-collapse  accordion style="text-align: left">
                 <h3>四.举报投诉问题</h3>
                 <el-collapse-item title="1.如何举报投诉？" name="1">
                     <div style="font-size: 15px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在违法用户发布信息界面有投诉按钮；用户可点击投诉按钮，选择举报投诉原因即可对违法用户予以举报投诉。<br>
<!--                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;若被举报用户对举报处置结果有异议的，可通过举报结果通报信息进行申诉，并提供文字或图片证据。-->
                     </div>
                 </el-collapse-item>
                 <el-collapse-item title="2.举报要求？" name="2">
                     <div style="font-size: 15px">
                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;①举报发布内容与本站无关、含广告信息、含血腥、政治、暴力、恶语、讽刺、骚扰等内容；<br>
                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;②有明确的涉及不良违法信息的文字或图片证据。
                     </div>
                 </el-collapse-item>
                 <el-collapse-item title="3.什么情况会被网站删除、屏蔽？" name="3">
                     <div style="font-size: 15px">
                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                         上传无意义、乱写乱填、与主题无关的内容2次以上；上传恶意捆绑病毒、木马的压缩包文件；上传违反国家相关法律、危害国家安全的内容及言论；未经原作者授权，在冒发他人原创作品。
                     </div>
                 </el-collapse-item>
            </el-collapse>
            <el-collapse  accordion style="text-align: left">
                <h3>五.网站相关问题</h3>
                <el-collapse-item title="1.网站是做什么的？" name="1">
                    <div style="font-size: 15px">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;波粒派（www.bolipi.com）隶属于无锡旷其信息技术有限公司，是一个免费的、以专业知识分享、交流、实践为中心的兴趣小组。<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;可以与志同道合的朋友进行知识交互、平等讨论、共同进步的聚合平台。<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;网站的口号是：陪伴学习，允许犯错。
                    </div>
                </el-collapse-item>
                <el-collapse-item title="2.网站适合哪些人加入进来？" name="2">
                    <div style="font-size: 15px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;对计算机、操作系统内核、新技术、eBPF感兴趣、希望学习更多有意思知识的任何人！</div>
                </el-collapse-item>
            </el-collapse>
            </el-main>
        </el-container>
</template>

<script>
    export default {
        name: "problem"
    }
</script>

<style scoped>
    /deep/ .el-collapse-item__header{
        font-size:15px;
    }
</style>