import axios from "../utils/http";

function getProjectMenus(page,size,parentId,grantfatherId) {
    return axios({
        url:"/api/user/menu/getProjectMenus/"+page+"/"+size,
        method:"get",
        params:{
           parentId:parentId,
           grantfatherId:grantfatherId
        }
    })
}
function getdata(page,size,type) {
    return axios({
        url:"/api/user/project/getdata/"+page+"/"+size,
        method:"get",
        params:{
            type:type
        }
    })
}
function getTag(relatedId){
    return axios({
        url:"/api/user/project/gettag",
        method:"get",
        params:{
            relatedId:relatedId
        }
    })
}


function uploadIcon(formData) {
    return axios({
        url:"/api/user/project/inserticon",
        method:"post",
        data:formData

    })
}
function getid() {
    return axios({
        url:"/api/user/project/get/token",
        method:"get"
    })
}
function insertProject(data) {
    return axios({
        url:"/api/user/project/insert",
        method:"post",
        data:data

    })
}

function updateProjectTag(data){
    return axios({
        url:"/api/user/project/updateTag",
        method:"put",
        data:data
    })
}

function setNotActive(id) {
    return axios({
        url:`/api/user/project/delete/${id}`,
        method:"delete"
    })
}
//获取博客
function getProject(projectId,clickName,userId,type) {
    return axios({
        url:"/api/user/project/get/"+projectId,
        method:"get",
        params:{
            clickName:clickName,
            userId:userId,
            type:type
        }
    })
}
//上传图片
function imgUpload(projectId,formData) {
    return axios({
        url:`/api/user/project/${projectId}/img`,
        method: 'post',
        data: formData,
        headers:{'content-Type':'multipart/form-data'},
    })
}

function deleteMutliById(ids) {
    return axios({
        url:"/api/user/project/deleteMulti",
        method:"delete",
        data:ids
    })
}
function getProjectByUserId(page,size) {
    return axios({
        url:"/api/user/project/getProjectByUserId/"+page+"/"+size,
        method:"get"
    })
}
function getProjectByAuthor(author) {
    return axios({
        url:"/api/user/project/getProjectByAuthor/",
        method:"get",
        params:{
            author:author
        }
    })
}

export default {getdata,getTag,getProjectMenus,uploadIcon,getid,insertProject,updateProjectTag,setNotActive,getProject,imgUpload,deleteMutliById
                ,getProjectByUserId,getProjectByAuthor

}


