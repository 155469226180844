<template>

    <el-card class="item-task-impl" shadow="hover">
        <el-row>
          <a class="my-link" v-on:click="goPage(item.url)" >
            <el-col :span="20">
                <span class="my-body-title">{{item.title}}</span>
                <p v-if="item.type === 'link'" >{{item.url}}</p>
                <p v-else>{{item.summary}}</p>
            </el-col>
          </a>
        </el-row>
        <el-row>
            <el-col :span="18">
                <el-button type="text" :id="item.relatedId" class="my-text-btn-primary" size="mini" @click="setNotPreference(item.relatedId)" v-if="flag===false">删除</el-button>
            </el-col>
            <el-col :span="6" style="float: right">
                <span style="padding-top: 5px;font-size: 14px;color: #cccccc;float: right">{{dateFormat(item.modifiedDate)}}</span>
            </el-col>
        </el-row>
    </el-card>

</template>

<script>
    import common from "@/utils/common";
    import fileApi from "@/utils/task/fileApi";
    // import personalQuery from "../../../utils/personalCenter/personalQuery";

    export default {
        name: "PreferenceItemComponent",
        props:{
            item:Object,
            flag:Boolean
        },
        data(){
            return {

                preferenceStatus:false,
                info:{}

            }
        },
        watch:{
          item:function () {
              // this.getPreferenceTitleUrl(this.item.relatedId,this.item.type)
          }
        },
        mounted(){
            if (!common.isBlank(this.item)){
                this.preferenceStatus = this.item.status === 1;
            }
            // this.getPreferenceTitleUrl(this.item.relatedId,this.item.type)
        },
        methods:{
            goPage(url){
              fileApi.goPage(url,this.$router)
            },
            dateFormat(date){
                return common.dateFormat(date,"yyyy-MM-dd hh:mm:ss")
            },
            setNotPreference(relatedId){
                this.$confirm('您确定要删除吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type:"warning"
                }).then(() => {
                    fileApi.setNotPreference(relatedId).then((res)=>{
                        if (!res.data.success){
                            this.$message.error(res.data.message)
                        }else{
                            this.$emit("refreshList")
                            document.getElementById(relatedId).style.color="#11B0E1"
                            this.$message("成功！")
                        }
                    })
                }).catch(() => {
                });
            },
           /* getPreferenceTitleUrl(relatedId,type){
                personalQuery.getPreferenceTitleUrl(relatedId,type).then((res)=>{
                if(res.data.success){
                    this.info=res.data.data.list
                }
                })
            }*/
        }
    }
</script>

<style scoped>

    .item-task-impl{
        margin-bottom: 8px;
    }
    ::v-deep .el-card__body{
        padding: 10px 20px;
    }
    p{
        white-space: pre-wrap;
        margin: 10px 0;
    }
    .item-content{
        margin-left: 16px;
    }
    ::v-deep .el-button{
        padding: 5px 0;
    }
    .status-content{
        font-size: 15px;
        color: #cccccc;
        float: right;
    }
    .btn-gray{
        color: #c8c8c8;
    }
</style>