<template>
    <div>
        <el-tabs  @tab-click="handleTabClick">
            <el-tab-pane label="笔记"  >
               <NoteListComponent v-if="tabIndex === '0'" :user-id="userId"></NoteListComponent>
            </el-tab-pane>
            <el-tab-pane label="收藏">
                <PreferenceListComponent v-if="tabIndex === '1'" :user-id="userId"></PreferenceListComponent>
            </el-tab-pane>
            <el-tab-pane label="提问" >
                <MyQuestionListComponent v-if="tabIndex === '2'" :user-id="userId" :is-center="true"></MyQuestionListComponent>
            </el-tab-pane>
            <el-tab-pane label="回答" >
                <MyAnswerListComponent v-if="tabIndex === '3'" :user-id="userId" :is-center="true"></MyAnswerListComponent>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import NoteListComponent from "../../components/user/notepad/NoteListComponent";
    import PreferenceListComponent from "../../components/user/notepad/PreferenceComponent";
    import MyQuestionListComponent from "../../components/user/notepad/MyQuestionListComponent";
    import MyAnswerListComponent from "../../components/user/notepad/MyAnswerListComponent";

    export default {
        name: "notepad",
        components:{
            PreferenceListComponent,
            NoteListComponent,
            MyQuestionListComponent,
            MyAnswerListComponent
        },
        data(){
            return {
                tabIndex:'0',
                userId:this.$cookies.get("userId")
            }
        },
        methods:{
            handleTabClick(tab){
                this.tabIndex = tab.index
            },
        }
    }
</script>

<style scoped>
    /* 滑块横条 */
    ::v-deep .el-tabs__nav-wrap::after {
        width:100%;
        height:100%;
        background-color:#b9d8e6;
    }
    /* 设置滑块颜色 */
    ::v-deep .el-tabs__active-bar{
        background-color:#2696bb !important;
    }
    /* 设置标签当前选中样式 */
    ::v-deep .el-tabs__item.is-active{
        color: #2696bb !important;
    }
    /* 设置标签未被选中样式 */
    ::v-deep .el-tabs__item{
        font-size:18px;
        padding: 0 30px !important;
        text-align: center;
        width:100px;
        box-sizing: border-box;
        display: inline-block;
        position: relative !important;
        color:#000000 !important;
    }
</style>