import axios from '@/utils/http';
import md5 from "js-md5";
//修改密码请求，用于首页忘记密码
function modifyPassword(data) {
    return axios({
        url:"/api/user/users/modifyPassword",
        method:"post",
        data:{
            account: data.account,
            status: data.status,
            verification: data.verification,
            password: md5(data.password),
            password2: md5(data.password2),
        }
    })
}
function personalModifyPassword(data){
    return axios({
        url:"/api/user/users/pmodifyPassword",
        method:"post",
        data:data
    })
}
//获取手机验证码
function getVerifyCodeBySMS(account) {
    return axios({
        url:"/api/user/sms/verifyCode",
        method:"get",
        params:{
            phoneNum:account
        }
    })
}
//获取邮箱验证码
function getVerifyCodeByEmail(account) {
    return axios({
        url:"/api/user/mail/verificationCode",
        method:"get",
        params:{
            receiver:account
        }
    })
}
//忘记密码
function forgetPassword(domain) {
     return axios({
         url:"/api/user/users/forget4ChangePass",
         method:"post",
         data:domain
     })
}



export default {modifyPassword,getVerifyCodeBySMS,getVerifyCodeByEmail,forgetPassword,personalModifyPassword}