import axios from '@/utils/http';
import md5 from "js-md5";

function loginByPassword(data) {
    data.password = md5(data.password);
    return axios({
        url:"/api/user/users/login/validationByPassword",
        method:"post",
        data:data
    })
}
function isPhoneExist(phoneNum) {
    return axios({
        url:"/api/user/users/phoneNum/exist",
        method:"get",
        params:{
            phoneNum:phoneNum
        }
    })
}
function loginByVerifyCode(data) {
    return axios({
        url:"/api/user/users/login/validationByVerifyCode",
        method:"post",
        data:data
    })
}

function getCodeByPhoneNumber(phoneNumber) {
    return axios({
        url:"/api/user/sms/verifyCode",
        method:"get",
        params:{
            phoneNum:phoneNumber
        }
    })
}

function logout() {
    return axios({
        url:"/api/user/users/logout",
        method:"get"
    })
}
function isEmailExist(email) {
    return axios({
        url:"/api/user/users/email/exist",
        method:"get",
        params:{
            email:email
        }
    })
}

export default {loginByPassword,isPhoneExist,loginByVerifyCode,getCodeByPhoneNumber,logout,isEmailExist}