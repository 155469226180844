import axios from "../http";

function getNote(page,size,userId){
    return axios({
        url:"/api/user/query/notelist/"+page+"/"+size,
        method:"get",
        params:{
            userId:userId
        }
    })
}
function getMysqlPreference(page,size,userId) {
    return axios({
        url:"/api/user/query/preferenceMysql/"+page+"/"+size,
        method:"get",
        params:{
            userId:userId
        }
    })
}
function getPreferenceTitleUrl(relatedId,type){
    return axios({
        url:"/api/user/query/preferenceurl",
        method:"get",
        params:{
            relatedId:relatedId,
            type:type
        }
    })
}
function  getHistory(page,size) {
    return axios({
        url:"/api/user/query/history/"+page+"/"+size,
        method:"get"

    })

}
function getHistorytags(relatedId) {
    return axios({
        url:"/api/user/query/historytags",
        method:"get",
        params:{
            relatedId: relatedId
        }

    })
}
function deleteHistory() {
    return axios({
        url:"/api/user/query/deleteHistory",
        method:"put"
    })
}
////////////my question and answer
function getMyQuestionList(page,size,userId) {
    return axios({
        url:"/api/user/query/myQuestion/list",
        method:"get",
        params:{
            page:page,
            size:size,
            userId:userId
        }
    })
}

function getMyAnswerList(page,size,userId) {
    return axios({
        url:"/api/user/query/myAnswer/list",
        method:"get",
        params:{
            page:page,
            size:size,
            userId:userId
        }
    })
}
function initHomePage() {
    return axios({
        url:"/api/user/query/home/init",
        method:"get"
    })
}
function updateGuide(pageValue) {
    return axios({
        url:"/api/user/users/update/guide",
        method:"put",
        params:{
            pageValue:pageValue
        }
    })
}
export default{getNote,getMysqlPreference,deleteHistory
    ,getPreferenceTitleUrl,getHistory,getMyQuestionList,getMyAnswerList,initHomePage,updateGuide,
    getHistorytags}