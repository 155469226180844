<template>
    <div   style="background-color:#E5EAF0;min-width: 1536px;max-width: 2000px;min-height: 750px; flex-grow: 1;position: relative;margin: 0 auto;width: 100%">
        <el-card style="border-radius:24px;color: #797979;font-size: 32px;font-family: 微软雅黑;top: 2%;left:9.7% ;float: left;width:  80.7%;height: 6.6%;background-color: #FBFBFB;position: absolute;">
            <span style="margin-top:-8px;margin-left:-330px;position: absolute;color: #074E67;font-family: youShe;">性&nbsp;&nbsp; 能 &nbsp;&nbsp;监&nbsp;&nbsp; 测&nbsp;&nbsp; 专&nbsp;&nbsp; 题&nbsp;&nbsp; —— C&nbsp;&nbsp;P&nbsp;&nbsp;U</span>
        </el-card>
        <el-card class="bgdiv1" >
          <div class="rounddiv" ></div>
          <span class="fonthead1" >课程简介</span>
          <el-divider class="sdivider"></el-divider>
            <p id="pid" style="text-align: left;">
                CPU和其他硬件资源都是由系统内核（Kernel）管理的，系统内核运行于一个特殊的模式下——系统模式（System mode）。系统内核不但会统计CPU所有非空闲的时间，还会统计CPU停留在用户模式和系统模式中的市场。
                <br>
                本专题为性能监测，本教程为基于lifbpf开发的cpu性能监测，你将看到........
            </p>
        </el-card>
        <el-card class="bgdiv" style="margin-bottom: 10px;">
            <div class="rounddiv" ></div>
            <span class="fonthead1" >课程内容</span>
            <span style="float: left;margin-left: 30px;margin-top: -2px;font-size: 13px;">共 {{courseTotal}} 个教程，已完成&nbsp;<span style="color: #1A85AB"> {{finishedCourseNum}}</span> 个</span>
            <el-divider class="sdivider2"  ></el-divider>
            <el-progress  :text-inside="true" :stroke-width="26" :percentage="percentage" style="float: right;width: 350px;margin-top: -50px;"></el-progress>
            <span class="fonthead1" style="float:right;margin-top: -45px;margin-right: 370px;">完成进度: </span>
            <img src="~@/assets/img/course/course-cpu.svg"  style="width: 240px;height: 240px;position: absolute;margin-left: -42.3%;margin-top: 60px;"/>
            <div style="margin-left: 5.8%;margin-top: 60px;width: 240px;height: 240px;border: 3px solid #A1A1A1;position: absolute;z-index: 1;border-radius: 24px;"></div>
            <div  v-for="(item,index) in dataCourse" :key="index" style="margin-left: 37.3%;position: relative;margin-top: 53px">
                <el-col :span="8">
                    <div v-if="index%3===0">
                        <i :class="item.icon"  ></i>
                        <div :id="item.id" :class="item.divstyle" @click="goPage(item.id,item.description)" >
                            <span >{{item.title}}</span>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div v-if="index%3===1">
                        <i :class="item.icon" style="margin-left: -17.7%"></i>
                        <div :id="item.id"  :class="item.divstyle"  style="margin-left: 10.6%;" @click="goPage(item.id,item.description)">
                            <span >{{item.title}}</span>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div v-if="index%3===2">
                        <i :class="item.icon" style="margin-left: -14.3%;"></i>
                        <div  :id="item.id" :class="item.divstyle" style="margin-left: 21.2%;" @click="goPage(item.id,item.description)">
                            <span >{{item.title}}</span>
                        </div>
                    </div>
                </el-col>
            </div>
        </el-card>
    </div>
</template>

<script>
    import taskApi from "../../utils/task/taskApi";

    export default {
        name: "coursePreview",
        data(){

          return{
              data:{"id":"","content":""},
              isVisible:false,
              courseTotal:9,
              finishedCourseNum:0,
              percentage:70,
              isShow:false,
              dataCourse:[{"id":"","icon":"","title":""}],
          }
        },
        beforeMount() {

        },
        mounted(){
            //查询当前用户已经完成几个
            this.getListByActivityId()


        },
        methods: {
            getListByActivityId(){
                taskApi.getTaskList("325632b48dc24fc0b59413881173b3b6").then((res)=>{
                    if(res.data.success){
                        this.dataCourse=res.data.data.list
                        for(var i=0;i<this.dataCourse.length;i++){
                           var english= /[a-zA-Z-]+/.exec(this.dataCourse[i].title)
                            var tmp2=this.dataCourse[i].title.replace(/[a-zA-Z-]+/g,english[0]+"\n")
                            this.dataCourse[i].title=tmp2
                           if(res.data.data.list[i].icon=='el-icon-success finished') {
                               this.finishedCourseNum=this.finishedCourseNum+1
                           }
                        }
                        this.percentage=Math.round((this.finishedCourseNum/this.courseTotal)*100);
                    }
                })
            },
            goPage(id,description){
                const toolName = description.split(":")[0];
                var url = '/ebpf/home/libbpf/lab?toolName='+toolName+'&taskId='+id;
               this.$router.push(url)
            },
        }
    }
</script>

<style scoped>
    .ic{
        font-size: 20px;
        margin-left: -21%;
        margin-top:25px;
        position: absolute;
        color: #B9BDC5;  /*深灰色 配divspan divspan2*/

    }
    .finished{
        font-size: 20px;
        margin-left: -21%;
        margin-top:25px;
        position: absolute;
        color: #2696BB;   /*深蓝色 配divspan2*/

    }
    .divspan{
        width:158px;
        height: 60px;
        background-color:  #E5EAF0;  /*浅灰色*/
        border-radius: 14px;
        white-space: pre-wrap;
        margin-bottom: 36px;
        color:#A1A1A1;
        font-weight: bolder;
        line-height: 58px;
        cursor: pointer;
    }
    .divspan2{
          width:158px;
          height: 60px;
          background-color:  #2696BB;  /*深蓝色*/
          border-radius: 14px;
          white-space: pre-wrap;
          margin-bottom: 36px;
          color:#FBFBFB;
          font-weight: bolder;
          line-height: 58px;
          cursor: pointer;

      }
    .bgdiv >>>.el-progress-bar__outer {
        height: 20px !important;
    }
    .bgdiv >>>.el-progress-bar__inner {
        background-color: #2696BB;
    }
    .bgdiv1{
        width: 80.7%;
        height: 21.3%;
        left:9.7% ;
        top:11%;
        border-radius: 24px;
        background-color:#FBFBFB;
        border: #BBBBBB;
        position: absolute;
    }
    .bgdiv{
        width: 80.7%;
        height: 64%;
        left:9.7% ;
        top:35%;
        border-radius: 25px;
        background-color:#FBFBFB;
        border: #BBBBBB;
        position: absolute;
    }
    .popover {
        padding: 10px;
        border: 1px solid black;
        display: none;
    }
    .popover.show {
        display: block;
    }
    .sdivider{
        width:140px ;
        background-color: #ADBDC2;

    }
    .sdivider2{
        width:310px ;
        background-color: #ADBDC2;

    }
    .rounddiv{
        border-radius: 100%;
        width: 10px;
        height: 10px;
        background-color: #074E67;
        float: left;
    }
    .fonthead1{
        color: #074E67;
        font-size: 20px;
        float: left;
        line-height: 8px;
        font-family: youShe;
        margin-left: 15px;
}
</style>