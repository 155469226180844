<template>
    <div class="background">
        <el-row>
            <el-col>
                <h2 style="text-align: center;margin-top: 50px;"><i class="bi bi-person-square"></i>&nbsp;实名认证</h2>
                <el-form :model="realname" :rules="rules" ref="ruleForm" label-width="60px" style="margin-left:85px;">
                    <el-form-item  prop="name" style="width:450px;margin-top:50px;">
                        <el-input  placeholder="姓名" v-model="realname.name" class="a1"></el-input>
                    </el-form-item>
                    <el-form-item prop="card" class="pr" style="width: 450px">
                        <el-input placeholder="身份证号"  v-model="realname.card" class="a1"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')" style="background-color:#2696BB;width:388px;margin-top: 23px;">确定</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import userInfoApi from "../../../utils/login/userInfoApi";

    export default {
        name: "realname",
        data(){
            //身份证号校验
            let code = (rule, value, callback) => {
                let authReg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
                if (value === "") {
                    return callback(new Error("请输入身份证号码"));
                }else if (!authReg.test(value)) {
                    callback(new Error("请输入正确的身份证号码"));
                } else {
                    return callback();
                }
            };
            return{
                realname:{
                    name:'',
                    card:''
                },
                rules:{
                    name: [
                        { required:true, message:"请输入姓名", trigger: 'blur'}
                    ],
                    card: [
                        { required:true, validator: code, trigger: 'blur'}
                    ]
                }
            }
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        userInfoApi.idcheck(this.realname.name,this.realname.card).then((res)=>{
                            this.$message(res.data.message);
                            if (res.data.success){
                                this.$message.success("实名认证成功！请重新登录！")
                                this.$emit("closeRealName")
                            }
                        })
                    } else {
                        return false;
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .background{
        background-color:#FBFEFF;
        width:680px;
        height:380px;
        border-radius: 10px 10px 10px 10px;
        box-shadow:4px 4px 10px gray;
        margin:150px auto 0;
    }
    /*阴影*/
    .a1{
        box-shadow:0 2px 3px 0 #EAE9E9;
    }
</style>