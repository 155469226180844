<template>
    <el-container class="personal">
        <!--上方栏目-->
        <el-header class="my-layout" style="height: 50px">
            <HeaderMenu></HeaderMenu>
        </el-header>
        <el-container class="my-layout-main">
            <!--左侧栏目-->
            <el-aside width="280px">
                <AsideMenu :menu="asideMenu"></AsideMenu>
            </el-aside>
            <!--中间内容-->
            <el-main style="margin: 0;padding: 0">
                <router-view></router-view>
            </el-main>
        </el-container>
        <el-footer class="my-layout" height="32px" style="background-color:#074E67;color:white;position: relative;">
            <a class="my-layout-icp" style="color: #d6d6d6" @click="goPage('/personalCenter/aboutus')">关于我们</a>
            <a class="my-layout-icp" style="color: #d6d6d6" @click="goPage('/personalCenter/agreement')">用户隐私协议</a>
            <a class="my-layout-icp" style="color: #d6d6d6" @click="goPage('/personalCenter/complaint')">举报申诉规则</a>
            <a class="my-layout-icp" style="color: #d6d6d6" @click="goPage('/personalCenter/problem')">常见问题</a>
            <a class="my-layout-icp" style="color: #d6d6d6" @click="goPage('/personalCenter/reports')">意见反馈</a>
            <a class="weixin">
                <img class="qrcode" src="~@/assets/img/bolipi.jpg" alt="微信二维码">
                <img src="~@/assets/img/vxicon2.png" class="my-layout-icp"   style="margin-top: 8px;width: 20px;height: 18px;">
            </a>

            <a class="my-layout-icp" style="color: #d6d6d6" href="https://beian.miit.gov.cn" target="_blank">苏ICP备2022009107号-1</a>
        </el-footer>
    </el-container>
</template>

<script>
    import AsideMenu from "../../components/common/AsideMenu";
    import HeaderMenu from "../../components/common/HeaderMenu";
    export default {
        name: "PersonalCenter",
        components:{
            AsideMenu,HeaderMenu
        },
        data(){
            return {
                asideMenu:[
                    {
                        title:"资料",
                        href:"/personalCenter/personalData",
                        icon:"el-icon-s-custom",
                        children:[
                        ]
                    },
                    {
                        title:"记事本",
                        icon: "el-icon-notebook-2",
                        href:"/personalCenter/notepad",
                        children:[
                        ]
                    },
                    {
                        title:"历史浏览",
                        icon: "el-icon-view",
                        href:"/personalCenter/history",
                        children:[
                        ]
                    },
                   /* {
                        title:"积分兑换",
                        icon: "el-icon-trophy-1",
                        href:"/personalCenter/achievement",
                        children:[
                        ]
                    },*/
                    {
                        title:"账号设置",
                        icon: "el-icon-s-tools",
                        href:"/personalCenter/setup",
                        children:[
                        ]
                    },
                    {
                        title:"帮助中心",
                        icon: "el-icon-s-opportunity",
                        href:"/personalCenter/helpCenter",
                        children:[
                        ]
                    },
                    {
                        title:"意见反馈",
                        icon: "el-icon-chat-line-round",
                        href:"/personalCenter/reports",
                        children:[]
                    }
                ]
            }
        },
        methods:{
            goPage(route){
                this.$router.push(route)
            }
        }
    }
</script>

<style scoped>

    .personal {
        min-height: 100vh;
        display: flex;
        text-align: left;
    }
    .weixin img.qrcode {
        position: absolute;
        z-index: 99;
        bottom: 30px;
        left:470px;
        width: 7.5rem;
        max-width: none;
        height: 7.5rem;
        transform: scale(0);
        transform-origin: bottom center;
        opacity: 0;
        border-radius: .25rem;
        -webkit-transition: all .4s ease-in-out;
        -o-transition: all .4s ease-in-out;
        transition: all .4s ease-in-out;
    }
    .weixin:hover img.qrcode {
        transform: scale(1);
        opacity: 1;
    }

</style>