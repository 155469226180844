import { render, staticRenderFns } from "./MyAnswerListComponent.vue?vue&type=template&id=05e84f38&scoped=true&"
import script from "./MyAnswerListComponent.vue?vue&type=script&lang=js&"
export * from "./MyAnswerListComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05e84f38",
  null
  
)

export default component.exports