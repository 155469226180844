<template>
    <el-container >
        <!--左侧导航-->
        <el-aside width="280px" :style="{'height': mainHeight+'px','overflow-y':'scroll'}">
            <BlogMenu :menu="menu.asideMenuData"  :show-back="true" :back-url="backUrl"
                       :index-valid="false" :default-href="menu.activeMenu"></BlogMenu>
        </el-aside>
        <!--中间文章内容-->
        <el-container>
            <el-main class="center">
                <div :style="{'height': mainHeight+'px','overflow-y':'scroll'}"
                     id="myPage" ref="page" class="center-content" v-on:click.left="leftClick($event)"
                     @contextmenu.prevent="oncontextmenu" v-html="result" @scroll="scrollHandler"></div>
            </el-main>
        </el-container>
        <el-aside width="350px" >
            <!--问题部分-->
            <div style="margin-top: 10px" :style="{'height': mainHeight+'px','overflow-y':'scroll'}">
                <el-tooltip class="item" effect="dark" content="鼠标左键选中一段文字，点击右键或此按钮即可提出问题" placement="bottom">
                    <el-button class="my-button" @click="questionMessageBox(false)">
                        问一问
                    </el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="选中专业名称点击此按钮即可进行百度搜索" placement="bottom">
                    <el-button class="my-button" @click="baidu(false)">
                        查一查
                    </el-button>
                </el-tooltip>
               <!-- <el-tooltip class="item" effect="dark" content="显示该博客相关评论" placement="bottom">
                    <el-button class="my-button" @click="showComments" >
                        评论区
                    </el-button>
                </el-tooltip>-->

                <div v-if="commentShowFlag===true" style="margin-top: 5px;">
                    <el-card  class="comment-edit">
                       <el-tabs  active-name="edit" style="margin-top: -15px;">
                         <el-tab-pane label="编辑" name="edit" >
                           <el-input type="textarea" id="commentInput" :autosize="{minRows:2,maxRows:6}"
                                  placeholder="这里，你可以讨论..." v-model="value" style="margin-top:-8px;">
                           </el-input>
                           <el-button size="mini"  @click="saveComment"  style="float: right;background-color:#2696BB; color: white;">评论</el-button>
                         </el-tab-pane>
                       </el-tabs>
                    </el-card>
                    <el-divider></el-divider>
                    <div v-for="(item,index) in array" :key="index + keyCount">
                        <TaskCommentItemComponent :data="item" :blogFlag="true" @refreshList="getCommentsList(curPage)" ></TaskCommentItemComponent>
                    </div>
                    <el-pagination style="margin-top: 20px;text-align: right" :current-page="curPage" :page-size="size"
                                   background layout="prev, pager, next" :total="total"
                                   @current-change="handleCurrentChange"></el-pagination>
                </div>
                <div style="padding: 10px" v-for="(item,index) in rightSide.asideContent" v-bind:key="index">
                    <aside-questions :item="item" :mark="rightSide.asideContentMark[index]"
                                     :userId="userId" @updateBlogContent="updateBlogContent"
                                     @updateAsideContent="updateAsideContent"></aside-questions>
                </div>
            </div>

        </el-aside>
    </el-container>
</template>

<script>

    import "mavon-editor/dist/css/index.css"
    import md5 from "js-md5"
    import AsideQuestions from "../../../components/blog/AsideQuestions";
    import markdownItAnchor from 'markdown-it-anchor';
    import BlogMenu from "@/components/blog/BlogMenu";
    import postilUtils from "@/utils/study/postilUtils";
    import common from "@/utils/common";
    import video from "@/utils/study/video"
    import "@/assets/css/main.css"
    import comment from "../../../utils/task/comment";

    import TaskCommentItemComponent from "../../../components/task/comment/TaskCommentItemComponent";
    export default {
        name: "postil_temp",
        components: {AsideQuestions,BlogMenu,TaskCommentItemComponent},
        data() {
            return {
                uuid:'',title:'',
                //博客md格式文本
                content:"",
                value:"",
                //博客html格式文本
                result:"",
                //固定博客id
                blogId:"",
                //判断滚动完成。如果滚动完成为true;如果未完成或未滚动,为false
                scrollFinished: false,
                //监听的可视窗口高度，在mounted中会改变
                mainHeight:0,
                //当前临时用户的id
                userId:"",
                backUrl:"",
                tag:"",
                menu:{
                    asideMenuData:[],
                    activeMenu:'',
                    anchors:[]
                },
                rightSide:{
                    //鼠标框选的段落内容
                    selectContent:"",
                    //问题栏内容列表，会根据可视窗口内容变动而变动
                    asideContent:[],
                    //当前可视窗口的位置标记列表
                    positionList:[],
                    //问题栏中的问题列表所对应的位置序号，与asideContent一一对应
                    asideContentMark:[],
                },
                commentShowFlag:false,
                array:[],
                //用于更新评论列表
                keyCount: 0,
                size:5,//comment list page size
                curPage:1,//current page
                total:0
            }
        },
        beforeMount() {
            this.title = this.$route.query.title;
            this.blogId = this.$route.params.id;
            this.tag=this.$route.query.tag;
        },
        mounted(){
            this.backUrl="/ebpf/home/study/doc?tag="+this.tag;
            this.uuid = common.uuid();
            //获取博客内容
            postilUtils.getBlog(this.blogId,this.uuid,this.title).then((res)=>{
                let ret = res.data;
                if (ret.success){
                    this.updateBlogContent(ret.data.content)
                }else {
                    this.$message(ret.message)
                }
            })
            //设置main部分的高度
            this.mainHeight = window.innerHeight -70;
            this.scrollFinished = true;
            this.userId = this.$cookies.get("userId")
            if (this.blogId){
                this.relatedId = this.blogId;
                this.getCommentsList(1,this.blogId)
            }
        },
        destroyed(){
            postilUtils.closeBlog(this.blogId,this.uuid,this.title).then((res)=>{
                if (!res.data.success){
                    console.log(res.data.message)
                }
            })
        },
        watch:{
            //通过监听result更新问题序号
            result:function () {
                this.$nextTick(()=>{
                    //清除positionList
                    this.rightSide.positionList = [];
                    //获取所有图片元素
                    var count = 1;
                    var images = document.querySelectorAll("#myPage img");
                    //遍历图片元素，如果图片是问题标记，将图片alt属性值入栈
                    for (let i = 0; i < images.length; i++) {
                        var url = images[i].getAttribute("src");
                        if (url === "/uploads/img/resource/help.png"){
                            images[i].before("["+count+"]");
                            images[i].setAttribute("count",count.toString());
                            this.rightSide.positionList.push({element:images[i],index:count});
                            count++;
                        }
                    }
                    this.menu.anchors = document.querySelectorAll("#myPage h1,h2,h3,h4,h5");
                    this.updateAsideContent()
                })
            },
            scrollFinished:function () {
                //如果滚动完成获取当前可视窗口的问题id
                // 并根据问题id向服务器请求问题内容，回显
                if (this.scrollFinished){
                    this.updateAsideContent();
                    this.scrollFinished = false;
                }
            }
        },
        methods:{
            saveComment(){
                if (common.isBlank(this.blogId)){
                    this.$message.error("无法获取任务id");
                    return;
                }
                var result = {
                    content:this.value,
                    relatedId:this.blogId,
                };
                comment.saveComment(result).then((res)=>{
                    if (!res.data.success){
                        this.$message.error(res.data.message);
                    }else {
                        this.getCommentsList(1)
                        this.value = ""
                    }
                })
            },
            handleCurrentChange(val){
                this.curPage = val;
                this.getCommentsList(val);
            },
            getCommentsList(page){
                this.curPage = page;
                comment.getList(page,this.size,this.blogId).then((res)=>{
                    if (res.data.success){
                        this.array = res.data.data.list;
                        this.total = res.data.data.total;
                        this.keyCount += this.size;
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            },
            showComments(){
                this.commentShowFlag=!this.commentShowFlag

            },
            //右键菜单列表
            oncontextmenu(event){
                //获取选中内容节点的全部文本
                let selection = window.getSelection();
                let keyword = selection.toString();
                let node = selection.focusNode;
                this.rightSide.selectContent = node.textContent.trim();
                this.$contextmenu({
                    items:[
                        {label:"提问",onClick:() => {this.questionMessageBox(true)}},
                        {label: "百度搜索",onClick:()=>{this.baidu(keyword)}}
                    ],
                    event, // 鼠标事件信息
                    customClass: "custom-class", // 自定义菜单 class
                    zIndex: 3, // 菜单样式 z-index
                    minWidth: 230 // 主菜单最小宽度
                })
            },
            baidu(keyword){

                if (keyword === false){
                    keyword = window.getSelection().toString();
                }
                var url = 'https://www.baidu.com/s?wd=' + keyword;
                window.open(url)
            },
            //提问问题
            questionMessageBox(isRightClick) {
                //--------------------save-md------------------------
                //获取选中内容节点的全部文本
                let node = window.getSelection().focusNode;
                var string = isRightClick?this.rightSide.selectContent:node.textContent.trim();
                //判断md文本中是否有选中的文本
                if (string.length==0||this.content.indexOf(string) < 0){
                    this.$message("源文本格式错误，请选择其他文本");
                    return;
                }
                this.$prompt('请输入问题', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPattern: /[\u4e00-\u9fa5]{3,90}/,
                    inputErrorMessage: '问题不得为空'
                }).then(({ value }) => {
                    if(this.$cookies.isKey("avatar")==false) {
                        this.$message("请先登录！")
                    } else{
                        this.$message({
                            type: 'success',
                            message: '你的问题是: ' + value
                        });
                        this.saveQuestion(string, value);
                    }
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消输入'
                    });
                });
            },
            //保存问题
            saveQuestion(string,question){
                //md5加密获得问题位置id
                var positionId = md5(string);
                var test = this.content;
                const positionReg = new RegExp(positionId,'g');
                //判断md文本中是否有选中的文本
                if (test.indexOf(string) < 0){
                    this.$message("选中的文本格式错误")
                    return;
                }
                //如果文中有该位置，无需操作，
                if (test.match(positionReg)){
                    test = ''
                }else{
                    // 如果文中没有该位置，需要对md文本进行文字替换，增加位置标识点
                    test = test.replace(string,string + "!["+positionId+"](/uploads/img/resource/help.png)");
                }
                //保存问题到服务器
                this.saveQuestionToServer(positionId,question,test);
            },
            //鼠标左键，帮助图片点击事件
            leftClick(e){
                if (e.target.tagName.toUpperCase() === 'IMG' &&
                    e.target.getAttribute("src") === "/uploads/img/resource/help.png"){
                    var alt = [e.target.getAttribute("alt")];
                    var index = [parseInt(e.target.getAttribute("count"))];
                    this.getAsideContent(alt,index)
                }
            },
            //滚动监听
            scrollHandler(){
                //监听滚动完成，如果滚动完成，设置scrollFinished为true
                var top = this.$refs.page.scrollTop;
                var vm = this;
                setTimeout(function () {
                    vm.scrollFinished = vm.$refs.page.scrollTop === top;
                    vm.menu.activeMenu = vm.getFirstAnchor();
                },100);
            },
            updateAsideContent(){
                var positionIndex = [];
                //获取在可视窗口的位置id，并将对应的序号一一对应记录到index数组中
                var positionIds = this.getVisible(positionIndex);
                if (positionIds.length>0){
                    //获取可视窗口内有位置标记的对应的所有问题
                    this.getAsideContent(positionIds,positionIndex)
                }else{
                    this.rightSide.asideContent = [];
                }
            },
            //获取当前可视窗口的问题id
            getVisible(positionIndex){
                //获取所有图片元素
                var positionIds = [];
                //遍历图片元素，如果图片是问题标记，将图片alt属性值入栈
                for (let i = 0; i < this.rightSide.positionList.length; i++) {
                    if (this.isVisible(this.rightSide.positionList[i].element)){
                        positionIds.push(this.rightSide.positionList[i].element.getAttribute("alt"));
                        positionIndex.push(this.rightSide.positionList[i].index);
                    }
                }
                //返回问题id数组
                return positionIds;
            },
            getFirstAnchor(){
                //遍历标题元素，如果图片是问题标记，将图片alt属性值入栈
                for (let i = 0; i < this.menu.anchors.length; i++) {
                    if (this.isVisible(this.menu.anchors[i])){
                        if (this.menu.anchors[i].childNodes[0].id !== undefined &&
                            this.menu.anchors[i].childNodes[0].id.length > 0){
                            return '#'+this.menu.anchors[i].childNodes[0].id
                        }else {
                            return this.menu.activeMenu;
                        }
                    }
                }
            },
            //判断元素是否在可视窗口内
            isVisible(element){
                //当前元素距离整体顶部的距离
                var objHeight = element.offsetTop;
                //可视窗口顶部距离整体顶部的距离
                var scrollTop = this.$refs.page.scrollTop;
                var val = objHeight - scrollTop;
                return val > 28 && val < this.mainHeight+28;
            },
            //保存临时用户提交的问题
            saveQuestionToServer(positionId,questionContent,blogContent){
                // let save = this.$cookies.get("isLogin") === '1'?
                //     postilUtils.saveQuestionToServer(this.blogId,positionId,questionContent,blogContent):
                //     postilUtils.saveTempQuestionToServer(this.blogId,positionId,questionContent,blogContent);
                //问题提交到服务器
                postilUtils.saveQuestionToServer(this.blogId,positionId,questionContent,blogContent).then((res)=>{
                    let ret = res.data;
                    //如果该位置没有标记，需要进行文本重新渲染
                    if (ret.success && !ret.data.positionExist){
                        this.updateBlogContent(blogContent)
                    }else if (ret.success && ret.data.positionExist){
                        this.updateAsideContent()
                    }
                    // 进行提示即可
                    this.$message(ret.message);
                })
            },
            //获取问题内容
            getAsideContent(positionIds,positionIndex){
                postilUtils.getAsideContent(this.blogId,positionIds,positionIndex).then((res)=>{
                    let ret = res.data;
                    if (ret.success){
                        this.rightSide.asideContent = ret.data.questions;
                        this.rightSide.asideContentMark = ret.data.questionMark;
                    }else {
                        this.rightSide.asideContent = [];
                        this.$message(ret.message)
                    }
                })
            },
            //更新博客内容
            updateBlogContent(content){
                this.content = content;
                //清楚上次缓存
                postilUtils.asideMenuBuildFinished = false;
                postilUtils.asideMenuData = [];
                //重新获取目录
                this.result = common.getMarkdownIt().use(markdownItAnchor,{
                    callback:function (token,info) {
                        if (postilUtils.asideMenuBuildFinished){
                            return;
                        }
                        var restr = info.title.replace(/\s/g,"_");
                        restr = restr.replace(/[^_0-9a-zA-Z]/g,'');
                        var node = {
                            title:info.title,
                            index:token.map[0],
                            href:"#"+restr+"_"+token.map[0],
                            tag:token.tag,
                            children:[]
                        }
                        postilUtils.addMenuNode(postilUtils.asideMenuData,node)
                    }
                }).render(content);
                this.menu.asideMenuData = postilUtils.asideMenuData;
                postilUtils.asideMenuBuildFinished = true;
            },
            goPage(url){
                video.goPage(url,this.$router)
            }
        }
    }
</script>

<style>
    /*  更改webkit内核浏览器样式，还原h1标题的大小   */
    :-webkit-any(article,aside,nav,section)
    :-webkit-any(article,aside,nav,section)
    :-webkit-any(article,aside,nav,section) h1 {
        font-size: 28px;
    }
    :-webkit-any(article,aside,nav,section)
    :-webkit-any(article,aside,nav,section)
    :-webkit-any(article,aside,nav,section) h2 {
        font-size: 24px;
    }

    :-webkit-any(article,aside,nav,section)
    :-webkit-any(article,aside,nav,section)
    :-webkit-any(article,aside,nav,section) h3 {
        font-size: 20px;
    }

    h1 h2 h3 h4 {
        color:  rgba(16, 54, 64, 0.86);
    }

</style>

<style scoped>
    .comment-edit >>> .el-textarea__inner{
        border: none !important;
        outline: none !important;
    }
    .comment-edit >>> p{
        margin: 5px 0;
        padding: 0 20px;
    }
    .relative >>> .el-card__body{
        padding: 10px !important;
    }
    .video-box{
        margin-top: 10px;
    }
    #myPage >>> img {
        max-width: 100%;
        height: auto;
    }
    .center {
        border-right: 1px solid rgba(0,0,0,.07);
        padding-top: 0;
        padding-right: 0;
    }
    .center-content {
        text-align: left;
        padding-right: 10px;
        font-size: 14px;
    }
    /* 设置滚动条的样式 */
    ::-webkit-scrollbar {
        width: 8px;
    }
    /* 滚动槽 */
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
        border-radius: 10px;
    }
    /* 滚动条滑块 */
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
    }
</style>