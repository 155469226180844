import axios from "../http";

function getList(page,size,relatedId){
    return axios({
        url:"/api/user/comment/list/"+page+"/"+size,
        method:"get",
        params:{
            relatedId:relatedId
        }
    })
}
function saveComment(form) {
    return axios({
        url:"/api/user/comment/save/comment",
        method:"post",
        data:form
    })
}
function saveReply(form,cmId) {
    return axios({
        url:`/api/user/comment/save/reply/${cmId}`,
        method:"post",
        data:form
    })
}
function deleteComment(deleteId) {
    return axios({
        url:`/api/user/comment/delete/${deleteId}`,
        method:"delete"
    })
}
function getIllegalType() {
    return axios({
        url:"/api/user/constance/illegal",
        method:"get"
    })
}
function saveIllegalReport(form) {
    return axios({
        url:"/api/user/comment/report/illegal",
        method:"post",
        data:form
    })
}
function getReplyById(id) {
    return axios({
        url:"/api/user/comment/get/reply",
        method:"get",
        params: {
            id:id
        }
    })
}
export default {getList,saveComment,saveReply,deleteComment,getIllegalType,saveIllegalReport,getReplyById}