<template>
    <div class="background">
        <el-row>
            <h2 style="text-align: center;"><i class="bi bi-patch-minus-fill"></i>&nbsp;注销</h2>
            <br>
            <el-col>
                <el-form :model="cancellationRule" :rules="rules" ref="ruleForm" style="margin-left:40px;margin-right: 40px;">
                    <el-form-item style="margin-top: -30px;">
                        <h3 style="color: black;font-size: 18px">注销账号则视为您主动放弃以下权益，且同意以下规则：</h3>
                        <p style="color: black;line-height: 25px;font-size: 18px;">
                            1. 账号注销后，您将无法登录、使用波粒派账号，移除该账号下的所有登录方式，且不支持找回任何个人资料。
                            <br>2. 您的账号信息、积分、已发布的内容、收到的评论、收藏浏览记录等所有相关内容将被清空，且无法恢复。
                            <br>3. 本次帐户注销不影响您再次通过手机号/邮箱方式重新注册；
                            <br>4.  注销波粒派账号并不代表您注销前的账号行为和相关责任得到豁免或减轻，注销时，该账号应处于安全状态，无被盗号风险，无任何纠纷，包括投诉举报或被投诉举报。
                            <br>5. 个人信息将会在波粒派的系统中被清除，使其保持不可被检索、不可访问的状态，对其进行匿名处理。
                        </p>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')" style="background-color:#2696BB;width:388px;margin-top:10px;margin-left: 110px;font-size: 18px">已知悉，永久注销</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import register from "../../../utils/login/register";

    export default {
        name: "cancellationRule",
        data() {
            return {
                cancellationRule:{
                    input:'',
                    textarea: '',
                },
            }
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        register.delUser().then((res)=>{
                            if (res.data.success){
                                this.$emit("next",0)
                            }else{
                                this.$message.error(res.data.message)
                            }
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .background{
        background-color:#FBFEFF;
        width:680px;
        height:523px;
        border-radius: 10px 10px 10px 10px;
        box-shadow:4px 4px 10px gray;
        margin:150px auto 0;
    }
</style>