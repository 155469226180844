<template>
    <el-container>
        <el-main class="center">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/personalCenter/helpCenter' }">帮助中心</el-breadcrumb-item>
                <el-breadcrumb-item>注销须知</el-breadcrumb-item>
            </el-breadcrumb>
            <h1 style="text-align: left">注销须知</h1>
            <p style="text-align: left;font-size: 18px">
                在您申请注销账号前，为了避免被盗号或非本人操作所带来的损失，请您仔细阅读、充分理解《波粒派账号注销须知》（以下简称“注销须知”）的所有内容。
                <b>当您按照注销提示填写并提交账号注销申请时，即表示您已充分阅读、理解本注销须知的全部内容。</b>如您不同意注销须知的相关条款和约定，请您及时停止注销程序。
                <br><br>波粒派帐号一旦被注销将不可恢复，若波粒派账号下的信息对您来说十分重要，您应在点击申请注销之前将所有数据和信息（包括但不限于个人资料、任务报告、积分兑换、评论收藏等）自行备份。
                <b>注销波粒派帐号，您将无法再使用本波粒派帐号，也将无法找回您的帐号中及与帐号相关的任何内容或信息（即使您使用相同的手机号码再次注册并使用波粒派），包括但不限于：</b>
                <br><br>1. 账号注销后，您将无法登录、使用波粒派账号，移除该账号下的所有登录方式，且不支持找回任何个人资料。
                <br>2. 您的账号信息、积分、已发布的内容、收到的评论、收藏浏览记录等所有相关内容将被清空，且无法恢复。
                <br>3. 注销波粒派账号并不代表您注销前的账号行为和相关责任得到豁免或减轻，注销时，该账号应处于安全状态，无被盗号风险，无任何纠纷，包括投诉举报或被投诉举报。
                <br>4. 个人信息将会在波粒派的系统中被清除，使其保持不可被检索、不可访问的状态，对其进行匿名处理。
                <br><br>此外，您知晓且同意，波粒派会在相关法律法规的规定数据保存期限内，就注销帐号的信息、记录在波粒派后台被保存。本次帐户注销不影响您再次通过手机号/邮箱方式重新注册。确认注销之后，将自动发送邮件，一般情况下我们将在15日内作出处理。
                <br><br>如对本《注销须知》或相关事宜有任何问题，请通过kuangqixinxi@126.com与我们联系。
            </p>
        </el-main>
    </el-container>
</template>

<script>
    export default {
        name: "cancellationNotice"
    }
</script>

<style scoped>

</style>