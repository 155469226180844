<template>
    <div class="background">
        <el-row>
            <el-col :span="12">
                <div class="left">
                    <img src="@/assets/img/ebpf2.png" style="width:408px;height:220px;margin-left: 25px;margin-top:130px;">
                    <a href="https://falco.org/" style="color: #539929;text-decoration:none;margin-top:122px;display: block" target="_blank">
                        <p style="font-size: 14px;color: #539929;margin-top:35px;margin-left: 65px;">Cloud-Native runtime security，https://falco.org/</p>
                    </a>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="right">
                    <router-link to="/register" style="color: black;left:92%;text-decoration: none;font-size:18px;padding-top: 45px;position: absolute">注册</router-link>
                    <el-tabs value="first" style="margin-left: 30px;padding-top:85px;">
                        <el-tab-pane label="密码登录" name="first">
                            <el-form :model="loginByPassword" :rules="rulesForPassword" ref="ruleForm2" label-width="60px" class="form">
                                <el-form-item  prop="account"  style="margin-left:-35px;width: 400px;margin-top: 20px;">
                                    <el-input placeholder="请输入邮箱或手机号" v-model="loginByPassword.account" class="a1"></el-input>
                                </el-form-item>
                                <el-form-item  prop="password"  style="margin-left:-35px;width: 400px;">
                                    <el-input  placeholder="请输入密码" type="password" v-model="loginByPassword.password" show-password class="a1"></el-input>
                                </el-form-item>
                                <el-form-item style="margin-left:-35px;width: 400px;margin-top: -15px">
                                    <el-checkbox v-model="loginByPassword.rememberMe" style="">自动登录</el-checkbox>
                                    <router-link to="/password" style="color: #606266;float: right;text-decoration: none;font-size:14px;">忘记密码</router-link>
                                </el-form-item>
                                <el-form-item style="margin-top: -20px">
                                    <el-col :span="2" :offset="8">
                                        <el-button  @click="submitByPassword('ruleForm2')"  class="btn-sign2">登录</el-button>
                                    </el-col>
                                </el-form-item>
                            </el-form>
                        </el-tab-pane>

                        <el-tab-pane label="短信登录" name="second">
                            <el-form :model="loginByVerifyCode" :rules="rulesForVerifyCode" ref="ruleForm" label-width="60px" class="form">
                                <el-form-item  prop="phoneNumber" style="margin-left:-35px;width: 400px;margin-top:20px;">
                                    <el-input  placeholder="请输入手机号" v-model="loginByVerifyCode.phoneNumber"
                                               class="a1"></el-input>
                                </el-form-item>
                                <el-form-item prop="status" style="margin-left:-35px;width: 400px;">
                                    <slider @success="setStatus" v-model="loginByVerifyCode.status"></slider>
                                </el-form-item>
                                <el-form-item  prop="verifyCode" class="pr" style="margin-left:-35px;width: 400px;">
                                    <el-input placeholder="请输入短信验证码"  v-model="loginByVerifyCode.verifyCode" class="a1"></el-input>
                                    <button @click.prevent="getCode()" class="code-btn" :disabled="!show" >
                                        <span v-show="show">获取验证码</span>
                                        <span v-show="!show" class="count">{{count}}秒</span>
                                    </button>
                                </el-form-item>
                                <el-form-item style="margin-top:-15px;margin-left:-35px;">
                                    <el-checkbox v-model="loginByVerifyCode.rememberMe">自动登录</el-checkbox>
                                </el-form-item>
                                <el-form-item style="margin-top: -20px">
                                    <el-col :span="2" :offset="8">
                                        <el-button @click="submitByVerifyCode('ruleForm')"  class="btn-sign1">登录</el-button>
                                    </el-col>
                                </el-form-item>
                            </el-form>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import Slider from "./verifySliderComponent";
    import loginApi from "@/utils/login/loginApi";
    export default {
        name: "loginComponent",
        components: {Slider},
        data(){
            //手机号校验
            let telCheck = (rule, value, callback) => {
                var reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
                if (value === "") {
                    return callback(new Error("请输入手机号码"));
                } else if (!reg.test(value)) {
                    return callback(new Error("请输入有效的手机号码"));
                } else {
                    callback();
                }
            };
            //验证码校验，6位数字验证码
            let code = (rule, value, callback) => {
                let authReg = /^\d{6}$/;
                if (value === "") {
                    return callback(new Error("请输入验证码"));
                }else if (!authReg.test(value)) {
                    callback(new Error("请输入正确的6位验证码"));
                } else {
                    return callback();
                }
            };
            //滑块验证
            let slider = (rule, value, callback) => {
                return value === false?callback(new Error("请验证滑块")):callback();
            };
            return {
                loginByVerifyCode:{
                    phoneNumber:'',
                    verifyCode:'',
                    rememberMe:false,
                    status:false,
                    checked1:false
                },
                loginByPassword:{
                    account:'',
                    password:'',
                    rememberMe:false,
                    checked2:false
                },
                //获取短信验证码
                show: true,
                count: 60*2,
                rulesForVerifyCode: {
                    phoneNumber: [{ required:true, validator: telCheck, trigger: 'blur'}],
                    status: [{required: true, validator: slider, trigger: 'blur'}],
                    verifyCode: [{ required:true, validator: code, trigger: 'blur'}]
                },
                rulesForPassword: {
                    account: [{required: true, message: '账号不得为空', trigger: 'blur'}],
                    password: [{ required:true, message: '密码不得为空', trigger: 'blur'}]
                },
            }
        },
        methods: {
            submitByVerifyCode(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        loginApi.loginByVerifyCode(this.loginByVerifyCode).then((res)=>{
                            let ret = res.data;
                            if (ret.success){
                                this.clearDialog();
                                this.$emit('setUserInfo');
                            }else{
                                this.$message(ret.message)
                            }
                        })
                    } else {
                        return false;
                    }
                });
            },
            submitByPassword(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        loginApi.loginByPassword(this.loginByPassword).then((res)=>{
                            if (res.data.success){
                                this.clearDialog();
                                this.$emit('setUserInfo');
                            }else{
                                this.$message(res.data.message)
                            }
                        })
                    } else {
                        return false;
                    }
                });
            },
            getCode() {
                if(this.loginByVerifyCode.phoneNumber === ""){
                    this.$message.error("手机号不得为空");
                    return;
                }
                if(this.loginByVerifyCode.phoneNumber.length!==11){
                    this.$message.error("请输入11位手机号");
                    return;
                }
                let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
                if(!reg.test(this.loginByVerifyCode.phoneNumber)){
                    this.$message.error("请输入有效手机号");
                    return;
                }
                if (this.loginByVerifyCode.status === false) {
                    this.$message.error("请验证滑块");
                    return;
                }
                loginApi.isPhoneExist(this.loginByVerifyCode.phoneNumber).then((res)=>{
                    if (!res.data.success){
                        this.$message.error("手机号不存在，请先注册")
                    }else{
                        this.sendVerifyCode();
                    }
                })
            },
            //获取短信结束
            setStatus(){
                this.loginByVerifyCode.status = true;
            },
            clearDialog(){
                this.loginByVerifyCode = {
                    phoneNumber:'',
                    verifyCode:'',
                    rememberMe:false,
                    status:false,
                    checked1:false
                };
                this.loginByPassword = {
                    account:'',
                    password:'',
                    rememberMe:false,
                    checked2:false
                };
            },
            sendVerifyCode(){
                //axios请求
                loginApi.getCodeByPhoneNumber(this.loginByVerifyCode.phoneNumber).then((res)=>{
                    if (!res.data.success){
                        this.$message(res.data.message);
                    }else{
                        this.setTimer(res.data.data.second);
                    }
                })
            },
            setTimer(count){
                // 验证码倒计时
                if (!this.timer) {
                    this.count = count;
                    this.show = false;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= count) {
                            this.count--;
                        } else {
                            this.show = true;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000);
                }
            }
        }
    }
</script>

<style scoped>
    .background{
        background-color: rgba(214, 214, 214, 0);
        border-radius: 40px 40px 40px 40px;
        margin:0px auto 0;
        width:960px;
        height: 540px;
    }
    .left{
        width:480px;
        height:540px;
        background-color:#aed0a6;
        border-radius: 40px 0  0 40px;
        box-shadow:4px 4px 10px gray;
    }
    .right{
        width:480px;
        height:540px;
        background-color:#FEFFfB;
        border-radius: 0 40px 40px 0;
        box-shadow:4px 4px 10px gray;
    }
    /*登录按钮*/
    .btn-sign1{
        width:338px;
        margin-left:-163px;
        margin-top:25px;
        background-color: #e5f0d0;
        color:#3e4a4a;
        box-shadow:0px 2px 6px 0px #EAE9E9;
    }
    .btn-sign2{
        width:338px;
        margin-left:-163px;
        margin-top:28px;
        background-color: #e5f0d0;
        color:#3e4a4a;
        box-shadow:0px 2px 6px 0px #EAE9E9;
    }
    .btn-sign1:hover,.btn-sign2:hover{
        border: 1px solid #7a9743;
    }
    /*阴影*/
    .a1{
        box-shadow:0px 2px 6px 0px #EAE9E9;
    }
    /* 短信验证码css */
    .pr {
        position: relative;
    }
    /*获取验证码按钮css*/
    .code-btn {
        width: 100px;
        height: 20px;
        position: absolute;
        top: 10px;
        right: 5px;
        z-index: 222;
        color: #539929;
        font-size: 14px;
        border: none;
        border-left: 1px solid #bababa;
        padding-left: 10px;
        background-color: #fff;
        cursor: pointer;
    }
    /*登录标签栏样式*/
    /* 滑块横条 */
    ::v-deep .el-tabs__nav-wrap::after {
        margin-left: 15px;
        width:350px;
        /*position: static !important;*/
    }
    /* 设置滑块颜色 */
    ::v-deep .el-tabs__active-bar{
        background-color:#3E4A4A !important;
    }
    /* 设置标签当前选中样式 */
    ::v-deep .el-tabs__item.is-active{
        color:black !important;
        z-index: 2;
    }
    /* 设置标签未被选中样式 */
    ::v-deep .el-tabs__item{
        font-size:18px;
        padding: 0 20px !important;
        width: 120px;
        box-sizing: border-box;
        display: inline-block;
        position: relative !important;
        color:#000000 !important;
        z-index: 2;
    }
</style>