<template>
    <div class="official">
        <!--<el-checkbox-group v-model="checkboxGroup1" style="margin-top:20px;margin-bottom: 20px; "  id="modeCheckBox">
            <el-checkbox  v-for="(item) in blogTagList" :label="item" :key="item"     >{{item}}</el-checkbox>
        </el-checkbox-group>-->
        <div v-for="(doc,index) in docList" v-bind:key="index+keyCount" >
            <el-card shadow="hover" @click.native="docClick(doc.id,doc.title)" style="height:120px; ">
                <el-link :underline="false" class="my-body-title" >{{doc.title}}</el-link>
                <p class="my-body-summary" style="text-align: left">
                    <span > {{doc.summary|ellipsis}} </span>
                </p>
                <el-tag v-for="tag in doc.tagList" disable-transitions v-bind:key="tag.id" size="small" class="my-tag">
                    {{tag.tagName}}
                </el-tag>
            </el-card>
        </div>
        <el-pagination v-show="showPage" style="margin-top: 20px;text-align: right" :page-size="pageSize"
                       background layout="prev, pager, next" :total="total" :current-page="currentPage"
                       @current-change="handleCurrentChange"></el-pagination>
    </div>
</template>

<script>
    import blogApi from "../../../utils/blog/blogApi";

    export default {
        name: "study_doc_official_temp",
        data(){
            return {
                tag:'官方文档',
                total:2,
                pageSize:6,
                docList:[],
                keyCount:0,
                currentPage:1,
                checkboxGroup1: [],
                blogTagList:[],
                showPage:false
            }
        },
        filters:{
          ellipsis(value){
              if (!value) return '';
              if (value.length > 90) {
                  return value.slice(0,90) + '...'
              }
              return value

          }
        },
        mounted(){
            this.tag = this.$route.query.tag;
            if(this.tag=='官方文档')
                //200为官方文档，201为拓展阅读
                this.getBlogTags(200)
            else
                this.getBlogTags(201)
            this.getBlogListByTags(1,this.pageSize,this.checkboxGroup1,this.$route.query.tag)
        },
        watch:{
            $route:function (value) {
                this.tag = value.query.tag;
                this.checkboxGroup1=[]
                //200为官方文档，201为拓展阅读
                if(this.tag=='官方文档')
                    this.getBlogTags(200)
                else
                    this.getBlogTags(201)
                this.getBlogListByTags(1,this.pageSize,this.checkboxGroup1,this.$route.query.tag)
                this.showPage=false
            },
            checkboxGroup1:function(){
                this.getBlogListByTags(1,this.pageSize,this.checkboxGroup1,this.$route.query.tag)
            }
        },
        methods: {
            getBlogTags(type){
                blogApi.getBlogTags(type).then((res)=>{
                    if(res.data.success){
                        this.blogTagList=res.data.data.list
                    }
                })
            },
            getBlogListByTags(page,size,tagsList,tag){
                this.currentPage=page
                blogApi.getBlogListByTags(page,size,tagsList,tag).then((res)=>{
                    if(res.data.success){
                        this.docList = res.data.data.list;
                        this.total = res.data.data.total;
                        if(this.total>this.pageSize)
                            this.showPage=true
                    }
                })
            },
            docClick(blogId,title){
                this.$router.push({
                    path:`/ebpf/home/study/document/${blogId}`,
                    query: {
                        title: title,
                        tag:this.tag
                    }
                })
            },
            queryDocumentList(page,size,tag){
                this.$axios({
                    url:`/api/user/blog/list/${page}/${size}`,
                    method:'get',
                    params:{
                        tag:tag
                    }
                }).then((res)=>{
                    if (res.data.success){
                        this.docList = res.data.data.list;
                        this.total = res.data.data.total;
                    }
                })
            },
            handleCurrentChange(val){
                this.keyCount = (val-1) * this.pageSize;
                // this.queryDocumentList(val,this.pageSize,this.tag)
                this.getBlogListByTags(val,this.pageSize,this.checkboxGroup1,this.$route.query.tag)
            }
        }
    }
</script>

<style scoped>
    .official >>> .el-card__body{
        cursor: pointer;
        padding: 15px 20px !important;
    }
    .official >>> .el-checkbox__inner {
        border: none;
        width: 0;
    }
    .official >>>.el-checkbox{
        background-color: #E0E0E0;
        margin-right: 10px ;
        border: solid 1px #E0E0E0;
        padding: 3px 20px 0 3px ;
        border-radius: 15px;
        width:60px;
        height: 20px;
    }
    .official >>>.el-checkbox__input.is-checked+.el-checkbox__label{
        background-color:dodgerblue !important;
        border-radius: 20%;
        border: none;
        width: 68px;
        height: 22px;
        content:"";
        margin-left:-3px;
        margin-top:-5px;
        position:absolute;
        color: white;
        padding:3px 10px 0 10px ;
        z-index: 2000;
    }
</style>