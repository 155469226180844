<template>

    <a  v-on:click="goPage(item.url)"   style="text-decoration:none;color: black;margin-left:50px;" >
        <el-card shadow="never" style="height: 90px;"  >
            <el-row>
                <el-col >
                    <div v-for="(item2,index2) in tagLists" :key="index2" style="float:left;">
                        <br>
                        <el-tag disable-transitions style="float:left;background-color: #BBE8EE;color:black;width:70px;height: 28px;text-align: center;" class="my-tag" v-if="item2.tagName==='任务'"  >{{item2.tagName}}</el-tag>
                        <el-tag disable-transitions style="float:left;background-color: #D2D2FF;color:black;width:70px;height: 28px;text-align: center;" class="my-tag" v-else-if="item2.tagName==='视频'"  >{{item2.tagName}}</el-tag>
                        <el-tag disable-transitions  style="float:left;background-color: #D9E9FF;color:black;width:70px;height: 28px;text-align: center;" class="my-tag"  v-else-if="item2.tagName==='文章'" >{{item2.tagName}}</el-tag>

                    </div>
                    <el-tag disable-transitions  style="float:left;background-color: #9DE7FF;color:black;width:70px;height: 28px;text-align: center;margin-top: 14px;" class="my-tag"  v-if="item.categoryId===106" >链接</el-tag>
                    <el-tag disable-transitions  style="float:left;background-color: #31B9CB;color:black;width:70px;height: 28px;text-align: center;margin-top: 14px;" class="my-tag"  v-if="item.categoryId===110" >项目</el-tag>
                    <h3 style="float:left;margin-left:5px;" >{{item.title}}</h3>
<!--                    <div style="margin-top: 20px;" v-if="item.categoryId===102||item.categoryId===106" >{{item.url}}</div>-->
<!--                    <div style="margin-top: 20px;color:white;" v-else >{{item.url}}</div>-->
                    <p style="float: right;margin-right: 200px;" >{{dateFormat(item.createdDate)}}</p>
                </el-col>
            </el-row>
        </el-card>
    </a>

</template>

<script>
    import personalQuery from "@/utils/personalCenter/personalQuery";
    import common from "@/utils/common";
    import fileApi from "../../../utils/task/fileApi";

    export default {
        name: "historyItem",
        props:{
            item:Object
        },
        data(){
            return {
                tagLists:[]
            }
        },
        watch:{
            item:function () {
                this.getHitsorytags(this.item.relatedId)
            }
        },
        mounted() {
            this.getHitsorytags(this.item.relatedId)

        },
        methods:{
            goPage(url){
                if(url.includes("ebpf")){
                    window.open(url)
                }else {
                    fileApi.goPage(url, this.$router)
                }
            },
            dateFormat(date){
                return common.dateFormat(date,"yyyy-MM-dd hh:mm:ss")
            },
            getHitsorytags(relatedId){
                personalQuery.getHistorytags(relatedId).then((res)=>{
                    if(res.data.success){
                        this.tagLists=res.data.data.list
                    }

                })
            }
        }
    }
</script>

<style scoped>

</style>