<template>
    <div class="bg">
        <div style="top:6% ;left: 39.8%;position: absolute;">
           <i class="bi bi-folder2-open" style="font-size: 30px;margin-right: 120px;"></i>
            <h2 style="margin-top: -33px;margin-left:50px;" v-if="editFlag===false">上传资料</h2>
            <h2 style="margin-top: -30px;margin-left: 40px;" v-else>编辑</h2>
        </div>
        <el-form label-position="right" label-width="100px" :model="uploadDataInfo"   :rules="rules" ref="ruleForm" style="top:17.4%;left:5.8%;position:absolute;">
            <el-form-item     prop="title">
                <span class="font" slot="label" >链接名称</span>
                <el-input style="width: 450px;height: 36px;float:left;" v-model="uploadDataInfo.title"></el-input>
            </el-form-item>
            <el-form-item   style=" padding: 10px 0px;" prop="summary">
                <span class="font" slot="label" >内容描述</span>
                <el-input type="textarea" style="width: 450px;float:left;" :rows="3" v-model="uploadDataInfo.summary"></el-input>
            </el-form-item>
            <el-form-item  style=" padding: 0px 0px;" >
                <span class="font" slot="label">方向标签</span>
                <el-col :span="5">
                  <el-input style="width: 100px;float: left;" v-model="inputValue" v-if="inputVisible" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm"></el-input>
                  <el-button v-else  style="width: 132px;height: 37px;float:left;"  @click="showInput">
                    <span>+ 添加文章标签</span>
                  </el-button>
                </el-col>
                <el-col :span="17" style="margin-left: 140px;margin-top: -37px;">
                  <el-tag :key="index" v-for="(tag,index) in tableData" closable class="sensitive-word" style="margin-right:5px; "
                        :disable-transitions="false" @close="handleClose(tag,index)" >
                    {{tag}}
                  </el-tag>
                </el-col>
            </el-form-item>
            <el-form-item  style=" padding: 0px 0px;" prop="type">
                <span class="font" slot="label">链接类别</span>
                <el-radio-group style="float: left;margin-top: 12px;" v-model="uploadDataInfo.type">
                    <el-radio :label="1">文章</el-radio>
                    <el-radio :label="2">视频</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item >
                <el-button  v-if="editFlag===false" style="width: 140px;height: 40px;top:86.4%;left:25.6%;color:#FFFFFF ;background-color: #2696BB;position: absolute;"  @click="insertDMFiles('ruleForm')">上传</el-button>
                <el-button  v-else style="width: 140px;height: 40px;top:86.4%;left:25.6%;color:#FFFFFF ;background-color: #2696BB;position: absolute;"  @click="updateDMFiles('ruleForm')">确定</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import fileApi from "../../../utils/task/fileApi";
    export default {
        name: "uploadData",
        props:{
          link:String,
          editFlag:Boolean,
          item:Object,
          taskId:String
        },
        data(){
            let typeCheck = (rule, value, callback) => {
            if (value === "") {
                return callback(new Error("请选择类型"));
            } else {
                callback();
            }
        };
            let titleCheck = (rule, value, callback) => {
                if (value === "") {
                    return callback(new Error("请输入标题"));
                } else {
                    callback();
                }
            };
            let summaryCheck = (rule, value, callback) => {
                if (value === "") {
                    return callback(new Error("请输入摘要"));
                } else {
                    callback();
                }
            };

            return{
                tagsList:[],
                tableData:[],
                tagsTmp:[],
                inputValue:[],
                uploadDataInfo:{
                    "title":"",
                    "summary":"",
                    "type":""
                },
                inputVisible:false,
                rules: {
                    type: [
                        {required: true, validator: typeCheck, trigger: 'blur'}
                    ],
                    title: [
                        {required: true, validator: titleCheck, trigger: 'blur'}
                    ],
                    summary: [
                        {required: true, validator: summaryCheck, trigger: 'blur'}
                    ]
                }
            }
        },
        watch:{
          link:function () {
                this.getLinkTitle()
                //清空弹窗内容
                this.uploadDataInfo={"title":"", "summary":"", "type":""}
                this.tableData=[]
          }
        },
        beforeMount() {
            if(this.editFlag==true){
                this.uploadDataInfo=this.item
                this.getTags()
            }
        },
        mounted() {
            this.getLinkTitle()

            },
        methods:{
            handleClose(tag,index){
                this.tableData.splice(index,1)
            },
            getTags(){
              fileApi.getTags(this.item.id).then((res)=>{
                  if(res.data.success) {
                      this.tagsList=res.data.data.list
                      this.tagsTmp = res.data.data.list
                      for(var i=0;i<this.tagsTmp.length;i++) {
                          if(this.tagsTmp[i].tagName=='文章')
                              this.uploadDataInfo.type=1
                          else if(this.tagsTmp[i].tagName=='视频')
                              this.uploadDataInfo.type=2
                          if(this.tagsTmp[i].tagName!="文章"&&this.tagsTmp[i].tagName!="视频"){
                              this.tableData.push(this.tagsTmp[i].tagName)}
                      }
                  }
              })
            },
            insertDMFiles(formName){
               this.$refs[formName].validate((valid)=>{
                   if(valid){
                       this.tagsList=[]
                       for(var i=0;i<this.tableData.length;i++)
                       {
                           this.tagsList.push(this.tableData[i])
                       }
                       if(this.uploadDataInfo.type==1) {
                           // this.tagsList=this.tableData
                           this.tagsList.push("文章")
                       }else {
                           this.tagsList.push("视频")
                       }
                       fileApi.saveLinkWithTitle(this.link,this.taskId,this.tagsList,this.uploadDataInfo.title
                           ,this.uploadDataInfo.summary).then((res)=>{
                           if(res.data.success){
                               this.$emit("refreshList")
                               this.$emit("closeLogin")
                               this.$message("成功！")
                           }
                           else{
                               this.$message("本站资料中已有该网址")
                           }
                       })
                   }else{
                       console.log("error submit")
                   }
               })
            },
            updateDMFiles(formName){
                this.$refs[formName].validate((valid)=>{
                    if(valid){
                        this.tagsList=[]
                        for(var i=0;i<this.tableData.length;i++)
                        {
                            this.tagsList.push(this.tableData[i])
                        }
                        if(this.uploadDataInfo.type==1) {
                            // this.tagsList=this.tableData
                            this.tagsList.push("文章")
                        }else {
                            this.tagsList.push("视频")
                        }
                        var data={
                                relatedId:this.item.id,
                                url:this.link,
                                title:this.item.title,
                                tag:this.tagsList,
                                summary:this.uploadDataInfo.summary
                        }
                        fileApi.updateLinkWithTitle(data).then((res)=>{
                            if(res.data.success){
                                this.$emit("refreshList")
                                this.$emit("closeLogin")
                                this.$message("成功！")
                            }
                            else{
                                this.$message("本站资料中已有该网址")
                            }
                        })
                    }else{
                        console.log("error submit")
                    }
                })
            },
            getLinkTitle(){
                fileApi.getLinkTitle(this.link).then((res)=>{
                    if(res.data.success){
                        this.uploadDataInfo.title=res.data.data.title
                    }
                })
            },
            handleInputConfirm(){
                let inputValue = this.inputValue;
                if (inputValue) {
                    this.tableData.push(inputValue);
                }
                this.inputVisible = false;
                this.inputValue = '';
            },
            showInput(){
              this.inputVisible=true;
            }

        }

    }
</script>

<style scoped>
.font{
    font-size: 16px;
    color: #848383;
}
.el-form-item{
    margin-bottom: 5px;
}
.bg{
    width: 645px;
    height: 387px;
    background-color: #FBFEFF;
    top: 11.7%;
    left: 28.2%;
    position: relative;
    border-radius: 10px 10px 10px 10px;
}
</style>