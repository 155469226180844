<template>
        <el-container>
            <el-main class="center">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/personalCenter/helpCenter' }">帮助中心</el-breadcrumb-item>
                    <el-breadcrumb-item>关于我们</el-breadcrumb-item>
                </el-breadcrumb>
                <h1 style="text-align: left">关于我们</h1>
                <el-row>
                    <el-col :span="2">
                        <img src="~@/assets/img/logo.svg" style="width:135px;height:135px;margin-top: -10px;">
                    </el-col>
                    <el-col :span="19">
                        <el-card shadow="never" style="text-align: left;margin-left: 35px;line-height: 25px;">
                            &nbsp;&nbsp;&nbsp;&nbsp;嗨！欢迎来到波粒派（www.bolipi.com）<br>&nbsp;&nbsp;&nbsp;&nbsp;bolipi是一个免费的学习、交流、实践空间<br>&nbsp;&nbsp;&nbsp;&nbsp;在使用网站的过程中，如有问题请发送至邮箱kuangqixinxi@126.com
                        </el-card>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
</template>

<script>
    export default {
        name: "aboutus"
    }
</script>

<style scoped>

</style>